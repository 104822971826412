import { SystemStyleObject } from '@chakra-ui/react';

type VariantStyle = 'checked' | 'unchecked' | 'variance';

export const pillVariants = [
  'default',
  'main',
  'warn',
  'simple',
  'star',
  'display',
] as const;
export type PillVariant = (typeof pillVariants)[number];

type PillStyleType = Record<
  PillVariant,
  Record<
    VariantStyle,
    {
      color?: string;
      bgColor?: string;
      cursor?: string;
      borderColor?: string;
      icons?: {
        main: {
          color: string;
          fill?: string;
        };
        cross?: {
          color: string;
          fill?: string;
        };
      };
      _hover?: SystemStyleObject;
      _focusWithin?: SystemStyleObject;
    }
  >
>;

const defaultStyle = {
  checked: {
    color: 'primary.600',
    bgColor: 'primary.25',
    borderColor: 'primary.600',
    icons: {
      main: {
        color: 'primary.600',
        fill: undefined,
      },
      cross: {
        color: 'primary.700',
        fill: undefined,
      },
    },
    _hover: {
      borderColor: 'primary.700',
    },
    _focusWithin: {
      borderColor: undefined,
    },
  },
  unchecked: {
    color: 'rythm.300',
    bgColor: 'transparent',
    borderColor: 'rythm.300',
    _hover: {
      borderColor: 'rythm.600',
    },
    _focusWithin: {
      borderColor: 'rythm.600',
    },
  },
  variance: {},
};

export const pillStyles: PillStyleType = {
  default: defaultStyle,
  simple: defaultStyle,
  main: {
    checked: {
      bgColor: 'success.200',
      borderColor: 'success.700',
      icons: {
        main: {
          color: 'success.700',
          fill: undefined,
        },
        cross: {
          color: 'success.700',
          fill: undefined,
        },
      },
      _hover: {
        bgColor: 'success.200',
      },
      _focusWithin: {
        borderColor: undefined,
      },
    },
    unchecked: {
      bgColor: 'transparent',
      borderColor: 'success.700',
      _hover: {
        bgColor: 'success.200',
      },
      _focusWithin: {
        bgColor: 'success.200',
      },
    },
    variance: {},
  },
  star: {
    checked: {
      bgColor: 'decorative.gold.100',
      borderColor: 'decorative.gold.700',
      icons: {
        main: {
          color: 'decorative.gold.700',
          fill: undefined,
        },
        cross: {
          color: 'decorative.gold.700',
          fill: undefined,
        },
      },
      _hover: {
        bgColor: 'decorative.gold.200',
      },
      _focusWithin: {
        borderColor: undefined,
      },
    },
    unchecked: {
      color: 'rythm.300',
      bgColor: 'transparent',
      borderColor: 'rythm.300',
      _hover: {
        borderColor: 'rythm.600',
      },
      _focusWithin: {
        borderColor: 'rythm.600',
      },
    },
    variance: {},
  },
  warn: {
    checked: {
      icons: {
        main: {
          color: 'critical.500',
          fill: 'critical.100',
        },
      },
    },
    unchecked: {
      icons: {
        main: {
          color: 'critical.500',
          fill: 'critical.100',
        },
      },
    },
    variance: {
      borderColor: 'critical.500',
      cursor: 'not-allowed',
      _hover: undefined,
      _focusWithin: undefined,
    },
  },
  display: {
    ...defaultStyle,
    variance: {
      cursor: 'default',
      bgColor: 'white',
      color: 'rythm.700',
      borderColor: 'rythm.300',
      _hover: undefined,
      _focusWithin: undefined,
    },
  },
};
