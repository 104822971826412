import { Textarea, TextareaProps } from '@chakra-ui/react';
import { forwardRef } from 'react';

import { CharsLeftCounter } from './chars-left-counter/chars-left-counter';

export type TextAreaProps = TextareaProps;

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  function TextArea(props, ref) {
    const { value, maxLength } = props;

    return (
      <>
        <Textarea ref={ref} {...props} />
        {!!maxLength && (
          <CharsLeftCounter maxLength={maxLength} value={value} />
        )}
      </>
    );
  }
);
