import {
  forwardRef,
  Heading as ChakraHeading,
  HeadingProps as ChakraHeadingProps,
  ResponsiveValue,
} from '@chakra-ui/react';
import { LegacyRef } from 'react';

import { HeadingVariant } from '../../themes/components/heading';

export type HeadingProps = Omit<ChakraHeadingProps, 'size'> & {
  variant?: ResponsiveValue<HeadingVariant>;
};

export const Heading = forwardRef<HeadingProps, 'h2'>((props, ref) => (
  <ChakraHeading ref={ref as LegacyRef<HTMLHeadingElement>} {...props} />
));
