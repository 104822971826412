import {
  Checkbox as ChakraCheckbox,
  CheckboxProps,
  Icon,
} from '@chakra-ui/react';
export type {
  CheckboxGroupContext,
  CheckboxGroupProps,
  CheckboxProps,
  CheckboxState,
  UseCheckboxGroupProps,
  UseCheckboxGroupReturn,
  UseCheckboxProps,
  UseCheckboxReturn,
} from '@chakra-ui/react';
export { CheckboxGroup, useCheckbox, useCheckboxGroup } from '@chakra-ui/react';

const CustomIcon = () => {
  return (
    <Icon viewBox="0 0 12 9" w="12px" h="9px">
      <path
        fill="none"
        stroke="currentColor"
        d="M11.1818 1L4.18182 8L1 4.81818"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

export const Checkbox = ({ children, ...rest }: CheckboxProps) => {
  return (
    <ChakraCheckbox icon={<CustomIcon />} {...rest}>
      {children}
    </ChakraCheckbox>
  );
};
