import '@fontsource/inter/100.css';
import '@fontsource/inter/200.css';
import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import '@fontsource/inter/800.css';
import '@fontsource/inter/900.css';
import '@fontsource/lexend/100.css';
import '@fontsource/lexend/200.css';
import '@fontsource/lexend/300.css';
import '@fontsource/lexend/400.css';
import '@fontsource/lexend/500.css';
import '@fontsource/lexend/600.css';
import '@fontsource/lexend/700.css';
import '@fontsource/lexend/800.css';
import '@fontsource/lexend/900.css';

import { css } from '@emotion/react';

export const space = {
  4.5: '1.125rem',
  5.5: '1.375rem',
  6.5: '1.875rem',
  13: '3.25rem',
  18: '4.5rem',
  31: '7.75rem',
  52: '13rem',
};

export const breakpoints = {
  xs: '320px',
  sm: '480px',
  md: '768px',
  lg: '1024px',
  xl: '1200px',
  '2xl': '1536px',
  '3xl': '1728px',
  '4xl': '1920px',
};

export type Breakpoint = keyof typeof breakpoints;

export const shadows = {
  // Defined in figma https://www.figma.com/file/hybey61GMfVfHuhKZRh6zL/%F0%9F%93%B1%5BDS%5D---Layouts-%26-Assets?type=design&node-id=3901-112&mode=design&t=XU0hS1tRCz81LZf6-4
  // ----------
  // rgba(52, 38, 229, 0.24) = primary.600 / 24% opacity
  // rgba(161, 155, 243, 0.12) = primary.300 / 12% opacity
  button:
    '1px 1px 6px 0px rgba(52, 38, 229, 0.24), 1px 4px 4px 0px rgba(161, 155, 243, 0.12)',
  // rgba(236, 236, 239, 0.5) = rythm.200 / 50% opacity
  sm: '1px 1px 10px rgba(236, 236, 239, 0.5)',
  // rgba(153, 151, 180, 0.16) = rythm.600 / 16% opacity
  // rgba(17, 16, 86, 0.12) = primary.800 / 12% opacity
  md: '1px 12px 18px rgba(153, 151, 180, 0.16), 2px 2px 8px rgba(17, 16, 86, 0.12)',
  // Used for the outlines on focus
  outline: {
    1: '0 0 0 1px var(--chakra-colors-primary-300)',
    3: '0 0 0 1px var(--chakra-colors-white), 0 0 0 3px var(--chakra-colors-primary-300)',
  },
};

export const GlobalStyles = css`
  /*
    This will hide the focus indicator if the element receives focus via the mouse,
    but it will still show up on keyboard focus.
  */
  .js-focus-visible :focus:not(.focus-visible) {
    outline: none;
  }
  .js-focus-visible :focus:not(.focus-visible) + span {
    outline: none;
  }
  [data-js-focus-visible] :focus:not([data-focus-visible-added]) {
    outline: none;
  }

  #chakra-toast-manager-bottom-left {
    margin-left: 2.5rem;
    margin-bottom: 2.5rem;
  }
`;
