import FontFaceObserver from 'fontfaceobserver';
import { useEffect, useState } from 'react';

const IsomorphicFontFaceObserver =
  typeof window !== 'undefined'
    ? FontFaceObserver
    : class {
        load() {
          return Promise.resolve();
        }
      };

export const useFontFaceObserver = (fontFamily: string) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const fontObserver = new IsomorphicFontFaceObserver(fontFamily);

    fontObserver
      .load()
      .then(() => {
        setIsLoaded(true);
      })
      .catch(() => {});
  }, [fontFamily]);

  return isLoaded;
};
