import styled from '@emotion/styled';
import { forwardRef, useState } from 'react';

import { IconEyeDisplayed, IconEyeHidden } from '../icon/icon';
import { TextInput, TextInputProps } from './text-input';

const Toggle = styled.span`
  display: inline-flex;
  cursor: pointer;
`;

export type PasswordInputProps = TextInputProps;

export const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(
  function PasswordInput(props, ref) {
    const [show, setShow] = useState(false);
    const togglePassword = () => {
      setShow(!show);
    };
    return (
      <TextInput
        ref={ref}
        type={show ? 'text' : 'password'}
        rightElement={
          <Toggle onClick={togglePassword} data-testid="toggle-show-password">
            {show ? (
              <IconEyeDisplayed
                color="rythm.600"
                _hover={{ color: 'rythm.900' }}
              />
            ) : (
              <IconEyeHidden
                color="rythm.600"
                _hover={{ color: 'rythm.900' }}
              />
            )}
          </Toggle>
        }
        rightElementStyle={{
          pointerEvents: 'initial',
        }}
        {...props}
      />
    );
  }
);
