import { useMergeRefs } from '@chakra-ui/react';
import { ChangeEventHandler, FocusEventHandler, forwardRef } from 'react';
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  TextArea,
  TextAreaProps,
} from '../../..';

type FieldTextAreaProps = TextAreaProps & {
  label?: React.ReactNode;
  info?: React.ReactNode;
};

// This component is controlled even though it is rather simple, in order to make the CharCounter work
export const FieldTextAreaInner = <
  FormFieldValues extends FieldValues = FieldValues,
  FieldName extends FieldPath<FormFieldValues> = FieldPath<FormFieldValues>
>(
  {
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
    label,
    info,
    ...rest
  }: UseControllerProps<FormFieldValues, FieldName> & FieldTextAreaProps,
  forwardedRef: React.ForwardedRef<HTMLTextAreaElement>
) => {
  const {
    field: { ref: controllerRef, ...fieldRest },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
  });
  const refs = useMergeRefs(controllerRef, forwardedRef);

  const onChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    fieldRest.onChange(e);
    rest.onChange?.(e);
  };
  const onBlur: FocusEventHandler<HTMLTextAreaElement> = (e) => {
    fieldRest.onBlur();
    rest.onBlur?.(e);
  };

  return (
    <FormControl isInvalid={!!error}>
      {!!label && <FormLabel variant="simple">{label}</FormLabel>}
      <TextArea
        ref={refs}
        {...fieldRest}
        {...rest}
        onChange={onChange}
        onBlur={onBlur}
      />
      {error?.message && <FormErrorMessage>{error.message}</FormErrorMessage>}
      {!!info && <FormHelperText>{info}</FormHelperText>}
    </FormControl>
  );
};

export const FieldTextArea = forwardRef(FieldTextAreaInner);
