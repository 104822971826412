import {
  TelephoneInput,
  TelephoneInputProps,
  TelephoneInputValue,
} from '../../inputs';
import { withField } from '../with-field';

export const FieldTelephoneInputLegacy = withField<
  TelephoneInputProps,
  TelephoneInputValue,
  TelephoneInputValue
>(TelephoneInput, {
  isEqual: (a: TelephoneInputValue, b: TelephoneInputValue) =>
    a?.country === b?.country && a?.telephone === b?.telephone,
});
