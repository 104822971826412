import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

import { SelectDropdown, SelectDropdownProps } from '../../inputs';
import { FormControl } from '../form-control';
import { FormErrorMessage } from '../form-error-message';
import { FormLabel } from '../form-label';

export type FieldSelectDropdownProps = {
  label?: string;
} & SelectDropdownProps<string, string>;

export const FieldSelectDropdown = <
  FormFieldValues extends FieldValues = FieldValues,
  FieldName extends FieldPath<FormFieldValues> = FieldPath<FormFieldValues>
>({
  label,
  name,
  control,
  rules,
  shouldUnregister,
  defaultValue,
  ...rest
}: UseControllerProps<FormFieldValues, FieldName> &
  FieldSelectDropdownProps) => {
  const {
    field: { ref, ...field },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
  });

  const onChange: SelectDropdownProps<string, string>['onChange'] = (e) => {
    field.onChange(e);
    rest.onChange?.(e);
  };

  return (
    <FormControl isInvalid={!!error}>
      {label && <FormLabel variant="simple">{label}</FormLabel>}
      <SelectDropdown {...field} {...rest} onChange={onChange} />
      {error?.message && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};
