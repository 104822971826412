const baseStyle = {
  button: {
    color: 'rythm.600',
    _hover: {
      color: 'rythm.900',
    },
    _active: {
      color: 'rythm.900',
    },
  },
  list: {
    boxShadow: 'lg',
    border: '1px solid',
    borderColor: 'rythm.200',
    borderRadius: '12px',
    // fix box-shadow not displayed with js-focus-visible
    '.js-focus-visible &:focus:not(.focus-visible)': {
      boxShadow: 'lg',
    },
  },
  item: {
    height: 10,
    _hover: {
      bg: 'primary.1',
    },
    _focus: {
      bg: 'primary.1',
    },
  },
  divider: {
    margin: '2px 0',
    borderColor: 'rythm.300',
  },
};

const variants = {
  borderlessDropdown: {
    list: {
      boxShadow: 'md',
      border: 'none',
      // fix box-shadow not displayed with js-focus-visible
      '.js-focus-visible &:focus:not(.focus-visible)': {
        boxShadow: 'md',
      },
    },
  },
};

export type MenuVariant = keyof typeof variants;

export default {
  baseStyle,
  variants,
};
