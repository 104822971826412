import { ReactNode } from 'react';
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

import { RadioBoxes, RadioBoxesProps, RadioGroup } from '../../inputs';
import { FormControl } from '../form-control';
import { FormErrorMessage } from '../form-error-message';
import { FormLabel } from '../form-label';

export type FieldRadioBoxesProps = {
  label?: ReactNode;
  shouldDisplayErrorMessage?: boolean;
} & RadioBoxesProps<string>;

export const FieldRadioBoxes = <
  FormFieldValues extends FieldValues = FieldValues,
  FieldName extends FieldPath<FormFieldValues> = FieldPath<FormFieldValues>
>({
  label,
  name,
  control,
  rules,
  shouldUnregister,
  defaultValue,
  shouldDisplayErrorMessage = true,
  ...rest
}: UseControllerProps<FormFieldValues, FieldName> & FieldRadioBoxesProps) => {
  const {
    field: { ref, ...field },
    fieldState: { error, invalid },
  } = useController({
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
  });

  const onChange: RadioBoxesProps<string>['onChange'] = (e: string) => {
    field.onChange(e);
    rest.onChange?.(e);
  };

  return (
    <FormControl isInvalid={!!error}>
      {!!label && <FormLabel variant="simple">{label}</FormLabel>}
      <RadioGroup name={field.name}>
        <RadioBoxes
          {...field}
          isInvalid={invalid}
          {...rest}
          onChange={onChange}
        />
      </RadioGroup>
      {shouldDisplayErrorMessage && error?.message && (
        <FormErrorMessage>{error.message}</FormErrorMessage>
      )}
    </FormControl>
  );
};
