import * as utilsDate from '@collective/utils/date';
import dayjs from 'dayjs';

export const generateUtcDate = (date?: Date) => {
  return utilsDate.dayjs
    .utc(date ?? new Date())
    .set('hour', 0)
    .set('minute', 0)
    .set('second', 0)
    .set('millisecond', 0)
    .toDate();
};

export const getYearAndMonthDifference = (
  startDate?: string | Date | null,
  endDate?: string | Date | null
): { years: number; months: number } | null => {
  if (!startDate) {
    return null;
  }

  const start = dayjs(startDate);
  const end = endDate ? dayjs(endDate) : dayjs();

  if (end.isBefore(start)) {
    return null;
  }

  const diff = dayjs(end).diff(start, 'months') + 1;

  const years = Math.floor(diff / 12);
  const months = diff % 12;

  return { years, months };
};
