import {
  FormControl,
  FormControlOptions,
  FormControlProps,
  FormHelperText,
  FormLabel,
} from '@chakra-ui/react';
import { ChangeEvent, JSXElementConstructor, ReactNode } from 'react';
import {
  Field,
  FieldProps,
  FieldRenderProps,
  UseFieldConfig,
} from 'react-final-form';

import { IconInfo } from '../icon/icon';
import { Tooltip } from '../tooltip/tooltip';

/**
 * Props from FinalForm Field we want to expose in our wrapper
 */
type ExposedFieldProps<ValueType> = Pick<
  FieldProps<ValueType, FieldRenderProps<ValueType>>,
  'name' | 'validate' | 'defaultValue' | 'initialValue'
>;

/**
 * Props from WrappedComponent we want to expose in our wrapper
 */
type WrappedComponentProps<ComponentPropsType, ChangeEventType> =
  ComponentPropsType & {
    label?: ReactNode;
    labelTooltip?: string;
    onChange?: (evt: ChangeEventType) => void;
    info?: ReactNode;
    width?: string;
    innerRef?: React.RefObject<HTMLElement> | null;
    formControlProps?: FormControlProps;
    allowEmpty?: boolean;
  };

/**
 * Merged props of ExposedFieldProps and WrappedComponentProps
 */
export type WithFieldProps<
  ComponentPropsType,
  ValueType = string,
  ChangeEventType = ChangeEvent<HTMLInputElement>
> = WrappedComponentProps<ComponentPropsType, ChangeEventType> &
  ExposedFieldProps<ValueType>;

/**
 * Interface the Wrapped Component should implement
 */
type WrappedComponentType<ChangeEventType> = FormControlOptions & {
  onChange?: (evt: ChangeEventType) => void;
  isChecked?: boolean;
};

/**
 * Props used to customize the behavior of the field
 */
type CustomFieldConfig<ValueType> = UseFieldConfig<ValueType> & {
  // This is used to set the name of the prop that sets the error state on the WrappedComponent
  // In Chakra, most of those fields on Form inputs are named 'isInvalid'
  propErrorDisplay?: string;
  hasFieldDisplayErrorMessage?: boolean;
};

export function withField<
  ComponentPropsType,
  ValueType = string,
  ChangeEventType = ChangeEvent<HTMLInputElement>
>(
  WrappedComponent: JSXElementConstructor<
    WrappedComponentType<ChangeEventType>
  >,
  fieldConfig?: CustomFieldConfig<ValueType>
) {
  return function WithField({
    name,
    validate,
    initialValue,
    onChange,
    label,
    labelTooltip,
    info,
    width,
    innerRef,
    formControlProps,
    allowEmpty = true,
    ...rest
  }: WithFieldProps<ComponentPropsType, ValueType, ChangeEventType>) {
    const {
      validate: fieldValidate,
      propErrorDisplay = 'isInvalid',
      hasFieldDisplayErrorMessage,
      ...restField
    } = fieldConfig || {};
    return (
      <Field
        name={name}
        validate={(...args) => {
          if (fieldValidate) {
            const result = fieldValidate(...args);
            if (result) {
              return result;
            }
          }
          return validate && validate(...args);
        }}
        initialValue={initialValue}
        // https://github.com/final-form/react-final-form/issues/130
        {...(allowEmpty ? { parse: (value) => value } : {})}
        {...restField}
      >
        {({ input, meta }) => {
          // We can pass meta.error === '' to trigger input error state
          // without error message
          const hasError =
            meta.error !== undefined || meta.submitError !== undefined;
          const shouldDisplayError = meta.touched && hasError;

          return (
            <FormControl id={name} width={width} {...formControlProps}>
              {!!label && (
                <FormLabel
                  fontSize="xs"
                  mb={1}
                  display="flex"
                  alignItems="center"
                  gridGap={1}
                  lineHeight={4}
                >
                  {label}
                  {labelTooltip && (
                    <Tooltip label={labelTooltip}>
                      <IconInfo size="xs" color="rythm.600" />
                    </Tooltip>
                  )}
                </FormLabel>
              )}
              <WrappedComponent
                backgroundColor="transparent"
                ref={innerRef}
                {...input}
                {...rest}
                // Connect the Final-Form checked value to isChecked prop
                {...(input.checked !== undefined && {
                  isChecked: input.checked,
                })}
                onChange={(evt) => {
                  // Final Form Field onChange
                  input.onChange(evt);

                  // Custom onChange
                  onChange && onChange(evt);
                }}
                {...(propErrorDisplay && {
                  [propErrorDisplay]: shouldDisplayError,
                })}
              />
              {hasFieldDisplayErrorMessage && shouldDisplayError && (
                <FormHelperText
                  fontSize="xs"
                  lineHeight={4}
                  color="critical.500"
                >
                  {meta.error || meta.submitError}
                </FormHelperText>
              )}
              {!!info && (
                <FormHelperText fontSize="xs" lineHeight={4} color="rythm.700">
                  {info}
                </FormHelperText>
              )}
            </FormControl>
          );
        }}
      </Field>
    );
  };
}
