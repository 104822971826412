import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ChangeEvent, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { styledIgnoredProps } from '../../utils';
import { TextInput, TextInputProps } from './text-input';

export interface AmountInputLegacyProps
  extends Omit<TextInputProps, 'value' | 'onChange'> {
  // Working with string instead of number, to manage intermediate non-valid number values (ex: '123.')
  value?: string;
  isDisplayOnly?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const StyledTextInput = styled(
  TextInput,
  styledIgnoredProps(['isDisplayOnly'])
)<{
  isDisplayOnly?: boolean;
}>`
  ${(p) =>
    p.isDisabled
      ? 'input[disabled]{ background: var(--chakra-colors-primary-1); }'
      : 'input[disabled]{ background: #fff; }'}
  ${(p) =>
    p.isDisplayOnly &&
    p.value &&
    p.value !== '0' &&
    css`
      &&& > * {
        opacity: 1;
      }
    `};
`;

// This is the legacy component for what used to be AmountInput, that got renamed CurrencyInput
// Ideally this should be removed and its usage replaced by CurrencyInput
export const AmountInputLegacy = forwardRef<
  HTMLInputElement,
  AmountInputLegacyProps
>(function AmountInputLegacy(
  { onChange, isDisplayOnly, isDisabled, ...rest },
  ref
) {
  const { t } = useTranslation('common');

  return (
    <StyledTextInput
      onChange={onChange}
      {...rest}
      isDisabled={isDisabled || isDisplayOnly}
      isDisplayOnly={isDisplayOnly}
      min={0}
      step={0.01}
      type="number"
      leftElement="€"
      isLeftElementWrapped
      ref={ref}
      placeholder={rest.placeholder || t('ui.amountInput.placeholder')}
    />
  );
});
