import {
  IconButton as ChakraIconButton,
  IconButtonProps as ChakraIconButtonProps,
  ResponsiveValue,
  useBreakpointValue,
} from '@chakra-ui/react';
import { cloneElement, forwardRef } from 'react';

import {
  ButtonSize,
  ButtonVariant,
  IconButtonSize,
} from '../../themes/components/button';
import { IconSize } from '../icon/icon';

export interface IconButtonProps extends ChakraIconButtonProps {
  size?: ResponsiveValue<ButtonSize>;
  variant?: ButtonVariant;
  // use 'isDisabled' instead. just using 'disabled' does not work as before https://github.com/chakra-ui/chakra-ui/issues/7243#issuecomment-1383939865
  disabled?: never;
}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  function IconButton({ size = 'md', icon, ...rest }, ref) {
    const iconButtonSizeMapping: Record<ButtonSize, IconButtonSize> = {
      sm: 'iconSm',
      md: 'iconMd',
      lg: 'iconLg',
    };

    const iconSizeMapping: Record<ButtonSize, IconSize> = {
      sm: 'xs',
      md: 'md',
      lg: 'md',
    };

    const currentSize = useCurrentSize(size);

    return (
      <ChakraIconButton
        ref={ref}
        size={iconButtonSizeMapping[currentSize] ?? size}
        minW="auto"
        icon={
          icon && !icon.props.size
            ? cloneElement(icon, {
                size: iconSizeMapping[currentSize],
              })
            : icon
        }
        {...rest}
      />
    );
  }
);

const useCurrentSize = (size: IconButtonProps['size']): ButtonSize => {
  const value = useBreakpointValue(
    typeof size === 'object' ? size : { base: size }
  );

  return value || 'md';
};
