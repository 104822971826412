import {
  cloneElement,
  ReactElement,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useBoolean } from '../../hooks/use-boolean';
import { RichTextEditorView } from '../inputs/rich-text-editor/rich-text-editor-view';
import { Box } from '../layout';
import { Text } from '../typography/text';

const EXPAND_DURATION = 250;

// useLayoutEffect to avoid flickering ({elementToDisplayWhenExpandable} appearing/disappearing)
// while doing computation on the layout
const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect;

type WrapperExandableProps = {
  callback?: (isExpanded: boolean) => void;
  numberOfLinesBeforeExpand: number;
  isHTML: boolean;
  content: string;
  elementToDisplayWhenExpandable?: ReactElement;
};

export function WrapperExpandable({
  callback,
  numberOfLinesBeforeExpand,
  isHTML,
  content,
  elementToDisplayWhenExpandable,
}: WrapperExandableProps) {
  // line height from rich text is 21, normal text is 20
  const lineHeight = isHTML ? 21 : 20;

  const parentRef = useRef<HTMLDivElement>(null);
  const childrenRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(
    `${lineHeight * numberOfLinesBeforeExpand}px`
  );
  const [isExpanded, setIsExpanded] = useBoolean(false);

  const expandWrapper = useCallback(() => {
    if (!childrenRef.current) {
      return;
    }

    const { height: childHeight } = childrenRef.current.getBoundingClientRect();
    setHeight(`${childHeight}px`);
    setIsExpanded.on();

    setTimeout(() => {
      setHeight('fit-content');
    }, EXPAND_DURATION + 50);
  }, [setIsExpanded]);

  const _elementToDisplayWhenExpandable = useMemo(
    () =>
      elementToDisplayWhenExpandable && !isExpanded
        ? cloneElement(elementToDisplayWhenExpandable, {
            onClick: expandWrapper,
          })
        : null,
    [elementToDisplayWhenExpandable, isExpanded, expandWrapper]
  );

  useIsomorphicLayoutEffect(() => {
    if (parentRef.current && childrenRef.current) {
      const { height: parentHeight } =
        parentRef.current.getBoundingClientRect();
      const { height: childrenHeight } =
        childrenRef.current.getBoundingClientRect();

      if (parentHeight === childrenHeight) {
        callback?.(true);
        setIsExpanded.on();
        setHeight('fit-content');
      } else {
        callback?.(false);
      }
    }
  }, [callback, setIsExpanded]);

  return (
    <Box>
      <Box
        ref={parentRef}
        maxH={height}
        transition={`max-height ${EXPAND_DURATION}ms ease-in`}
        overflow="hidden"
        cursor={isExpanded ? undefined : 'pointer'}
        onClick={isExpanded ? undefined : expandWrapper}
        position="relative"
      >
        {isHTML ? (
          <RichTextEditorView ref={childrenRef} content={content} />
        ) : (
          <Text ref={childrenRef} whiteSpace="pre-line" wordBreak="break-word">
            {content}
          </Text>
        )}
      </Box>

      <Box position="relative">{_elementToDisplayWhenExpandable}</Box>
    </Box>
  );
}
