import { SystemStyleObject } from '@chakra-ui/react';

const CloseButton: SystemStyleObject = {
  baseStyle: {
    color: 'rythm.600',
    _hover: {
      bg: 'none',
      color: 'rythm.900',
    },
    _active: {
      bg: 'none',
    },
  },
};

export default CloseButton;
