export const fileToUrl = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (reader.result) {
        resolve(
          typeof reader.result === 'string'
            ? reader.result
            : Buffer.from(reader.result).toString()
        );
      } else {
        reject();
      }
    };
    reader.onerror = (error) => reject(error);
  });
};

export const renameFile = (file: File, newName: string) => {
  const blob = file.slice(0, file.size, file.type);
  const renamedFile = new File([blob], newName, { type: file.type });
  return renamedFile;
};

export const getMimeTypeFromDataContent = (dataContent: string) => {
  // The mime type appears on the first 20 characters of the content
  // and running match on large files can result in a memory error
  // Thus, we only process the 100 first characters
  const match = dataContent.slice(0, 100).match(/^data:(.+);/);

  if (!match) {
    return undefined;
  }

  return match[1];
};

// https://en.wikipedia.org/wiki/List_of_file_signatures
const FILE_HEADERS = {
  png: [0x89, 0x50, 0x4e, 0x47],
  pdf: [0x25, 0x50, 0x44, 0x46],
};

export type FileHeader = keyof typeof FILE_HEADERS;

export const getArrayBufferFromFile = (
  file: File,
  { start = 0, end = 4 } = {}
): Promise<ArrayBuffer> => {
  // /!\ FileReader is from the Web APIs /!\
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.readAsArrayBuffer(file.slice(start, end));
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result as ArrayBuffer);
    };
  });
};

const checkFileHeader = (bytes: Uint8Array, header: number[]) => {
  if (bytes.length === 0) {
    return false;
  }

  return bytes.every((byte, index) => byte === header[index]);
};

export const isFileFormatValid = async (file: File, extension: FileHeader) => {
  const arrayBuffer = await getArrayBufferFromFile(file, {
    start: 0,
    end: FILE_HEADERS[extension].length,
  });

  return checkFileHeader(new Uint8Array(arrayBuffer), FILE_HEADERS[extension]);
};
