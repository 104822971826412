export { default as alert } from './alert.svg';
export { default as illustrationDiamond } from './diamond.svg';
export {
  default as freeWorkIcon,
  ReactComponent as FreeWorkIconComponent,
} from './freework.svg';
export * as pictureBroken from './picture-broken.svg';
export * as pictureFlyingLetters3 from './picture-flying-letters-3.svg';
export * as pictureStarsBackground from './picture-stars-background.svg';
export {
  default as welcomeToTheJungleIcon,
  ReactComponent as WelcomeToTheJungleIconComponent,
} from './wttj.svg';
