import { ReactNode } from 'react';
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  TelephoneInput,
  TelephoneInputProps,
} from '../../..';

type FieldTelephoneInputProps = TelephoneInputProps & {
  label?: string | ReactNode;
};

export const FieldTelephoneInput = <
  FormFieldValues extends FieldValues = FieldValues,
  FieldName extends FieldPath<FormFieldValues> = FieldPath<FormFieldValues>
>({
  name,
  control,
  rules,
  shouldUnregister,
  defaultValue,
  label,
  ...rest
}: UseControllerProps<FormFieldValues, FieldName> &
  FieldTelephoneInputProps) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
  });

  return (
    <FormControl isInvalid={!!error}>
      {!!label && <FormLabel variant="simple">{label}</FormLabel>}
      <TelephoneInput {...field} {...rest} />
      {error?.message && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};
