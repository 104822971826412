import { Tooltip as ChakraTooltip, TooltipProps } from '@chakra-ui/react';

export type { TooltipProps };
export type { UseTooltipProps, UseTooltipReturn } from '@chakra-ui/react';
export { useTooltip } from '@chakra-ui/react';

export const Tooltip = ({
  children,
  hasArrow = true,
  placement = 'top',
  ...rest
}: TooltipProps) => {
  return (
    <ChakraTooltip hasArrow={hasArrow} placement={placement} {...rest}>
      {children}
    </ChakraTooltip>
  );
};
