export const buttonFontStyles = {
  'desktop-s-button': {
    fontSize: '0.75rem', // 12px
    lineHeight: '1rem', // 16px
    fontWeight: 600,
  },
  'desktop-m-button': {
    fontSize: '.875rem', // 14px
    lineHeight: '1.5rem', // 24px
    fontWeight: 600,
  },
};

export const variants = {
  'desktop-l-regular': {
    fontSize: '1.125rem', // 18px
    lineHeight: '1.75rem', // 28px
    fontWeight: 400,
    letterSpacing: '-0.01em',
  },
  // Base style
  'desktop-m-regular': {
    fontSize: '.875rem', // 14px
    lineHeight: '1.25rem', // 20px
    fontWeight: 400,
  },
  'desktop-m-medium': {
    fontSize: '.875rem', // 14px
    lineHeight: '1.25rem', // 20px
    fontWeight: 500,
  },
  'desktop-m-semibold': {
    fontSize: '.875rem', // 14px
    lineHeight: '1.25rem', // 20px
    fontWeight: 600,
  },
  'desktop-m-bold': {
    fontSize: '.875rem', // 14px
    lineHeight: '1.25rem', // 20px
    fontWeight: 700,
  },
  'desktop-m-link': {
    color: 'rythm.700',
    fontSize: '.875rem', // 14px
    lineHeight: '1.25rem', // 20px
    fontWeight: 500,
    cursor: 'pointer',
    textDecoration: 'underline',
    _hover: {
      color: 'rythm.900',
    },
    _active: {
      color: 'rythm.900',
    },
  },
  'desktop-s-regular': {
    fontSize: '0.75rem', // 12px
    lineHeight: '1rem', // 16px
    fontWeight: 400,
  },
  'desktop-s-medium': {
    fontSize: '0.75rem', // 12px
    lineHeight: '1rem', // 16px
    fontWeight: 500,
  },
  'desktop-s-semibold': {
    fontSize: '0.75rem', // 12px
    lineHeight: '1rem', // 16px
    fontWeight: 600,
  },
  'desktop-s-bold': {
    fontSize: '0.75rem', // 12px
    lineHeight: '1rem', // 16px
    fontWeight: 700,
  },
  'mobile-m-regular': {
    fontSize: '1rem', // 16px
    lineHeight: '1.375rem', // 22px
    fontWeight: 400,
  },
  'mobile-m-medium': {
    fontSize: '1rem', // 16px
    lineHeight: '1.375rem', // 22px
    fontWeight: 500,
  },
  'mobile-m-bold': {
    fontSize: '1rem', // 16px
    lineHeight: '1.375rem', // 22px
    fontWeight: 700,
  },
  'mobile-m-link': {
    color: 'rythm.700',
    fontSize: '1rem', // 16px
    lineHeight: '1.375rem', // 22px
    fontWeight: 500,
    cursor: 'pointer',
    textDecoration: 'underline',
    _hover: {
      color: 'rythm.900',
    },
    _active: {
      color: 'rythm.900',
    },
  },
  'mobile-s-regular': {
    fontSize: '.875rem', // 14px
    lineHeight: '1.125rem', // 18px
    fontWeight: 400,
  },
  'mobile-s-medium': {
    fontSize: '.875rem', // 14px
    lineHeight: '1.125rem', // 18px
    fontWeight: 500,
  },
  'mobile-s-semibold': {
    fontSize: '.875rem', // 14px
    lineHeight: '1.125rem', // 18px
    fontWeight: 600,
  },
  'mobile-s-bold': {
    fontSize: '.875rem', // 14px
    lineHeight: '1.125rem', // 18px
    fontWeight: 700,
  },
  'brand-desktop-m-regular': {
    fontSize: '1rem', // 16px,
    lineHeight: '1.5rem', // 24px
    fontWeight: 400,
  },
  'brand-desktop-m-medium': {
    fontSize: '1rem', // 16px,
    lineHeight: '1.5rem', // 24px
    fontWeight: 500,
  },

  // --- Buttons
  ...buttonFontStyles,
};

export type TextVariant = keyof typeof variants;

export default {
  baseStyle: {
    color: 'rythm.900',
    transition: '.1s ease-in-out color',
  },
  defaultProps: {
    variant: 'desktop-m-regular',
  },
  variants,
};
