/**
 * Copy text to clipboard + a fallback when the Clipboard API is not supported
 * Detailed explanation here: https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
 +*/

const fallbackCopyTextToClipboard = (text: string) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  document.execCommand('copy');
  document.body.removeChild(textArea);
};

export const copyTextToClipboard = (text: string): Promise<void> => {
  if (!navigator.clipboard) {
    return new Promise((resolve) => {
      resolve(fallbackCopyTextToClipboard(text));
    });
  }
  return navigator.clipboard.writeText(text);
};
