import type { MenuProps as ChakraMenuProps } from '@chakra-ui/react';
import { Menu as ChakraMenu } from '@chakra-ui/react';

import { MenuVariant } from '../../themes/components/menu';

export type {
  MenuButtonProps,
  MenuCommandProps,
  MenuDividerProps,
  MenuGroupProps,
  MenuItemOptionProps,
  MenuItemProps,
  MenuListProps,
  MenuOptionGroupProps,
  UseMenuButtonProps,
  UseMenuItemProps,
  UseMenuListProps,
  UseMenuOptionGroupProps,
  UseMenuOptionOptions,
  UseMenuOptionProps,
  UseMenuProps,
  UseMenuReturn,
} from '@chakra-ui/react';
export {
  MenuButton,
  MenuCommand,
  MenuDescendantsProvider,
  MenuDivider,
  MenuGroup,
  MenuIcon,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  useMenu,
  useMenuButton,
  useMenuContext,
  useMenuDescendant,
  useMenuDescendants,
  useMenuDescendantsContext,
  useMenuItem,
  useMenuList,
  useMenuOption,
  useMenuOptionGroup,
  useMenuPositioner,
  useMenuState,
} from '@chakra-ui/react';

export type MenuProps = Omit<ChakraMenuProps, 'variant'> & {
  variant?: MenuVariant;
};
export const Menu = (props: MenuProps) => {
  return <ChakraMenu {...props} />;
};
