import React, { cloneElement, useRef } from 'react';

import { Button, ButtonProps } from '../button';
import { IconX } from '../icon/icon';
import { Flex } from '../layout/flex';
import { Heading } from '../typography/heading';
import { Text } from '../typography/text';

type KPIVariants = 'naked' | 'gray';

type KPIValue = {
  amount: number | string;
  prefix?: string | React.ReactNode;
  suffix?: string | React.ReactNode;
  subtitleTag?: React.ReactNode;
};

type KPIProps = {
  variant?: KPIVariants;
  label: string;
  icon?: React.ReactNode;
  values: KPIValue | KPIValue[];
  onClick?: () => void;
  isSelected?: boolean;
  isDisabled?: boolean;
  xOnSelection?: boolean;
} & Omit<ButtonProps, 'variant'>;

const variantStyles: Record<KPIVariants, ButtonProps> = {
  naked: {
    variant: 'naked',
    bgColor: 'white',
  },
  gray: {
    variant: 'naked',
    bgColor: 'rythm.100',
  },
} as const;

function cloneEl(element: React.ReactNode | string): React.ReactNode {
  return React.isValidElement(element)
    ? cloneElement(element as React.ReactElement<{ color: string }>, {
        color: 'currentcolor',
      })
    : element;
}

export const KPI = ({
  variant = 'naked',
  label,
  icon,
  values,
  onClick,
  isSelected = false,
  isDisabled = false,
  xOnSelection = false,
  ...buttonProps
}: KPIProps) => {
  const internalRef = useRef<HTMLButtonElement>(null);
  const KPIStyle = variantStyles[variant] || variantStyles.naked;
  const KPIValues: KPIValue[] = Array.isArray(values) ? values : [values];

  const onKPIClick = () => {
    internalRef.current?.blur();
    onClick?.();
  };

  const selectedCss = isSelected
    ? {
        border: '1px solid var(--chakra-colors-rythm-700)',
        color: 'primary.600',
        _focus: {
          border: '1px solid var(--chakra-colors-rythm-700)',
          boxShadow: 'none',
          color: 'primary.600',
        },
        _hover: {
          border: '1px solid var(--chakra-colors-rythm-600)',
          color: 'primary.600',
        },
      }
    : {
        _focus: {
          border: '1px solid var(--chakra-colors-rythm-700)',
          boxShadow: 'none',
          color: 'rythm.700',
        },
        _hover: {
          border: '1px solid var(--chakra-colors-rythm-600)',
          color: 'rythm.600',
        },
      };

  const interactiveProps =
    onClick && !isDisabled
      ? {
          ...selectedCss,
          _active: {
            border: '1px solid var(--chakra-colors-rythm-700)',
            color: 'primary.600',
          },
          _disabled: {
            border: 0,
            boxShadow: 'none',
            color: 'rythm.300',
          },
        }
      : {
          cursor: 'default',
          _focus: {
            boxShadow: 'none',
          },
        };

  return (
    <Button
      ref={internalRef}
      {...KPIStyle}
      position="relative"
      minH="132px"
      padding="12px"
      borderRadius="12px"
      flex={1}
      transition="color 0.2s, border 0.2s, fade-in-out"
      color="rythm.300"
      onClick={onKPIClick}
      border={
        isSelected && !isDisabled
          ? '1px solid var(--chakra-colors-rythm-700)'
          : '1px solid transparent'
      }
      opacity={isDisabled ? 0.5 : 1}
      isDisabled={isDisabled}
      aria-label={label}
      {...interactiveProps}
      {...buttonProps}
    >
      <Flex gap={3} direction="column" h="100%">
        {isSelected && xOnSelection && (
          <Flex position="absolute" top={4} right={4} color="rythm.600">
            <IconX
              size="xs"
              sx={onClick ? { 'button:focus &': { color: 'rythm.900' } } : {}}
              aria-label="unselect card"
            />
          </Flex>
        )}

        <Flex gap={2} alignItems="center">
          {icon}
          <Text
            variant="desktop-s-medium"
            color={isSelected ? 'rythm.900' : 'rythm.700'}
            sx={onClick ? { 'button:focus &': { color: 'rythm.900' } } : {}}
          >
            {label}
          </Text>
        </Flex>

        <Flex mt="auto" mb={0} gap="16px">
          {KPIValues.map((value, index) => {
            const [prefixElement, suffixElement] = [
              cloneEl(value.prefix),
              cloneEl(value.suffix),
            ];

            return (
              <Flex gap={1} direction="column" key={index} flex={1}>
                <Flex alignItems="center">
                  {prefixElement}
                  <Heading
                    variant="desktop-xl-bold"
                    color="rythm.900"
                    ml={
                      value.prefix && typeof value.prefix !== 'string'
                        ? '10px'
                        : 0
                    }
                    mr={
                      value.suffix && typeof value.suffix !== 'string'
                        ? '10px'
                        : 0
                    }
                  >
                    {value.amount}
                  </Heading>
                  {suffixElement}
                </Flex>
                {value.subtitleTag}
              </Flex>
            );
          })}
        </Flex>
      </Flex>
    </Button>
  );
};
