import {
  AlertStatus as ToastStatus,
  CloseButton,
  CreateToastFnReturn,
  Flex,
  useToast as useChakraToast,
  UseToastOptions,
} from '@chakra-ui/react';
import { MouseEvent, useMemo } from 'react';

import { IconToastError, IconToastInfo, IconToastSuccess } from '../icon/icon';
import { Box } from '../layout';
import { Text } from '../typography';

export type { ToastStatus };

export const useInfoToast = (duration = 5000) => {
  return useToast('info', duration);
};

export const useSuccessToast = (duration = 5000) => {
  return useToast('success', duration);
};

export const useErrorToast = (duration = 5000) => {
  return useToast('error', duration);
};

const toastIcons = {
  info: (
    <IconToastInfo color="primary.600" sx={{ path: { fill: 'primary.600' } }} />
  ),
  warning: (
    <IconToastInfo color="primary.600" sx={{ path: { fill: 'primary.600' } }} />
  ),
  success: (
    <IconToastSuccess
      color="success.700"
      sx={{ path: { fill: 'success.700' } }}
    />
  ),
  error: (
    <IconToastError
      color="critical.700"
      sx={{ path: { fill: 'critical.700' } }}
    />
  ),
  loading: null,
};

type ToastOptions = UseToastOptions & {
  onClick?: () => void;
};

const createToastFn = (
  chakraToast: CreateToastFnReturn,
  status: ToastStatus,
  duration: number
) => {
  const toast = (opts: ToastOptions) => {
    const { onClick, ...baseOptions } = opts;

    const options: UseToastOptions = {
      ...baseOptions,
      status,
      position: 'bottom-left',
      duration,
    };

    chakraToast({
      ...options,
      render: ({ onClose }) => (
        <ToastAlert onClose={onClose} onClick={onClick} {...options} />
      ),
    });
  };

  toast.isActive = chakraToast.isActive;
  toast.closeAll = chakraToast.closeAll;
  toast.close = chakraToast.close;

  return toast;
};

export const useToast = (status: ToastStatus, duration = 5000) => {
  const chakraToast = useChakraToast();

  return useMemo(
    () => createToastFn(chakraToast, status, duration),
    [chakraToast, duration, status]
  );
};

interface ToastAlertProps extends UseToastOptions {
  onClose?: () => void;
  onClick?: () => void;
  status?: ToastStatus;
}
export const ToastAlert = ({
  status,
  title,
  description,
  onClose,
  onClick,
}: ToastAlertProps) => {
  const handleClose = (e: MouseEvent) => {
    e.stopPropagation();
    onClose?.();
  };

  const color = getColorByStatus(status);

  return (
    <Box
      alignItems="start"
      borderRadius="lg"
      boxShadow="lg"
      textAlign="start"
      maxWidth="560px" // Mirror chakra toast max width
      minWidth="300px" // Mirror chakra toast min width
      bg="white"
      onClick={onClick}
      cursor={onClick ? 'pointer' : undefined}
    >
      <Flex gap="12px" padding="16px" alignItems="center">
        {status && (
          <Box {...(description && { alignSelf: 'start' })}>
            {toastIcons[status]}
          </Box>
        )}
        <Box maxWidth="100%">
          {!!title && (
            <Text
              variant="desktop-m-medium"
              verticalAlign="middle"
              color={color ?? 'rythm.700'}
              mb={description ? 1 : 0}
              {...(description && { minHeight: '5' })} // when we show a toast without a description, the title height take more space than expected which it leaves not centered vertically with the icon
            >
              {title}
            </Text>
          )}
          {!!description && <Text verticalAlign="middle">{description}</Text>}
        </Box>
        {onClose && (
          <CloseButton
            size="md"
            onClick={handleClose}
            ml="auto"
            alignSelf="start"
          />
        )}
      </Flex>
    </Box>
  );
};

export const FixedToast = (props: ToastAlertProps) => (
  <Box
    p={8}
    pl={8}
    pr={16}
    position="fixed"
    left="50%"
    bottom="0"
    transform="translateX(-50%)"
    zIndex={2}
  >
    <ToastAlert {...props} />
  </Box>
);

function getColorByStatus(status?: ToastStatus) {
  switch (status) {
    case 'success':
      return 'success.700';
    case 'error':
      return 'critical.700';
    case 'info':
      return 'primary.600';
    default:
      return undefined;
  }
}
