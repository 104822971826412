import PasswordValidator from 'password-validator';

import { AnyObject, removeObjectProperty } from './object';

// Strings sent by PasswordValidator schema if a password value does not meet specified criteria
export const PASSWORD_KEY_LETTERS = 'letters';
export const PASSWORD_KEY_SYMBOLS = 'symbols';
export const PASSWORD_KEY_DIGITS = 'digits';
export const PASSWORD_KEY_MIN = 'min';
export const PASSWORD_KEY_MAX = 'max';
export const PASSWORD_LENGTH_MIN = 8;
export const PASSWORD_LENGTH_MAX = 200;

const schema = new PasswordValidator();
export const passwordSchema = schema
  .is()
  .min(PASSWORD_LENGTH_MIN)
  .is()
  .max(PASSWORD_LENGTH_MAX)
  .has()
  .letters()
  .has()
  .symbols()
  .has()
  .digits()
  .has()
  .not()
  .spaces();

export const getPasswordChecks = (password: string | undefined) => {
  const errors = passwordSchema.validate(password || '', {
    list: true,
  }) as string[];
  const checks = {
    hasMinCharacters: !errors.includes(PASSWORD_KEY_MIN),
    hasLetter: !errors.includes(PASSWORD_KEY_LETTERS),
    hasDigit: !errors.includes(PASSWORD_KEY_DIGITS),
    hasSymbol: !errors.includes(PASSWORD_KEY_SYMBOLS),
    isPasswordValid: errors.length === 0,
  };
  return checks;
};

export const redactPassword = <T extends AnyObject>(obj: T) =>
  removeObjectProperty(obj, 'password');
