import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

export const variants = {
  active: defineStyle({
    bgColor: 'primary.25',
    color: 'primary.500',
  }),
  inactive: defineStyle({
    bgColor: 'rythm.100',
    color: 'rythm.700',
  }),
  accent: defineStyle({
    bgColor: 'rythm.200',
    color: 'rythm.700',
  }),
};

export const sizes = {
  md: {
    px: '4px',
  },
};

export type CounterVariant = keyof typeof variants;
export type CounterSize = keyof typeof sizes;

export default defineStyleConfig({
  baseStyle: {
    borderRadius: '4px',
    _groupHover: {
      color: 'primary.500',
    },
  },
  sizes,
  variants,
});
