import {
  BoxProps,
  chakra,
  useCheckbox,
  UseCheckboxProps,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import React, { cloneElement, ComponentProps, useMemo } from 'react';

import { pillStyles, PillVariant } from '../../themes/components/pill';
import {
  IconCheck,
  IconExclamationCircle,
  IconPlus,
  IconSmallStar,
  IconUsers,
  IconX,
} from '../icon/icon';
import { Box, HStack } from '../layout';
import { Text } from '../typography';

export interface PillProps extends UseCheckboxProps {
  children?: React.ReactNode;
  icon?: React.ReactElement;
  variant?: PillVariant | undefined;
  displayedText: string;
  pictureUrl?: string;
  height?: number;
  width?: number;
  noWrap?: boolean;
  isReadOnly?: boolean;
  bg?: BoxProps['bg'];
  color?: BoxProps['color'];
  fontWeight?: BoxProps['fontWeight'];
}

export const PILL_HEIGHT = 8;

const PillButton = styled(chakra.label)`
  .check,
  .check-disabled {
    position: absolute;
    opacity: 100%;
    transition: opacity 0.1s ease-in-out;
  }

  .cross {
    position: absolute;
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
  }

  .plus {
    color: var(--chakra-colors-rythm-600);
  }

  :hover {
    .cross {
      opacity: 100%;
    }

    .check {
      opacity: 0;
    }

    .plus {
      color: var(--chakra-colors-rythm-900);
    }
  }
`;

export const Pill = ({
  variant = 'default',
  icon,
  displayedText,
  pictureUrl,
  children,
  noWrap,
  isReadOnly,
  ...rest
}: PillProps) => {
  const { state, getInputProps, getCheckboxProps, getLabelProps, htmlProps } =
    useCheckbox({
      ...rest,
      isDisabled: rest.isDisabled || variant === 'warn',
      isReadOnly,
    });

  const keyboardSelection: ComponentProps<'label'>['onKeyUp'] = (event) => {
    if (event.key === 'Enter') {
      // to not submit the form
      event.preventDefault();
      event.stopPropagation();
      // @ts-expect-error appeared on NX upgrade to v15
      event.target?.click();
    }
  };

  const cta = useMemo(() => {
    if (isReadOnly) {
      return null;
    }

    const getVariantNode = (
      variant: PillVariant,
      icon: React.ReactNode,
      plusIcon: React.ReactNode
    ) => (
      <>
        {state.isChecked
          ? !!icon && (
              <Box w={6} h={6} position="relative" display="flex">
                {!!icon &&
                  cloneElement(
                    icon as React.ReactElement<{
                      color: string;
                      className: string;
                    }>,
                    {
                      color: pillStyles[variant].checked.icons?.main.color,
                      className: !state.isDisabled ? 'check' : 'check-disabled',
                    }
                  )}
                {!state.isDisabled && (
                  <IconX
                    color={pillStyles[variant].checked.icons?.cross?.color}
                    className="cross"
                  />
                )}
              </Box>
            )
          : !!plusIcon && (
              <Box w={6} h={6} position="relative">
                {cloneElement(
                  plusIcon as React.ReactElement<{ className: string }>,
                  {
                    className: 'plus',
                  }
                )}
              </Box>
            )}
      </>
    );

    switch (variant) {
      case 'default':
        return getVariantNode(variant, icon ?? <IconCheck />, <IconPlus />);
      case 'main':
        return getVariantNode(variant, icon ?? <IconUsers />, <IconPlus />);
      case 'display':
        return getVariantNode(variant, icon ?? null, null);
      case 'star':
        return getVariantNode(
          variant,
          icon ?? <IconSmallStar />,
          <IconSmallStar />
        );
      case 'simple':
        return null;
      case 'warn': // To make it clear
      default:
        return (
          <Box w={6} h={6} position="relative">
            <IconExclamationCircle
              fill={pillStyles[variant].checked.icons?.main?.fill}
              color={pillStyles[variant].checked.icons?.main?.color}
            />
          </Box>
        );
    }
  }, [icon, isReadOnly, state.isDisabled, state.isChecked, variant]);

  const iconPosition = variant === 'star' ? 'left' : 'right';

  return (
    <PillButton
      position="relative"
      h={PILL_HEIGHT}
      align="center"
      display="inline-flex"
      border="1px solid"
      borderColor={
        state.isChecked
          ? pillStyles[variant].checked.borderColor
          : pillStyles[variant].unchecked.borderColor
      }
      backgroundColor={
        state.isChecked
          ? pillStyles[variant].checked.bgColor
          : pillStyles[variant].unchecked.bgColor
      }
      borderRadius="lg"
      py={1}
      px={2}
      {...htmlProps}
      {...(!isReadOnly && {
        cursor: 'pointer',
        _hover: {
          borderColor: state.isChecked
            ? pillStyles[variant].checked._hover?.borderColor
            : pillStyles[variant].unchecked._hover?.borderColor,
          bgColor: state.isChecked
            ? pillStyles[variant].checked._hover?.bgColor
            : pillStyles[variant].unchecked._hover?.bgColor,
        },
        _focusWithin: {
          borderColor: state.isChecked
            ? pillStyles[variant].checked._focusWithin?.borderColor
            : pillStyles[variant].unchecked._focusWithin?.borderColor,
          bgColor: state.isChecked
            ? pillStyles[variant].checked._focusWithin?.bgColor
            : pillStyles[variant].unchecked._focusWithin?.bgColor,
        },
        onKeyDown: keyboardSelection,
        ...pillStyles[variant].variance,
      })}
      {...(rest.isDisabled && {
        opacity: 0.5,
        _hover: {},
        _focusWithin: {},
        cursor: 'not-allowed',
      })}
    >
      <input {...getInputProps()} />
      <HStack {...getCheckboxProps()}>
        {iconPosition === 'left' && cta}
        {children}
        <Text
          color="inherit"
          {...getLabelProps()}
          variant="desktop-m-medium"
          whiteSpace={noWrap ? 'nowrap' : undefined}
        >
          {displayedText}
        </Text>
        {iconPosition === 'right' && cta}
      </HStack>
    </PillButton>
  );
};
