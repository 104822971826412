export default {
  baseStyle: {
    text: {
      mt: 1,
      color: 'critical.500',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
};
