import { Center, CenterProps } from '@chakra-ui/react';
import { ReactNode } from 'react';

import { useTransparentize } from '../../hooks/use-transparentize';

export type LayerBorderSize = '2xs' | 'xs' | 'sm' | 'md';

type SizeProperty = Record<LayerBorderSize, string>;

const outerSize: SizeProperty = {
  '2xs': '28px',
  xs: '32px',
  sm: '40px',
  md: '56px',
};

const outerBorderRadius: SizeProperty = {
  '2xs': '8px',
  xs: '12px',
  sm: '12px',
  md: '20px',
};

export type LayerBorderColorProps = {
  color: string;
  colorOutsideOverride?: string;
};
export type LayerBorderProps = CenterProps &
  LayerBorderColorProps & {
    children: ReactNode;
    size: LayerBorderSize;
  };

export const LayerBorder = ({
  children,
  color,
  colorOutsideOverride,
  size,
  ...rest
}: LayerBorderProps) => {
  const transparentColorOut = useTransparentize(color, 0.12);

  const colorOutside = colorOutsideOverride || transparentColorOut;

  return (
    <Center
      display="inline-flex"
      w={outerSize[size]}
      h={outerSize[size]}
      bgColor={colorOutside}
      borderRadius={outerBorderRadius[size]}
      flexShrink={0}
      {...rest}
    >
      {children}
    </Center>
  );
};
