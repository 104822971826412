/* eslint-disable @typescript-eslint/ban-ts-comment */

import { chakra } from '@chakra-ui/react';
import arrayMutators from 'final-form-arrays';
import { isEqual } from 'lodash';
import { Form as FinalForm, FormProps } from 'react-final-form';

/**
 * Wrapper for react-final-form Form
 * @param children
 * @param initialValues
 * @param validate
 * @param onSubmit
 * @constructor
 */
export const FormLegacy = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  FormValues extends Record<string, any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  InitialValues extends Record<string, any> = Partial<FormValues>
>({
  children,
  initialValues,
  initialValuesEqual,
  validate,
  onSubmit,
  ...rest
}: FormProps<FormValues, InitialValues>) => {
  return (
    <FinalForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={validate}
      initialValuesEqual={initialValuesEqual || isEqual}
      // @ts-ignore:next-line
      mutators={{
        // potentially other mutators could be merged here
        ...arrayMutators,
        ...(rest.mutators || {}),
      }}
    >
      {(props) => (
        <chakra.form onSubmit={props.handleSubmit} role="form" {...rest}>
          {/* @ts-ignore weird issue between public-pages and this typing */}
          {children && typeof children === 'function'
            ? // @ts-ignore weird issue between public-pages and this typing
              children(props)
            : children}
        </chakra.form>
      )}
    </FinalForm>
  );
};
