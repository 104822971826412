import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { NumberInput, NumberInputProps } from './number-input';

export type CurrencyInputProps = NumberInputProps;

export const CurrencyInput = forwardRef<HTMLInputElement, CurrencyInputProps>(
  function CurrencyInput(props, ref) {
    const { t } = useTranslation('common');

    return (
      <NumberInput
        ref={ref}
        decimalScale={2}
        fixedDecimalScale
        placeholder={t('ui.amountInput.placeholder')}
        leftElement="€"
        isLeftElementWrapped
        {...props}
      />
    );
  }
);
