import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconStarFilled } from '../../icon/icon';
import { Box } from '../../layout/box';
import { Flex } from '../../layout/flex';
import { Text } from '../../typography';

export type RatingStarsProps = {
  value?: number;
  onChange?: (value: number) => void;
  labelPlacement?: 'bottom' | 'right' | null;
};

export const RatingStars = ({
  value = 0,
  onChange,
  labelPlacement = null,
}: RatingStarsProps) => {
  const [selectedValue, setSelectedValue] = useState<number>(0);
  const [hoveredValue, setHoveredValue] = useState<number>(0);

  const items = new Array(5).fill(0).map((_, index) => index + 1);
  const label = useRatingLabel(hoveredValue || selectedValue);

  useEffect(() => {
    setSelectedValue(value);
  }, [value, setSelectedValue]);

  const onClick = (index: number) => {
    setSelectedValue(index);
    onChange?.(index);
  };

  return (
    <Flex
      alignItems="center"
      gap={labelPlacement === 'bottom' ? '16px' : '24px'}
      flexDirection={labelPlacement === 'bottom' ? 'column' : 'row'}
    >
      <Flex gap="4px">
        {items.map((index) => (
          <Box
            key={index}
            cursor="pointer"
            onClick={() => onClick(index)}
            onMouseEnter={() => setHoveredValue(index)}
            onMouseLeave={() => setHoveredValue(0)}
          >
            <IconStarFilled
              color={
                index <= (hoveredValue || selectedValue || 0)
                  ? 'decorative.gold.500'
                  : 'rythm.300'
              }
            />
          </Box>
        ))}
      </Flex>
      {labelPlacement && (
        <Text variant="desktop-m-medium" whiteSpace="nowrap" minH="20px">
          {label}
        </Text>
      )}
    </Flex>
  );
};

const useRatingLabel = (value: number): string => {
  const { t } = useTranslation('common');

  switch (value) {
    case 1:
      return t('ui.ratingStars.1');
    case 2:
      return t('ui.ratingStars.2');
    case 3:
      return t('ui.ratingStars.3');
    case 4:
      return t('ui.ratingStars.4');
    case 5:
      return t('ui.ratingStars.5');
    default:
      return '';
  }
};
