import { ListItem, ListItemProps } from '@chakra-ui/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { UseSelectReturnValue } from 'downshift';
import { isEqual } from 'lodash';

import { styledIgnoredProps } from '../../../utils';

const ListItemStyled = styled(
  ListItem,
  styledIgnoredProps(['isSelected', 'isHighlighted', 'inlineElements'])
)<{ isSelected: boolean; isHighlighted: boolean; inlineElements: boolean }>`
  ${(p) =>
    p.inlineElements
      ? css`
          padding: 2px;
          margin: 0 3.5px;

          &:hover {
            cursor: pointer;
          }

          svg {
            justify-content: center;
            align-items: center;
            display: flex;
          }
        `
      : css`
          padding: 10px 16px;

          &:hover {
            cursor: pointer;
            background-color: var(--chakra-colors-primary-1);
          }
        `}

  ${(p) =>
    p.isSelected &&
    p.inlineElements &&
    css`
      background-color: var(--chakra-colors-primary-25);
      border-radius: 4px;

      svg.legacy-icon {
        fill: var(--chakra-colors-primary-600) !important;
        path {
          fill: var(--chakra-colors-primary-600) !important;
        }
      }

      svg.non-legacy-icon {
        stroke: var(--chakra-colors-primary-600) !important;
        path {
          stroke: var(--chakra-colors-primary-600) !important;
        }
      }
    `}

  ${(p) =>
    p.isSelected &&
    !p.inlineElements &&
    css`
      color: var(--chakra-colors-primary-600);
      box-shadow: inset 2px 0 0 var(--chakra-colors-primary-600);
    `}

  ${(p) =>
    p.isHighlighted &&
    p.inlineElements &&
    css`
      svg.legacy-icon {
        fill: var(--chakra-colors-rythm-900) !important;
        path {
          fill: var(--chakra-colors-rythm-900) !important;
        }
      }

      svg.non-legacy-icon {
        stroke: var(--chakra-colors-rythm-900) !important;
        path {
          stroke: var(--chakra-colors-rythm-900) !important;
        }
      }
    `}

  ${(p) =>
    p.isHighlighted &&
    !p.inlineElements &&
    css`
      background-color: var(--chakra-colors-primary-1);
    `}

  ${(p) =>
    p._disabled &&
    css`
      &:hover {
        cursor: not-allowed;
    `}
`;

type SelectOptionProps<T> = ListItemProps & {
  item: T;
  index: number;
  // Those next 3 props are usually spread to the component from the select render prop
  selectedItem: T | null;
  highlightedIndex: number;
  getItemProps: UseSelectReturnValue<T>['getItemProps'];
  inlineElements?: boolean;
  disabled?: boolean;
};

export const SelectOption = <T,>({
  item,
  selectedItem,
  index,
  highlightedIndex,
  getItemProps,
  inlineElements = false,
  disabled = false,
  children,
  ...rest
}: SelectOptionProps<T>) => {
  return (
    <ListItemStyled
      inlineElements={inlineElements}
      display={inlineElements ? 'inline-block' : 'block'}
      isSelected={isEqual(item, selectedItem)}
      isHighlighted={index === highlightedIndex}
      {...getItemProps({ item, index, disabled })}
      {...rest}
    >
      {children}
    </ListItemStyled>
  );
};
