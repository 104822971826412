import { FlexProps, InputProps, useMergeRefs } from '@chakra-ui/react';
import { union, without } from 'lodash';
import { FocusEvent, forwardRef, useEffect, useRef, useState } from 'react';

import { Flex } from '../../layout';
import { Tag, TagProps } from '../../tag/tag';
import { TextInput } from '../text-input';

export interface TextAreaWithTagsInputProps
  extends Omit<InputProps, 'onChange'> {
  value?: string[];
  placeholder?: string;
  allowSpaces?: boolean;
  tagProps?: Partial<TagProps>;
  onChange?: (value: string[]) => void;
  handleSubmit?: () => void;
  validateTag?: (value: string) => string | undefined;
  wrapperProps?: FlexProps;
  inputWrapperProps?: FlexProps;
}

export const TextAreaWithTagsInput = forwardRef<
  HTMLInputElement,
  TextAreaWithTagsInputProps
>(function TextAreaWithTagsInput(
  {
    name,
    value,
    placeholder,
    allowSpaces,
    minHeight = '96px',
    handleSubmit,
    validateTag,
    autoFocus,
    onChange: onInputChange,
    onBlur: onInputBlur,
    onFocus: onInputFocus,
    tagProps,
    wrapperProps,
    inputWrapperProps,
    ...inputProps
  }: TextAreaWithTagsInputProps,
  forwardedRef
) {
  // calculate possible insertion and split keys
  const possibleInsertionKeys = ['Enter', 'Tab', ';', ','];
  const possibleSplitKeys = [';', ','];
  if (!allowSpaces) {
    // add the space to the insertion and split keys list if we don't allow space, as it becomes considered as a separator
    possibleInsertionKeys.push(' ');
    possibleSplitKeys.push(' ');
  }

  const [internalInputValue, setInternalInputValue] = useState('');
  const [tags, setTags] = useState(value || []);
  const [isFocused, setIsFocused] = useState(autoFocus);

  // ref used to focus on the input when clicking on the parent that acts as a "textarea"
  const ref = useRef<HTMLInputElement>(null);
  const inputRef = useMergeRefs(ref, forwardedRef);

  useEffect(() => {
    setTags(value || []);
  }, [value, setTags]);

  const getUpdatedTags = () => {
    const trimmedValue = internalInputValue?.trim();
    if (!trimmedValue) {
      return tags;
    }
    return union(tags, [trimmedValue]);
  };

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (internalInputValue && internalInputValue.trim() !== '') {
      const updatedTags = getUpdatedTags();

      setTags(updatedTags);
      setInternalInputValue('');
      onInputChange?.(updatedTags);
    }

    onInputBlur?.(event);
    setIsFocused(false);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const trimmedValue = internalInputValue.trim();
    const insertionTriggered = possibleInsertionKeys.includes(event.key);
    const submissionTriggered = event.metaKey && event.key === 'Enter';

    if (submissionTriggered) {
      handleSubmit?.();
      return;
    }

    if (insertionTriggered) {
      if (trimmedValue) {
        event.preventDefault(); // To prevent form submission
        setInternalInputValue('');

        if (!tags.includes(trimmedValue)) {
          const updatedTags = getUpdatedTags();

          setTags(updatedTags);
          setInternalInputValue('');

          onInputChange?.(updatedTags);
        }
      } else {
        event.key !== 'Tab' && event.preventDefault(); // To prevent form submission

        // Avoid inserting with many whitespaces
        setInternalInputValue('');
      }
    } else if (event.key === 'Backspace' && !trimmedValue) {
      const updatedTags = tags.slice(0, -1);
      setTags(updatedTags);
      onInputChange?.(updatedTags);
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setInternalInputValue(value);
  };

  const onPaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();

    const { clipboardData } = event;
    const pastedText = clipboardData.getData('text');

    if (!pastedText) {
      return;
    }

    const newTags = union(
      tags,
      pastedText
        .split(new RegExp(possibleSplitKeys.join('|'), 'g'))
        .map((e: string) => e.trim())
        .filter((e: string) => e.length > 0)
    );

    setTags(newTags);
    setInternalInputValue('');

    onInputChange?.(newTags);
  };

  const onRemove = (value: string) => {
    if (value) {
      const updatedTags = without(tags, value);
      setTags(updatedTags);

      // Done this way otherwise the onInputChange is getting out of sync updated tags since setState is asynchronous
      // Also, calling onInputChange within a setState triggers errors
      onInputChange?.(updatedTags);
    }
  };

  const onFocus = (event: FocusEvent<HTMLInputElement, Element>) => {
    setIsFocused(true);
    onInputFocus?.(event);
  };

  const isInvalid = validateTag
    ? tags.some((tag) => {
        return validateTag(tag) !== undefined;
      })
    : false;

  const getTextAreaBorderColor = () => {
    if (isFocused) {
      return 'primary.600';
    }

    if (isInvalid) {
      return 'critical.500';
    }

    return 'rythm.300';
  };

  const getTextAreaHoverBorderColor = () => {
    if (!isFocused && !isInvalid) {
      return 'rythm.600';
    }

    return '';
  };

  const getTextAreaBoxShadow = () => {
    if (isFocused) {
      return 'outline.1';
    }

    return '';
  };

  return (
    <Flex
      role="group"
      flexWrap="wrap"
      border="1px solid"
      _hover={{ borderColor: getTextAreaHoverBorderColor() }}
      borderColor={getTextAreaBorderColor()}
      boxShadow={getTextAreaBoxShadow()}
      borderRadius="8px"
      bg="white"
      minHeight={minHeight}
      px={1.5}
      py={2}
      gridGap={2}
      alignContent="flex-start"
      onClick={() => {
        // Focus back on the children <TextInput> to make it look like a "TextArea"
        ref?.current?.focus();
      }}
      {...wrapperProps}
    >
      {tags.map((value) => {
        const isValid = validateTag ? !validateTag(value) : true;

        return (
          <Tag
            label={value}
            key={value}
            size="md"
            height={8}
            overflow="hidden"
            data-value={value}
            onClose={() => onRemove(value)}
            onKeyDown={({ code }) => {
              if (code === 'Backspace') {
                onRemove(value);
                ref?.current?.focus();
              }
            }}
            isWholeTagFocus
            role="listitem"
            aria-label={value}
            bgColor="primary.25"
            color="primary.600"
            {...tagProps}
            {...(!isValid && {
              bgColor: 'critical.100',
              color: 'critical.500',
            })}
          />
        );
      })}

      <Flex flexGrow={1} {...inputWrapperProps}>
        <TextInput
          {...inputProps}
          autoFocus={autoFocus}
          ref={inputRef}
          border={0}
          _focus={{
            boxShadow: 'none',
          }}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          onChange={onChange}
          onPaste={onPaste}
          value={internalInputValue}
          onFocus={onFocus}
          p={0}
          {...(tags.length === 0 && { placeholder })}
        />
      </Flex>
    </Flex>
  );
});
