import { default as slugifyLib } from 'slugify';

import { onlyLetters, replaceAccents } from './string';

export const slugify = (s: string) => {
  return slugifyLib(s, { lower: true, strict: true });
};

type User = { firstname: string; lastname: string };

export function generateSoloSlug(user: User, hash = ''): string {
  const input = [user.firstname, user.lastname, hash]
    .join(' ')
    .split(' ')
    .map(replaceAccents)
    .map(onlyLetters)
    .filter(Boolean)
    .join('-');

  return slugify(input);
}
