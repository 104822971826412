import { getTrBoxShadow } from '../../lib/table/table';

const Table = {
  baseStyle: {
    th: {
      textTransform: 'none',
      fontWeight: 'semibold',
      color: 'rythm.700',
      paddingInlineStart: 0,
      paddingInlineEnd: 0,
    },
    td: {
      paddingInlineStart: 0,
      paddingInlineEnd: 0,
    },
  },
  variants: {
    simple: {
      table: {
        borderColor: 'rythm.300',
      },
      th: {
        color: 'rythm.700',
      },
    },
    // This variant makes it so that drag & dropping rows moves the borders as well, which is not the case with classic borders in a table
    shadowBorder: {
      tr: {
        boxShadow: getTrBoxShadow(),
      },
    },
    // Variant to use in example stories to display different component states
    exampleStory: {
      th: {
        color: 'rythm.600',
        borderBottom: '1px',
        borderColor: 'rythm.300',
      },
    },
  },
  sizes: {
    sm: {
      th: {
        px: '4px',
        py: 2,
      },
      td: {
        p: '4px',
      },
    },
    md: {
      th: {
        px: '8px',
        py: '10px',
      },
      td: {
        px: '8px',
        py: '12px',
      },
    },
    lg: {
      th: {
        px: 4,
        py: 4.5,
        fontSize: 'xs',
      },
      td: {
        px: 4,
        py: 6,
      },
    },
  },
  defaultProps: {
    size: 'lg',
  },
};

export default Table;
