import { forwardRef, ReactNode } from 'react';

import { Box, BoxProps } from './box';

export type SplitBoxProps = BoxProps & {
  topBox: ReactNode;
  topBoxProps?: BoxProps;
  bottomBox: ReactNode;
  bottomBoxProps?: BoxProps;
};

export const SplitBox = forwardRef<HTMLDivElement, SplitBoxProps>(
  function SplitBox(
    { topBox, topBoxProps, bottomBox, bottomBoxProps, ...rest },
    ref
  ) {
    return (
      <Box
        ref={ref}
        bgColor="white"
        border="1px solid"
        borderColor="rythm.200"
        borderRadius="12px"
        overflow="hidden"
        transition="border-color .2s"
        {...rest}
      >
        <Box p="20px" pb="12px" {...topBoxProps}>
          {topBox}
        </Box>
        <Box
          p="20px"
          borderTop="1px solid"
          borderColor="rythm.200"
          bg="rythm.100"
          {...bottomBoxProps}
        >
          {bottomBox}
        </Box>
      </Box>
    );
  }
);
