import 'react-day-picker/lib/style.css';

import utilsDate from '@collective/utils/date';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Flex } from '../layout';
import { Select } from './select/select';

const DEFAULT_MIN_YEAR = 1900;
const DEFAULT_MAX_YEAR = new Date().getFullYear() + 50;

type MonthYearState = {
  year: number | null;
  month: number | null;
};

export interface MonthYearPickerProps {
  onChange?: (date: string | null) => void;
  isDisabled?: boolean;
  value?: string | Date;
  monthDay: 'start' | 'end';
  allowEmptyMonth?: boolean;
  minYear?: number;
  maxYear?: number;
}

export const MonthYearPicker = ({
  value,
  onChange,
  isDisabled,
  monthDay,
  allowEmptyMonth = false,
  minYear = DEFAULT_MIN_YEAR,
  maxYear = DEFAULT_MAX_YEAR,
}: MonthYearPickerProps) => {
  const { t } = useTranslation('common');

  const months = dayjs.months();
  const years = new Array(maxYear - minYear + 1)
    .fill(maxYear)
    .map((_, index) => maxYear - index);

  const [date, setDate] = useState<MonthYearState>({
    month: value ? dayjs(value).utc(false).get('month') : null,
    year: value ? dayjs(value).utc(false).get('year') : null,
  });

  const changeDate = (input: Partial<MonthYearState>) => {
    const newDate = { ...date, ...input };
    setDate(newDate);

    const { month, year } = newDate;

    if (!isNil(month) && !isNil(year)) {
      const date = utilsDate.convertToUTC(
        (monthDay === 'start'
          ? dayjs().set('year', year).set('month', month).startOf('month')
          : dayjs().set('year', year).set('month', month).endOf('month')
        ).toDate(),
        true
      );

      onChange?.(date);
    } else if (!isNil(year) && allowEmptyMonth) {
      const date = utilsDate.convertToUTC(
        dayjs().set('year', year).set('month', 0).startOf('month').toDate()
      );

      onChange?.(date);
    } else {
      onChange?.(null);
    }
  };

  return (
    <Flex gap="8px">
      <Select<string>
        items={months}
        value={isNil(date.month) ? undefined : months[date.month]}
        placeholder={t('ui.monthYearPicker.month')}
        onChange={(value) =>
          changeDate({
            month: months.findIndex((m) => m === value),
          })
        }
        isDisabled={isDisabled}
      />
      <Select<number>
        items={years}
        value={isNil(date.year) ? undefined : date.year}
        placeholder={t('ui.monthYearPicker.year')}
        onChange={(value) => changeDate({ year: value })}
        isDisabled={isDisabled}
      />
    </Flex>
  );
};
