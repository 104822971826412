import { BorderBox, Box, Center, CenterProps } from '../layout';
import { ListItem, UnorderedList } from '../layout/list';
import { Heading, Text } from '../typography';

export type NoResultsProps = Omit<CenterProps, 'title'> & {
  title: React.ReactNode;
  subtitle: string;
  listItems: string[];
  imgComponent?: React.ReactNode;
  footer?: React.ReactNode;
};

export const NoResults = ({
  title,
  subtitle,
  imgComponent,
  listItems,
  footer,
  ...rest
}: NoResultsProps) => {
  return (
    <Center h="100%" flexDir="column" {...rest}>
      <Box w="728px">
        <Box position="relative">
          <Heading variant="desktop-xl-bold" pb={12} maxW="500px">
            {title}
          </Heading>
          {imgComponent}
        </Box>
        <BorderBox
          p={5}
          bgColor="white"
          boxShadow="sm"
          position="relative"
          zIndex={2}
          noHover
        >
          <Text variant="desktop-m-bold" mb={3}>
            {subtitle}
          </Text>

          <UnorderedList ml={5} mb={6}>
            {listItems.map((item) => (
              <ListItem key={item} color="rythm.700">
                {item}
              </ListItem>
            ))}
          </UnorderedList>

          {footer}
        </BorderBox>
      </Box>
    </Center>
  );
};
