export enum ToolbarFeature {
  Title,
  Bold,
  Underline,
  Italic,
  BulletList,
  OrderedList,
  TextAlignement,
  Link,
  ImageUpload,
  VideoIntegration,
}

export const FULL_TOOLBAR = [
  [
    ToolbarFeature.Title,
    ToolbarFeature.Bold,
    ToolbarFeature.Underline,
    ToolbarFeature.Italic,
  ],
  [
    ToolbarFeature.BulletList,
    ToolbarFeature.OrderedList,
    ToolbarFeature.TextAlignement,
  ],
  [
    ToolbarFeature.Link,
    ToolbarFeature.ImageUpload,
    ToolbarFeature.VideoIntegration,
  ],
];

export const TOOLBAR_WITHOUT_ALIGNMENT = [
  [
    ToolbarFeature.Title,
    ToolbarFeature.Bold,
    ToolbarFeature.Underline,
    ToolbarFeature.Italic,
  ],
  [ToolbarFeature.BulletList, ToolbarFeature.OrderedList],
  [
    ToolbarFeature.Link,
    ToolbarFeature.ImageUpload,
    ToolbarFeature.VideoIntegration,
  ],
];
