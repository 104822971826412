import { datadogLogs, LoggerConfiguration } from '@datadog/browser-logs';
import { Logger as DatadogLogger } from '@datadog/browser-logs/cjs/domain/logger';

import { HandlerType } from './handlers';

export type LogLevel = 'debug' | 'info' | 'warn' | 'error';

// LogContext can be anything and is handled by the underlying library,
// for this reason we disable eslint here
// eslint-disable-next-line @typescript-eslint/ban-types
export type LogContext = object;

export default class Logger {
  private logger: DatadogLogger;

  constructor(name: string, conf?: LoggerConfiguration) {
    this.logger = datadogLogs.createLogger(name, conf);
  }

  debug(message: string, context?: LogContext) {
    this.logger.debug(message, context);
  }

  info(message: string, context?: LogContext) {
    this.logger.info(message, context);
  }

  warn(message: string, context?: LogContext) {
    this.logger.warn(message, context);
  }

  warnWithMsg(message: string, error: unknown, context?: LogContext) {
    this.logger.warn(message, {
      ...context,
      error: this.serializeError(error),
    });
  }

  error(message: string, context?: LogContext) {
    this.logger.error(message, { ...context, ...this.getPredefinedTags() });
  }

  errorWithMsg(message: string, error: unknown, context?: LogContext) {
    this.error(message, {
      ...context,
      error: this.serializeError(error),
    });
  }

  setLevel(level: LogLevel) {
    this.logger.setLevel(level);
  }

  setHandler(handler: HandlerType | HandlerType[]) {
    this.logger.setHandler(handler);
  }

  getPredefinedTags(): LogContext {
    return {
      // we use this tag to monitor all the errors coming from this source
      monitoredError: true,
    };
  }

  serializeError(err: unknown) {
    // Workaround to have the error message and stack in logs (as the Error class is not serializable)
    return JSON.parse(JSON.stringify(err, Object.getOwnPropertyNames(err)));
  }
}
