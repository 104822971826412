import Decimal from 'decimal.js';
import { isArray, partition } from 'lodash';

import { NUMBER_OF_FRACTION_DIGITS } from './constants';

/**
 * This a simple function to round any number, it hides the complexity behind that and offers the possibility to update the rounding once and it will applied everywhere
 * toDecimalPlaces uses by default the rounding method ROUND_HALF_UP which will apply the following:
 * 10.549 => 10.55
 * 10.544 => 10.54
 * 10.545 => 10.55
 * We choose this rounding method because @vianney told us so 😂
 * @param num
 */
export function round(num: Decimal): Decimal {
  return num.toDecimalPlaces(NUMBER_OF_FRACTION_DIGITS);
}

/**
 * Round an amount in cents
 * Converts the amount to euros, round it, then convert it back to cents
 */
export const roundCents = (amount: Decimal) => {
  return round(amount.div(100)).mul(100);
};

/**
 * Convert a number to an amount
 * 23.42 => 2342
 * @param num
 */

export function numberToAmount(num: number): number {
  if (!Number.isFinite(num) || Number.isNaN(num)) {
    throw new Error(`Number ${num} cannot be converted to an Amount`);
  }
  return new Decimal(num).mul(100).round().toNumber();
}

/**
 * Convert an amount to a number
 * 5413 => 54.13
 * @param amount
 */
export function amountToNumber(amount: number): number {
  if (!Number.isFinite(amount)) {
    throw new Error(`Number ${amount} is not a valid Amount`);
  }
  return round(new Decimal(amount).div(100)).toNumber();
}

export function formatNumber(value: number, language = 'fr'): string {
  return new Intl.NumberFormat(getIntlCodeByLanguage(language)).format(value);
}

/**
 * Format an amount (in cents) for display
 * @param amount in cents
 */
export function displayCurrencyFromAmount(
  amount: number,
  withDecimals = true,
  language = 'fr'
): string {
  const amountNumber = amountToNumber(amount);

  return displayCurrencyFromNumber(amountNumber, withDecimals, language);
}
/**
 * Format an amount (in euros) for display
 * @param amount in euros
 */
export function displayCurrencyFromNumber(
  amount: number,
  withDecimals = true,
  language = 'fr'
): string {
  const intl = new Intl.NumberFormat(getIntlCodeByLanguage(language), {
    style: 'currency',
    currency: 'EUR',
    ...(!withDecimals && {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }),
  });
  return intl.format(amount);
}

/**
 * Format an amount for display and return it as parts
 * @param amount
 * @param options
 */
export function getCurrencyPartsFromAmount(
  amount: number,
  options?: Intl.NumberFormatOptions,
  language = 'fr'
): string[] {
  const amountNumber = amountToNumber(amount);

  return getCurrencyPartsFromNumber(amountNumber, options, language);
}
/**
 * Format an amount for display and return it as parts
 * @param amount
 * @param options
 */
export function getCurrencyPartsFromNumber(
  amount: number,
  options?: Intl.NumberFormatOptions,
  language = 'fr'
): string[] {
  const intl = new Intl.NumberFormat(getIntlCodeByLanguage(language), {
    style: 'currency',
    currency: 'EUR',
    ...options,
  });

  const formattedAmountNumber = intl.formatToParts(amount);

  const [[currency], parts] = partition(formattedAmountNumber, ({ type }) => {
    return type === 'currency';
  });
  const position =
    formattedAmountNumber[0].type === 'currency' ? 'left' : 'right';

  return [parts.map(({ value }) => value).join(''), currency.value, position];
}

/**
 * Subtract a percentage from a Decimal representing the amount in cents
 *
 * @param amount Amount in cents
 * @param percentage
 */
export function subtractPercentage(
  amount: Decimal,
  percentage: number
): Decimal {
  if (!amount.isFinite()) {
    throw new Error(`Number ${amount} is not a valid Amount`);
  }

  return amount
    .mul(new Decimal(1).minus(new Decimal(percentage).div(100)))
    .floor();
}

/**
 * Subtract a percentage from a Decimal representing the amount in euros
 *
 * @param amount Amount in euros
 * @param percentage
 */
export function subtractNumberPercentage(
  amount: Decimal,
  percentage: number
): Decimal {
  return new Decimal(
    amountToNumber(
      subtractPercentage(
        new Decimal(numberToAmount(amount.toNumber())),
        percentage
      ).toNumber()
    )
  );
}

/**
 * Compute percentage from a Decimal representing the amount in cents
 *
 * @param amount Amount in cents
 * @param percentage
 */
export function computePercentage(
  amount: Decimal,
  percentage: number
): Decimal {
  if (!amount.isFinite()) {
    throw new Error(`Number ${amount} is not a valid Amount`);
  }
  return round(amount.div(100).mul(percentage).div(100)).mul(100);
}

/**
 * Compute percentage from a Decimal representing the amount in euros
 *
 * @param amount Amount in euros
 * @param percentage
 */
export function computeNumberPercentage(
  amount: Decimal,
  percentage: number
): Decimal {
  if (!amount.isFinite()) {
    throw new Error(`Number ${amount} is not a valid Amount`);
  }
  return amount.mul(percentage).div(100);
}

/**
 * Split an amount in equal integer parts
 * and spread the remaining
 *
 * @param amount
 * @param by
 */
export function splitAmount(amount: Decimal, by: number): Decimal[] {
  // Check if by is positive, and an integer
  if (by <= 0 || !Number.isInteger(by)) {
    throw new Error('by parameter should be a positive int number');
  }

  const quotient = amount.mul(100).div(by).floor();
  const remainder = amount.mul(100).mod(by);
  const roundedUpLength = remainder;
  const normalLength = new Decimal(by).minus(remainder);

  const repartitionUp = Array(roundedUpLength.toNumber()).fill(
    quotient.div(100).add(0.01)
  );
  const repartitionNormal = Array(normalLength.toNumber()).fill(
    quotient.div(100)
  );

  return repartitionUp.concat(repartitionNormal);
}

export function getDecimalSeparator(locale: string) {
  const separator = Intl.NumberFormat(locale)
    .formatToParts(1.1) // Just an arbitrary decimal number
    .find((part) => part.type === 'decimal')?.value;
  return separator || ',';
}

export function parseDecimal(value: string): Decimal {
  const parsedValue = parseFloat(value);
  if (isNaN(parsedValue)) {
    return new Decimal(0);
  }
  return new Decimal(parsedValue);
}

export function sumDecimals(values?: Decimal.Value[]): Decimal {
  return !isArray(values) || !values?.length
    ? new Decimal(0)
    : Decimal.sum(...values);
}

function getIntlCodeByLanguage(language: string) {
  switch (language) {
    case 'fr':
      return 'fr-FR';
    default:
      return 'en-US';
  }
}
