import { useMergeRefs, useTheme } from '@chakra-ui/react';
import { sanitize } from '@collective/utils/helpers';
import { HTMLContent } from '@tiptap/react';
import { forwardRef, useEffect, useRef } from 'react';
import { createRoot } from 'react-dom/client';

import { Box, BoxProps } from '../../layout';
import { ViewVideoComponent } from './extensions/video';
import { richTextEditorStyles } from './rich-text-editor-styles';

type RichTextEditorViewProps = BoxProps & {
  content: HTMLContent;
  customEditorStyles?: Record<string, Record<string, string | number>>;
};

export const RichTextEditorView = forwardRef(function RichTextEditorView(
  { content, customEditorStyles = {}, ...rest }: RichTextEditorViewProps,
  ref
) {
  const theme = useTheme();
  const htmlContentRef = useRef<HTMLDivElement>(null);
  const hasLoadedVideo = useRef(false);
  const refs = useMergeRefs(htmlContentRef, ref);

  // Replace the custom tags for the video by a react component
  // It's the only way to do it because of the `dangerouslySetInnerHTML`
  useEffect(() => {
    if (htmlContentRef.current && !hasLoadedVideo.current) {
      hasLoadedVideo.current = true;
      const videoContainer =
        htmlContentRef.current.querySelectorAll('video-component');

      videoContainer.forEach((videoNode) => {
        const root = createRoot(videoNode);
        root.render(<ViewVideoComponent src={videoNode.getAttribute('src')} />);
      });
    }
  }, [htmlContentRef]);

  return (
    <Box
      sx={{
        ...richTextEditorStyles(theme.colors, false),
        ...customEditorStyles,
        // Readonly style added to preserve empty lines
        'p:empty::after': {
          content: '"\u00A0"',
        },
        // Hack when users paste code from somewhere and they have a trailing br at the end
        // https://stackoverflow.com/questions/15008205/br-not-causing-new-line-on-chrome
        // The character is : https://www.fileformat.info/info/unicode/char/200b/index.htm
        'p:after': {
          content: "'\\0200B'",
        },
      }}
      {...rest}
    >
      <Box
        ref={refs}
        className="ProseMirror"
        dangerouslySetInnerHTML={{
          __html: sanitize(content),
        }}
      />
    </Box>
  );
});
