import { initI18n } from '@collective/translation';
import { initReactI18next } from 'react-i18next';

export const getI18nInstance = (lang = 'fr') => {
  const instance = initI18n({
    ns: 'public-pages',
    enableLanguageDetection: false,
    initReactI18next,
    language: lang,
  });

  if (instance.isInitialized && lang !== instance.language) {
    instance.changeLanguage(lang);
  }

  return instance;
};
