import {
  forwardRef,
  ResponsiveValue,
  Text as ChakraText,
  TextProps as ChakraTextProps,
} from '@chakra-ui/react';
import { LegacyRef } from 'react';

import { TextVariant } from '../../themes/components/text';

export type TextProps = Omit<ChakraTextProps, 'size'> & {
  variant?: ResponsiveValue<TextVariant>;
};

export const Text = forwardRef<TextProps, 'p'>((props, ref) => (
  <ChakraText ref={ref as LegacyRef<HTMLParagraphElement>} {...props} />
));
