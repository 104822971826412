import {
  Table as ChakraTable,
  TableProps as ChakraTableProps,
} from '@chakra-ui/react';
import { forwardRef } from 'react';

export type {
  TableBodyProps,
  TableCaptionProps,
  TableCellProps,
  TableColumnHeaderProps,
  TableContainerProps,
  TableFooterProps,
  TableHeadProps,
  TableOptions,
  TableRowProps,
} from '@chakra-ui/react';
export {
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';

// Used in the shadowBorder table variant
export const getTrBoxShadow = (color = 'var(--chakra-colors-rythm-300)') => {
  return `inset 0 -1px ${color}`;
};

export interface TableProps extends ChakraTableProps {
  tableLayout?: React.CSSProperties['tableLayout'];
}

export const Table = forwardRef<HTMLTableElement, TableProps>(function Table(
  { sx, tableLayout, ...rest },
  ref
) {
  const mergedSx = {
    // tableLayout unfortunately doesn't exist on chakra's table props
    tableLayout,
    ...sx,
  };

  return <ChakraTable ref={ref} sx={mergedSx} {...rest} />;
});
