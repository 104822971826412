import {
  Box,
  BoxProps,
  Flex,
  InputProps,
  Text,
  useRadio,
  useRadioGroup,
  UseRadioProps,
} from '@chakra-ui/react';
import { forwardRef, ReactNode, useEffect } from 'react';

export type RadioBoxesProps<T> = {
  isInvalid?: boolean;
  choices: Array<{ label: string; value: T }>;
  onChange?: (data: T) => void;
  containerProps?: BoxProps;
  radioItemProps?: BoxProps;
  itemProps?: BoxProps;
  selectedProps?: BoxProps;
} & Omit<InputProps, 'onChange'>;

const RadioBoxesInner = <T extends string>(
  {
    choices,
    onChange,
    containerProps = {},
    radioItemProps = {},
    itemProps = {},
    selectedProps = {},
    isInvalid = false,
    ...rest
  }: RadioBoxesProps<T>,
  ref: React.ForwardedRef<HTMLButtonElement>
) => {
  const { getRootProps, getRadioProps, value, setValue } = useRadioGroup({
    name: rest.name,
    defaultValue: rest.value as T,
    onChange,
  });

  const group = getRootProps();
  const selectedValueIndex = choices.findIndex(
    ({ value }) => value === rest.value
  );

  useEffect(() => {
    if (rest.value !== value) {
      const matchingChoice = choices.find(
        ({ value }) => rest.value === value
      )?.value;

      setValue(matchingChoice ?? '');
    }
  }, [choices, rest.value, setValue, value]);

  return (
    <Flex w="100%" ref={ref} {...group} {...containerProps}>
      {choices.map((choice, index) => {
        const radio = getRadioProps({
          value: choice.value,
        });

        return (
          <RadioBox
            key={choice.value}
            {...radio}
            radioItemProps={radioItemProps}
            itemProps={itemProps}
            selectedProps={selectedProps}
            selectedValueIndex={selectedValueIndex}
            currentIndex={index}
            isInvalid={isInvalid}
          >
            {choice.label}
          </RadioBox>
        );
      })}
    </Flex>
  );
};

type RadioBoxProps = {
  children: ReactNode;
  radioItemProps: BoxProps;
  itemProps: BoxProps;
  selectedProps: BoxProps;
  selectedValueIndex: number;
  currentIndex: number;
} & UseRadioProps;

const RadioBox = ({
  children,
  radioItemProps,
  itemProps,
  selectedProps,
  selectedValueIndex,
  currentIndex,
  isInvalid,
  ...props
}: RadioBoxProps) => {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box
      as="label"
      flex={1}
      sx={{
        // Round the start and end of the line
        '&:first-of-type > div': {
          borderRadius: '8px 0 0 8px',
        },
        '&:last-of-type > div': {
          borderRadius: '0 8px 8px 0',
        },
        // If not checked, we remove the right borders of all items
        // + the left one of the second item (if not the last) to avoid double border
        '&:nth-of-type(2):not(:last-of-type) > div': !input.checked
          ? {
              borderLeftWidth: '0px',
            }
          : {},
        '&:not(:first-of-type):not(:last-of-type) > div': !input.checked
          ? {
              borderRightWidth: '0px',
            }
          : {},
        // We remove the adjacent borders of the selected item
        ...(selectedValueIndex !== -1
          ? currentIndex === selectedValueIndex - 1
            ? {
                '& > div': { borderRightWidth: '0px' },
              }
            : currentIndex === selectedValueIndex + 1
            ? {
                '& > div': { borderLeftWidth: '0px' },
              }
            : {}
          : {}),
      }}
      {...itemProps}
    >
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        border="1px solid"
        borderColor="rythm.300"
        color="rythm.700"
        bg="white"
        textAlign="center"
        p="4px 8px"
        h="32px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        _focus={{
          borderColor: 'primary.600',
        }}
        {...radioItemProps}
        _checked={{
          bg: 'primary.25',
          color: 'primary.600',
          borderColor: 'primary.600',
          ...selectedProps,
        }}
        {...(isInvalid && { borderColor: 'critical.500' })}
      >
        <Text variant="desktop-s-semibold" color="inherit">
          {children}
        </Text>
      </Box>
    </Box>
  );
};

export const RadioBoxes = forwardRef(RadioBoxesInner);
