import Input from './input';

const Textarea = {
  baseStyle: {
    px: 2,
    py: 1.5,
    lineHeight: '1.25rem', // 20px
  },
  variants: {
    outline: {
      ...Input.variants.outline.field,
      borderRadius: 'lg',
      px: 2,
      py: 1.5,
    },
  },
  defaultProps: {
    size: 'sm',
  },
};

export default Textarea;
