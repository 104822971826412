import { Button, ButtonProps } from '../../../button/button';
import { IconButton, IconButtonProps } from '../../../button/icon-button';
import { MenuItem } from '../../../menu/menu';
import { Text } from '../../../typography/text';

type ToolbarIconButtonProps = IconButtonProps & {
  isActive?: boolean;
};

export const ToolbarIconButton = ({
  isActive,
  variant = 'editorToolbar',
  ...rest
}: ToolbarIconButtonProps) => (
  <IconButton
    w={6}
    variant={variant}
    {...(variant === 'iconButton' && { border: 0 })}
    {...(isActive && {
      color: 'primary.600',
      backgroundColor: 'primary.25',
      _hover: {
        borderColor: 'primary.300',
      },
    })}
    {...rest}
  />
);

type ToolbarMenuItemButtonProps = ButtonProps & {
  label: string;
};

export const ToolbarMenuItemButton = ({
  label,
  variant = 'editorToolbar',
  ...rest
}: ToolbarMenuItemButtonProps) => (
  <Button
    as={MenuItem}
    variant={variant}
    {...(variant === 'iconButton' && { border: 0 })}
    width="auto"
    iconSpacing={1}
    justifyContent="start"
    {...rest}
  >
    <Text variant="desktop-s-medium" color="rythm.700">
      {label}
    </Text>
  </Button>
);
