import { FocusEventHandler } from 'react';
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
  SelectItem,
  SelectProps,
} from '../../..';

export type FieldSelectProps<SelectItemType extends SelectItem> =
  SelectProps<SelectItemType> & {
    label?: string;
  };

export const FieldSelect = <
  SelectItemType extends SelectItem = string,
  FormFieldValues extends FieldValues = FieldValues,
  FieldName extends FieldPath<FormFieldValues> = FieldPath<FormFieldValues>
>({
  name,
  control,
  rules,
  shouldUnregister,
  defaultValue,
  label,
  ...rest
}: UseControllerProps<FormFieldValues, FieldName> &
  FieldSelectProps<SelectItemType>) => {
  const {
    field,
    fieldState: { error, invalid },
  } = useController({
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
  });

  const onChange: SelectProps<SelectItemType>['onChange'] = (e) => {
    field.onChange(e);
    rest.onChange?.(e);
  };
  const onBlur: FocusEventHandler<HTMLSelectElement> = (e) => {
    field.onBlur();
    rest.onBlur?.(e);
  };

  return (
    <FormControl isInvalid={!!error}>
      {label && <FormLabel variant="simple">{label}</FormLabel>}
      <Select
        {...field}
        isInvalid={invalid}
        {...rest}
        onChange={onChange}
        onBlur={onBlur}
      />
      {error?.message && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};
