import { Select, SelectItem, SelectProps } from '../../inputs';
import { withField, WithFieldProps } from '../with-field';

/**
 * Using withField HOC in a component to pass ValueType type
 * @param props
 * @constructor
 */
export const FieldSelectLegacy = <ValueType extends SelectItem>(
  props: WithFieldProps<SelectProps<ValueType>, ValueType, SelectItem>
) => {
  const Comp = withField<SelectProps<ValueType>, ValueType, SelectItem>(Select);
  return Comp(props);
};
