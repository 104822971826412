import { ChangeEvent } from 'react';

import { TextArea, TextAreaProps } from '../../inputs';
import { withField } from '../with-field';

export const FieldTextAreaLegacy = withField<
  TextAreaProps,
  string,
  ChangeEvent<HTMLTextAreaElement>
>(TextArea);

export const FieldTextAreaWithErrorLegacy = withField<
  TextAreaProps,
  string,
  ChangeEvent<HTMLTextAreaElement>
>(TextArea, { hasFieldDisplayErrorMessage: true });
