import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  SelectItem,
  SelectWithSearch,
  SelectWithSearchProps,
} from '../../..';

export type FieldSelectWithSearchProps<SelectItemType extends SelectItem> =
  SelectWithSearchProps<SelectItemType> & {
    label?: string;
  };

export const FieldSelectWithSearch = <
  SelectItemType extends SelectItem = string,
  FormFieldValues extends FieldValues = FieldValues,
  FieldName extends FieldPath<FormFieldValues> = FieldPath<FormFieldValues>
>({
  name,
  control,
  rules,
  shouldUnregister,
  defaultValue,
  label,
  ...rest
}: UseControllerProps<FormFieldValues, FieldName> &
  FieldSelectWithSearchProps<SelectItemType>) => {
  const {
    field: { ref, ...field },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
  });

  const isInvalid = !!error;

  return (
    <FormControl isInvalid={!!error}>
      {label && <FormLabel variant="simple">{label}</FormLabel>}
      <SelectWithSearch {...field} {...rest} isInvalid={isInvalid} />
      {error?.message && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};
