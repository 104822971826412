import { FieldValidator } from 'final-form';
import { ReactNode } from 'react';
import { Field } from 'react-final-form';

import { RadioGroup } from '../../inputs';

export type FieldRadioGroupLegacyProps = {
  name: string;
  children: ReactNode;
  validate?: FieldValidator<string>;
};

export const FieldRadioGroupLegacy = ({
  name,
  children,
  validate,
}: FieldRadioGroupLegacyProps) => {
  return (
    <Field name={name} validate={validate}>
      {({ input }) => <RadioGroup {...input}>{children}</RadioGroup>}
    </Field>
  );
};
