import {
  DefaultOptions,
  Query,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactNode, useMemo } from 'react';

export const queryClientDefaultOptions: DefaultOptions = {
  queries: {
    retry: false,
    staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: false,
  },
};

export type QueryCacheOnError = (
  error: unknown,
  query: Query<unknown, unknown, unknown>
) => void;

type ReactQueryClientProps = {
  children: ReactNode;
  onError?: QueryCacheOnError;
};

export const ReactQueryProvider = ({
  children,
  onError,
}: ReactQueryClientProps) => {
  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: queryClientDefaultOptions,
        queryCache: new QueryCache({
          onError,
        }),
      }),
    [onError]
  );

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};
