import { randomColor } from '@chakra-ui/theme-tools';

import { Avatar, AvatarProps } from '../avatar';
import { LayerBorder, LayerBorderSize } from '../layer-border/layer-border';

type LayerAvatarSize = 'xs' | 'sm';

export type LayerAvatarProps = Omit<AvatarProps, 'size'> & {
  size?: LayerAvatarSize;
};

const layerAvatarToLayerBorderSize: Record<LayerAvatarSize, LayerBorderSize> = {
  xs: 'xs',
  sm: 'md',
};

export const LayerAvatar = ({
  children,
  size = 'sm',
  name,
  color,
  ...rest
}: LayerAvatarProps) => {
  const layerSize = layerAvatarToLayerBorderSize[size];
  const avatarColor =
    color ??
    (name
      ? randomColor({
          string: name,
          colors: ['primary.300', 'primary.600', 'primary.700', 'primary.800'],
        })
      : 'rythm.600');

  return (
    <LayerBorder color={avatarColor} size={layerSize}>
      <Avatar {...rest} size={size} color={avatarColor} name={name} />
    </LayerBorder>
  );
};
