import 'react-image-crop/dist/ReactCrop.css';

import {
  fileToUrl,
  getMimeTypeFromDataContent,
} from '@collective/utils/frontend';
import { ComponentProps, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactCrop, {
  centerCrop,
  Crop,
  makeAspectCrop,
  ReactCropProps,
} from 'react-image-crop';

import { Box, Button, Flex, Modal, Spinner } from '../..';

export interface CropperProps
  extends Omit<
    ReactCropProps,
    'crop' | 'onChange' | 'minHeight' | 'minWidth' | 'maxWidth' | 'maxHeight'
  > {
  image?: File | string;
  isOpen: boolean;
  onSubmit: (blob: File) => void | Promise<void>;
  onClose: VoidFunction;
  onError?: VoidFunction;
  title?: string;
  // Only for storybook, to be able to edit the controls
  // without messing with default accessibility of the modal
  trapFocus?: boolean;
}

export const Cropper = ({
  image,
  isOpen,
  title,
  onSubmit,
  onClose,
  onError,
  trapFocus = true,
  aspect = 1,
  ...props
}: CropperProps) => {
  const { t } = useTranslation('common');
  const [isLoading, setIsLoading] = useState(false);
  const [crop, setCrop] = useState<Crop>();
  const [imageSrc, setImageSrc] = useState<string>('');
  const imgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (!image) {
      return;
    }

    // handle if image is an url or a File entity
    if (typeof image === 'string') {
      setImageSrc(image);
    } else {
      fileToUrl(image).then(setImageSrc);
    }
  }, [image]);

  if (!image) {
    return null;
  }

  // Little trick to have easy TS typing
  const onImageLoad: ComponentProps<'img'>['onLoad'] = (e) => {
    const { width, height } = e.currentTarget;

    const crop = centerCrop(
      makeAspectCrop(
        {
          unit: 'px',
          width,
          height,
        },
        aspect,
        width,
        height
      ),
      width,
      height
    );

    setCrop(crop);
  };

  const onSave = async () => {
    setIsLoading(true);
    if (imgRef.current && crop) {
      const imageData = await canvasExport(imageSrc, imgRef.current, crop);

      if (imageData) {
        // Extension is used for the upload and canvas.toBlob generate png by default
        // The filename is not used and a random string is used on S3 so we don't really care of it
        onSubmit(new File([imageData], 'image.png'));
        setIsLoading(false);
      } else {
        onError?.();
        setIsLoading(false);
      }
    }
  };

  return (
    <Modal
      title={title || t('ui.cropper.title')}
      onClose={onClose}
      isOpen={isOpen}
      trapFocus={trapFocus}
      returnFocusOnClose={false}
    >
      <Box p={5} pt={4}>
        <Box textAlign="center">
          <ReactCrop crop={crop} onChange={setCrop} aspect={aspect} {...props}>
            <img
              src={imageSrc}
              onLoad={onImageLoad}
              ref={imgRef}
              data-chromatic="ignore"
              alt="Crop preview"
            />
          </ReactCrop>
        </Box>

        <Flex mt={5} justifyContent="flex-end">
          <Button variant="secondary" type="button" onClick={onClose}>
            {t('ui.cropper.cancel')}
          </Button>
          <Button
            ml={4}
            type="submit"
            isDisabled={!imgRef.current || !isValidCrop(crop) || isLoading}
            onClick={onSave}
            rightIcon={
              isLoading ? <Spinner size="md" color="white" /> : undefined
            }
          >
            {t('ui.cropper.save')}
          </Button>
        </Flex>
      </Box>
    </Modal>
  );
};

function isValidCrop(crop?: Crop) {
  return !!crop && !!crop.height && !!crop.width;
}

/**
 *
 * The preview will have some limit with the cropper, but on the scale image (in the modal).
 * With this canvasExport function, we take the fragment of the image on the real sized one,
 * not the one on the preview. It results to have an image that can be much more bigger
 * than what you cropped. If one day we need the max props, then we'll need to address this issue
 */
async function canvasExport(
  imageSrc: string,
  imgRef: HTMLImageElement,
  crop: Crop
): Promise<Blob | null> {
  return new Promise((resolve, reject) => {
    // In memory canvas to extract image data
    const tmpDrawCanvas = document.createElement('canvas');
    const tmpDrawCanvasContext = tmpDrawCanvas.getContext('2d');

    // Cross product to convert size or coordinates from the preview to the real image size
    const coordAtoB = (coordA: number, maxA: number, maxB: number) => {
      return (coordA * maxB) / maxA;
    };

    // Browser has cache so it should not redownload the image since it's already in the page
    const img = new Image();

    img.onload = () => {
      // Set the canvas to the real size of the image
      tmpDrawCanvas.width = img.width;
      tmpDrawCanvas.height = img.height;
      tmpDrawCanvasContext?.drawImage(img, 0, 0, img.width, img.height);

      if (tmpDrawCanvasContext) {
        const cropWidthRealSize = coordAtoB(
          crop.width,
          imgRef.width,
          img.width
        );
        const cropHeightRealSize = coordAtoB(
          crop.height,
          imgRef.height,
          img.height
        );
        // Get the fragment of the image
        const croppedImage = tmpDrawCanvasContext?.getImageData(
          coordAtoB(crop.x, imgRef.width, img.width),
          coordAtoB(crop.y, imgRef.height, img.height),
          cropWidthRealSize,
          cropHeightRealSize
        );

        // In memory canvas to be able to use the toDataURL method
        // Sadly we can't easily transform an ImageData into a Blob/File
        const tmpExportCanvas = document.createElement('canvas');
        const tmpExportCanvasContext = tmpExportCanvas.getContext('2d');
        tmpExportCanvas.width = cropWidthRealSize;
        tmpExportCanvas.height = cropHeightRealSize;
        tmpExportCanvasContext?.putImageData(croppedImage, 0, 0);

        // For more explanation on the toBlob params : https://github.com/Collective-work/Collective/pull/2455
        tmpExportCanvas.toBlob(
          resolve,
          getMimeTypeFromDataContent(imageSrc),
          0.9
        );
      } else {
        reject('Draw canvas context unavailable');
      }
    };
    img.onerror = () => reject();
    img.src = imageSrc;
    // Avoid canvas tainted error on `getImageData`
    img.setAttribute('crossOrigin', '');
  });
}
