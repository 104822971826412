import {
  BoxProps,
  ButtonProps,
  chakra,
  CheckboxGroup,
  List,
  ListProps,
  RadioGroup,
} from '@chakra-ui/react';
import { InMemorySearch } from '@collective/utils/helpers';
import {
  forwardRef,
  MouseEventHandler,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useClickAway } from 'react-use';

import { IconMagnifyingGlass, IconSelector } from '../../icon/icon';
import { BorderBox } from '../../layout/border-box';
import { Box } from '../../layout/box';
import { Flex } from '../../layout/flex';
import { Tooltip } from '../../tooltip/tooltip';
import { Text } from '../../typography';
import { Checkbox } from '../checkbox';
import { Radio } from '../radio';
import { TextInput, TextInputProps } from '../text-input';

export type SelectDropdownItemType<T extends string, U extends string> = {
  category?: T;
  value: U;
  label: string;
};

export type SelectDropdownProps<T extends string, U extends string> = {
  name?: string;
  boxLabel?: string | ((values: U[]) => string | undefined);
  placeholder?: string;
  items?: SelectDropdownItemType<T, U>[];
  disabledItems?: SelectDropdownItemType<T, U>[];
  categories?: {
    icon?: ReactNode;
    label?: string;
    value: T | null;
  }[];
  values?: U[];
  onChange?: (data: U[]) => void;
  multiple?: boolean;
  withSearch?: boolean;
  isReadOnly?: boolean;
  isDisabled?: boolean;
  isInvalid?: boolean;
  limit?: number;
  disableHintMessage?: string;
  wrapperProps?: BoxProps;
  buttonProps?: ButtonProps;
  menuProps?: ListProps;
  dropdownProps?: BoxProps;
};

export function SelectDropdown<
  CategoryType extends string = string,
  ItemType extends string = string
>({
  name,
  items = [],
  disabledItems = [],
  categories = [{ value: null }],
  values = [],
  onChange,
  multiple = false,
  wrapperProps,
  placeholder,
  isDisabled = false,
  isReadOnly = false,
  isInvalid,
  menuProps,
  dropdownProps,
  boxLabel,
  withSearch,
  limit,
  disableHintMessage,
  ...rest
}: SelectDropdownProps<CategoryType, ItemType>) {
  const { t } = useTranslation('common');
  const selectButtonRef = useRef<HTMLButtonElement>(null);
  const selectDropdownRef = useRef<HTMLDivElement>(null);
  const [isListOpen, updateIsListOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [filteredItems, setFilteredItems] = useState(items);
  const searchRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (search) {
      const inMemorySearch = new InMemorySearch(items, {
        keys: ['label'],
        threshold: 0.4,
      });

      setFilteredItems(inMemorySearch.query(search));
    } else {
      setFilteredItems(items);
    }
  }, [items, setFilteredItems, search]);

  useClickAway(selectDropdownRef, (e) => {
    // To handle the case of the click outside, on the button
    if (isListOpen) {
      e.preventDefault();
      e.stopPropagation();
      updateIsListOpen(false);
    }
  });

  const toggleList: MouseEventHandler<HTMLButtonElement> = () => {
    updateIsListOpen(!isListOpen);

    if (!isListOpen && withSearch && searchRef.current) {
      // setTimeout to make it work, otherwise it doesn't select the input
      setTimeout(() => searchRef.current!.focus(), 100);
    }
  };

  const onInputChange = (value: ItemType | ItemType[]) => {
    onChange?.(multiple ? (value as ItemType[]) : [value as ItemType]);
  };

  const wrapInputs = (children: ReactNode[]) => {
    return multiple ? (
      <CheckboxGroup
        value={values}
        onChange={(values) => onInputChange(values as ItemType[])}
      >
        {children}
      </CheckboxGroup>
    ) : (
      <RadioGroup
        value={values[0]}
        onChange={(values) => onInputChange(values as ItemType)}
      >
        {children}
      </RadioGroup>
    );
  };

  const _boxLabel =
    values.length === 0
      ? placeholder || t('ui.selectDropdown.placeholder')
      : (typeof boxLabel === 'string'
          ? boxLabel
          : typeof boxLabel === 'function'
          ? boxLabel(values)
          : undefined) ||
        t('ui.selectDropdown.selected', { count: values.length });

  return (
    <Box position="relative" w="100%" {...wrapperProps} ref={selectDropdownRef}>
      <chakra.button
        ref={selectButtonRef}
        type="button"
        layerStyle={
          isDisabled
            ? 'disabledSelectButton'
            : isReadOnly
            ? 'readOnlySelectButton'
            : 'selectButton'
        }
        sx={{
          '&:focus, &[aria-expanded="true"]': {
            borderColor: 'primary.600',
            'svg path': {
              stroke: 'rythm.900',
              transition: '.1s ease-in-out stroke',
            },
          },
          '&:hover svg path': {
            stroke: 'rythm.900',
            transition: '.1s ease-in-out stroke',
          },
        }}
        {...(isInvalid && { borderColor: 'critical.500' })}
        disabled={isReadOnly}
        {...rest}
        onMouseDown={toggleList}
      >
        <Text
          flex={1}
          textAlign="start"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          {...(!values.length && { color: 'rythm.600' })}
        >
          {_boxLabel}
        </Text>
        {!isReadOnly && <IconSelector color="rythm.600" />}
      </chakra.button>

      <List {...menuProps}>
        <BorderBox
          position="absolute"
          width="100%"
          boxShadow="md"
          zIndex={10}
          mt={1}
          display={isListOpen && !isReadOnly && !isDisabled ? 'block' : 'none'}
          _hover={{}}
          {...dropdownProps}
        >
          {withSearch && (
            <SelectDropdownSearchBar ref={searchRef} onChange={setSearch} />
          )}
          {/* This box is to contain and display a scrollbar in a decent manner */}
          <Box maxH="300px" overflowY="auto" mr="-1px" pr="1px" py={2}>
            {wrapInputs(
              categories.map((category, categoryIndex) => {
                const choices = filteredItems.filter((item) =>
                  typeof item.category !== 'undefined'
                    ? item.category === category.value
                    : category.value === null
                );

                if (!choices.length) {
                  return null;
                }

                return (
                  <Box key={categoryIndex}>
                    {!!category.label && (
                      <Flex pl="20px" alignItems="center" h="40px">
                        {category.icon}
                        <Text
                          variant="desktop-s-bold"
                          color="rythm.700"
                          pl="12px"
                        >
                          {category.label}
                        </Text>
                      </Flex>
                    )}
                    {choices.map((choice, index) => {
                      const isDisabled = limit
                        ? values.length >= limit &&
                          !values.includes(choice.value)
                        : false;

                      return (
                        <Flex
                          key={index}
                          pl={category.label ? '40px' : '20px'}
                          pr="20px"
                          h="40px"
                          alignItems="center"
                          borderLeft={
                            values.includes(choice.value)
                              ? '2px solid var(--chakra-colors-primary-600)'
                              : '2px solid transparent'
                          }
                          sx={
                            values.includes(choice.value)
                              ? {
                                  '.chakra-checkbox__label': {
                                    color: 'primary.600',
                                  },
                                }
                              : {}
                          }
                          position="relative"
                        >
                          <Tooltip
                            hasArrow
                            placement="right"
                            label={disableHintMessage}
                            shouldWrapChildren
                            isDisabled={!isDisabled}
                          >
                            {multiple ? (
                              <Checkbox
                                name={name}
                                value={choice.value}
                                isDisabled={
                                  isDisabled ||
                                  disabledItems.some(
                                    (item) => item.value === choice.value
                                  )
                                }
                              >
                                <Text>{choice.label}</Text>
                              </Checkbox>
                            ) : (
                              <Radio
                                name={name}
                                value={choice.value}
                                isDisabled={
                                  isDisabled ||
                                  disabledItems.some(
                                    (item) => item.value === choice.value
                                  )
                                }
                              >
                                {choice.label}
                              </Radio>
                            )}
                          </Tooltip>
                        </Flex>
                      );
                    })}
                  </Box>
                );
              })
            )}
          </Box>
        </BorderBox>
      </List>
    </Box>
  );
}

type SelectDropdownSearchBarProps = {
  onChange: (search: string) => void;
};

const SelectDropdownSearchBar = forwardRef<
  HTMLInputElement,
  SelectDropdownSearchBarProps
>(function SelectDropdownSearchBar(
  { onChange }: SelectDropdownSearchBarProps,
  ref
) {
  const [search, setSearch] = useState('');
  const timeoutIdRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    clearTimeout(timeoutIdRef.current);

    timeoutIdRef.current = setTimeout(() => {
      onChange(search);
    }, 200);

    return () => {
      clearTimeout(timeoutIdRef.current);
    };
  }, [search, onChange]);

  const searchChanged: TextInputProps['onChange'] = (e) => {
    setSearch(e.target.value);
  };

  return (
    <Box
      m="16px 16px 8px"
      sx={{
        'input:focus + div svg path': {
          fill: 'rythm.900',
        },
      }}
    >
      <TextInput
        ref={ref}
        leftElement={<IconMagnifyingGlass color="rythm.600" />}
        value={search}
        onChange={searchChanged}
      />
    </Box>
  );
});
