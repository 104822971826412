import { mergeAttributes, Node, NodeViewRendererProps } from '@tiptap/core';
import { NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';
import { TFunction } from 'react-i18next';

import { AspectRatio } from '../../../layout/aspect-ratio';
import { Box } from '../../../layout/box';
import { useErrorToast } from '../../../toast/toast';

// Only for Youtube for now but it can be extended to other video provider
const YOUTUBE_REGEX_GLOBAL =
  /(?:(?:youtube\.com\/watch\?.*v=)|(?:youtu\.be\/))([0-9A-Za-z_-]+)/i;
const YOUTUBE_EMBED_VALID_REGEX = /youtube\.com\/embed\/([0-9A-Za-z_-]+)/i;

function isValidYoutubeUrl(url: string | null): boolean {
  return !!url?.match(YOUTUBE_REGEX_GLOBAL);
}
function isValidYoutubeEmbedUrl(url: string | null): boolean {
  return !!url?.match(YOUTUBE_EMBED_VALID_REGEX);
}
function getEmbedUrlFromYoutubeUrl(url: string): string | null {
  if (!isValidYoutubeUrl(url)) {
    return null;
  }

  const match = url.match(YOUTUBE_REGEX_GLOBAL);

  return `https://www.youtube.com/embed/${match![1]}`;
}

type SetVideoOptions = {
  src: string;
  errorToast: ReturnType<typeof useErrorToast>;
  t: TFunction;
};

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    video: {
      setVideo: (options: SetVideoOptions) => ReturnType;
    };
  }
}

export const VideoExtension = Node.create({
  name: 'video-component',
  draggable: false,
  group: 'block',
  atom: true,

  addAttributes() {
    return {
      src: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'video-component',
      },
    ];
  },

  addCommands() {
    return {
      setVideo:
        (options: SetVideoOptions) =>
        ({ commands }) => {
          if (!isValidYoutubeUrl(options.src)) {
            options.errorToast({
              title: options.t('ui.richEditor.video.invalidLinkTitle'),
              description: options.t(
                'ui.richEditor.video.invalidLinkDescription'
              ),
            });
            return false;
          }

          return commands.insertContent({
            type: this.name,
            attrs: {
              src: getEmbedUrlFromYoutubeUrl(options.src),
            },
          });
        },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'video-component',
      mergeAttributes(HTMLAttributes, this.options.HTMLAttributes),
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(VideoComponent);
  },
});

// Used to render the node in the editor
const VideoComponent = (props: NodeViewRendererProps) => {
  return (
    <NodeViewWrapper className="video-component">
      <Box _hover={{ bg: 'primary.25' }} py="1rem" px={0} borderRadius={12}>
        <AspectRatio maxW="560px" ratio={16 / 9} mx="auto">
          <iframe
            title="Youtube video"
            allowFullScreen
            src={props.node.attrs.src}
          />
        </AspectRatio>
      </Box>
    </NodeViewWrapper>
  );
};

// Used to render the node in the viewer
export const ViewVideoComponent = ({ src }: { src: string | null }) => {
  // If there is no src or it's not valid, doesn't render the iframe
  // It avoids hacks where someone insert malicious code with a video-component tag but a custom src
  if (!isValidYoutubeEmbedUrl(src)) {
    return null;
  }

  return (
    // bg="rythm.200" is not resolved, probably because it's inserted in a new react tree without the UI provider ?
    <Box w="100%" bg="var(--chakra-colors-rythm-200)" borderRadius={12}>
      <AspectRatio maxW="560px" ratio={16 / 9} mx="auto">
        {src && <iframe title="Youtube video" allowFullScreen src={src} />}
      </AspectRatio>
    </Box>
  );
};
