import { useTheme } from '@chakra-ui/react';
import { getRichEditorStrippedValue } from '@collective/utils/helpers';
import Focus from '@tiptap/extension-focus';
import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import {
  AnyExtension,
  BubbleMenu,
  EditorContent,
  EditorEvents,
  HTMLContent,
  useEditor,
} from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { Box, BoxProps, Center, FlexProps } from '../../layout';
import { Spinner } from '../../spinner/spinner';
import { CharsLeftCounter } from '../chars-left-counter/chars-left-counter';
import { EnhancedImageExtension } from './extensions/enhanced-image';
import { ImageDropExtension } from './extensions/image-drop';
import { ImagePasteExtension } from './extensions/image-paste';
import { VideoExtension } from './extensions/video';
import { richTextEditorStyles } from './rich-text-editor-styles';
import { ToolbarFeature } from './toolbar/constants';
import { Toolbar } from './toolbar/toolbar';

export * from './toolbar/constants';

export const DEBOUNCE_TIME_WITH_CHAR_LIMIT = 200;
export const DEBOUNCE_TIME_WITHOUT_CHAR_LIMIT = 500;

export const getEditorExtensions = (
  mergedToolbar: ToolbarFeature[][],
  placeholder?: string,
  onUploadImage?: (file: File) => Promise<string | null>,
  setIsUploadingImage?: (state: boolean) => void
) => {
  const toolbarImplementedFeatures: ToolbarFeature[] = mergedToolbar.reduce(
    (_implementedFeatures, toolbarFeatures) => {
      toolbarFeatures.forEach(
        (feature) =>
          !_implementedFeatures.includes(feature) &&
          _implementedFeatures.push(feature)
      );

      return _implementedFeatures;
    },
    []
  );

  // We only load the extension used and present in the toolbar to
  // not allow shortcuts of not intended extensions
  const extensions = toolbarImplementedFeatures.reduce(
    (acc, feature) => {
      switch (feature) {
        case ToolbarFeature.Underline:
          acc.push(Underline);
          break;
        case ToolbarFeature.TextAlignement:
          acc.push(
            TextAlign.configure({
              types: ['heading', 'paragraph'],
              alignments: ['left', 'right', 'center', 'justify'],
            })
          );
          break;
        case ToolbarFeature.Link:
          acc.push(Link);
          break;
        case ToolbarFeature.ImageUpload:
          acc.push(EnhancedImageExtension);

          if (onUploadImage) {
            acc.push(
              ImageDropExtension.configure({
                upload: onUploadImage,
                setIsUploadingImage,
              })
            );
            acc.push(
              ImagePasteExtension.configure({
                upload: onUploadImage,
                setIsUploadingImage,
              })
            );
          }
          break;
        case ToolbarFeature.VideoIntegration:
          acc.push(VideoExtension);
          break;
        default:
          break;
      }

      return acc;
    },
    [
      // We need to use the StarterKit to get basic nodes to make tiptap work
      // and avoid having to add multiple packages in the package.json
      // So the default feature are handled here differently than the extensions added
      // https://tiptap.dev/api/extensions/starter-kit
      StarterKit.configure({
        heading: toolbarImplementedFeatures.includes(ToolbarFeature.Title)
          ? {
              levels: [1, 2, 3],
            }
          : false,
        ...(!toolbarImplementedFeatures.includes(ToolbarFeature.Bold) && {
          bold: false,
        }),
        ...(!toolbarImplementedFeatures.includes(ToolbarFeature.Italic) && {
          italic: false,
        }),
        ...(!toolbarImplementedFeatures.includes(ToolbarFeature.BulletList) && {
          bulletList: false,
        }),
        ...(!toolbarImplementedFeatures.includes(
          ToolbarFeature.OrderedList
        ) && { orderedList: false }),
        // We didn't implement this so we disable it, to not have it accessible with the shortcuts
        code: false,
        codeBlock: false,
        blockquote: false,
        horizontalRule: false,
      }),
      Focus,
      Placeholder.configure({
        placeholder,
      }),
    ] as AnyExtension[]
  );

  return extensions;
};

type RichTextEditorVariants = 'naked' | 'textarea';

export type RichTextEditorProps = Omit<BoxProps, 'onChange'> & {
  value?: HTMLContent;
  isInvalid?: boolean;
  placeholder?: string;
  menuTitle?: string;
  onChange?: (value: HTMLContent) => void;
  onUploadImage?: (file: File) => Promise<string | null>;
  variant?: RichTextEditorVariants;
  toolbar?: ToolbarFeature[][];
  bottomToolbar?: ToolbarFeature[][];
  bottomToolbarStyle?: FlexProps;
  maxLength?: number;
};

export const RichTextEditor = forwardRef<HTMLDivElement, RichTextEditorProps>(
  function RichTextEditor(
    {
      value,
      isInvalid,
      placeholder,
      menuTitle,
      onChange,
      variant = 'naked',
      onUploadImage,
      toolbar,
      bottomToolbar,
      bottomToolbarStyle = {},
      'aria-label': ariaLabel = '',
      maxLength,
      ...rest
    },
    ref
  ) {
    const [isUploadingImage, setIsUploadingImage] = useState(false);
    const theme = useTheme();

    // We debounce to avoid too much refresh, for better performance
    // Instead of calling the onChange after each characters, we kinda "batch" the changes
    // The debounce time is different when we display the character left count to update it more frequently
    // It makes it more UX friendly and it doesn't feel weird while typing in the rich text
    const debouncedOnUpdate = useDebouncedCallback(
      ({ editor }: EditorEvents['update']) => {
        const html = editor.getHTML();

        onChange?.(html);
      },
      maxLength
        ? DEBOUNCE_TIME_WITH_CHAR_LIMIT
        : DEBOUNCE_TIME_WITHOUT_CHAR_LIMIT
    );

    const extensions = useMemo(
      () =>
        getEditorExtensions(
          [...(toolbar || []), ...(bottomToolbar || [])],
          placeholder,
          onUploadImage,
          setIsUploadingImage
        ),
      [toolbar, bottomToolbar, placeholder, onUploadImage, setIsUploadingImage]
    );

    // This is a hack needed because of useEditor's handling of the dependency array (the editor looses focus, which is bad with our current debounced onUpdate)
    // https://github.com/ueberdosis/tiptap/issues/2403
    const initialTruthyValue = useRef(value);

    const editor = useEditor(
      {
        extensions,
        content: initialTruthyValue.current,
        editorProps: {
          attributes: { role: 'textbox', 'aria-label': ariaLabel },
        },
      },
      [] // Keep this dependency array empty so the editor won't rerender and so not lose the focus
    );

    useEffect(() => {
      if (!initialTruthyValue.current && value) {
        initialTruthyValue.current = value;
        editor?.commands.setContent(value);
      }
    }, [editor?.commands, value]);

    // https://github.com/ueberdosis/tiptap/issues/2403#issuecomment-1193225262
    useEffect(() => {
      editor && editor.on('update', debouncedOnUpdate);
      return () => {
        editor && editor.off('update', debouncedOnUpdate);
      };
    }, [editor, debouncedOnUpdate]);

    // -- End of hack --

    const variantStyle = getVariantStyle(variant, isInvalid);

    const handleUploadImage = useCallback(
      async (file: File) => {
        if (editor && onUploadImage) {
          setIsUploadingImage(true);
          const newPictureUrl = await onUploadImage(file);
          if (newPictureUrl) {
            editor.chain().focus().setImage({ src: newPictureUrl }).run();
          }
          setIsUploadingImage(false);
        }
      },
      [editor, onUploadImage]
    );

    const contentWithoutHTML = getRichEditorStrippedValue(value);

    return (
      // The ref are here and not on the box having the editor
      // Because there was an error on react-hook-form validation
      // Since we use the `as` prop, the ref was not an HTML element
      // And RHF tries to call a focus method on error, which didn't exist
      <Box ref={ref}>
        {editor && (
          <BubbleMenu editor={editor} tippyOptions={{ duration: 100 }}>
            <Toolbar
              editor={editor}
              menuTitle={menuTitle}
              toolbar={toolbar}
              {...(onUploadImage && { onUploadImage: handleUploadImage })}
            />
          </BubbleMenu>
        )}
        <Box
          as={EditorContent}
          editor={editor}
          sx={richTextEditorStyles(theme.colors)}
          {...variantStyle}
          {...rest}
        >
          <Box
            position="absolute"
            top={0}
            bottom={0}
            left={0}
            right={0}
            transition="opacity .2s ease-in-out"
            opacity={0}
            bg="rythm.900"
            {...(isUploadingImage && {
              zIndex: 1,
              opacity: 0.5,
            })}
          >
            <Center h="100%">
              <Spinner />
            </Center>
          </Box>
        </Box>
        {editor && !!bottomToolbar?.length && (
          <Toolbar
            editor={editor}
            toolbar={bottomToolbar}
            toolbarStyle={{
              px: '26px',
              py: '18px',
              border: 0,
              bg: 'transparent',
              boxSizing: 'content-box',
              ...bottomToolbarStyle,
            }}
            variant="iconButton"
            {...(onUploadImage && { onUploadImage: handleUploadImage })}
          />
        )}
        {!!maxLength && (
          <CharsLeftCounter maxLength={maxLength} value={contentWithoutHTML} />
        )}
      </Box>
    );
  }
);

function getVariantStyle(
  variant: RichTextEditorVariants,
  isInvalid?: boolean
): Partial<BoxProps> {
  switch (variant) {
    case 'textarea':
      return {
        border: '1px solid',
        borderColor: isInvalid ? 'critical.700' : 'rythm.300',
        borderRadius: '8px',
        _hover: {
          borderColor: isInvalid ? 'critical.700' : 'rythm.600',
        },
        p: '6px 8px',
        _focusWithin: {
          borderColor: 'primary.600',
        },
      };
    default:
      return {};
  }
}
