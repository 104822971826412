import { useMergeRefs } from '@chakra-ui/react';
import { FocusEventHandler, ForwardedRef, forwardRef } from 'react';
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  RichTextEditor,
  RichTextEditorProps,
} from '../../..';

export type FieldRichTextEditorProps = RichTextEditorProps & {
  label?: React.ReactNode;
  info?: React.ReactNode;
};

const FieldRichTextEditorInner = <
  FormFieldValues extends FieldValues = FieldValues,
  FieldName extends FieldPath<FormFieldValues> = FieldPath<FormFieldValues>
>(
  {
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
    label,
    info,
    ...rest
  }: UseControllerProps<FormFieldValues, FieldName> & FieldRichTextEditorProps,
  forwardedRef: ForwardedRef<HTMLDivElement>
) => {
  const {
    field: { ref: controllerRef, ...fieldRest },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
  });
  const refs = useMergeRefs(controllerRef, forwardedRef);

  const onBlur: FocusEventHandler<HTMLDivElement> = (e) => {
    fieldRest.onBlur();
    rest.onBlur?.(e);
  };
  const onChange = (value: string) => {
    fieldRest.onChange(value);
    rest.onChange?.(value);
  };

  return (
    <FormControl isInvalid={!!error}>
      {!!label && <FormLabel variant="simple">{label}</FormLabel>}
      <RichTextEditor
        ref={refs}
        {...fieldRest}
        {...rest}
        isInvalid={!!error}
        onBlur={onBlur}
        onChange={onChange}
      />
      {error?.message && <FormErrorMessage>{error.message}</FormErrorMessage>}
      {!!info && <FormHelperText>{info}</FormHelperText>}
    </FormControl>
  );
};

export const FieldRichTextEditor = forwardRef(FieldRichTextEditorInner);
