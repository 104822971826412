import type { ShowProps as ChakraShowProps } from '@chakra-ui/react';
import { Show as ChakraShow } from '@chakra-ui/react';

import type { Breakpoint } from '../../themes/values';
import { Box } from '../layout/box';

export type ShowProps = Pick<ChakraShowProps, 'children' | 'ssr'> & {
  above: Breakpoint;
};

export const Show = ({ above, children, ssr = false }: ShowProps) => {
  return (
    <ChakraShow above={above} ssr={ssr}>
      {children}
    </ChakraShow>
  );
};

type MediaQueryShowProps = Omit<ShowProps, 'ssr'>;

export const MediaQueryShow = ({ above, children }: MediaQueryShowProps) => {
  return <Box display={{ base: 'none', [above]: 'block' }}>{children}</Box>;
};
