const variants = {
  dropdown: {
    popper: {
      boxShadow: 'md',
      border: '1px solid',
      borderColor: 'rythm.200',
      borderRadius: '12px',
      overflow: 'hidden',
    },
    content: {
      py: '8px',
      border: 'none',
    },
  },
};

export default {
  baseStyle: {
    body: {
      py: 4,
      px: 5,
    },
    footer: {
      borderTopWidth: 0,
      py: 0,
      px: 5,
      pb: 4,
    },
  },
  sizes: {
    small: { w: 352 },
    regular: { w: 608 },
    large: { w: 800 },
  },
  variants,
};
