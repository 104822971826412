import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormErrorMessage,
} from '../../..';

export type FieldCheckboxProps = CheckboxProps & {
  label?: string;
};

export const FieldCheckbox = <
  FormFieldValues extends FieldValues = FieldValues,
  FieldName extends FieldPath<FormFieldValues> = FieldPath<FormFieldValues>
>({
  name,
  control,
  rules,
  shouldUnregister,
  defaultValue,
  label,
  children,
  ...rest
}: UseControllerProps<FormFieldValues, FieldName> & FieldCheckboxProps) => {
  const {
    field: { ref, ...field },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
  });

  return (
    <FormControl isInvalid={!!error}>
      <Checkbox {...field} {...rest} isChecked={field.value}>
        {label}
        {children}
      </Checkbox>
      {error?.message && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};
