import { getDecimalSeparator } from '@collective/utils/helpers';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  NumberFormatValues,
  NumericFormat,
  NumericFormatProps,
} from 'react-number-format';

import { TextInput, TextInputProps } from './text-input';

export type NumberInputProps = Omit<TextInputProps, 'onChange' | 'value'> &
  Omit<NumericFormatProps, 'onChange'> & {
    isDisplayOnly?: boolean;
    onChange?: (value: string) => void;
    allowNegative?: boolean;
  };

export const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
  function NumberInput(
    {
      value,
      isDisplayOnly,
      isDisabled,
      onChange,
      onFocus,
      allowNegative = false,
      ...rest
    },
    ref
  ) {
    const { i18n } = useTranslation();
    const decimalSeparator = getDecimalSeparator(i18n.language);
    const showDisplayOnlyNormally = !!(isDisplayOnly && value && value !== '0');

    return (
      <TextInput
        as={NumericFormat}
        // --- NumericFormat props
        value={value || ''}
        getInputRef={ref}
        allowNegative={allowNegative}
        valueIsNumericString
        allowedDecimalSeparators={['.', ',']}
        decimalSeparator={decimalSeparator}
        decimalScale={2}
        onValueChange={(values: NumberFormatValues) => {
          onChange?.(values.value);
        }}
        // --- TextInput props
        isDisabled={isDisabled || isDisplayOnly}
        onFocus={(e) => {
          e.target.select();
          onFocus?.(e);
        }}
        backgroundColor="white"
        {...(showDisplayOnlyNormally && {
          inputGroupProps: {
            sx: {
              '&& > *': { opacity: 1 },
            },
          },
        })}
        {...rest}
      />
    );
  }
);
