// The en locale doesn't export the relativeTime language compared to the french language
// Because it rely on a default value, not exported
// (see : https://github.com/iamkun/dayjs/blob/c9370ea96bf420439ee7eaa4146e8ed643160312/src/plugin/relativeTime/index.js)

const defaultRelativeTime = {
  future: 'in %s',
  past: '%s ago',
  s: 'a few seconds',
  m: 'a minute',
  mm: '%d minutes',
  h: 'an hour',
  hh: '%d hours',
  d: 'a day',
  dd: '%d days',
  M: 'a month',
  MM: '%d months',
  y: 'a year',
  yy: '%d years',
};

export const enLocaleConfig = {
  locale: 'en',
  config: {
    formats: {
      L: 'DD / MM / YYYY',
      ll: 'MMM D, YYYY',
      mask: 'd{ / }`m{ / }`Y',
    },
    relativeTime: {
      ...defaultRelativeTime,
      m: '1 minute',
      h: '1 hour',
      d: '1 day',
      M: '1 month',
      y: '1 year',
    },
  },
};
