import type { HideProps as ChakraHideProps } from '@chakra-ui/react';
import { Hide as ChakraHide } from '@chakra-ui/react';

import type { Breakpoint } from '../../themes/values';
import { Box } from '../layout/box';

export type HideProps = Pick<ChakraHideProps, 'children' | 'ssr'> & {
  above: Breakpoint;
};

export const Hide = ({ above, children, ssr = false }: HideProps) => {
  return (
    <ChakraHide above={above} ssr={ssr}>
      {children}
    </ChakraHide>
  );
};

type MediaQueryHideProps = Omit<HideProps, 'ssr'>;

export const MediaQueryHide = ({ above, children }: MediaQueryHideProps) => {
  return <Box display={{ base: 'block', [above]: 'none' }}>{children}</Box>;
};
