const sizes = {
  xs: {
    container: {
      width: '16px',
      height: '16px',
      borderRadius: '6px',
    },
    label: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: '600',
    },
  },
  sm: {
    container: {
      width: '24px',
      height: '24px',
      borderRadius: '8px',
    },
    label: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: '700',
    },
  },
  smRound: {
    container: {
      width: '24px',
      height: '24px',
      borderRadius: '8px',
    },
    label: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: '700',
    },
  },
  md: {
    container: {
      width: '32px',
      height: '32px',
      borderRadius: '10px',
    },
    label: {
      fontSize: '18px',
      lineHeight: '28px',
      fontWeight: '700',
    },
  },
  lg: {
    container: {
      width: '40px',
      height: '40px',
      borderRadius: '12px',
    },
    label: {
      fontSize: '18px',
      lineHeight: '28px',
      fontWeight: '700',
    },
  },
  xl: {
    container: {
      width: '48px',
      height: '48px',
      borderRadius: '12px',
    },
    label: {
      fontSize: '18px',
      lineHeight: '28px',
      fontWeight: '700',
    },
  },
  xxl: {
    container: {
      width: '60px',
      height: '60px',
      borderRadius: '12px',
    },
    label: {
      fontSize: '18px',
      lineHeight: '28px',
      fontWeight: '700',
    },
  },
  xxlRound: {
    container: {
      width: '60px',
      height: '60px',
      borderRadius: '60px',
    },
    label: {
      fontSize: '18px',
      lineHeight: '28px',
      fontWeight: '700',
    },
  },
  xxxxlRound: {
    container: {
      width: '128px',
      height: '128px',
      borderRadius: '128px',
    },
    label: {
      fontSize: '36px',
      lineHeight: '50px',
      fontWeight: '700',
    },
  },
};

export type AvatarSize = keyof typeof sizes;

type avatarWithBorderPropertiesType = Record<
  AvatarSize,
  {
    container: {
      borderRadius?: string;
    };
    firstBoxStyle: {
      width?: string;
      height?: string;
      borderRadius?: string;
    };
    secondBoxStyle: {
      width?: string;
      height?: string;
      borderRadius?: string;
    };
  }
>;

export const avatarWithBorderProperties: avatarWithBorderPropertiesType = {
  xs: {
    container: {
      borderRadius: '6px',
    },
    firstBoxStyle: {
      width: '20px',
      height: '20px',
      borderRadius: '7px',
    },
    secondBoxStyle: {
      width: '18px',
      height: '18px',
      borderRadius: '6px',
    },
  },
  sm: {
    container: {
      borderRadius: '8px',
    },
    firstBoxStyle: {
      width: '28px',
      height: '28px',
      borderRadius: '10px',
    },
    secondBoxStyle: {
      width: '26px',
      height: '26px',
      borderRadius: '9px',
    },
  },
  smRound: {
    container: {
      borderRadius: '18px',
    },
    firstBoxStyle: {
      width: '28px',
      height: '28px',
      borderRadius: '18px',
    },
    secondBoxStyle: {
      width: '26px',
      height: '26px',
      borderRadius: '18px',
    },
  },
  md: {
    container: {
      borderRadius: '10px',
    },
    firstBoxStyle: {
      width: '40px',
      height: '40px',
      borderRadius: '14px',
    },
    secondBoxStyle: {
      width: '36px',
      height: '36px',
      borderRadius: '12px',
    },
  },
  lg: {
    container: {
      borderRadius: '10px',
    },
    firstBoxStyle: {
      width: '48px',
      height: '48px',
      borderRadius: '14px',
    },
    secondBoxStyle: {
      width: '44px',
      height: '44px',
      borderRadius: '12px',
    },
  },
  xl: {
    container: {
      borderRadius: '14px',
    },
    firstBoxStyle: {
      width: '56px',
      height: '56px',
      borderRadius: '18px',
    },
    secondBoxStyle: {
      width: '52px',
      height: '52px',
      borderRadius: '16px',
    },
  },
  xxl: {
    container: {
      borderRadius: '16px',
    },
    firstBoxStyle: {
      width: '68px',
      height: '68px',
      borderRadius: '20px',
    },
    secondBoxStyle: {
      width: '64px',
      height: '64px',
      borderRadius: '18px',
    },
  },
  xxlRound: {
    container: {
      borderRadius: '64px',
    },
    firstBoxStyle: {
      width: '68px',
      height: '68px',
      borderRadius: '64px',
    },
    secondBoxStyle: {
      width: '64px',
      height: '64px',
      borderRadius: '64px',
    },
  },
  xxxxlRound: {
    container: {
      borderRadius: '124px',
    },
    firstBoxStyle: {
      width: '128px',
      height: '128px',
      borderRadius: '128px',
    },
    secondBoxStyle: {
      width: '124px',
      height: '124px',
      borderRadius: '124px',
    },
  },
};

const Avatar = {
  baseStyle: {
    container: {
      borderColor: 'white',
      color: 'white',
      overflow: 'hidden',
    },
  },
  sizes,
};

export default Avatar;
