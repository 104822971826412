import { HTMLContent } from '@tiptap/react';

import { RichTextEditor, RichTextEditorProps } from '../../inputs';
import { withField } from '../with-field';

export const FieldRichTextEditorLegacy = withField<
  RichTextEditorProps,
  HTMLContent,
  HTMLContent
>(RichTextEditor);
