import {
  amountToNumber,
  numberToAmount,
  parseDecimal,
} from '@collective/utils/helpers';
import { isNil } from 'lodash';
import { ChangeEvent } from 'react';

import { AmountInputLegacy, AmountInputLegacyProps } from '../../inputs';
import { withField } from '../with-field';

// This is the legacy component for what used to be FieldAmountInput, that got renamed FieldCurrencyInput
// Ideally this should be removed and its usage replaced by FieldCurrencyInput
export const FieldAmountInputLegacy = withField<
  AmountInputLegacyProps,
  number | null,
  ChangeEvent<HTMLInputElement>
>(AmountInputLegacy, {
  format: (amount) => {
    if (isNil(amount)) {
      return null;
    }

    return amount.toString();
  },
  parse: (value: string) => {
    if (value === '') {
      return null;
    }
    const parsedValue = parseDecimal(value);

    return parsedValue.mul(100).trunc().div(100).toNumber();
  },
});

// The legacy amount input using cents
export const FieldAmountInputLegacyWithCents = withField<
  AmountInputLegacyProps,
  number | null,
  ChangeEvent<HTMLInputElement>
>(AmountInputLegacy, {
  format: (amount) => {
    if (isNil(amount)) {
      return null;
    }

    return amountToNumber(amount).toString();
  },
  parse: (value: string) => {
    if (value === '') {
      return null;
    }
    const parsedValue = parseFloat(value);
    if (isNaN(parsedValue)) {
      return null;
    }
    return Math.abs(numberToAmount(parsedValue));
  },
});
