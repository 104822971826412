import {
  PlacementWithLogical,
  Slider as SliderComponent,
  SliderFilledTrack,
  SliderProps as SliderPropsType,
  SliderThumb,
  SliderTrack,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

import { Tooltip } from '../tooltip/tooltip';

export type {
  UseRangeSliderProps,
  UseRangeSliderReturn,
  UseSliderProps,
  UseSliderReturn,
} from '@chakra-ui/react';
export { useRangeSlider, useSlider } from '@chakra-ui/react';

export type SliderProps = SliderPropsType & {
  children?: ReactNode;
  isInvalid?: boolean;
  tooltip?: {
    label: string;
    isOpen: boolean;
    position?: PlacementWithLogical;
  };
};

export const Slider = ({
  children,
  tooltip,
  size = 'sm',
  ...rest
}: SliderProps) => {
  return (
    <SliderComponent ba size={size} {...rest}>
      <SliderTrack background="rythm.300">
        <SliderFilledTrack background="primary.600" />
      </SliderTrack>
      <Tooltip
        hasArrow
        placement={tooltip?.position || 'top'}
        isDisabled={!tooltip}
        isOpen={tooltip?.isOpen}
        label={tooltip?.label}
      >
        <SliderThumb
          background="primary.300"
          w="16px"
          h="16px"
          borderColor="primary.600"
          borderWidth="1px"
        />
      </Tooltip>
      {children}
    </SliderComponent>
  );
};

export { SliderMark } from '@chakra-ui/react';
