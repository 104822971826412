import Decimal from 'decimal.js';

import { NumberInputLegacy, NumberInputLegacyProps } from '../../inputs';
import { withField } from '../with-field';

export const FieldNumberInputLegacy = withField<
  NumberInputLegacyProps,
  string | null,
  string
>(NumberInputLegacy, {
  parse: (value: string) => {
    const cleanValue = value.replace(/[^0-9.,]+/, '');

    if (cleanValue === '') {
      return null;
    }

    const parsedValue = parseFloat(cleanValue);
    if (isNaN(parsedValue)) {
      return null;
    }

    // Do not allow more than one decimal separator character in the field
    // Round to 2 decimals
    if (
      (cleanValue.match(/[.,]/g) || []).length > 1 ||
      !Number.isInteger(parsedValue)
    ) {
      return new Decimal(parsedValue)
        .toDecimalPlaces(2, Decimal.ROUND_DOWN)
        .toString();
    }

    return cleanValue;
  },
});
