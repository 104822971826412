import { defineStyle, defineStyleConfig, HeadingProps } from '@chakra-ui/react';

const baseVariants = {
  // --- Desktop
  'desktop-xxxxl-medium': defineStyle({
    fontSize: '3rem', // 48px
    lineHeight: '4rem', // 62px
    letterSpacing: '-0.02rem',
    fontWeight: 500,
  }),
  'desktop-xxxl-bold': defineStyle({
    fontSize: '2.5rem', // 40px
    lineHeight: '3.5rem', // 56px
    letterSpacing: '-0.02rem',
    fontWeight: 700,
  }),
  'desktop-xxl-medium': defineStyle({
    fontSize: '2rem', // 32px
    lineHeight: '2.5rem', // 40px
    letterSpacing: '-0.02rem',
    fontWeight: 500,
  }),
  'desktop-xxl-bold': defineStyle({
    fontSize: '2rem', // 32px
    lineHeight: '2.5rem', // 40px
    letterSpacing: '-0.02rem',
    fontWeight: 700,
  }),
  'desktop-xl-bold': defineStyle({
    fontSize: '1.5rem', // 24px
    lineHeight: '2rem', // 32px
    letterSpacing: '-0.02rem',
    fontWeight: 700,
  }),
  'desktop-l-bold': defineStyle({
    fontSize: '1.125rem', // 18px
    lineHeight: '1.75rem', // 28px
    letterSpacing: '-0.01rem',
    fontWeight: 700,
  }),
  'desktop-l-semibold': defineStyle({
    fontSize: '1.125rem', // 18px
    lineHeight: '1.75rem', // 28px
    fontWeight: 600,
  }),
  'desktop-m-bold': defineStyle({
    fontSize: '1rem', // 16px
    lineHeight: '1.5rem', // 24px
    fontWeight: 700,
  }),
  // --- Mobile
  'mobile-m-bold': defineStyle({
    fontSize: '1rem', // 16px
    lineHeight: '1.375rem', // 22px
    fontWeight: 700,
  }),
};

// To create new variants with the same base style, but with a new prefix and extra properties
const variants: Record<string, HeadingProps> = [
  {
    prefix: 'brand',
    styleAdded: { fontFamily: "Lexend, 'Inter', Arial, Helvetica" },
  },
].reduce((acc, { prefix, styleAdded }) => {
  const newVariant: Record<string, HeadingProps> = {};

  for (const variantKey in baseVariants) {
    newVariant[`${prefix}-${variantKey}`] = {
      ...baseVariants[variantKey as keyof typeof baseVariants],
      ...styleAdded,
    };
  }

  return {
    ...acc,
    ...newVariant,
  };
}, baseVariants);

export type HeadingVariant = keyof typeof variants;

export default defineStyleConfig({
  baseStyle: {
    color: 'rythm.900',
    fontWeight: 700,
    transition: '.1s ease-in-out color',
  },
  variants,
});
