import { normalizeHTML, stripTags } from '@collective/utils/helpers';
import i18n, { TOptions } from 'i18next';
import { partialRight } from 'lodash';

type TestTOptions = {
  withNormalize?: boolean;
  withTrim?: boolean;
};

// Helper function to be used in tests/stories instead of i18n.t
export const testT = (
  key: string,
  tOptions?: TOptions,
  tagsToKeep?: string[],
  testOptions: TestTOptions = {}
) => {
  const { withTrim = true, withNormalize = false } = testOptions;

  const translation = i18n.t(key, tOptions);
  const translationStripped = stripTags(
    translation,
    tagsToKeep,
    true,
    withTrim
  );

  return withNormalize
    ? normalizeHTML(translationStripped)
    : translationStripped;
};

export const normalizedTestT = partialRight<
  string,
  TOptions | undefined,
  string[] | undefined,
  TestTOptions,
  string
>(testT, undefined, { withNormalize: true });
