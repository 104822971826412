import { FALLBACK_LANGUAGE, isPresent } from '@collective/utils/helpers';
import countries from 'i18n-iso-countries';
import en from 'i18n-iso-countries/langs/en.json';
import fr from 'i18n-iso-countries/langs/fr.json';
import {
  AsYouType,
  CountryCode,
  getCountries,
  getCountryCallingCode,
  parsePhoneNumberFromString,
} from 'libphonenumber-js';
import { lowerCase, sortBy, upperCase } from 'lodash';
import { ChangeEvent, forwardRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Merge } from 'type-fest';

import { Box, Flex } from '../../layout';
import { Text } from '../../typography';
import { Select } from '../select/select';
import { SelectOption } from '../select/select-option';
import { TextInput, TextInputProps } from '../text-input';

export const TELEPHONE_DEFAULT_COUNTRY = 'FR';

countries.registerLocale(en);
countries.registerLocale(fr);

export type TelephoneInputValue = { country: CountryCode; telephone: string };
export type TelephoneInputProps = Merge<
  TextInputProps,
  {
    onChange?: (props: TelephoneInputValue) => void;
    value?: TelephoneInputValue;
  }
>;
export type CountryObject = {
  label: string;
  value: CountryCode;
  countryName: string;
  flag: string;
};

export const TelephoneInput = forwardRef<HTMLInputElement, TelephoneInputProps>(
  function TelephoneInput({ onChange, value, ...inputProps }, ref) {
    const { i18n } = useTranslation();
    const { isDisabled, isReadOnly } = inputProps;

    // List of countries for the select
    const countriesList = useMemo(() => {
      const countryOptions = getCountries()
        .map((countryCode) => getCountryObject(countryCode, i18n.language))
        .filter(isPresent);

      return sortBy(countryOptions, ['countryName']);
    }, [i18n.language]);

    const phoneCountryCode = useMemo<CountryCode>(() => {
      const countryCodes = getCountries();
      const formattedCountryCode = upperCase(value?.country) as CountryCode;

      return formattedCountryCode && countryCodes.includes(formattedCountryCode)
        ? formattedCountryCode
        : TELEPHONE_DEFAULT_COUNTRY;
    }, [value?.country]);

    // Displayed value to show inside the input
    const displayValue = useMemo(() => {
      if (!value?.telephone || !phoneCountryCode) {
        return '';
      }
      const parsedPhone = parsePhoneNumberFromString(
        value.telephone,
        phoneCountryCode
      );
      const parsedInputValue = parsedPhone?.formatNational() || value.telephone;
      return new AsYouType(phoneCountryCode).input(
        parsedInputValue.replace(/\D/g, '')
      );
    }, [phoneCountryCode, value?.telephone]);

    const onTelChange = (event: ChangeEvent<HTMLInputElement>) => {
      onChange?.({
        country: value?.country || TELEPHONE_DEFAULT_COUNTRY,
        telephone: event.target.value,
      });
    };

    const onCountryChange = (country: CountryObject) => {
      onChange?.({
        country: country.value,
        telephone: value?.telephone || '',
      });
    };

    return (
      <Flex align="center" zIndex={1}>
        <Flex
          position="absolute"
          align="center"
          width="66px"
          height="30px"
          zIndex={5}
          borderRight="1px solid"
          borderColor="rythm.300"
        >
          <Select<CountryObject>
            items={countriesList
              .map(({ value }) => getCountryObject(value))
              .filter(isPresent)}
            value={getCountryObject(phoneCountryCode)}
            onChange={onCountryChange}
            itemToString={(country) => country?.countryName || ''}
            itemToLabel={(country) => country?.flag || ''}
            data-testid="country-select"
            dropdownProps={{
              w: 'auto',
              zIndex: 6,
            }}
            border={0}
            pl={3}
            pr={1}
            fontSize="18px"
            bg="transparent"
            isDisabled={isDisabled}
            isReadOnly={isReadOnly}
          >
            {({ items, optionProps }) =>
              items.map((country, i) => (
                <SelectOption
                  key={country.value}
                  item={country}
                  index={i}
                  {...optionProps}
                >
                  <Text whiteSpace="nowrap">
                    <Box as="span" mr={2}>
                      {country.flag}
                    </Box>
                    {country.label}
                  </Text>
                </SelectOption>
              ))
            }
          </Select>
        </Flex>
        <TextInput
          ref={ref}
          type="tel"
          value={displayValue}
          onChange={onTelChange}
          pl="74px"
          {...inputProps}
        />
      </Flex>
    );
  }
);

/**
 * Using Flag emoji at the moment
 * => https://dev.to/jorik/country-code-to-flag-emoji-a21
 * @param countryCode
 */
function getFlagEmoji(countryCode: CountryCode) {
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map((char: string) => 127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
}

function getCountryObject(
  countryCode: CountryCode,
  lang: string = FALLBACK_LANGUAGE
): CountryObject | undefined {
  const countryName = countries.getName(countryCode, lang, {
    select: 'official',
  });
  if (!countryName) {
    return;
  }
  const flag = getFlagEmoji(countryCode);
  const countryCallingCode = getCountryCallingCode(countryCode);

  return {
    label: `${countryName} (+${countryCallingCode})`,
    value: countryCode,
    countryName: lowerCase(countryName),
    flag,
  };
}
