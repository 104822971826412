import {
  isIban,
  isSepa,
  pipeValidators,
} from '../../form-validators/form-validators';
import { IbanInput, IbanInputProps } from '../../inputs';
import { withField } from '../with-field';

export const FieldIbanInputLegacy = withField<IbanInputProps, string>(
  IbanInput,
  {
    validate: pipeValidators(isSepa(), isIban()),
    parse: (value: string) => value?.split(' ').join(''),
    hasFieldDisplayErrorMessage: true,
  }
);
