import type { CollapseProps as CollapsePropsChakra } from '@chakra-ui/react';
import { Box, Collapse as ChakraCollapse } from '@chakra-ui/react';
import { ForwardedRef, useRef } from 'react';

type CollapseSpeed = 'default' | 'fast';

export type CollapseProps = CollapsePropsChakra & {
  speed?: CollapseSpeed;
  overflowVisible?: boolean;
};

export const CollapseTransitions: Record<
  CollapseSpeed,
  CollapseProps['transition']
> = {
  default: { enter: { duration: 0.8 }, exit: { duration: 0.8 } },
  fast: { enter: { duration: 0.3 }, exit: { duration: 0.3 } },
};

export const Collapse = ({
  speed = 'default',
  transition,
  overflowVisible,
  ...rest
}: CollapseProps) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <Box sx={{ '.visible-collapse': { overflow: 'visible !important' } }}>
      <ChakraCollapse
        ref={ref as ForwardedRef<HTMLDivElement | null>}
        onAnimationStart={() => {
          if (!ref.current || !overflowVisible) {
            return;
          }

          const height = parseInt(getComputedStyle(ref.current).height, 10);
          if (height > 0) {
            ref.current.classList.remove('visible-collapse');
          }
        }}
        onAnimationComplete={() => {
          if (!ref.current || !overflowVisible) {
            return;
          }

          const height = parseInt(getComputedStyle(ref.current).height, 10);
          if (height > 0) {
            ref.current.classList.add('visible-collapse');
          }
        }}
        transition={transition || CollapseTransitions[speed]}
        {...rest}
      />
    </Box>
  );
};
