export { useBoolean } from '@chakra-ui/react';

export type UseBooleanMutator = {
  on: VoidFunction;
  off: VoidFunction;
  toggle: VoidFunction;
};

export const defaultUseBooleanMutator = {
  on: () => {},
  off: () => {},
  toggle: () => {},
};
