import { Editor } from '@tiptap/react';

import { Button } from '../../../button/button';
import { IconChevronDown } from '../../../icon/icon';
import { Divider } from '../../../layout/divider';
import { Flex } from '../../../layout/flex';
import { Menu, MenuButton, MenuList, MenuProps } from '../../../menu/menu';
import { Text } from '../../../typography/text';

export type ToolbarMenuProps = MenuProps & {
  editor: Editor;
  menuIcon: React.ReactNode;
  children: React.ReactNode;
  menuTitle?: string;
  ariaLabel?: string;
};

export const ToolbarMenu = ({
  editor,
  menuIcon,
  children,
  menuTitle,
  ariaLabel,
  ...rest
}: ToolbarMenuProps) => {
  return (
    <Menu
      variant="borderlessDropdown"
      gutter={4}
      // The focus needs to be put at the end of the queue, otherwise it conflicts with the menu focus and doesn't register on the FloatingMenu
      onClose={() => setTimeout(() => editor.chain().focus().run(), 0)}
      {...rest}
    >
      <MenuButton
        as={Button}
        variant="editorToolbar"
        iconSpacing={-0.5}
        pr={1}
        aria-label={ariaLabel}
        rightIcon={
          <IconChevronDown
            size="3xs"
            mt="1px"
            sx={{
              '*': {
                strokeWidth: 4,
              },
            }}
          />
        }
      >
        {menuIcon}
      </MenuButton>
      <MenuList p={2} minWidth="175px" bg="rythm.100">
        {menuTitle && (
          <>
            <Text variant="desktop-s-regular" mb={2} color="rythm.700">
              {menuTitle}
            </Text>
            <Divider borderColor="rythm.300" borderRadius="2px" mb={2} />
          </>
        )}
        <Flex direction="column" gridGap={1}>
          {children}
        </Flex>
      </MenuList>
    </Menu>
  );
};
