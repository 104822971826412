import { Box, Flex, FlexProps } from '@chakra-ui/react';
import { ReactNode } from 'react';

import { AvatarSize } from '../../../themes/components/avatar';
import { Tooltip } from '../../tooltip/tooltip';
import { Avatar, AvatarProps, AvatarTooltipProps } from '../avatar/avatar';

export interface GroupAvatarProps extends AvatarProps {
  id: string;
}

export type AvatarGroupProps = {
  avatars: GroupAvatarProps[];
  customExcessAvatarsTooltipFn?: (avatar: GroupAvatarProps) => ReactNode;
  excessAvatarsTooltipProps?: AvatarTooltipProps;
  shownAvatarsTooltipProps?: AvatarTooltipProps;
  size?: AvatarSize;
  max?: number;
} & FlexProps;

export const AvatarGroup = ({
  avatars,
  customExcessAvatarsTooltipFn,
  excessAvatarsTooltipProps = {},
  shownAvatarsTooltipProps = {},
  max = 4,
  size = 'sm',
  borderColor,
  ...rest
}: AvatarGroupProps) => {
  // So that we don't show a needless "+1" excess avatars
  const shiftedMax = avatars.length === max ? max : max - 1;

  const shownAvatars = avatars.slice(0, shiftedMax);
  const excessAvatars = avatars.slice(shiftedMax);

  const borderProps = borderColor
    ? {
        showBorder: true,
        borderColor,
      }
    : {
        border: '1px solid',
      };

  // The display order is reversed so that the visual overlap is descending
  return (
    <Flex
      direction="row-reverse"
      justify="start"
      isolation="isolate"
      sx={{
        '& > *:not(:last-child)': {
          marginInlineStart: '-4px',
        },
      }}
      {...rest}
    >
      {excessAvatars.length > 0 && (
        <Tooltip
          label={
            <Flex flexDirection="column">
              {excessAvatars.map((avatar) =>
                customExcessAvatarsTooltipFn ? (
                  customExcessAvatarsTooltipFn(avatar)
                ) : (
                  <span key={avatar.id}>{`• ${avatar.name}`}</span>
                )
              )}
            </Flex>
          }
          {...(customExcessAvatarsTooltipFn && {
            closeDelay: 5000,
            pointerEvents: 'all',
          })}
          {...excessAvatarsTooltipProps}
        >
          <Avatar
            name={`+${excessAvatars.length}`}
            getInitials={(name) => name}
            size={size}
            fontWeight={500}
            bg="primary.800"
            fontSize="0.75rem"
            lineHeight="1rem"
            letterSpacing="-1px"
            {...borderProps}
            data-chromatic={undefined}
          />
        </Tooltip>
      )}
      {shownAvatars.reverse().map((avatar) => (
        <Box key={avatar.id} zIndex={0}>
          <Avatar
            key={avatar.id}
            size={size}
            withTooltip
            {...borderProps}
            {...avatar}
            tooltipProps={shownAvatarsTooltipProps}
          />
        </Box>
      ))}
    </Flex>
  );
};
