const Progress = {
  defaultProps: {
    size: 'sm',
  },
  baseStyle: {
    filledTrack: {
      bgColor: 'primary.600',
    },
  },
  variants: {
    primary: {
      filledTrack: {
        bg: 'linear-gradient(101.51deg, var(--chakra-colors-primary-500) 4.78%, var(--chakra-colors-primary-600) 42.96%)',
      },
    },
    success: {
      filledTrack: {
        bgColor: 'success.700',
      },
    },
  },
};

export default Progress;
