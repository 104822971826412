import { FormControlProps, useMergeRefs } from '@chakra-ui/react';
import { forwardRef } from 'react';
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

import {
  FileInputImage,
  FileInputImageProps,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
} from '../../..';

type FieldFileInputImageProps = FileInputImageProps & {
  label?: string;
  info?: React.ReactNode;
  formControlProps?: FormControlProps;
};

export const FieldFileInputImageInner = <
  FormFieldValues extends FieldValues = FieldValues,
  FieldName extends FieldPath<FormFieldValues> = FieldPath<FormFieldValues>
>(
  {
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
    label,
    info,
    formControlProps,
    ...rest
  }: UseControllerProps<FormFieldValues, FieldName> & FieldFileInputImageProps,
  forwardedRef: React.ForwardedRef<HTMLInputElement>
) => {
  const {
    field: { ref: controllerRef, ...fieldRest },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
  });
  const refs = useMergeRefs(controllerRef, forwardedRef);

  return (
    <FormControl isInvalid={!!error} {...formControlProps}>
      {label && <FormLabel variant="simple">{label}</FormLabel>}
      <FileInputImage ref={refs} {...fieldRest} {...rest} />
      {error?.message && <FormErrorMessage>{error.message}</FormErrorMessage>}
      {!!info && <FormHelperText>{info}</FormHelperText>}
    </FormControl>
  );
};

export const FieldFileInputImage = forwardRef(FieldFileInputImageInner);
