import creditCardType, { types } from 'credit-card-type';

export function isAmexCard(cardNumber: string) {
  const detectedTypes = creditCardType(cardNumber);
  return (
    detectedTypes.length > 0 && detectedTypes[0].type === types.AMERICAN_EXPRESS
  );
}

export function isDiscoverCard(cardNumber: string) {
  const detectedTypes = creditCardType(cardNumber);
  return detectedTypes.length > 0 && detectedTypes[0].type === types.DISCOVER;
}
