import { extendTheme } from '@chakra-ui/react';

import { colors } from './colors';
import components from './components';
import * as layerStyles from './layer-styles';
import { breakpoints, shadows, space } from './values';

export const theme = extendTheme({
  styles: {
    global: {
      html: {
        height: '100%',
      },
      body: {
        fontSize: '0.875rem', // 14px
        height: '100%',
      },
      '#root': {
        height: '100%',
      },
    },
  },
  fonts: {
    body: "'Inter', Arial, Helvetica",
    heading: "'Inter', Arial, Helvetica",
  },
  space,
  sizes: space,
  shadows,
  breakpoints,
  colors,
  components,
  layerStyles,
});
