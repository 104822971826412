import {
  forwardRef,
  Input,
  InputGroup,
  InputGroupProps,
  InputLeftAddon,
  InputLeftAddonProps,
  InputLeftElement,
  InputProps,
  InputRightAddon,
  InputRightAddonProps,
  InputRightElement,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { FocusEvent, ReactNode, useState } from 'react';
import InputMask from 'react-input-mask';

import { CharsLeftCounter } from './chars-left-counter/chars-left-counter';

export interface TextInputProps extends InputProps {
  isLeftElementWrapped?: boolean;
  isRightElementWrapped?: boolean;
  leftElementStyle?: InputLeftAddonProps;
  rightElementStyle?: InputRightAddonProps;
  leftElement?: ReactNode;
  rightElement?: ReactNode;
  className?: string;
  inputGroupProps?: InputGroupProps;
  mask?: string;
  hideCharsCounter?: boolean;
}

const StyledInputGroup = styled(InputGroup, {
  shouldForwardProp: (prop: string) =>
    !['isFocused', 'isInvalid'].includes(prop),
})<{ isFocused: boolean; isInvalid: boolean | undefined }>`
  > * {
    transition-duration: var(--chakra-transition-duration-normal);
  }
  border-color: ${({ isFocused, isInvalid }) =>
    isFocused
      ? 'var(--chakra-colors-primary-600)'
      : isInvalid
      ? 'var(--chakra-colors-error)'
      : 'var(--chakra-colors-rythm-300)'};
  &:hover {
    border-color: ${({ isFocused, isInvalid }) =>
      isFocused
        ? 'var(--chakra-colors-primary-600)'
        : isInvalid
        ? 'var(--chakra-colors-error)'
        : 'var(--chakra-colors-rythm-600)'};
  }
`;

export const TextInput = forwardRef<TextInputProps, 'input'>(function TextInput(
  {
    className,
    leftElement,
    rightElement,
    leftElementStyle,
    rightElementStyle,
    isLeftElementWrapped = false,
    isRightElementWrapped = false,
    size,
    onFocus,
    onBlur,
    isInvalid,
    isDisabled,
    value,
    maxLength,
    hideCharsCounter,
    inputGroupProps,
    mask,
    fontSize,
    ...rest
  },
  ref
) {
  const { autoComplete } = rest;
  const [isFocused, setIsFocused] = useState(false);
  const leftElementComponent = isLeftElementWrapped ? (
    <InputLeftAddon
      backgroundColor="rythm.100"
      color="rythm.600"
      borderRadius="8px 0 0 8px"
      borderColor={
        isFocused ? 'primary.600' : isInvalid ? 'critical.700' : undefined
      }
      boxShadow={isFocused ? 'outline.1' : undefined}
      pointerEvents="none"
      fontWeight="medium"
      w={10}
      justifyContent="center"
      opacity={isDisabled ? 0.5 : 1}
      {...leftElementStyle}
    >
      {leftElement}
    </InputLeftAddon>
  ) : (
    <InputLeftElement pointerEvents="none" w={10} {...leftElementStyle}>
      {leftElement}
    </InputLeftElement>
  );
  const rightElementComponent = isRightElementWrapped ? (
    <InputRightAddon
      backgroundColor="rythm.100"
      color="rythm.600"
      borderRadius="0 8px 8px 0"
      borderColor={
        isFocused ? 'primary.600' : isInvalid ? 'critical.700' : undefined
      }
      boxShadow={isFocused ? 'outline.1' : undefined}
      pointerEvents="none"
      fontWeight="medium"
      minW={10}
      justifyContent="center"
      opacity={isDisabled ? 0.5 : 1}
      {...rightElementStyle}
    >
      {rightElement}
    </InputRightAddon>
  ) : (
    <InputRightElement pointerEvents="none" w={10} {...rightElementStyle}>
      {rightElement}
    </InputRightElement>
  );

  const handleFocus = (evt: FocusEvent<HTMLInputElement, Element>) => {
    setIsFocused(true);
    onFocus && onFocus(evt);
  };
  const handleOnBlur = (evt: FocusEvent<HTMLInputElement, Element>) => {
    setIsFocused(false);
    onBlur && onBlur(evt);
  };

  return (
    <>
      <StyledInputGroup
        size={size}
        isFocused={isFocused}
        isInvalid={isInvalid}
        className={className}
        {...inputGroupProps}
      >
        {!!leftElement && isLeftElementWrapped && leftElementComponent}
        <Input
          {...rest}
          ref={ref}
          value={value}
          maxLength={maxLength}
          onFocus={handleFocus}
          onBlur={handleOnBlur}
          isDisabled={isDisabled}
          isInvalid={isInvalid}
          fontSize={fontSize ?? { base: '16px', md: 'inherit' }}
          {...(leftElement && !isLeftElementWrapped && { pl: 10 })}
          {...(rightElement && !isRightElementWrapped && { pr: 10 })}
          {...(mask && { as: InputMask, mask, maskPlaceholder: null })}
          data-form-type={autoComplete === 'off' ? 'other' : undefined}
          data-1p-ignore={autoComplete === 'off' ? 'true' : undefined}
        />
        {!!leftElement && !isLeftElementWrapped && leftElementComponent}
        {!!rightElement && rightElementComponent}
      </StyledInputGroup>
      {!!maxLength && !hideCharsCounter && (
        <CharsLeftCounter maxLength={maxLength} value={value} />
      )}
    </>
  );
});
