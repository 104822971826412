import { Box, BoxProps } from '../layout/box';

export type DotProps = BoxProps & {
  size?: BoxProps['width'];
};

export const Dot = ({ size = '4px', ...rest }: DotProps) => {
  return (
    <Box
      bg="rythm.300"
      {...rest}
      width={size}
      height={size}
      borderRadius="50%"
    />
  );
};
