import { SelectDropdown, SelectDropdownProps } from '../../inputs';
import { withField, WithFieldProps } from '../with-field';

/**
 * Using withField HOC in a component to pass ValueType type
 * @param props
 * @constructor
 */
export const FieldSelectDropdownLegacy = <
  CategoryType extends string = string,
  ItemType extends string = string
>(
  props: WithFieldProps<
    SelectDropdownProps<CategoryType, ItemType>,
    ItemType[],
    ItemType[]
  >
) => {
  const Comp = withField<
    SelectDropdownProps<CategoryType, ItemType>,
    ItemType[],
    ItemType[]
  >(SelectDropdown<CategoryType, ItemType>);
  return Comp(props);
};
