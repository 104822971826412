import { TabList as ChakraTabList, TabListProps } from '@chakra-ui/react';

export type { TabListProps };

export const TabList = ({ children, ...rest }: TabListProps) => {
  return (
    <ChakraTabList
      borderBottom="1px solid"
      borderColor="inherit"
      pl="6"
      {...rest}
    >
      {children}
    </ChakraTabList>
  );
};
