// There was no doc on https://tiptap.dev/introduction that demonstrates drag and paste events but thanks to these 2 threads it was possible
// https://gist.github.com/slava-vishnyakov/16076dff1a77ddaca93c4bccd4ec4521?permalink_comment_id=4081365#gistcomment-4081365
// https://gist.github.com/waptik/f44b0d3c803fade75456817b1b1df6b4

import { isPresent } from '@collective/utils/helpers';
import { Extension } from '@tiptap/core';
import { Plugin, PluginKey } from 'prosemirror-state';

const ImageDropExtension = Extension.create({
  name: 'ImageDrop',

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('imageDrop'),
        props: {
          handleDOMEvents: {
            drop: (view, event: Event): boolean | void => {
              const dragEvent = event as DragEvent;
              const files = dragEvent.dataTransfer?.files;

              const images = Array.from(files ?? []);

              if (images.length === 0) {
                return;
              }

              event.preventDefault();

              const { schema } = view.state;

              const coordinates = view.posAtCoords({
                left: dragEvent.clientX,
                top: dragEvent.clientY,
              });
              if (!coordinates) {
                return false;
              }

              this.options.setIsUploadingImage(true);

              const promises = images
                .filter((image) => image.type.indexOf('image') === 0)
                .map((image) => this.options.upload(image));

              Promise.all(promises).then((urls: string[]) => {
                urls.filter(isPresent).forEach((url: string) => {
                  const node = schema.nodes.image.create({
                    src: url,
                  });
                  const transaction = view.state.tr.insert(
                    coordinates.pos,
                    node
                  );
                  view.dispatch(transaction);
                });

                this.options.setIsUploadingImage(false);
              });
            },
          },
        },
      }),
    ];
  },
});

export { ImageDropExtension };
