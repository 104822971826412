import { mergeAttributes } from '@tiptap/core';
import Image from '@tiptap/extension-image';

const EnhancedImageExtension = Image.extend({
  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      {
        class: 'image-container',
      },
      [
        'div',
        { class: 'image-row-wrapper' },
        [
          'div',
          { class: 'image-wrapper' },
          ['img', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)],
          [
            'div',
            {
              class: 'image-overlay',
            },
          ],
        ],
      ],
    ];
  },
});

export { EnhancedImageExtension };
