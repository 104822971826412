export const addSearchParams = (
  url: string | undefined | null,
  searchParams: URLSearchParams | Record<string, string | number>
) => {
  if (!url) {
    return;
  }
  const newUrl = new URL(url.startsWith('http') ? url : `https://${url}`);

  const searchParamEntries =
    typeof searchParams?.entries === 'function'
      ? searchParams.entries()
      : Object.entries(searchParams);

  for (const [key, value] of searchParamEntries) {
    newUrl.searchParams.append(key, String(value));
  }

  return newUrl.toString();
};

export const getUrlWithoutParams = (url: string) => {
  try {
    const { origin, pathname } = new URL(url);
    return `${origin}${
      pathname.endsWith('/')
        ? pathname.substring(0, pathname.length - 1)
        : pathname
    }`;
  } catch (_) {
    return url;
  }
};
