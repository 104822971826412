import PDFMerger from 'pdf-merger-js';

export const mergePdf = async (documents: Buffer[], metadata?: Metadata) => {
  const merger = new PDFMerger();

  for (const document of documents) {
    await merger.add(document);
  }

  if (metadata) {
    await merger.setMetadata(metadata);
  }

  return merger.saveAsBuffer();
};
