import { forwardRef } from '@chakra-ui/react';
import { fileToUrl } from '@collective/utils/frontend';
import { ReactNode, useEffect, useState } from 'react';

import { useTransparentize } from '../../../hooks/use-transparentize';
import { Cropper } from '../../cropper/cropper';
import { IconEdit } from '../../icon/icon';
import { Image } from '../../image/image';
import { Center } from '../../layout';
import { Text } from '../../typography/text';
import { FileInput, FileInputProps } from '../file-input';

export type FileInputImageProps =
  | Omit<FileInputProps, 'onChange' | 'value'> & {
      value?: File | string;
      text?: ReactNode;
      subText?: string;
      icon?: ReactNode;
      height?: string | number;
      aspectRatio?: number;
      onChange?: (file: File) => void;
      noCropper?: boolean;
      checkFile?: (file: File) => boolean;
      cropperTitle?: string;
    };

export const FileInputImage = forwardRef<FileInputImageProps, 'input'>(
  (
    {
      value,
      text,
      subText,
      icon,
      height,
      buttonProps,
      onChange,
      isInvalid,
      noCropper,
      checkFile,
      aspectRatio = 1,
      cropperTitle,
      ...rest
    },
    ref
  ) => {
    const [imageSrc, setImageSrc] = useState<string | undefined>(undefined);
    const [imageToCrop, setImageToCrop] = useState<File | undefined>(undefined);

    useEffect(() => {
      if (value instanceof Blob) {
        fileToUrl(value).then(setImageSrc);
      } else {
        setImageSrc(value);
      }
    }, [value]);

    const mergedButtonProps = {
      variant: 'secondary' as const,
      width: '100%',
      height,
      display: 'flex',
      flexDirection: 'column' as const,
      alignItems: 'center',
      gridGap: 2,
      color: 'rythm.600',
      bg: 'rythm.100',
      overflow: 'hidden',
      borderRadius: '8px',
      border: imageSrc ? 'none' : '1px dashed',
      borderColor: isInvalid ? 'var(--chakra-colors-error)' : 'rythm.200',
      sx: {
        '& > *': {
          transition: 'color var(--chakra-transition-duration-normal)',
        },
      },
      _hover: {
        bg: 'primary.25',
        borderColor: 'rythm.600',
        '& > *': {
          color: 'rythm.900',
        },
      },
      ...buttonProps,
    };

    const onChangeFileInput = (files: FileList | null) => {
      const file = files?.[0];

      if (!file) {
        return;
      }

      if (checkFile && !checkFile(file)) {
        return;
      }

      if (noCropper) {
        onChange?.(file);
      } else {
        setImageToCrop(file);
      }
    };

    const onSubmitCrop = (croppedImage: File) => {
      onChange?.(croppedImage);
      setImageToCrop(undefined);
    };

    const backgroundColor = useTransparentize('black', 0.2);

    return (
      <>
        <FileInput
          ref={ref}
          accept=".jpg, .jpeg, .png"
          buttonProps={mergedButtonProps}
          value={value}
          onChange={onChangeFileInput}
          {...rest}
        >
          {imageSrc ? (
            <>
              <Image
                src={imageSrc}
                alt="cover image"
                objectFit="cover"
                position="absolute"
                w="100%"
                h="100%"
              />
              <Center
                position="absolute"
                w="100%"
                h="100%"
                backgroundColor={backgroundColor}
                transition="opacity .1s ease-in-out"
                opacity={0}
                _hover={{
                  opacity: 1,
                }}
              >
                <IconEdit color="white" size="md" />
              </Center>
            </>
          ) : (
            <>
              {icon}
              <Text
                variant="desktop-s-medium"
                color="rythm.700"
                whiteSpace="pre-line"
              >
                {text}
              </Text>
              <Text
                variant="desktop-s-regular"
                color="rythm.700"
                whiteSpace="pre-line"
              >
                {subText}
              </Text>
            </>
          )}
        </FileInput>
        <Cropper
          isOpen={!!imageToCrop}
          aspect={aspectRatio}
          image={imageToCrop}
          onSubmit={onSubmitCrop}
          onClose={() => setImageToCrop(undefined)}
          title={cropperTitle}
        />
      </>
    );
  }
);
