/*
  This file list the tally forms we have in our app per language
*/

type TallyFormsType = Record<string, Record<string, string>>;

export const tallyForms: TallyFormsType = {
  en: {
    joinCollective: 'mZ9A8o',
  },
  fr: {
    joinCollective: 'npepyZ',
  },
};
