export const DEFAULT_BACKGROUND =
  'linear-gradient( 109.6deg,  rgba(6,183,249,1) 11.2%, rgba(25,74,236,1) 91.1% );' as const;
export const RANDOM_BACKGROUNDS = [
  'linear-gradient( 358.7deg,  rgba(42,136,157,1) 1.7%, rgba(122,197,214,1) 51.1%, rgba(211,232,242,1) 95.5% );',
  'linear-gradient( 179.6deg,  rgba(0,197,233,1) -3.4%, rgba(196,246,255,1) 88.3% );',
  'radial-gradient( circle farthest-corner at 10% 20%,  rgba(222,168,248,1) 0%, rgba(168,222,248,1) 21.8%, rgba(189,250,205,1) 35.6%, rgba(243,250,189,1) 52.9%, rgba(250,227,189,1) 66.8%, rgba(248,172,172,1) 90%, rgba(254,211,252,1) 99.7% );',
  'linear-gradient( 109.6deg,  rgba(6,183,249,1) 11.2%, rgba(25,74,236,1) 91.1% );',
  'linear-gradient( 109.6deg,  rgba(39,142,255,1) 11.2%, rgba(98,113,255,0.78) 100.2% );',
  'linear-gradient( 109.6deg,  rgba(204,228,247,1) 11.2%, rgba(237,246,250,1) 100.2% );',
  'linear-gradient( 90.5deg,  rgba(255,207,139,0.50) 1.1%, rgba(255,207,139,1) 81.3% );',
  'radial-gradient( circle farthest-corner at 10% 20%,  rgba(97,186,255,1) 0%, rgba(166,239,253,1) 90.1% );',
  'radial-gradient( circle farthest-corner at 50.7% 54%,  rgba(204,254,152,1) 0%, rgba(229,253,190,1) 92.4% );',
  'radial-gradient( circle farthest-corner at 3.6% 5.5%,  rgba(255,150,197,1) 9.8%, rgba(255,190,94,1) 26.3%, rgba(246,255,94,1) 43.8%, rgba(113,255,129,1) 59.2%, rgba(187,255,255,1) 74.6%, rgba(214,146,255,1) 89.4% );',
  'linear-gradient( 109.6deg,  rgba(121,203,202,1) 11.2%, rgba(119,161,211,1) 91.1% );',
] as const;
