import { Tab as ChakraTab, TabProps } from '@chakra-ui/react';

import { Box } from './../layout/box';
import { Flex } from './../layout/flex';

export type { TabProps };

export const Tab = ({ isSelected, children, ...rest }: TabProps) => {
  return (
    <ChakraTab
      border="none"
      pb="0"
      pt="0"
      h="100%"
      color={isSelected ? 'rythm.900' : 'rythm.700'}
      _selected={{ color: isSelected ? 'rythm.900' : 'rythm.700' }}
      _hover={{ color: 'rythm.900' }}
      _active={{ bg: 'none' }}
      px={0}
      {...rest}
    >
      <Flex direction="column" role="group" w="100%" px={4}>
        <Box display="block" h="5" mb="7px">
          {children}
        </Box>
        <Box
          display="block"
          w="100%"
          borderBottom="2px solid"
          borderColor={isSelected ? 'primary.600' : 'transparent'}
          borderRadius="2px 2px 0 0"
          mt="auto"
          _groupHover={{
            borderColor: isSelected ? 'primary.600' : 'rythm.300',
          }}
        />
      </Flex>
    </ChakraTab>
  );
};
