import { ButtonProps } from '@chakra-ui/react';

export const selectButton: ButtonProps = {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: 8,
  px: 2,
  py: 1,
  border: '1px solid',
  borderColor: 'rythm.300',
  borderRadius: '8px',
  outline: 'none',
  backgroundColor: 'white',
  cursor: 'pointer',
  _hover: {
    borderColor: 'rythm.600',
  },
};

export const readOnlySelectButton: ButtonProps = {
  ...selectButton,
  backgroundColor: 'primary.1',
  cursor: 'not-allowed',
  _hover: {
    borderColor: 'rythm.300',
  },
};

export const disabledSelectButton: ButtonProps = {
  ...selectButton,
  opacity: 0.5,
  cursor: 'not-allowed',
  _hover: {
    borderColor: 'rythm.300',
  },
};
