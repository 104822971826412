import frLocale from 'dayjs/locale/fr';

export const frLocaleConfig = {
  locale: 'fr',
  config: {
    formats: {
      L: 'DD / MM / YYYY',
      ll: 'D MMM YYYY',
      mask: 'd{ / }`m{ / }`Y',
    },
    relativeTime: {
      ...frLocale.relativeTime,
      m: '1 minute',
      h: '1 heure',
      d: '1 jour',
      M: '1 mois',
      y: '1 an',
    },
  },
};
