import { FlexProps, Input } from '@chakra-ui/react';
import { Editor } from '@tiptap/react';
import { forwardRef, Fragment, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { IconButtonProps } from '../../../button/icon-button';
import {
  IconEditorAlignedCenter,
  IconEditorAlignedDistributed,
  IconEditorAlignedLeft,
  IconEditorAlignedRight,
  IconEditorBold,
  IconEditorBulletPoint,
  IconEditorH1,
  IconEditorH2,
  IconEditorH3,
  IconEditorItalic,
  IconEditorNumbers,
  IconEditorStyle,
  IconEditorUnderline,
  IconLink,
  IconPicture,
  IconVideoCamera,
} from '../../../icon/icon';
import { Divider } from '../../../layout/divider';
import { Flex } from '../../../layout/flex';
import { useErrorToast } from '../../../toast/toast';
import { ToolbarFeature } from './constants';
import { ToolbarIconButton, ToolbarMenuItemButton } from './toolbar-buttons';
import { ToolbarMenu } from './toolbar-menu';

export type ToolbarProps = {
  editor: Editor | null;
  menuTitle?: string;
  onUploadImage?: (file: File) => void;
  toolbar?: ToolbarFeature[][];
  toolbarStyle?: FlexProps;
  variant?: IconButtonProps['variant'];
};

export const Toolbar = forwardRef<HTMLDivElement, ToolbarProps>(
  function Toolbar(
    {
      editor,
      toolbar = [],
      menuTitle,
      onUploadImage,
      toolbarStyle = {},
      variant,
    },
    ref
  ) {
    const { t } = useTranslation('common');
    const fileInputRef = useRef<HTMLInputElement>(null);
    const errorToast = useErrorToast();

    const onSetLink = useCallback(() => {
      if (!editor) {
        return;
      }
      const previousUrl = editor.getAttributes('link').href;
      const url = window.prompt('URL', previousUrl);

      if (url === null) {
        // cancelled
        return;
      } else if (url === '') {
        // empty
        editor.chain().focus().extendMarkRange('link').unsetLink().run();
        return;
      }

      editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url, target: '_blank' })
        .run();
    }, [editor]);

    const onUploadFile =
      onUploadImage &&
      ((evt: React.ChangeEvent<HTMLInputElement>) => {
        const file = evt.target.files?.[0];
        file && onUploadImage(file);
      });

    const onSetVideoIntegration = useCallback(() => {
      if (!editor) {
        return;
      }

      const previousUrl = editor.getAttributes('video-component').src;

      const url = window.prompt(
        t('ui.richEditor.video.promptMessage'),
        previousUrl
      );

      if (!url) {
        // cancelled
        return;
      }

      editor.chain().focus().setVideo({ src: url, errorToast, t }).run();
    }, [t, editor, errorToast]);

    if (!editor) {
      return null;
    }

    const getStyleMenuIcon = () => {
      if (editor.isActive('heading', { level: 1 })) {
        return <IconEditorH1 />;
      } else if (editor.isActive('heading', { level: 2 })) {
        return <IconEditorH2 />;
      } else if (editor.isActive('heading', { level: 3 })) {
        return <IconEditorH3 />;
      }
      return <IconEditorStyle />;
    };

    const getAlignmentMenuIcon = () => {
      if (editor.isActive({ textAlign: 'center' })) {
        return <IconEditorAlignedCenter />;
      } else if (editor.isActive({ textAlign: 'right' })) {
        return <IconEditorAlignedRight />;
      } else if (editor.isActive({ textAlign: 'justify' })) {
        return <IconEditorAlignedDistributed />;
      }
      return <IconEditorAlignedLeft />;
    };

    return (
      <Flex
        ref={ref}
        align="center"
        gridGap={1}
        h={10}
        w="fit-content"
        px={2}
        bg="rythm.100"
        border="1px solid"
        borderColor="rythm.200"
        borderRadius="12px"
        color="rythm.700"
        {...toolbarStyle}
      >
        {toolbar.map((toolbarSet, index) => (
          <Fragment key={index}>
            {index > 0 && (
              <Divider
                orientation="vertical"
                h={6}
                mx={2}
                borderColor="rythm.300"
                borderRadius="8px"
              />
            )}
            {toolbarSet.map((menuItem: ToolbarFeature, index: number) => {
              switch (menuItem) {
                case ToolbarFeature.Title:
                  return (
                    <ToolbarMenu
                      key={index}
                      editor={editor}
                      menuTitle={menuTitle}
                      menuIcon={getStyleMenuIcon()}
                      ariaLabel="text style"
                    >
                      <ToolbarMenuItemButton
                        label={t('ui.richEditor.toolbar.textStyle.text')}
                        leftIcon={<IconEditorStyle size="xs" />}
                        onClick={() =>
                          editor.chain().focus().setParagraph().run()
                        }
                        variant={variant}
                      />
                      <ToolbarMenuItemButton
                        label={t('ui.richEditor.toolbar.textStyle.h1')}
                        leftIcon={<IconEditorH1 size="xs" />}
                        onClick={() =>
                          editor.chain().focus().setHeading({ level: 1 }).run()
                        }
                        variant={variant}
                      />
                      <ToolbarMenuItemButton
                        label={t('ui.richEditor.toolbar.textStyle.h2')}
                        leftIcon={<IconEditorH2 size="xs" />}
                        onClick={() =>
                          editor.chain().focus().setHeading({ level: 2 }).run()
                        }
                        variant={variant}
                      />
                      <ToolbarMenuItemButton
                        label={t('ui.richEditor.toolbar.textStyle.h3')}
                        leftIcon={<IconEditorH3 size="xs" />}
                        onClick={() =>
                          editor.chain().focus().setHeading({ level: 3 }).run()
                        }
                        variant={variant}
                      />
                    </ToolbarMenu>
                  );
                case ToolbarFeature.Bold:
                  return (
                    <ToolbarIconButton
                      key={index}
                      icon={<IconEditorBold />}
                      aria-label="bold"
                      isActive={editor.isActive('bold')}
                      onClick={() => editor.chain().focus().toggleBold().run()}
                      variant={variant}
                    />
                  );
                case ToolbarFeature.Underline:
                  return (
                    <ToolbarIconButton
                      key={index}
                      icon={<IconEditorUnderline />}
                      aria-label="underline"
                      isActive={editor.isActive('underline')}
                      onClick={() =>
                        editor.chain().focus().toggleUnderline().run()
                      }
                      variant={variant}
                    />
                  );
                case ToolbarFeature.Italic:
                  return (
                    <ToolbarIconButton
                      key={index}
                      icon={<IconEditorItalic />}
                      aria-label="italic"
                      isActive={editor.isActive('italic')}
                      onClick={() =>
                        editor.chain().focus().toggleItalic().run()
                      }
                      variant={variant}
                    />
                  );
                case ToolbarFeature.BulletList:
                  return (
                    <ToolbarIconButton
                      key={index}
                      icon={<IconEditorBulletPoint />}
                      aria-label="bullet list"
                      isActive={editor.isActive('bulletList')}
                      onClick={() =>
                        editor.chain().focus().toggleBulletList().run()
                      }
                      variant={variant}
                    />
                  );
                case ToolbarFeature.OrderedList:
                  return (
                    <ToolbarIconButton
                      key={index}
                      icon={<IconEditorNumbers />}
                      aria-label="ordered list"
                      isActive={editor.isActive('orderedList')}
                      onClick={() =>
                        editor.chain().focus().toggleOrderedList().run()
                      }
                      variant={variant}
                    />
                  );
                case ToolbarFeature.TextAlignement:
                  return (
                    <ToolbarMenu
                      key={index}
                      editor={editor}
                      menuTitle={menuTitle}
                      menuIcon={getAlignmentMenuIcon()}
                      ariaLabel="text alignment"
                    >
                      <ToolbarMenuItemButton
                        label={t('ui.richEditor.toolbar.textAlignment.left')}
                        leftIcon={<IconEditorAlignedLeft />}
                        onClick={() =>
                          editor.chain().focus().setTextAlign('left').run()
                        }
                        variant={variant}
                      />
                      <ToolbarMenuItemButton
                        label={t('ui.richEditor.toolbar.textAlignment.center')}
                        leftIcon={<IconEditorAlignedCenter />}
                        onClick={() =>
                          editor.chain().focus().setTextAlign('center').run()
                        }
                        variant={variant}
                      />
                      <ToolbarMenuItemButton
                        label={t('ui.richEditor.toolbar.textAlignment.right')}
                        leftIcon={<IconEditorAlignedRight />}
                        onClick={() =>
                          editor.chain().focus().setTextAlign('right').run()
                        }
                        variant={variant}
                      />
                      <ToolbarMenuItemButton
                        label={t('ui.richEditor.toolbar.textAlignment.justify')}
                        leftIcon={<IconEditorAlignedDistributed />}
                        onClick={() =>
                          editor.chain().focus().setTextAlign('justify').run()
                        }
                        variant={variant}
                      />
                    </ToolbarMenu>
                  );
                case ToolbarFeature.Link:
                  return (
                    <ToolbarIconButton
                      key={index}
                      icon={<IconLink />}
                      aria-label="link"
                      onClick={onSetLink}
                      variant={variant}
                    />
                  );
                case ToolbarFeature.ImageUpload:
                  return (
                    onUploadFile && (
                      <Fragment key={index}>
                        <Input
                          ref={fileInputRef}
                          type="file"
                          onChange={onUploadFile}
                          display="none"
                        />
                        <ToolbarIconButton
                          icon={<IconPicture />}
                          aria-label="ordered list"
                          onClick={() => fileInputRef.current?.click()}
                          variant={variant}
                        />
                      </Fragment>
                    )
                  );
                case ToolbarFeature.VideoIntegration:
                  return (
                    <ToolbarIconButton
                      key={index}
                      icon={<IconVideoCamera />}
                      aria-label="video integration"
                      onClick={onSetVideoIntegration}
                      variant={variant}
                    />
                  );
                default:
                  return null;
              }
            })}
          </Fragment>
        ))}
      </Flex>
    );
  }
);
