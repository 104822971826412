import { i18n } from 'i18next';

import { ProjectTypeCategoryEnum, ProjectTypeEnum } from './definition';

export function getTranslationMapping(
  t: i18n['t']
): (str: string | ProjectTypeEnum | ProjectTypeCategoryEnum) => string {
  const mapping = {
    // --------
    [ProjectTypeCategoryEnum.TECH]: t('projectTypeCategory.tech'),
    [ProjectTypeEnum.DEVOPS]: t('projectType.tech.devOps'),
    [ProjectTypeEnum.SITE_RELIABILITY_ENGINEERING]: t(
      'projectType.tech.siteReliabilityEngineering'
    ),
    [ProjectTypeEnum.SALESFORCE_DEVELOPMENT]: t(
      'projectType.tech.salesforceDevelopment'
    ),
    [ProjectTypeEnum.WEB3]: t('projectType.tech.web3'),
    [ProjectTypeEnum.BLOCKCHAIN]: t('projectType.tech.blockchain'),
    [ProjectTypeEnum.CYBERSECURITY]: t('projectType.tech.cybersecurity'),
    [ProjectTypeEnum.HARDWARE]: t('projectType.tech.hardware'),
    [ProjectTypeEnum.TESTING_QA]: t('projectType.tech.testingQa'),
    [ProjectTypeEnum.DOMAIN_DRIVEN_DESIGN]: t(
      'projectType.tech.domainDrivenDesign'
    ),
    [ProjectTypeEnum.TEST_DRIVEN_DEVELOPMENT]: t(
      'projectType.tech.testDrivenDevelopment'
    ),
    [ProjectTypeEnum.TEST_DESIGN]: t('projectType.tech.testDesign'),
    [ProjectTypeEnum.CLEAN_CODE]: t('projectType.tech.cleanCode'),
    [ProjectTypeEnum.SOFTWARE_CRAFTSMANSHIP]: t(
      'projectType.tech.softwareCraftsmanship'
    ),
    [ProjectTypeEnum.CLEAN_ARCHITECTURE]: t(
      'projectType.tech.cleanArchitecture'
    ),
    [ProjectTypeEnum.HEXAGONAL_ARCHITECTURE]: t(
      'projectType.tech.hexagonalArchitecture'
    ),
    [ProjectTypeEnum.EVENT_DRIVEN_ARCHITECTURE]: t(
      'projectType.tech.eventDrivenArchitecture'
    ),
    [ProjectTypeEnum.MICROSERVICES]: t('projectType.tech.microservices'),
    [ProjectTypeEnum.BACKEND_DEVELOPMENT]: t(
      'projectType.tech.backendDevelopment'
    ),
    [ProjectTypeEnum.FRONTEND_DEVELOPMENT]: t(
      'projectType.tech.frontendDevelopment'
    ),
    [ProjectTypeEnum.FULLSTACK_DEVELOPMENT]: t(
      'projectType.tech.fullstackDevelopment'
    ),
    [ProjectTypeEnum.MOBILE_DEVELOPMENT]: t(
      'projectType.tech.mobileDevelopment'
    ),
    [ProjectTypeEnum.DATA_ANALYSIS]: t('projectType.tech.dataAnalysis'),
    [ProjectTypeEnum.DATA_VIZUALIZATION]: t(
      'projectType.tech.dataVisualization'
    ),
    [ProjectTypeEnum.SQL_DATABASE]: t('projectType.tech.sqlDatabase'),
    [ProjectTypeEnum.SQL_QUERIES]: t('projectType.tech.sqlQueries'),
    [ProjectTypeEnum.DATA_SCIENCE]: t('projectType.tech.dataScience'),
    [ProjectTypeEnum.MACHINE_LEARNING]: t('projectType.tech.machineLearning'),
    [ProjectTypeEnum.DEEP_LEARNING]: t('projectType.tech.deepLearning'),
    [ProjectTypeEnum.DATA_MINING]: t('projectType.tech.dataMining'),
    [ProjectTypeEnum.AI]: t('projectType.tech.ai'),
    [ProjectTypeEnum.LLMS_LARGE_LANGUAGE_MODELS]: t(
      'projectType.tech.llmsLargeLanguageModels'
    ),
    [ProjectTypeEnum.DATA_ENGINEERING]: t('projectType.tech.dataEngineering'),
    [ProjectTypeEnum.ETL]: t('projectType.tech.etl'),
    [ProjectTypeEnum.SCRAPPING]: t('projectType.tech.scrapping'),
    [ProjectTypeEnum.BIG_DATA]: t('projectType.tech.bigData'),

    // --------
    [ProjectTypeCategoryEnum.TECH_LANGUAGES_AND_FRAMEWORKS]: t(
      'projectTypeCategory.techLanguagesAndFrameworks'
    ),
    [ProjectTypeEnum.JAVASCRIPT]: t(
      'projectType.techLanguagesAndFrameworks.javascript'
    ),
    [ProjectTypeEnum.TYPESCRIPT]: t(
      'projectType.techLanguagesAndFrameworks.typescript'
    ),
    [ProjectTypeEnum.SOLIDITY]: t(
      'projectType.techLanguagesAndFrameworks.solidity'
    ),
    [ProjectTypeEnum.VERCEL]: t(
      'projectType.techLanguagesAndFrameworks.vercel'
    ),
    [ProjectTypeEnum.HTML]: t('projectType.techLanguagesAndFrameworks.html'),
    [ProjectTypeEnum.CSS]: t('projectType.techLanguagesAndFrameworks.css'),
    [ProjectTypeEnum.SASS]: t('projectType.techLanguagesAndFrameworks.sass'),
    [ProjectTypeEnum.REACT]: t('projectType.techLanguagesAndFrameworks.react'),
    [ProjectTypeEnum.ANGULAR]: t(
      'projectType.techLanguagesAndFrameworks.angular'
    ),
    [ProjectTypeEnum.VUE]: t('projectType.techLanguagesAndFrameworks.vue'),
    [ProjectTypeEnum.NEXT]: t('projectType.techLanguagesAndFrameworks.next'),
    [ProjectTypeEnum.NUXT]: t('projectType.techLanguagesAndFrameworks.nuxt'),
    [ProjectTypeEnum.NODE]: t('projectType.techLanguagesAndFrameworks.node'),
    [ProjectTypeEnum.NEST]: t('projectType.techLanguagesAndFrameworks.nest'),
    [ProjectTypeEnum.PYTHON]: t(
      'projectType.techLanguagesAndFrameworks.python'
    ),
    [ProjectTypeEnum.FLASK]: t('projectType.techLanguagesAndFrameworks.flask'),
    [ProjectTypeEnum.DJANGO]: t(
      'projectType.techLanguagesAndFrameworks.django'
    ),
    [ProjectTypeEnum.PHP]: t('projectType.techLanguagesAndFrameworks.php'),
    [ProjectTypeEnum.LARAVEL]: t(
      'projectType.techLanguagesAndFrameworks.laravel'
    ),
    [ProjectTypeEnum.SYMFONY]: t(
      'projectType.techLanguagesAndFrameworks.symfony'
    ),
    [ProjectTypeEnum.RUBY]: t('projectType.techLanguagesAndFrameworks.ruby'),
    [ProjectTypeEnum.RUBY_ON_RAILS]: t(
      'projectType.techLanguagesAndFrameworks.rubyOnRails'
    ),
    [ProjectTypeEnum.DART]: t('projectType.techLanguagesAndFrameworks.dart'),
    [ProjectTypeEnum.GOLANG]: t(
      'projectType.techLanguagesAndFrameworks.golang'
    ),
    [ProjectTypeEnum.JAVA]: t('projectType.techLanguagesAndFrameworks.java'),
    [ProjectTypeEnum.C_SHARP]: t(
      'projectType.techLanguagesAndFrameworks.cSharp'
    ),
    [ProjectTypeEnum.DOT_NET]: t('projectType.techLanguagesAndFrameworks.net'),
    [ProjectTypeEnum.IOS]: t('projectType.techLanguagesAndFrameworks.ios'),
    [ProjectTypeEnum.SWIFT]: t('projectType.techLanguagesAndFrameworks.swift'),
    [ProjectTypeEnum.OBJECTIVE_C]: t(
      'projectType.techLanguagesAndFrameworks.objectiveC'
    ),
    [ProjectTypeEnum.ANDROID]: t(
      'projectType.techLanguagesAndFrameworks.android'
    ),
    [ProjectTypeEnum.KOTLIN]: t(
      'projectType.techLanguagesAndFrameworks.kotlin'
    ),
    [ProjectTypeEnum.REACT_NATIVE]: t(
      'projectType.techLanguagesAndFrameworks.reactNative'
    ),
    [ProjectTypeEnum.FLUTTER]: t(
      'projectType.techLanguagesAndFrameworks.flutter'
    ),
    [ProjectTypeEnum.C]: t('projectType.techLanguagesAndFrameworks.c'),
    [ProjectTypeEnum.C_PLUS_PLUS]: t(
      'projectType.techLanguagesAndFrameworks.cPlusPlus'
    ),
    [ProjectTypeEnum.RUST]: t('projectType.techLanguagesAndFrameworks.rust'),
    [ProjectTypeEnum.SCALA]: t('projectType.techLanguagesAndFrameworks.scala'),
    [ProjectTypeEnum.F_SHARP]: t(
      'projectType.techLanguagesAndFrameworks.fSharp'
    ),
    [ProjectTypeEnum.ELIXIR]: t(
      'projectType.techLanguagesAndFrameworks.elixir'
    ),
    [ProjectTypeEnum.BACKBONE_JS]: t(
      'projectType.techLanguagesAndFrameworks.backboneJs'
    ),
    [ProjectTypeEnum.BOOTSTRAP]: t(
      'projectType.techLanguagesAndFrameworks.bootstrap'
    ),
    [ProjectTypeEnum.EXPRESS]: t(
      'projectType.techLanguagesAndFrameworks.express'
    ),
    [ProjectTypeEnum.REDUX]: t('projectType.techLanguagesAndFrameworks.redux'),
    [ProjectTypeEnum.SPRING_BOOT]: t(
      'projectType.techLanguagesAndFrameworks.springBoot'
    ),
    [ProjectTypeEnum.SVELTE]: t(
      'projectType.techLanguagesAndFrameworks.svelte'
    ),
    [ProjectTypeEnum.PHOENIX]: t(
      'projectType.techLanguagesAndFrameworks.phoenix'
    ),
    [ProjectTypeEnum.MATLAB]: t(
      'projectType.techLanguagesAndFrameworks.matlab'
    ),
    [ProjectTypeEnum.NUMPY]: t('projectType.techLanguagesAndFrameworks.numpy'),
    [ProjectTypeEnum.PANDAS]: t(
      'projectType.techLanguagesAndFrameworks.pandas'
    ),
    [ProjectTypeEnum.R]: t('projectType.techLanguagesAndFrameworks.r'),
    [ProjectTypeEnum.SCIKIT_LEARN]: t(
      'projectType.techLanguagesAndFrameworks.scikitLearn'
    ),
    [ProjectTypeEnum.KERAS]: t('projectType.techLanguagesAndFrameworks.keras'),
    [ProjectTypeEnum.PYTORCH]: t(
      'projectType.techLanguagesAndFrameworks.pytorch'
    ),
    [ProjectTypeEnum.TENSORFLOW]: t(
      'projectType.techLanguagesAndFrameworks.tensorflow'
    ),

    // --------
    [ProjectTypeCategoryEnum.TECH_CLOUD_DATABASES]: t(
      'projectTypeCategory.techCloudDatabases'
    ),
    [ProjectTypeEnum.AWS]: t('projectType.techCloudDatabases.aws'),
    [ProjectTypeEnum.AZURE]: t('projectType.techCloudDatabases.azure'),
    [ProjectTypeEnum.GOOGLE_CLOUD_PLATFORM]: t(
      'projectType.techCloudDatabases.googleCloudPlatform'
    ),
    [ProjectTypeEnum.IBM_CLOUD]: t('projectType.techCloudDatabases.ibmCloud'),
    [ProjectTypeEnum.ORACLE_CLOUD]: t(
      'projectType.techCloudDatabases.oracleCloud'
    ),
    [ProjectTypeEnum.SALESFORCE_CLOUD_SERVICES]: t(
      'projectType.techCloudDatabases.salesforceCloudServices'
    ),
    [ProjectTypeEnum.SAP_CLOUD]: t('projectType.techCloudDatabases.sapCloud'),
    [ProjectTypeEnum.RACKSPACE]: t('projectType.techCloudDatabases.rackspace'),
    [ProjectTypeEnum.DIGITALOCEAN]: t(
      'projectType.techCloudDatabases.digitalOcean'
    ),
    [ProjectTypeEnum.LINODE]: t('projectType.techCloudDatabases.linode'),
    [ProjectTypeEnum.OVHCLOUD]: t('projectType.techCloudDatabases.ovhcloud'),
    [ProjectTypeEnum.COCKROACHDB]: t(
      'projectType.techCloudDatabases.cockroachdb'
    ),
    [ProjectTypeEnum.POSTGRESQL]: t(
      'projectType.techCloudDatabases.postgresql'
    ),
    [ProjectTypeEnum.MYSQL]: t('projectType.techCloudDatabases.mysql'),
    [ProjectTypeEnum.MONGODB]: t('projectType.techCloudDatabases.mongodb'),
    [ProjectTypeEnum.DYNAMODB]: t('projectType.techCloudDatabases.dynamodb'),
    [ProjectTypeEnum.ELASTICSEARCH]: t(
      'projectType.techCloudDatabases.elasticsearch'
    ),
    [ProjectTypeEnum.MARIADB]: t('projectType.techCloudDatabases.mariadb'),
    [ProjectTypeEnum.MICROSOFT_SQL_SERVER]: t(
      'projectType.techCloudDatabases.microsoftSqlServer'
    ),
    [ProjectTypeEnum.ORACLE_DATABASE]: t(
      'projectType.techCloudDatabases.oracleDatabase'
    ),
    [ProjectTypeEnum.SQLITE]: t('projectType.techCloudDatabases.sqlite'),
    [ProjectTypeEnum.AMAZON_EC2]: t('projectType.techCloudDatabases.amazonEc2'),
    [ProjectTypeEnum.AWS_LAMBDA]: t('projectType.techCloudDatabases.awsLambda'),
    [ProjectTypeEnum.CASSANDRA]: t('projectType.techCloudDatabases.cassandra'),
    [ProjectTypeEnum.SCYLLADB]: t('projectType.techCloudDatabases.scylladb'),
    [ProjectTypeEnum.REDIS]: t('projectType.techCloudDatabases.redis'),
    [ProjectTypeEnum.COUCHDB]: t('projectType.techCloudDatabases.couchdb'),
    [ProjectTypeEnum.NEO4J]: t('projectType.techCloudDatabases.neo4j'),
    [ProjectTypeEnum.IBM_DB2]: t('projectType.techCloudDatabases.ibmDb2'),
    [ProjectTypeEnum.AMAZON_REDSHIFT]: t(
      'projectType.techCloudDatabases.amazonRedshift'
    ),
    [ProjectTypeEnum.MICROSOFT_AZURE_COSMOS_DB]: t(
      'projectType.techCloudDatabases.microsoftAzureCosmosDb'
    ),
    [ProjectTypeEnum.SAP_HANA]: t('projectType.techCloudDatabases.sapHana'),
    [ProjectTypeEnum.KAFKA]: t('projectType.techCloudDatabases.kafka'),
    [ProjectTypeEnum.RABBITMQ]: t('projectType.techCloudDatabases.rabbitmq'),
    [ProjectTypeEnum.SNOWFLAKE]: t('projectType.techCloudDatabases.snowflake'),
    [ProjectTypeEnum.GOOGLE_BIGQUERY]: t(
      'projectType.techCloudDatabases.googleBigquery'
    ),

    // --------
    [ProjectTypeCategoryEnum.TECH_TOOLS]: t('projectTypeCategory.techTools'),
    [ProjectTypeEnum.GLPI]: t('projectType.techTools.glpi'),
    [ProjectTypeEnum.METABASE]: t('projectType.techTools.metabase'),
    [ProjectTypeEnum.POWER_BI]: t('projectType.techTools.powerBi'),
    [ProjectTypeEnum.TABLEAU]: t('projectType.techTools.tableau'),
    [ProjectTypeEnum.LOOKER]: t('projectType.techTools.looker'),
    [ProjectTypeEnum.DBT]: t('projectType.techTools.dbt'),
    [ProjectTypeEnum.DATABRICKS]: t('projectType.techTools.databricks'),
    [ProjectTypeEnum.ALTERYX]: t('projectType.techTools.alteryx'),
    [ProjectTypeEnum.QUALTRICS]: t('projectType.techTools.qualtrics'),
    [ProjectTypeEnum.FIREBASE]: t('projectType.techTools.firebase'),
    [ProjectTypeEnum.KUBERNETES]: t('projectType.techTools.kubernetes'),
    [ProjectTypeEnum.TERRAFORM]: t('projectType.techTools.terraform'),
    [ProjectTypeEnum.DOCKER]: t('projectType.techTools.docker'),
    [ProjectTypeEnum.LINUX]: t('projectType.techTools.linux'),
    [ProjectTypeEnum.GIT]: t('projectType.techTools.git'),
    [ProjectTypeEnum.TAILWIND_CSS]: t('projectType.techTools.tailwindCss'),
    [ProjectTypeEnum.GRAPHQL]: t('projectType.techTools.graphql'),
    [ProjectTypeEnum.SUPABASE]: t('projectType.techTools.supabase'),
    [ProjectTypeEnum.GATSBY]: t('projectType.techTools.gatsby'),
    [ProjectTypeEnum.HEROKU]: t('projectType.techTools.heroku'),
    [ProjectTypeEnum.GITHUB]: t('projectType.techTools.github'),
    [ProjectTypeEnum.GITLAB]: t('projectType.techTools.gitlab'),
    [ProjectTypeEnum.BITBUCKET]: t('projectType.techTools.bitbucket'),
    [ProjectTypeEnum.CIRCLECI]: t('projectType.techTools.circleci'),
    [ProjectTypeEnum.JENKINS]: t('projectType.techTools.jenkins'),
    [ProjectTypeEnum.POSTMAN]: t('projectType.techTools.postman'),
    [ProjectTypeEnum.WINDEV]: t('projectType.techTools.windev'),
    [ProjectTypeEnum.RESTFUL_APIS]: t('projectType.techTools.restfulApis'),
    [ProjectTypeEnum.SWAGGER_OPENAPI]: t(
      'projectType.techTools.swaggerOpenapi'
    ),
    [ProjectTypeEnum.WEBPACK]: t('projectType.techTools.webpack'),
    [ProjectTypeEnum.DATADOG]: t('projectType.techTools.datadog'),
    [ProjectTypeEnum.GRAFANA]: t('projectType.techTools.grafana'),
    [ProjectTypeEnum.PROMETHEUS]: t('projectType.techTools.prometheus'),
    [ProjectTypeEnum.SPLUNK]: t('projectType.techTools.splunk'),
    [ProjectTypeEnum.BURP_SUITE]: t('projectType.techTools.burpSuite'),
    [ProjectTypeEnum.ZAP]: t('projectType.techTools.zap'),
    [ProjectTypeEnum.SNORT]: t('projectType.techTools.snort'),
    [ProjectTypeEnum.IONIC]: t('projectType.techTools.ionic'),
    [ProjectTypeEnum.XCODE]: t('projectType.techTools.xcode'),
    [ProjectTypeEnum.MAVEN]: t('projectType.techTools.maven'),
    [ProjectTypeEnum.GRADLE]: t('projectType.techTools.gradle'),
    [ProjectTypeEnum.ANSIBLE]: t('projectType.techTools.ansible'),
    [ProjectTypeEnum.CYPRESS]: t('projectType.techTools.cypress'),
    [ProjectTypeEnum.SELENIUM]: t('projectType.techTools.selenium'),
    [ProjectTypeEnum.PLAYWRIGHT]: t('projectType.techTools.playwright'),

    // --------
    [ProjectTypeCategoryEnum.PRODUCT]: t('projectTypeCategory.product'),
    [ProjectTypeEnum.PRODUCT_STRATEGY]: t(
      'projectType.product.productStrategy'
    ),
    [ProjectTypeEnum.PRODUCT_MANAGEMENT]: t(
      'projectType.product.productManagement'
    ),
    [ProjectTypeEnum.USER_RESEARCH]: t('projectType.product.userResearch'),
    [ProjectTypeEnum.PRODUCT_OWNERSHIP]: t(
      'projectType.product.productOwnership'
    ),
    [ProjectTypeEnum.PRODUCT_MARKETING]: t(
      'projectType.product.productMarketing'
    ),
    [ProjectTypeEnum.DESIGN_SYSTEM]: t('projectType.product.designSystem'),
    [ProjectTypeEnum.GAME_DESIGN]: t('projectType.product.gameDesign'),
    [ProjectTypeEnum.SCRUM]: t('projectType.product.scrum'),
    [ProjectTypeEnum.DESIGN_THINKING]: t('projectType.product.designThinking'),
    [ProjectTypeEnum.AGILE_COACHING]: t('projectType.product.agileCoaching'),
    [ProjectTypeEnum.A_B_TESTING]: t('projectType.product.abTesting'),

    // --------
    [ProjectTypeCategoryEnum.PRODUCT_TOOLS]: t(
      'projectTypeCategory.productTools'
    ),
    [ProjectTypeEnum.ASANA]: t('projectType.productTools.asana'),
    [ProjectTypeEnum.BASECAMP]: t('projectType.productTools.basecamp'),
    [ProjectTypeEnum.CONFLUENCE]: t('projectType.productTools.confluence'),
    [ProjectTypeEnum.JIRA]: t('projectType.productTools.jira'),
    [ProjectTypeEnum.MONDAY_COM]: t('projectType.productTools.mondayCom'),
    [ProjectTypeEnum.OPTIMIZELY]: t('projectType.productTools.optimizely'),
    [ProjectTypeEnum.AMPLITUDE]: t('projectType.productTools.amplitude'),

    // --------
    [ProjectTypeCategoryEnum.NOCODE_AND_LOWCODE]: t(
      'projectTypeCategory.nocodeAndLowcode'
    ),
    [ProjectTypeEnum.NOCODE_APPLICATIONS]: t(
      'projectType.nocodeAndLowcode.nocodeApplications'
    ),
    [ProjectTypeEnum.WEBSITES]: t('projectType.nocodeAndLowcode.websites'),
    [ProjectTypeEnum.E_COMMERCE_WEBSITES]: t(
      'projectType.nocodeAndLowcode.eCommerceWebsites'
    ),
    [ProjectTypeEnum.NOCODE_AUTOMATIONS]: t(
      'projectType.nocodeAndLowcode.nocodeAutomations'
    ),
    [ProjectTypeEnum.CMS]: t('projectType.nocodeAndLowcode.cms'),
    [ProjectTypeEnum.E_COMMERCE_CMS]: t(
      'projectType.nocodeAndLowcode.eCommerceCms'
    ),

    // --------
    [ProjectTypeCategoryEnum.NOCODE_AND_LOWCODE_TOOLS]: t(
      'projectTypeCategory.nocodeAndLowcodeTools'
    ),
    [ProjectTypeEnum.BUBBLE]: t('projectType.nocodeAndLowcodeTools.bubble'),
    [ProjectTypeEnum.XANO]: t('projectType.nocodeAndLowcodeTools.xano'),
    [ProjectTypeEnum.WEWEB]: t('projectType.nocodeAndLowcodeTools.weWeb'),
    [ProjectTypeEnum.FLUTTERFLOW]: t(
      'projectType.nocodeAndLowcodeTools.flutterFlow'
    ),
    [ProjectTypeEnum.WEBFLOW]: t('projectType.nocodeAndLowcodeTools.webflow'),
    [ProjectTypeEnum.WORDPRESS]: t(
      'projectType.nocodeAndLowcodeTools.wordpress'
    ),
    [ProjectTypeEnum.DRUPAL]: t('projectType.nocodeAndLowcodeTools.drupal'),
    [ProjectTypeEnum.FRAMER]: t('projectType.nocodeAndLowcodeTools.framer'),
    [ProjectTypeEnum.PRESTASHOP]: t(
      'projectType.nocodeAndLowcodeTools.prestaShop'
    ),
    [ProjectTypeEnum.MAGENTO]: t('projectType.nocodeAndLowcodeTools.magento'),
    [ProjectTypeEnum.WOOCOMMERCE]: t(
      'projectType.nocodeAndLowcodeTools.wooCommerce'
    ),
    [ProjectTypeEnum.SHOPIFY]: t('projectType.nocodeAndLowcodeTools.shopify'),
    [ProjectTypeEnum.ZAPIER]: t('projectType.nocodeAndLowcodeTools.zapier'),
    [ProjectTypeEnum.MAKE]: t('projectType.nocodeAndLowcodeTools.make'),
    [ProjectTypeEnum.N8N]: t('projectType.nocodeAndLowcodeTools.n8n'),
    [ProjectTypeEnum.STRAPI]: t('projectType.nocodeAndLowcodeTools.strapi'),
    [ProjectTypeEnum.AIRTABLE]: t('projectType.nocodeAndLowcodeTools.airtable'),
    [ProjectTypeEnum.NOTION]: t('projectType.nocodeAndLowcodeTools.notion'),
    [ProjectTypeEnum.GLIDE]: t('projectType.nocodeAndLowcodeTools.glide'),
    [ProjectTypeEnum.SQUARESPACE]: t(
      'projectType.nocodeAndLowcodeTools.squarespace'
    ),
    [ProjectTypeEnum.RETOOL]: t('projectType.nocodeAndLowcodeTools.retool'),

    // --------
    [ProjectTypeCategoryEnum.MARKETING_AND_SALES]: t(
      'projectTypeCategory.marketingAndSales'
    ),
    [ProjectTypeEnum.MARKETING_STRATEGY]: t(
      'projectType.marketingAndSales.marketingStrategy'
    ),
    [ProjectTypeEnum.GROWTH]: t('projectType.marketingAndSales.growth'),
    [ProjectTypeEnum.SALES_OPS]: t('projectType.marketingAndSales.salesOps'),
    [ProjectTypeEnum.MARKETING_OPS]: t(
      'projectType.marketingAndSales.marketingOps'
    ),
    [ProjectTypeEnum.CRO]: t('projectType.marketingAndSales.cro'),
    [ProjectTypeEnum.SEA]: t('projectType.marketingAndSales.sea'),
    [ProjectTypeEnum.SEO]: t('projectType.marketingAndSales.seo'),
    [ProjectTypeEnum.SOCIAL_ADS]: t('projectType.marketingAndSales.socialAds'),
    [ProjectTypeEnum.WEB_ANALYTICS]: t(
      'projectType.marketingAndSales.webAnalytics'
    ),
    [ProjectTypeEnum.SALES_STRATEGY]: t(
      'projectType.marketingAndSales.salesStrategy'
    ),
    [ProjectTypeEnum.BUSINESS_DEVELOPMENT]: t(
      'projectType.marketingAndSales.businessDevelopment'
    ),
    [ProjectTypeEnum.GO_TO_MARKET]: t(
      'projectType.marketingAndSales.goToMarket'
    ),
    [ProjectTypeEnum.OUTBOUND]: t('projectType.marketingAndSales.outbound'),
    [ProjectTypeEnum.LEAD_GENERATION]: t(
      'projectType.marketingAndSales.leadGeneration'
    ),
    [ProjectTypeEnum.LEAD_NURTURING]: t(
      'projectType.marketingAndSales.leadNurturing'
    ),
    [ProjectTypeEnum.COLD_CALLING]: t(
      'projectType.marketingAndSales.coldCalling'
    ),
    [ProjectTypeEnum.EMAILING]: t('projectType.marketingAndSales.emailing'),
    [ProjectTypeEnum.COMMUNICATION_CAMPAIGN]: t(
      'projectType.marketingAndSales.communicationCampaign'
    ),
    [ProjectTypeEnum.EVENT_MANAGEMENT]: t(
      'projectType.marketingAndSales.eventManagement'
    ),
    [ProjectTypeEnum.CONTENT_STRATEGY]: t(
      'projectType.marketingAndSales.contentStrategy'
    ),
    [ProjectTypeEnum.ARTICLES_CREATION]: t(
      'projectType.marketingAndSales.articlesCreation'
    ),
    [ProjectTypeEnum.COPYWRITING]: t(
      'projectType.marketingAndSales.copywriting'
    ),
    [ProjectTypeEnum.COMMUNITY_MANAGEMENT]: t(
      'projectType.marketingAndSales.communityManagement'
    ),
    [ProjectTypeEnum.PRESS_RELATIONS]: t(
      'projectType.marketingAndSales.pressRelations'
    ),
    [ProjectTypeEnum.BRANDING_STRATEGY]: t(
      'projectType.marketingAndSales.brandingStrategy'
    ),
    [ProjectTypeEnum.CRM]: t('projectType.marketingAndSales.crm'),

    // --------
    [ProjectTypeCategoryEnum.MARKETING_AND_SALES_TOOLS]: t(
      'projectTypeCategory.marketingAndSalesTools'
    ),
    [ProjectTypeEnum.GOOGLE_ADS]: t(
      'projectType.marketingAndSalesTools.googleAds'
    ),
    [ProjectTypeEnum.LINKEDIN_ADS]: t(
      'projectType.marketingAndSalesTools.linkedinAds'
    ),
    [ProjectTypeEnum.META_ADS]: t('projectType.marketingAndSalesTools.metaAds'),
    [ProjectTypeEnum.TIKTOK_ADS]: t(
      'projectType.marketingAndSalesTools.tiktokAds'
    ),
    [ProjectTypeEnum.YOUTUBE_ADS]: t(
      'projectType.marketingAndSalesTools.youtubeAds'
    ),
    [ProjectTypeEnum.SALESFORCE]: t(
      'projectType.marketingAndSalesTools.salesforce'
    ),
    [ProjectTypeEnum.SALESFORCE_MARKETING_CLOUD]: t(
      'projectType.marketingAndSalesTools.salesforceMarketingCloud'
    ),
    [ProjectTypeEnum.HUBSPOT]: t('projectType.marketingAndSalesTools.hubspot'),
    [ProjectTypeEnum.CUSTOMER_IO]: t(
      'projectType.marketingAndSalesTools.customerIo'
    ),
    [ProjectTypeEnum.KLAVIYO]: t('projectType.marketingAndSalesTools.klaviyo'),
    [ProjectTypeEnum.PIPEDRIVE]: t(
      'projectType.marketingAndSalesTools.pipedrive'
    ),
    [ProjectTypeEnum.GOOGLE_ANALYTICS]: t(
      'projectType.marketingAndSalesTools.googleAnalytics'
    ),
    [ProjectTypeEnum.ACTIVE_CAMPAIGN]: t(
      'projectType.marketingAndSalesTools.activeCampaign'
    ),
    [ProjectTypeEnum.AHREFS]: t('projectType.marketingAndSalesTools.ahrefs'),
    [ProjectTypeEnum.MARKETO]: t('projectType.marketingAndSalesTools.marketo'),
    [ProjectTypeEnum.SEMRUSH]: t('projectType.marketingAndSalesTools.semrush'),
    [ProjectTypeEnum.ZOHO_CRM]: t('projectType.marketingAndSalesTools.zohoCrm'),
    [ProjectTypeEnum.MAILCHIMP]: t(
      'projectType.marketingAndSalesTools.mailchimp'
    ),
    [ProjectTypeEnum.GOOGLE_TAG_MANAGER]: t(
      'projectType.marketingAndSalesTools.googleTagManager'
    ),
    [ProjectTypeEnum.HOTJAR]: t('projectType.marketingAndSalesTools.hotjar'),
    [ProjectTypeEnum.CONVERTKIT]: t(
      'projectType.marketingAndSalesTools.convertkit'
    ),
    [ProjectTypeEnum.CONSTANTCONTACT]: t(
      'projectType.marketingAndSalesTools.constantcontact'
    ),
    [ProjectTypeEnum.BUFFER]: t('projectType.marketingAndSalesTools.buffer'),

    // --------
    [ProjectTypeCategoryEnum.DESIGN_AND_CONTENT]: t(
      'projectTypeCategory.designAndContent'
    ),
    [ProjectTypeEnum.VISUAL_IDENTITY]: t(
      'projectType.designAndContent.visualIdentity'
    ),
    [ProjectTypeEnum.ARTISTIC_DIRECTION]: t(
      'projectType.designAndContent.artisticDirection'
    ),
    [ProjectTypeEnum.GRAPHIC_DESIGN]: t(
      'projectType.designAndContent.graphicDesign'
    ),
    [ProjectTypeEnum.ILLUSTRATION]: t(
      'projectType.designAndContent.illustration'
    ),
    [ProjectTypeEnum.PRINT_DESIGN]: t(
      'projectType.designAndContent.printDesign'
    ),
    [ProjectTypeEnum.MOTION_DESIGN]: t(
      'projectType.designAndContent.motionDesign'
    ),
    [ProjectTypeEnum.DESIGN_3D]: t('projectType.designAndContent.design3D'),
    [ProjectTypeEnum.ANIMATION]: t('projectType.designAndContent.animation'),
    [ProjectTypeEnum.VIDEO_FILM]: t('projectType.designAndContent.videoFilm'),
    [ProjectTypeEnum.PHOTOGRAPHY]: t(
      'projectType.designAndContent.photography'
    ),
    [ProjectTypeEnum.SOUND_DESIGN]: t(
      'projectType.designAndContent.soundDesign'
    ),
    [ProjectTypeEnum.UX_PRODUCT_DESIGN]: t(
      'projectType.designAndContent.uxProductDesign'
    ),
    [ProjectTypeEnum.UI_PRODUCT_DESIGN]: t(
      'projectType.designAndContent.uiProductDesign'
    ),
    [ProjectTypeEnum.PROOFREADING]: t(
      'projectType.designAndContent.proofreading'
    ),
    [ProjectTypeEnum.TRANSLATION]: t(
      'projectType.designAndContent.translation'
    ),
    [ProjectTypeEnum.WEB_DESIGN]: t('projectType.designAndContent.webDesign'),

    // --------
    [ProjectTypeCategoryEnum.DESIGN_AND_CONTENT_TOOLS]: t(
      'projectTypeCategory.designAndContentTools'
    ),
    [ProjectTypeEnum.DESIGN_3D_STUDIOMAX]: t(
      'projectType.designAndContentTools.design3DStudiomax'
    ),
    [ProjectTypeEnum.ADOBE_AFTER_EFFECTS]: t(
      'projectType.designAndContentTools.adobeAfterEffects'
    ),
    [ProjectTypeEnum.ADOBE_ANIMATE]: t(
      'projectType.designAndContentTools.adobeAnimate'
    ),
    [ProjectTypeEnum.ADOBE_AUDITION]: t(
      'projectType.designAndContentTools.adobeAudition'
    ),
    [ProjectTypeEnum.ADOBE_CREATIVE_SUITE]: t(
      'projectType.designAndContentTools.adobeCreativeSuite'
    ),
    [ProjectTypeEnum.ADOBE_ILLUSTRATOR]: t(
      'projectType.designAndContentTools.adobeIllustrator'
    ),
    [ProjectTypeEnum.ADOBE_INDESIGN]: t(
      'projectType.designAndContentTools.adobeIndesign'
    ),
    [ProjectTypeEnum.ADOBE_LIGHTROOM]: t(
      'projectType.designAndContentTools.adobeLightroom'
    ),
    [ProjectTypeEnum.ADOBE_PHOTOSHOP]: t(
      'projectType.designAndContentTools.adobePhotoshop'
    ),
    [ProjectTypeEnum.ADOBE_PREMIERE_PRO]: t(
      'projectType.designAndContentTools.adobePremierePro'
    ),
    [ProjectTypeEnum.AFFINITY_DESIGNER]: t(
      'projectType.designAndContentTools.affinityDesigner'
    ),
    [ProjectTypeEnum.AFFINITY_PHOTO]: t(
      'projectType.designAndContentTools.affinityPhoto'
    ),
    [ProjectTypeEnum.AFFINITY_PUBLISHER]: t(
      'projectType.designAndContentTools.affinityPublisher'
    ),
    [ProjectTypeEnum.ASEPRITE]: t('projectType.designAndContentTools.aseprite'),
    [ProjectTypeEnum.AUTOCAD]: t('projectType.designAndContentTools.autocad'),
    [ProjectTypeEnum.AUTODESK_3DS_MAX]: t(
      'projectType.designAndContentTools.autodesk3dsMax'
    ),
    [ProjectTypeEnum.AUTODESK_INVENTOR]: t(
      'projectType.designAndContentTools.autodeskInventor'
    ),
    [ProjectTypeEnum.AUTODESK_MAYA]: t(
      'projectType.designAndContentTools.autodeskMaya'
    ),
    [ProjectTypeEnum.BLENDER]: t('projectType.designAndContentTools.blender'),
    [ProjectTypeEnum.CINEMA_4D]: t(
      'projectType.designAndContentTools.cinema4D'
    ),
    [ProjectTypeEnum.CORELDRAW]: t(
      'projectType.designAndContentTools.coreldraw'
    ),
    [ProjectTypeEnum.DAVINCI_RESOLVE]: t(
      'projectType.designAndContentTools.davinciResolve'
    ),
    [ProjectTypeEnum.FINAL_CUT_PRO]: t(
      'projectType.designAndContentTools.finalCutPro'
    ),
    [ProjectTypeEnum.FUSION_360]: t(
      'projectType.designAndContentTools.fusion360'
    ),
    [ProjectTypeEnum.GIMP]: t('projectType.designAndContentTools.gimp'),
    [ProjectTypeEnum.INKSCAPE]: t('projectType.designAndContentTools.inkscape'),
    [ProjectTypeEnum.PROCREATE]: t(
      'projectType.designAndContentTools.procreate'
    ),
    [ProjectTypeEnum.RHINO]: t('projectType.designAndContentTools.rhino'),
    [ProjectTypeEnum.SKETCH]: t('projectType.designAndContentTools.sketch'),
    [ProjectTypeEnum.SKETCHUP]: t('projectType.designAndContentTools.sketchup'),
    [ProjectTypeEnum.V_RAY]: t('projectType.designAndContentTools.vRay'),
    [ProjectTypeEnum.ZBRUSH]: t('projectType.designAndContentTools.zbrush'),
    [ProjectTypeEnum.CANVA]: t('projectType.designAndContentTools.canva'),
    [ProjectTypeEnum.FIGMA]: t('projectType.designAndContentTools.figma'),

    // --------
    [ProjectTypeCategoryEnum.BUSINESS]: t('projectTypeCategory.business'),
    [ProjectTypeEnum.STRATEGY_CONSULTING]: t(
      'projectType.business.strategyAndConsulting'
    ),
    [ProjectTypeEnum.PROJECT_MANAGEMENT_PMO]: t(
      'projectType.business.projectManagementPMO'
    ),
    [ProjectTypeEnum.PROJECT_OWNER]: t('projectType.business.projectOwner'),
    [ProjectTypeEnum.HR]: t('projectType.business.hr'),
    [ProjectTypeEnum.TALENT_ACQUISITION]: t(
      'projectType.business.talentAcquisition'
    ),
    [ProjectTypeEnum.LEGAL]: t('projectType.business.legal'),
    [ProjectTypeEnum.GDPR]: t('projectType.business.gdpr'),
    [ProjectTypeEnum.FINANCE]: t('projectType.business.finance'),
    [ProjectTypeEnum.OPERATION_PROCUREMENT]: t(
      'projectType.business.operationProcurement'
    ),
    [ProjectTypeEnum.CONSULTING_IT]: t('projectType.business.consultingIt'),
    [ProjectTypeEnum.CONSULTING_CYBERSECURITY]: t(
      'projectType.business.consultingCybersecurity'
    ),
    [ProjectTypeEnum.CSR]: t('projectType.business.csr'),
    [ProjectTypeEnum.E_LEARNING]: t('projectType.business.eLearning'),
    [ProjectTypeEnum.KNOWLEDGE_MANAGEMENT]: t(
      'projectType.business.knowledgeManagement'
    ),
    [ProjectTypeEnum.COACHING]: t('projectType.business.coaching'),
    [ProjectTypeEnum.CUSTOMER_CARE]: t('projectType.business.customerCare'),
    [ProjectTypeEnum.CUSTOMER_SUCCESS]: t(
      'projectType.business.customerSuccess'
    ),
    [ProjectTypeEnum.ACCOUNTING]: t('projectType.business.accounting'),

    // --------
    [ProjectTypeCategoryEnum.BUSINESS_TOOLS]: t(
      'projectTypeCategory.businessTools'
    ),
    [ProjectTypeEnum.ERP]: t('projectType.businessTools.erp'),
    [ProjectTypeEnum.ACUMATICA_CLOUD_ERP]: t(
      'projectType.businessTools.acumaticaCloudERP'
    ),
    [ProjectTypeEnum.DELTEK_VISION]: t(
      'projectType.businessTools.deltekVision'
    ),
    [ProjectTypeEnum.EPICOR_ERP]: t('projectType.businessTools.epicorERP'),
    [ProjectTypeEnum.FINANCIALFORCE_ERP]: t(
      'projectType.businessTools.financialForceERP'
    ),
    [ProjectTypeEnum.IFS_APPLICATIONS]: t(
      'projectType.businessTools.IFSApplications'
    ),
    [ProjectTypeEnum.MICROSOFT_DYNAMICS_365]: t(
      'projectType.businessTools.microsoftDynamics365'
    ),
    [ProjectTypeEnum.ODOO]: t('projectType.businessTools.odoo'),
    [ProjectTypeEnum.ORACLE]: t('projectType.businessTools.oracle'),
    [ProjectTypeEnum.RAMCO_ERP_SUITE]: t(
      'projectType.businessTools.ramcoERPSuite'
    ),
    [ProjectTypeEnum.ROOTSTOCK_ERP]: t(
      'projectType.businessTools.rootstockERP'
    ),
    [ProjectTypeEnum.SAGE_INTACCT]: t('projectType.businessTools.sageIntacct'),
    [ProjectTypeEnum.SAP_ERP]: t('projectType.businessTools.sapERP'),
    [ProjectTypeEnum.TYLER_TECHNOLOGIES_ERP]: t(
      'projectType.businessTools.tylerTechnologiesERP'
    ),
    [ProjectTypeEnum.UNIT4_ERP]: t('projectType.businessTools.unit4ERP'),
    [ProjectTypeEnum.WORKDAY]: t('projectType.businessTools.workday'),
  };

  return (str: string | ProjectTypeEnum | ProjectTypeCategoryEnum) =>
    mapping[str as ProjectTypeEnum | ProjectTypeCategoryEnum];
}

export function getTranslatedString(
  projectTypes: { code: string }[],
  projectTypeTranslator: (code: string) => string
) {
  return projectTypes.map(({ code }) => projectTypeTranslator(code)).join(', ');
}
