import { CenterProps } from '@chakra-ui/react';
import { JSXElementConstructor } from 'react';

import { IconProps, IconSize } from '../icon/icon';
import {
  LayerBorder,
  LayerBorderColorProps,
} from '../layer-border/layer-border';

export type LayerIconSize = '2xs' | 'xs' | 'sm' | 'md';
export type LayerIconProps = CenterProps &
  LayerBorderColorProps & {
    Icon: JSXElementConstructor<IconProps>;
    size?: LayerIconSize;
  };

const layerSizeToIconSize: Record<LayerIconSize, IconSize> = {
  '2xs': 'xs',
  xs: 'md',
  sm: 'md',
  md: 'md',
};

export const LayerIcon = ({
  children,
  color,
  Icon,
  size = 'xs',
  ...rest
}: LayerIconProps) => {
  return (
    <LayerBorder {...rest} color={color} size={size}>
      <Icon color={color} size={layerSizeToIconSize[size]} />
    </LayerBorder>
  );
};
