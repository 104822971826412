import { FormControlProps, useMergeRefs } from '@chakra-ui/react';
import { get } from 'lodash';
import {
  ChangeEventHandler,
  FocusEventHandler,
  ForwardedRef,
  forwardRef,
  ReactNode,
} from 'react';
import {
  Control,
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
  useFormState,
} from 'react-hook-form';

import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  TextInput,
  TextInputProps,
} from '../../..';

type FieldTextInputProps<FormFieldValues extends FieldValues> =
  TextInputProps & {
    name: FieldPath<FormFieldValues>;
    control: Control<FormFieldValues>;
    label?: string | ReactNode;
    info?: ReactNode;
    isControlled?: boolean;
    formControlProps?: FormControlProps;
  };

const FieldTextInputInner = <FormFieldValues extends FieldValues = FieldValues>(
  {
    label,
    info,
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
    isControlled = false,
    formControlProps,
    ...rest
  }: UseControllerProps<FormFieldValues> & FieldTextInputProps<FormFieldValues>,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const { errors } = useFormState({ control, name });
  const fieldError = get(errors, name);
  const isInvalid = !!fieldError;
  const errorMessage = fieldError?.message?.toString();

  const {
    field: { ref: controllerRef, ...fieldRest },
  } = useController({
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
  });

  const refs = useMergeRefs(controllerRef, ref);

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (isControlled) {
      fieldRest.onChange(e);
    }

    rest.onChange?.(e);
  };
  const onBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    if (isControlled) {
      fieldRest.onBlur();
    }

    rest.onBlur?.(e);
  };

  // Mostly done to work with the chars counter as it needs to be controlled to pass the value to it..
  const inputProps = {
    ...rest,
    ...(isControlled && { ...fieldRest }),
    ...(!isControlled && { ref, name }),
    isInvalid,
  };

  return (
    <FormControl isInvalid={isInvalid} {...formControlProps}>
      {!!label && <FormLabel variant="simple">{label}</FormLabel>}
      <TextInput
        ref={refs}
        {...inputProps}
        onChange={onChange}
        onBlur={onBlur}
      />
      {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
      {!!info && <FormHelperText>{info}</FormHelperText>}
    </FormControl>
  );
};

export const FieldTextInput = forwardRef(FieldTextInputInner);
