const Input = {
  baseStyle: {
    field: {
      _readOnly: {
        cursor: 'not-allowed',
      },
    },
  },
  sizes: {
    sm: {
      field: {
        borderRadius: 'lg',
        px: 2,
      },
    },
    lg: {
      field: {
        fontSize: 'sm',
        borderRadius: 'xl',
        px: 2,
      },
    },
  },
  variants: {
    outline: {
      field: {
        backgroundColor: 'transparent',
        borderColor: 'rythm.300',
        color: 'rythm.900',
        _placeholder: {
          color: 'rythm.600',
        },
        _hover: {
          borderColor: 'rythm.600',
        },
        _focus: {
          zIndex: 1,
          borderColor: 'primary.600',
          '.js-focus-visible &:focus:not(.focus-visible)': {
            boxShadow: 'outline.1',
          },
        },
        _focusVisible: {
          boxShadow: 'outline.1',
        },
        _readOnly: {
          bg: 'primary.1',
        },
        _invalid: {
          borderColor: 'critical.500',
          boxShadow: 'none',
        },
        _disabled: {
          opacity: 0.5,
        },
      },
      addon: {
        borderColor: 'rythm.300',
      },
    },
  },
  defaultProps: {
    size: 'sm',
  },
};

export default Input;
