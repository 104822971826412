import {
  datadogLogs,
  LoggerConfiguration,
  LogsEvent,
  LogsInitConfiguration,
} from '@datadog/browser-logs';

import {
  DATADOG_CLIENT_TOKEN,
  DATADOG_SITE,
  ENV,
  LEVEL_ENV,
  LOGGER_HANDLERS,
  LOGGER_TAGS,
  VERSION,
} from '../env';
import { getHandlers, HandlerType } from './handlers';
import Logger, { LogLevel } from './logger';
import { getLoggerTags, TagsType } from './tags';

const defaultLogger = new Logger('main');

export function getLogger(
  name?: string,
  conf?: LoggerConfiguration,
  level: LogLevel = 'info',
  handler: HandlerType | HandlerType[] = 'console'
): Logger {
  if (name) {
    return new Logger(name, {
      level,
      handler,
      ...conf,
    });
  }

  return defaultLogger;
}

const defaultLoggerConfig = {
  clientToken: DATADOG_CLIENT_TOKEN,
  site: DATADOG_SITE,
  forwardErrorsToLogs: true,
  sampleRate: 100,
  env: ENV,
  version: VERSION,
  silentMultipleInit: true,
};
const defaultLogLevel = (LEVEL_ENV || 'info') as LogLevel;
const defaultLoggerHandlers = getHandlers(LOGGER_HANDLERS || 'console');
const defaultTags = getLoggerTags(LOGGER_TAGS);

export function initLogging(
  config?: Partial<LogsInitConfiguration>,
  level: LogLevel = defaultLogLevel,
  handler: HandlerType | HandlerType[] = defaultLoggerHandlers,
  tags: TagsType = defaultTags
) {
  const mergedConfig = {
    ...defaultLoggerConfig,
    ...config,
    beforeSend: (event: LogsEvent): void | boolean => {
      /**
       * Ignore the error events without stack
       * Meaning that the Promise.reject() are not catch compared to the Error thrown
       * It was made to avoid logging Unauthenticated errors on datadog (see axios-graphql.ts)
       */
      if (
        event.status === 'error' &&
        !event.monitoredError &&
        !event.error?.stack
      ) {
        return false;
      }
    },
  };

  if (mergedConfig.clientToken) {
    datadogLogs.init(mergedConfig);
    // add tags too logger context so they appear on all logs
    datadogLogs.setLoggerGlobalContext(tags);

    defaultLogger.setLevel(level);
    defaultLogger.setHandler(handler);

    defaultLogger.info(
      `Logging initialised with level ${level}, handlers ${handler} and config ${JSON.stringify(
        mergedConfig
      )}`
    );
  }
}
