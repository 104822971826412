import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  RatingStars,
  RatingStarsProps,
} from '../../..';

type FieldRatingStarsProps = RatingStarsProps & {
  label?: string;
};

export const FieldRatingStars = <
  FormFieldValues extends FieldValues = FieldValues,
  FieldName extends FieldPath<FormFieldValues> = FieldPath<FormFieldValues>
>({
  name,
  control,
  rules,
  shouldUnregister,
  defaultValue,
  label,
  ...rest
}: UseControllerProps<FormFieldValues, FieldName> & FieldRatingStarsProps) => {
  const {
    field: { ref, ...field },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
  });

  const onChange: RatingStarsProps['onChange'] = (value) => {
    field.onChange(value);
    rest.onChange?.(value);
  };

  return (
    <FormControl isInvalid={!!error}>
      {label && <FormLabel variant="simple">{label}</FormLabel>}
      <RatingStars {...field} {...rest} onChange={onChange} />
      {error?.message && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};
