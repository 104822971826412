import {
  SelectItem,
  SelectWithSearch,
  SelectWithSearchProps,
} from '../../inputs';
import { withField, WithFieldProps } from '../with-field';

/**
 * Using withField HOC in a component to pass ValueType type
 * @param props
 * @constructor
 */
export const FieldSelectWithSearchLegacy = <ValueType extends SelectItem>(
  props: WithFieldProps<SelectWithSearchProps<ValueType>, ValueType, SelectItem>
) => {
  const Comp = withField<
    SelectWithSearchProps<ValueType>,
    ValueType,
    SelectItem
  >(SelectWithSearch);
  return Comp(props);
};
