import {
  INVALID_SCHEMA_ERROR_TOAST_ID,
  NETWORK_ERROR_TOAST_ID,
  REFRESH_ERROR_TOAST_ID,
  UNEXPECTED_ERROR_TOAST_ID,
} from './constants';

const errorToastIds = [
  INVALID_SCHEMA_ERROR_TOAST_ID,
  REFRESH_ERROR_TOAST_ID,
  UNEXPECTED_ERROR_TOAST_ID,
  NETWORK_ERROR_TOAST_ID,
];

type ErrorToastIdType = (typeof errorToastIds)[number];

export const shouldDisplayToast = (
  errorToastId: ErrorToastIdType,
  isToastActive: (id: string) => boolean
) => {
  switch (errorToastId) {
    case UNEXPECTED_ERROR_TOAST_ID:
      return errorToastIds.every(
        (errorToastId) => !isToastActive(errorToastId)
      );
    default:
      return !isToastActive(errorToastId);
  }
};
