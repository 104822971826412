import { Flex } from '../layout/flex';
import { Tag, TagProps } from './tag';

export type NestedTagProps = {
  value?: string | number;
} & TagProps;

export const NestedTag = ({ value, label, ...rest }: NestedTagProps) => {
  return (
    <Tag
      label={
        <Flex alignItems="center">
          <span>{label}</span>
          <Tag
            label={value}
            ml={2}
            p={1}
            minW="auto"
            minH="auto"
            color="rythm.700"
            bg="rythm.100"
            borderRadius="4px"
            labelStyle={{ lineHeight: 1 }}
          />
        </Flex>
      }
      {...rest}
    />
  );
};
