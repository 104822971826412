type User = { firstname?: string | null; lastname?: string | null };

export function displayName(user?: User | null): string {
  return [user?.firstname, user?.lastname].filter(Boolean).join(' ');
}

export function sortMembers<T extends { user: User }>(members: T[]): T[] {
  return members.sort((member1, member2) => {
    const displayNameMember1 = displayName(member1.user);
    const displayNameMember2 = displayName(member2.user);

    return displayNameMember1 > displayNameMember2
      ? 1
      : displayNameMember1 < displayNameMember2
      ? -1
      : 0;
  });
}

export function sortUsers<T extends User>(users: T[]): T[] {
  return users.sort((user1, user2) => {
    const displayNameUser1 = displayName(user1);
    const displayNameUser2 = displayName(user2);

    return displayNameUser1 > displayNameUser2
      ? 1
      : displayNameUser1 < displayNameUser2
      ? -1
      : 0;
  });
}
