import { get } from 'lodash';
import { ForwardedRef, forwardRef } from 'react';
import { Control, FieldPath, FieldValues, useFormState } from 'react-hook-form';

import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  PasswordInput,
  PasswordInputProps,
} from '../../..';

type FieldPasswordInputProps<FormFieldValues extends FieldValues> =
  PasswordInputProps & {
    name: FieldPath<FormFieldValues>;
    control: Control<FormFieldValues>;
    label?: string;
    info?: React.ReactNode;
  };

const FieldPasswordInputInner = <
  FormFieldValues extends FieldValues = FieldValues
>(
  {
    label,
    info,
    name,
    control,
    ...rest
  }: FieldPasswordInputProps<FormFieldValues>,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const { errors } = useFormState({ control, name });
  const fieldError = get(errors, name);
  const isInvalid = !!fieldError;
  const errorMessage = fieldError?.message?.toString();

  return (
    <FormControl isInvalid={isInvalid}>
      {label && <FormLabel variant="simple">{label}</FormLabel>}
      <PasswordInput ref={ref} name={name} {...rest} />
      {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
      {!!info && <FormHelperText>{info}</FormHelperText>}
    </FormControl>
  );
};

export const FieldPasswordInput = forwardRef(FieldPasswordInputInner);
