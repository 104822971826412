import type { BoxProps, FlexProps } from '@chakra-ui/react';
import type { ReactNode } from 'react';

import { Box, Flex, Grid } from '../layout';
import { Text } from '../typography';

export type InfoboxProps = BoxProps & {
  title?: ReactNode;
  description?: ReactNode;
  align?: FlexProps['align'];
  icon?: ReactNode;
  children?: ReactNode;
  iconContainerStyle?: BoxProps;
  gridContainerStyle?: BoxProps;
};

export const Infobox = ({
  description,
  icon,
  title,
  children,
  iconContainerStyle,
  gridContainerStyle,
  align,
  ...rest
}: InfoboxProps) => {
  return (
    <Box
      bgColor="primary.25"
      borderWidth="1px"
      borderColor="primary.600"
      borderRadius="8px"
      color="rythm.900"
      padding={4}
      {...rest}
    >
      <Flex align={align}>
        {!!icon && (
          <Box color="primary.600" mt="-2px" mr="12px" {...iconContainerStyle}>
            {icon}
          </Box>
        )}
        <Grid rowGap={1} {...gridContainerStyle}>
          {title && (
            <Text variant="desktop-m-medium" color="inherit">
              {title}
            </Text>
          )}
          {!!description && <Text>{description}</Text>}
          {children}
        </Grid>
      </Flex>
    </Box>
  );
};
