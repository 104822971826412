import { stringify } from 'query-string';

import { JOBS_PREFIX, PUBLIC_PROFILE_PREFIX } from '../constants';

const slugPlaceholder = '[slug]';

type GetCollectivePublicPageUrlQuery = {
  collectiveSelection?: string;
  source?: string;
  t?: string;
};

export const getCollectivePublicPageUrl = (
  slug: string,
  query: GetCollectivePublicPageUrlQuery = {},
  path?: string
) => {
  const url = (
    process.env.NX_PUBLIC_PAGE_URL || 'http://[slug].localhost:4201'
  ).replace(slugPlaceholder, slug);

  const fullUrl = path ? `${url}/${path}` : url;

  return [fullUrl, stringify(query)].filter(Boolean).join('?');
};

export const getPublicProfileLink = (slug: string) => {
  const base = getCollectivePublicPageUrl(PUBLIC_PROFILE_PREFIX);

  return `${base}/${slug}`;
};

export const getPublicJobLink = (slug: string) => {
  const base = getCollectivePublicPageUrl(JOBS_PREFIX);

  return `${base}/${slug}`;
};

export const getCaseStudyPublicPageUrl = (
  collectiveSlug: string,
  caseStudySlug: string
) => {
  const collectivePageUrl = getCollectivePublicPageUrl(collectiveSlug);

  return `${collectivePageUrl}/case-study/${caseStudySlug}`;
};
