import { useMergeRefs } from '@chakra-ui/react';
import { ForwardedRef, forwardRef } from 'react';
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

import {
  CurrencyInput,
  CurrencyInputProps,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
} from '../../..';
type FieldCurrencyInputProps = CurrencyInputProps & {
  label?: React.ReactNode;
  info?: React.ReactNode;
};

const FieldCurrencyInputInner = <
  FormFieldValues extends FieldValues = FieldValues,
  FieldName extends FieldPath<FormFieldValues> = FieldPath<FormFieldValues>
>(
  {
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
    label,
    info,
    ...rest
  }: UseControllerProps<FormFieldValues, FieldName> & FieldCurrencyInputProps,
  forwardedRef: ForwardedRef<HTMLInputElement>
) => {
  const {
    field: { ref: controllerRef, ...fieldRest },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
  });
  const refs = useMergeRefs(controllerRef, forwardedRef);

  const onChange = (value: string) => {
    fieldRest.onChange(value);
    rest.onChange?.(value);
  };

  return (
    <FormControl isInvalid={!!error}>
      {!!label && <FormLabel variant="simple">{label}</FormLabel>}
      <CurrencyInput
        ref={refs}
        isInvalid={!!error}
        {...fieldRest}
        {...rest}
        onChange={onChange}
      />
      {error?.message && <FormErrorMessage>{error.message}</FormErrorMessage>}
      {!!info && <FormHelperText>{info}</FormHelperText>}
    </FormControl>
  );
};

export const FieldCurrencyInput = forwardRef(FieldCurrencyInputInner);
