import {
  FormLabel as ChakraFormLabel,
  FormLabelProps as ChakraFormLabelProps,
} from '@chakra-ui/react';

import { FormLabelVariants } from '../../themes/components/form-label';

export type FormLabelProps = Omit<ChakraFormLabelProps, 'variant'> & {
  variant?: FormLabelVariants;
};

export const FormLabel = (props: FormLabelProps) => (
  <ChakraFormLabel {...props} />
);
