import { toString } from 'lodash';
import { Trans } from 'react-i18next';

import { Text } from '../../typography';

type CharsLeftCounterProps = {
  maxLength: number;
  value: string | number | readonly string[] | undefined;
};

// NOTE: Will only work when value is provided, so with controlled fields
export const CharsLeftCounter = ({
  maxLength,
  value,
}: CharsLeftCounterProps) => {
  const stringValue = toString(value);
  const charsLeft = Math.max(maxLength - stringValue.length, 0);

  return (
    <Text variant="desktop-s-regular" color="rythm.700" mt={1} w="100%">
      <Trans
        i18nKey="fields.charsLeft"
        values={{ count: charsLeft }}
        components={{
          b: <Text color="inherit" as="span" variant="desktop-s-semibold" />,
        }}
      />
    </Text>
  );
};
