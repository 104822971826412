import { Breakpoint, breakpoints } from '../../themes/values';

function matchMedia(maxW: string, minW?: string) {
  return window.matchMedia(
    `${minW ? `(min-width: ${minW}) and ` : ''}(max-width: ${maxW})`
  ).matches;
}

export function useScreenSize(size: Breakpoint) {
  return matchMedia(breakpoints[size]);
}
