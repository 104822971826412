import utilsDate from '@collective/utils/date';
import { ChangeEvent, forwardRef, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { Merge } from 'type-fest';

import { TextInput, TextInputProps } from '.';

export type DateInputProps = Merge<
  TextInputProps,
  {
    value?: string | undefined;
    min?: Date;
    max?: Date;
    onChange?: (isoDate: string | null) => void;
  }
>;

export const DateInput = forwardRef<HTMLInputElement, DateInputProps>(
  function DateInput(
    { min, max, value, onChange, onBlur, onFocus, placeholder, ...rest },
    ref
  ) {
    const [inputValue, setInputValue] = useState<string | null>(
      value ? utilsDate.toInputDate(new Date(value)) : null
    );

    useEffect(() => {
      // In case we got undefined first, and then the value is fetch
      // Mostly a case for testing purpose
      if (!value) {
        return;
      }

      setInputValue(utilsDate.toInputDate(new Date(value)));
    }, [value]);

    const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value);

      const parsedDate = utilsDate.parseLocalizedDate(e.target.value);

      onChange?.(parsedDate ? utilsDate.convertToUTC(parsedDate) : null);
    };

    return (
      <InputMask
        mask="99 / 99 / 9999"
        onChange={onInputChange}
        onBlur={onBlur}
        onFocus={onFocus}
        maskPlaceholder={null}
        value={inputValue || ''}
      >
        <TextInput
          ref={ref}
          placeholder={utilsDate.getLocalizedPlaceholder()}
          {...rest}
        />
      </InputMask>
    );
  }
);
