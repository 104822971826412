export const colors = {
  primary: {
    1: '#F8F8FC',
    25: '#F0F0FF',
    50: '#ECEBFF',
    100: '#C4C2FF',
    200: '#BAB5F7',
    300: '#A19BF3',
    400: '#7E76EF',
    500: '#5752EA',
    600: '#3426E5',
    700: '#1E1C9B',
    800: '#111056',
    900: '#141331',
  },
  rythm: {
    100: '#F9FAFB',
    200: '#ECECEF',
    300: '#DCDBE6',
    600: '#9997B4',
    700: '#747288',
    900: '#2B2749',
  },
  success: {
    100: '#F8FEFA',
    200: '#E2F3E8',
    700: '#138741',
    900: '#0F572C',
  },
  critical: {
    100: '#FCF2F5',
    500: '#D22856',
    700: '#BC244D',
  },
  caution: {
    100: '#FFF3CB',
    500: '#C28800',
    700: '#856500',
  },
  decorative: {
    sky: {
      100: '#F6FAFE',
      500: '#1870D8',
    },
    gold: {
      100: '#FEFBF8',
      400: '#FFE799',
      700: '#FFCD29',
      800: '#F5B800',
    },
    fuschia: {
      500: '#FB1EA6',
    },
    lagoon: {
      500: '#74D6C3',
    },
  },
};
