const Radio = {
  baseStyle: {
    container: {
      borderColor: 'rythm.600',
      _hover: {
        borderColor: 'rythm.700',
      },
      _checked: {
        borderColor: 'primary.600',
        color: 'primary.600',
        _hover: {
          borderColor: 'primary.700',
          color: 'primary.700',
        },
      },
    },
    control: {
      borderColor: 'inherit',
      color: 'inherit',
      _checked: {
        bg: 'white',
        borderColor: 'inherit',
        color: 'inherit',
        _before: {
          w: 2,
          h: 2,
        },
        _hover: {
          bg: 'white',
          borderColor: 'inherit',
          color: 'inherit',
        },
        _disabled: {
          bg: 'white',
          color: 'inherit',
          borderColor: 'inherit',
        },
      },
      _disabled: {
        bg: 'white',
        borderColor: 'inherit',
        color: 'inherit',
        opacity: 0.5,
      },
    },
    label: {
      color: 'rythm.900',
      _disabled: {
        opacity: 0.5,
      },
    },
  },
  sizes: {
    md: {
      control: {
        w: 4.5,
        h: 4.5,
        ml: 0.5,
        mr: 0.5,
      },
      label: {
        fontSize: 'xs',
        fontWeight: 600,
        ml: 2,
      },
    },
  },
};

export default Radio;
