import { ReactNode } from 'react';
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

import { FormControl, FormErrorMessage, Switch, SwitchProps } from '../../..';

export type FieldSwitchProps = SwitchProps & {
  label?: ReactNode;
};

export const FieldSwitch = <
  FormFieldValues extends FieldValues = FieldValues,
  FieldName extends FieldPath<FormFieldValues> = FieldPath<FormFieldValues>
>({
  name,
  control,
  rules,
  shouldUnregister,
  defaultValue,
  label,
  children,
  ...rest
}: UseControllerProps<FormFieldValues, FieldName> & FieldSwitchProps) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
  });

  return (
    <FormControl isInvalid={!!error}>
      <Switch {...field} {...rest} isChecked={field.value}>
        {label}
        {children}
      </Switch>
      {error?.message && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};
