import { CountryCode } from '../countries';

export enum VAT_STATEMENT {
  VAT_MANUALLY_REVIEWED = 'VAT_MANUALLY_REVIEWED',
  VAT_NOT_APPLICABLE = 'VAT_NOT_APPLICABLE',
  VAT_NOT_APPLICABLE_ARTICLE_259 = 'VAT_NOT_APPLICABLE_ARTICLE_259',
  VAT_NOT_APPLICABLE_ARTICLE_293 = 'VAT_NOT_APPLICABLE_ARTICLE_293',
  VAT_NOT_APPLICABLE_REVERSE_CHARGE = 'VAT_NOT_APPLICABLE_REVERSE_CHARGE',
  SHOW_VAT_TABLE = 'SHOW_VAT_TABLE',
}

export type VatTable = {
  base: string;
  vat: string;
  noVatBase?: string;
  noVatAmount?: string;
  amount: string;
};

export type VatSettings = {
  shouldDisplayDetailedVat: boolean;
  hasOnlyMembersThatChargeVat: boolean;
  hasOnlyMembersThatDontChargeVat: boolean;
  membersCompanies: {
    isChargingVat: boolean;
    country: CountryCode;
  }[];
};

export type VatInformation = {
  vatTable: VatTable | null;
  vatPercentage: number | null;
  vatStatement: VAT_STATEMENT;
  vatSettings: VatSettings | null;
};
