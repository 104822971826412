export const WEBSITES = {
  LINKEDIN: 'LINKEDIN',
  GITHUB: 'GITHUB',
  STACKOVERFLOW: 'STACKOVERFLOW',
  DRIBBBLE: 'DRIBBBLE',
  BEHANCE: 'BEHANCE',
  YOUTUBE: 'YOUTUBE',
  INSTAGRAM: 'INSTAGRAM',
  X: 'X',
  TIKTOK: 'TIKTOK',
  MALT: 'MALT',
  FIVER: 'FIVER',
  UPWORK: 'UPWORK',
  VIMEO: 'VIMEO',
  PINTEREST: 'PINTEREST',
  TWITCH: 'TWITCH',
};

export type Website = keyof typeof WEBSITES;

export const detectWebsite = (url?: string): Website | null => {
  const websitePatterns: { [key in Website]: RegExp } = {
    LINKEDIN: /^(?:https?:\/\/)?(?:www\.)?linkedin\.[a-z]{2,}.*$/,
    GITHUB: /^(?:https?:\/\/)?(?:www\.)?github\.com.*$/,
    STACKOVERFLOW:
      /^(?:https?:\/\/)?(?:www\.)?(stackoverflow\.com|stackexchange\.com).*$/,
    DRIBBBLE: /^(?:https?:\/\/)?(?:www\.)?dribbble\.com.*$/,
    BEHANCE: /^(?:https?:\/\/)?(?:www\.)?(behance\.net|be\.net).*$/,
    YOUTUBE: /^(?:https?:\/\/)?(?:www\.)?(youtube\.com|youtu\.be).*$/,
    INSTAGRAM: /^(?:https?:\/\/)?(?:www\.)?(instagram\.com|instagr\.am).*$/,
    X: /^(?:https?:\/\/)?(?:www\.)?(x\.com|twitter\.com).*$/,
    TIKTOK: /^(?:https?:\/\/)?(?:www\.)?tiktok\.com.*$/,
    MALT: /^(?:https?:\/\/)?(?:www\.)?malt\..*$/,
    FIVER: /^(?:https?:\/\/)?(?:www\.)?fiver\.com.*$/,
    UPWORK: /^(?:https?:\/\/)?(?:www\.)?upwork\.com.*$/,
    VIMEO: /^(?:https?:\/\/)?(?:www\.)?vimeo\.com.*$/,
    PINTEREST: /^(?:https?:\/\/)?(?:www\.)?pinterest\..*$/,
    TWITCH: /^(?:https?:\/\/)?(?:www\.)?twitch\.tv.*$/,
  };

  for (const [name, pattern] of Object.entries(websitePatterns)) {
    if (url && pattern.test(url)) {
      return name as Website;
    }
  }

  return null;
};

export const getLinkHref = (url?: string) => {
  return url && !/^(http|https):\/\//i.test(url) ? `https://${url}` : url;
};
