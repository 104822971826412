import { BoxProps, chakra } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { ReactNode } from 'react';

import { Box } from '../layout';

interface HorizontalStepperProps {
  children: ReactNode;
}

export const HorizontalStepper = ({ children }: HorizontalStepperProps) => {
  return <HorizontalList>{children}</HorizontalList>;
};

const HorizontalList = styled(chakra.ol)`
  position: relative;
  list-style-type: none;
  display: flex;
  flex-direction: row;

  li:first-of-type {
    .horizontal-stepper-item-box {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
  }

  li:last-child {
    .horizontal-stepper-item-box {
      border-right: 1px solid;
      border-color: var(--chakra-colors-rythm-300);
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  li {
    flex: 1;
  }

  .horizontal-stepper-item-box {
    position: relative;
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-left: 1px solid;
    border-color: var(--chakra-colors-rythm-300);
  }

  .horizontal-stepper-item-tooltip {
    top: calc(100% - 8px); // 8px ref to the border radius
  }

  li:hover {
    & {
      .horizontal-stepper-item-box {
        border-color: var(--chakra-colors-rythm-600);
      }
    }

    & + li {
      .horizontal-stepper-item-box {
        border-left-color: var(--chakra-colors-rythm-600);
      }
    }

    & > .horizontal-stepper-item-tooltip {
      opacity: 100%;
      visibility: visible;
    }
  }
`;

interface HorizontalStepperItemProps {
  children: ReactNode;
}

const Item = ({ children }: HorizontalStepperItemProps) => {
  return <chakra.li role="group">{children}</chakra.li>;
};

type HorizontalStepperItemBoxProps = BoxProps;

const Container = ({ children, ...props }: HorizontalStepperItemBoxProps) => {
  return (
    <Box
      position="relative"
      w="100%"
      h="100%"
      bg="white"
      {...props}
      className="horizontal-stepper-item-box"
    >
      {children}
    </Box>
  );
};

const Tooltip = ({ children, ...props }: BoxProps) => {
  return (
    <Box
      position="absolute"
      background="rythm.100"
      border="1px solid"
      borderColor="rythm.300"
      right={0}
      paddingTop={2}
      width="100%"
      minHeight="100%"
      opacity={0}
      borderRadius="0 0 12px 12px"
      transition="visibility 0.1s, opacity 0.1s ease-in-out"
      visibility="hidden"
      boxShadow="md"
      zIndex={4}
      {...props}
      className="horizontal-stepper-item-tooltip"
    >
      {children}
    </Box>
  );
};

HorizontalStepper.Item = Item;
Item.Container = Container;
Item.Tooltip = Tooltip;
