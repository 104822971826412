export enum FrenchCompanyClassification {
  FrenchEiEirl = 'FRENCH_EI_EIRL',
  FrenchMdaAgessa = 'FRENCH_MDA_AGESSA',
  FrenchMicroAuto = 'FRENCH_MICRO_AUTO',
  FrenchOther = 'FRENCH_OTHER',
  FrenchSarlEurlSasuSas = 'FRENCH_SARL_EURL_SASU_SAS',
}

export enum EuropeCompanyClassification {
  EuropeIndividual = 'EUROPE_INDIVIDUAL',
  EuropeRegisteredCompany = 'EUROPE_REGISTERED_COMPANY',
}

export enum OtherCountriesCompanyClassification {
  OtherCountriesIndividual = 'OTHER_COUNTRIES_INDIVIDUAL',
  OtherCountriesRegisteredCompany = 'OTHER_COUNTRIES_REGISTERED_COMPANY',
}

export const isFrenchCompanyFromClassification = (
  companyClassification: string
) => {
  return Object.values(FrenchCompanyClassification).includes(
    companyClassification as FrenchCompanyClassification
  );
};
