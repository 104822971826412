import { Theme, transparentize } from '../../../hooks/use-transparentize';

export const richTextEditorStyles = (theme: Theme, isEditMode = true) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',

  '.ProseMirror': {
    flex: 1,
    height: '100%',
    overflow: 'auto',

    ':focus-visible': {
      outline: 'none',
    },

    h1: {
      lineHeight: '2rem',
      fontSize: '1.5rem',
      fontWeight: 700,
    },
    h2: {
      lineHeight: '1.75rem',
      fontSize: '1.125rem',
      fontWeight: 700,
    },
    h3: {
      lineHeight: '1.125rem',
      fontSize: '0.875rem',
      fontWeight: 700,
    },
    a: {
      cursor: 'pointer',
      textDecoration: 'underline',
      color: 'rythm.700',
      _hover: {
        color: 'rythm.900',
      },
      _active: {
        color: 'rythm.900',
      },
    },
    '> ul, ol': {
      padding: '0 1rem 0 1.6rem',
    },
    '*::selection': {
      background: 'primary.25',
    },

    // Styles for Image
    '.image-container': {
      position: 'relative',
      margin: '1.5rem 0',
      textAlign: 'center',
      '&.has-focus .image-row-wrapper': {
        background: 'primary.25',
      },
      '&.has-focus .image-overlay': {
        background: transparentize('primary.25', 0.2)(theme),
      },
      ...(isEditMode && {
        '&:hover .image-row-wrapper': {
          background: 'primary.25',
        },
        '&:hover .image-overlay': {
          background: transparentize('primary.800', 0.12)(theme),
        },
        // without this, the hover doesn't override the focus
        '&.has-focus:hover .image-overlay': {
          background: transparentize('primary.800', 0.12)(theme),
        },
      }),
    },
    '.image-row-wrapper': {
      padding: '1rem 0',
      position: 'relative',
      ...(isEditMode && { cursor: 'pointer' }),
      transition: 'background .1s',
    },
    '.image-wrapper': {
      display: 'inline-block',
      position: 'relative',
    },
    img: {
      borderRadius: '12px',
    },
    '.image-overlay': {
      borderRadius: '12px',
      ...(isEditMode && { cursor: 'pointer' }),
      position: 'absolute',
      inset: 0,
      transition: 'background .1s',
    },
    // Styles for the tiptap placeholder
    'p.is-editor-empty:first-of-type': {
      opacity: 1,
      transition: 'opacity .2s',

      '&::before': {
        color: 'rythm.600',
        content: 'attr(data-placeholder)',
        float: 'left',
        height: 0,
        pointerEvents: 'none',
      },
    },
  },
});
