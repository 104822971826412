/**
 * ⚠ ⚠ ⚠ ⚠ ⚠ ⚠ ⚠ ⚠
 *
 * Modify this file with caution
 * Once the backend is deployed, the command sync-project-types is run
 * and it will reflect the changes done here on the database
 *
 * Here are the steps if you want to add a project type category/project type
 * - Add an entry in the following enums below
 *
 * In case of a category :
 * - Add your category in the root of `projectTypeStructure` (The order matters here, it will be reflected on the frontend)
 *
 * In case of a project type :
 * - Add your project type in array `projectTypes` of a category
 * Careful, a project type can only be in one category (otherwise the last one will always prevail)
 *
 * - Update the `./utils.ts` to add the translation. If you don't, you wont be able to compile anyway
 *
 * And it's done 🎉
 *
 * ⚠ ⚠ ⚠ ⚠ ⚠ ⚠ ⚠ ⚠
 */
export enum ProjectTypeCategoryEnum {
  TECH = 'TECH',
  TECH_CLOUD_DATABASES = 'TECH_CLOUD_DATABASES',
  TECH_LANGUAGES_AND_FRAMEWORKS = 'TECH_LANGUAGES_AND_FRAMEWORKS',
  TECH_TOOLS = 'TECH_TOOLS',
  PRODUCT = 'PRODUCT',
  PRODUCT_TOOLS = 'PRODUCT_TOOLS',
  NOCODE_AND_LOWCODE = 'NOCODE_AND_LOWCODE',
  NOCODE_AND_LOWCODE_TOOLS = 'NOCODE_AND_LOWCODE_TOOLS',
  MARKETING_AND_SALES = 'MARKETING_AND_SALES',
  MARKETING_AND_SALES_TOOLS = 'MARKETING_AND_SALES_TOOLS',
  DESIGN_AND_CONTENT = 'DESIGN_AND_CONTENT',
  DESIGN_AND_CONTENT_TOOLS = 'DESIGN_AND_CONTENT_TOOLS',
  BUSINESS = 'BUSINESS',
  BUSINESS_TOOLS = 'BUSINESS_TOOLS',
}

export enum ProjectTypeEnum {
  // - TECH
  DEVOPS = 'DEVOPS',
  SITE_RELIABILITY_ENGINEERING = 'SITE_RELIABILITY_ENGINEERING',
  SALESFORCE_DEVELOPMENT = 'SALESFORCE_DEVELOPMENT',
  WEB3 = 'WEB3',
  BLOCKCHAIN = 'BLOCKCHAIN',
  CYBERSECURITY = 'CYBERSECURITY',
  HARDWARE = 'HARDWARE',
  TESTING_QA = 'TESTING_QA',
  DOMAIN_DRIVEN_DESIGN = 'DOMAIN_DRIVEN_DESIGN',
  TEST_DRIVEN_DEVELOPMENT = 'TEST_DRIVEN_DEVELOPMENT',
  TEST_DESIGN = 'TEST_DESIGN',
  CLEAN_CODE = 'CLEAN_CODE',
  SOFTWARE_CRAFTSMANSHIP = 'SOFTWARE_CRAFTSMANSHIP',
  CLEAN_ARCHITECTURE = 'CLEAN_ARCHITECTURE',
  HEXAGONAL_ARCHITECTURE = 'HEXAGONAL_ARCHITECTURE',
  EVENT_DRIVEN_ARCHITECTURE = 'EVENT_DRIVEN_ARCHITECTURE',
  MICROSERVICES = 'MICROSERVICES',
  BACKEND_DEVELOPMENT = 'BACKEND_DEVELOPMENT',
  FRONTEND_DEVELOPMENT = 'FRONTEND_DEVELOPMENT',
  FULLSTACK_DEVELOPMENT = 'FULLSTACK_DEVELOPMENT',
  MOBILE_DEVELOPMENT = 'MOBILE_DEVELOPMENT',
  DATA_ANALYSIS = 'DATA_ANALYSIS',
  DATA_VIZUALIZATION = 'DATA_VIZUALIZATION',
  SQL_QUERIES = 'SQL_QUERIES',
  DATA_SCIENCE = 'DATA_SCIENCE',
  MACHINE_LEARNING = 'MACHINE_LEARNING',
  DEEP_LEARNING = 'DEEP_LEARNING',
  DATA_MINING = 'DATA_MINING',
  AI = 'AI',
  LLMS_LARGE_LANGUAGE_MODELS = 'LLMS_LARGE_LANGUAGE_MODELS',
  DATA_ENGINEERING = 'DATA_ENGINEERING',
  ETL = 'ETL',
  SCRAPPING = 'SCRAPPING',
  BIG_DATA = 'BIG_DATA',

  // - TECH_CLOUD_DATABASES
  AWS = 'AWS',
  AZURE = 'AZURE',
  GOOGLE_CLOUD_PLATFORM = 'GOOGLE_CLOUD_PLATFORM',
  IBM_CLOUD = 'IBM_CLOUD',
  ORACLE_CLOUD = 'ORACLE_CLOUD',
  SALESFORCE_CLOUD_SERVICES = 'SALESFORCE_CLOUD_SERVICES',
  SAP_CLOUD = 'SAP_CLOUD',
  RACKSPACE = 'RACKSPACE',
  DIGITALOCEAN = 'DIGITALOCEAN',
  LINODE = 'LINODE',
  OVHCLOUD = 'OVHCLOUD',
  COCKROACHDB = 'COCKROACHDB',
  SQL_DATABASE = 'SQL_DATABASE',
  POSTGRESQL = 'POSTGRESQL',
  MYSQL = 'MYSQL',
  MONGODB = 'MONGODB',
  DYNAMODB = 'DYNAMODB',
  ELASTICSEARCH = 'ELASTICSEARCH',
  MARIADB = 'MARIADB',
  MICROSOFT_SQL_SERVER = 'MICROSOFT_SQL_SERVER',
  ORACLE_DATABASE = 'ORACLE_DATABASE',
  SQLITE = 'SQLITE',
  AMAZON_EC2 = 'AMAZON_EC2',
  AWS_LAMBDA = 'AWS_LAMBDA',
  CASSANDRA = 'CASSANDRA',
  SCYLLADB = 'SCYLLADB',
  REDIS = 'REDIS',
  COUCHDB = 'COUCHDB',
  NEO4J = 'NEO4J',
  IBM_DB2 = 'IBM_DB2',
  AMAZON_REDSHIFT = 'AMAZON_REDSHIFT',
  MICROSOFT_AZURE_COSMOS_DB = 'MICROSOFT_AZURE_COSMOS_DB',
  SAP_HANA = 'SAP_HANA',
  KAFKA = 'KAFKA',
  RABBITMQ = 'RABBITMQ',
  SNOWFLAKE = 'SNOWFLAKE',
  GOOGLE_BIGQUERY = 'GOOGLE_BIGQUERY',

  // - TECH_LANGUAGES_AND_FRAMEWORKS
  JAVASCRIPT = 'JAVASCRIPT',
  TYPESCRIPT = 'TYPESCRIPT',
  SOLIDITY = 'SOLIDITY',
  HTML = 'HTML',
  CSS = 'CSS',
  SASS = 'SASS',
  REACT = 'REACT',
  ANGULAR = 'ANGULAR',
  VUE = 'VUE',
  NEXT = 'NEXT',
  NUXT = 'NUXT',
  NODE = 'NODE',
  NEST = 'NEST',
  PYTHON = 'PYTHON',
  FLASK = 'FLASK',
  DJANGO = 'DJANGO',
  PHP = 'PHP',
  LARAVEL = 'LARAVEL',
  SYMFONY = 'SYMFONY',
  RUBY = 'RUBY',
  RUBY_ON_RAILS = 'RUBY_ON_RAILS',
  DART = 'DART',
  GOLANG = 'GOLANG',
  JAVA = 'JAVA',
  C_SHARP = 'C_SHARP',
  DOT_NET = 'DOT_NET',
  IOS = 'IOS',
  SWIFT = 'SWIFT',
  OBJECTIVE_C = 'OBJECTIVE_C',
  ANDROID = 'ANDROID',
  KOTLIN = 'KOTLIN',
  REACT_NATIVE = 'REACT_NATIVE',
  FLUTTER = 'FLUTTER',
  C = 'C',
  C_PLUS_PLUS = 'C_PLUS_PLUS',
  RUST = 'RUST',
  SCALA = 'SCALA',
  F_SHARP = 'F_SHARP',
  ELIXIR = 'ELIXIR',
  BACKBONE_JS = 'BACKBONE_JS',
  BOOTSTRAP = 'BOOTSTRAP',
  EXPRESS = 'EXPRESS',
  REDUX = 'REDUX',
  SPRING_BOOT = 'SPRING_BOOT',
  SVELTE = 'SVELTE',
  PHOENIX = 'PHOENIX',
  MATLAB = 'MATLAB',
  NUMPY = 'NUMPY',
  PANDAS = 'PANDAS',
  R = 'R',
  SCIKIT_LEARN = 'SCIKIT_LEARN',
  KERAS = 'KERAS',
  PYTORCH = 'PYTORCH',
  TENSORFLOW = 'TENSORFLOW',

  // - TECH_TOOLS
  GLPI = 'GLPI',
  METABASE = 'METABASE',
  POWER_BI = 'POWER_BI',
  TABLEAU = 'TABLEAU',
  LOOKER = 'LOOKER',
  DBT = 'DBT',
  DATABRICKS = 'DATABRICKS',
  ALTERYX = 'ALTERYX',
  QUALTRICS = 'QUALTRICS',
  FIREBASE = 'FIREBASE',
  KUBERNETES = 'KUBERNETES',
  TERRAFORM = 'TERRAFORM',
  VERCEL = 'VERCEL',
  DOCKER = 'DOCKER',
  LINUX = 'LINUX',
  GIT = 'GIT',
  TAILWIND_CSS = 'TAILWIND_CSS',
  GRAPHQL = 'GRAPHQL',
  SUPABASE = 'SUPABASE',
  GATSBY = 'GATSBY',
  HEROKU = 'HEROKU',
  GITHUB = 'GITHUB',
  GITLAB = 'GITLAB',
  BITBUCKET = 'BITBUCKET',
  CIRCLECI = 'CIRCLECI',
  JENKINS = 'JENKINS',
  POSTMAN = 'POSTMAN',
  WINDEV = 'WINDEV',
  RESTFUL_APIS = 'RESTFUL_APIS',
  SWAGGER_OPENAPI = 'SWAGGER_OPENAPI',
  WEBPACK = 'WEBPACK',
  DATADOG = 'DATADOG',
  GRAFANA = 'GRAFANA',
  PROMETHEUS = 'PROMETHEUS',
  SPLUNK = 'SPLUNK',
  BURP_SUITE = 'BURP_SUITE',
  ZAP = 'ZAP',
  SNORT = 'SNORT',
  IONIC = 'IONIC',
  XCODE = 'XCODE',
  MAVEN = 'MAVEN',
  GRADLE = 'GRADLE',
  ANSIBLE = 'ANSIBLE',
  CYPRESS = 'CYPRESS',
  SELENIUM = 'SELENIUM',
  PLAYWRIGHT = 'PLAYWRIGHT',

  // - PRODUCT
  PRODUCT_STRATEGY = 'PRODUCT_STRATEGY',
  PRODUCT_MANAGEMENT = 'PRODUCT_MANAGEMENT',
  USER_RESEARCH = 'USER_RESEARCH',
  PRODUCT_OWNERSHIP = 'PRODUCT_OWNERSHIP',
  PRODUCT_MARKETING = 'PRODUCT_MARKETING',
  GAME_DESIGN = 'GAME_DESIGN',
  SCRUM = 'SCRUM',
  DESIGN_THINKING = 'DESIGN_THINKING',
  AGILE_COACHING = 'AGILE_COACHING',
  A_B_TESTING = 'A_B_TESTING',

  // - PRODUCT_TOOLS
  ASANA = 'ASANA',
  BASECAMP = 'BASECAMP',
  CONFLUENCE = 'CONFLUENCE',
  JIRA = 'JIRA',
  MONDAY_COM = 'MONDAY_COM',
  OPTIMIZELY = 'OPTIMIZELY',
  AMPLITUDE = 'AMPLITUDE',

  // - NOCODE_AND_LOWCODE
  NOCODE_APPLICATIONS = 'NOCODE_APPLICATIONS',
  WEBSITES = 'WEBSITES',
  E_COMMERCE_WEBSITES = 'E_COMMERCE_WEBSITES',
  NOCODE_AUTOMATIONS = 'NOCODE_AUTOMATIONS',
  CMS = 'CMS',
  E_COMMERCE_CMS = 'E_COMMERCE_CMS',

  // - NOCODE_AND_LOWCODE_TOOLS
  BUBBLE = 'BUBBLE',
  XANO = 'XANO',
  WEWEB = 'WEWEB',
  FLUTTERFLOW = 'FLUTTERFLOW',
  WEBFLOW = 'WEBFLOW',
  WORDPRESS = 'WORDPRESS',
  DRUPAL = 'DRUPAL',
  FRAMER = 'FRAMER',
  PRESTASHOP = 'PRESTASHOP',
  MAGENTO = 'MAGENTO',
  WOOCOMMERCE = 'WOOCOMMERCE',
  SHOPIFY = 'SHOPIFY',
  ZAPIER = 'ZAPIER',
  MAKE = 'MAKE',
  N8N = 'N8N',
  STRAPI = 'STRAPI',
  AIRTABLE = 'AIRTABLE',
  NOTION = 'NOTION',
  GLIDE = 'GLIDE',
  SQUARESPACE = 'SQUARESPACE',
  RETOOL = 'RETOOL',

  // - MARKETING_AND_SALES
  MARKETING_STRATEGY = 'MARKETING_STRATEGY',
  CRO = 'CRO',
  SEO = 'SEO',
  SEA = 'SEA',
  SOCIAL_ADS = 'SOCIAL_ADS',
  WEB_ANALYTICS = 'WEB_ANALYTICS',
  SALES_STRATEGY = 'SALES_STRATEGY',
  LEAD_GENERATION = 'LEAD_GENERATION',
  COLD_CALLING = 'COLD_CALLING',
  EMAILING = 'EMAILING',
  COMMUNICATION_CAMPAIGN = 'COMMUNICATION_CAMPAIGN',
  EVENT_MANAGEMENT = 'EVENT_MANAGEMENT',
  CONTENT_STRATEGY = 'CONTENT_STRATEGY',
  ARTICLES_CREATION = 'ARTICLES_CREATION',
  COPYWRITING = 'COPYWRITING',
  COMMUNITY_MANAGEMENT = 'COMMUNITY_MANAGEMENT',
  PRESS_RELATIONS = 'PRESS_RELATIONS',
  BRANDING_STRATEGY = 'BRANDING_STRATEGY',
  GROWTH = 'GROWTH',
  SALES_OPS = 'SALES_OPS',
  MARKETING_OPS = 'MARKETING_OPS',
  BUSINESS_DEVELOPMENT = 'BUSINESS_DEVELOPMENT',
  GO_TO_MARKET = 'GO_TO_MARKET',
  OUTBOUND = 'OUTBOUND',
  LEAD_NURTURING = 'LEAD_NURTURING',
  CRM = 'CRM',

  // - MARKETING_AND_SALES_TOOLS
  GOOGLE_ADS = 'GOOGLE_ADS',
  LINKEDIN_ADS = 'LINKEDIN_ADS',
  META_ADS = 'META_ADS',
  TIKTOK_ADS = 'TIKTOK_ADS',
  YOUTUBE_ADS = 'YOUTUBE_ADS',
  SALESFORCE = 'SALESFORCE',
  SALESFORCE_MARKETING_CLOUD = 'SALESFORCE_MARKETING_CLOUD',
  HUBSPOT = 'HUBSPOT',
  CUSTOMER_IO = 'CUSTOMER_IO',
  KLAVIYO = 'KLAVIYO',
  PIPEDRIVE = 'PIPEDRIVE',
  GOOGLE_ANALYTICS = 'GOOGLE_ANALYTICS',
  ACTIVE_CAMPAIGN = 'ACTIVE_CAMPAIGN',
  AHREFS = 'AHREFS',
  MARKETO = 'MARKETO',
  SEMRUSH = 'SEMRUSH',
  ZOHO_CRM = 'ZOHO_CRM',
  MAILCHIMP = 'MAILCHIMP',
  GOOGLE_TAG_MANAGER = 'GOOGLE_TAG_MANAGER',
  HOTJAR = 'HOTJAR',
  CONVERTKIT = 'CONVERTKIT',
  CONSTANTCONTACT = 'CONSTANTCONTACT',
  BUFFER = 'BUFFER',

  // - DESIGN_AND_CONTENT
  VISUAL_IDENTITY = 'VISUAL_IDENTITY',
  ARTISTIC_DIRECTION = 'ARTISTIC_DIRECTION',
  GRAPHIC_DESIGN = 'GRAPHIC_DESIGN',
  ILLUSTRATION = 'ILLUSTRATION',
  PRINT_DESIGN = 'PRINT_DESIGN',
  MOTION_DESIGN = 'MOTION_DESIGN',
  DESIGN_3D = 'DESIGN_3D', // we can't write 3D_DESIGN because it begins with a number
  ANIMATION = 'ANIMATION',
  VIDEO_FILM = 'VIDEO_FILM',
  PHOTOGRAPHY = 'PHOTOGRAPHY',
  SOUND_DESIGN = 'SOUND_DESIGN',
  UX_PRODUCT_DESIGN = 'UX_PRODUCT_DESIGN',
  UI_PRODUCT_DESIGN = 'UI_PRODUCT_DESIGN',
  DESIGN_SYSTEM = 'DESIGN_SYSTEM',
  PROOFREADING = 'PROOFREADING',
  TRANSLATION = 'TRANSLATION',
  WEB_DESIGN = 'WEB_DESIGN',

  // - DESIGN_AND_CONTENT_TOOLS
  DESIGN_3D_STUDIOMAX = 'DESIGN_3D_STUDIOMAX',
  ADOBE_AFTER_EFFECTS = 'ADOBE_AFTER_EFFECTS',
  ADOBE_ANIMATE = 'ADOBE_ANIMATE',
  ADOBE_AUDITION = 'ADOBE_AUDITION',
  ADOBE_CREATIVE_SUITE = 'ADOBE_CREATIVE_SUITE',
  ADOBE_ILLUSTRATOR = 'ADOBE_ILLUSTRATOR',
  ADOBE_INDESIGN = 'ADOBE_INDESIGN',
  ADOBE_LIGHTROOM = 'ADOBE_LIGHTROOM',
  ADOBE_PHOTOSHOP = 'ADOBE_PHOTOSHOP',
  ADOBE_PREMIERE_PRO = 'ADOBE_PREMIERE_PRO',
  AFFINITY_DESIGNER = 'AFFINITY_DESIGNER',
  AFFINITY_PHOTO = 'AFFINITY_PHOTO',
  AFFINITY_PUBLISHER = 'AFFINITY_PUBLISHER',
  ASEPRITE = 'ASEPRITE',
  AUTOCAD = 'AUTOCAD',
  AUTODESK_3DS_MAX = 'AUTODESK_3DS_MAX',
  AUTODESK_INVENTOR = 'AUTODESK_INVENTOR',
  AUTODESK_MAYA = 'AUTODESK_MAYA',
  BLENDER = 'BLENDER',
  CINEMA_4D = 'CINEMA_4D',
  CORELDRAW = 'CORELDRAW',
  DAVINCI_RESOLVE = 'DAVINCI_RESOLVE',
  FINAL_CUT_PRO = 'FINAL_CUT_PRO',
  FUSION_360 = 'FUSION_360',
  GIMP = 'GIMP',
  INKSCAPE = 'INKSCAPE',
  PROCREATE = 'PROCREATE',
  RHINO = 'RHINO',
  SKETCH = 'SKETCH',
  SKETCHUP = 'SKETCHUP',
  V_RAY = 'V_RAY',
  ZBRUSH = 'ZBRUSH',
  CANVA = 'CANVA',
  FIGMA = 'FIGMA',

  // - BUSINESS
  STRATEGY_CONSULTING = 'STRATEGY_CONSULTING',
  PROJECT_MANAGEMENT_PMO = 'PROJECT_MANAGEMENT_PMO',
  PROJECT_OWNER = 'PROJECT_OWNER',
  HR = 'HR',
  TALENT_ACQUISITION = 'TALENT_ACQUISITION',
  LEGAL = 'LEGAL',
  GDPR = 'GDPR',
  FINANCE = 'FINANCE',
  OPERATION_PROCUREMENT = 'OPERATION_PROCUREMENT',
  CONSULTING_IT = 'CONSULTING_IT',
  CONSULTING_CYBERSECURITY = 'CONSULTING_CYBERSECURITY',
  CSR = 'CSR',
  E_LEARNING = 'E_LEARNING',
  KNOWLEDGE_MANAGEMENT = 'KNOWLEDGE_MANAGEMENT',
  COACHING = 'COACHING',
  CUSTOMER_CARE = 'CUSTOMER_CARE',
  ACCOUNTING = 'ACCOUNTING',
  CUSTOMER_SUCCESS = 'CUSTOMER_SUCCESS',

  // - BUSINESS_TOOLS
  ERP = 'ERP',
  ACUMATICA_CLOUD_ERP = 'ACUMATICA_CLOUD_ERP',
  DELTEK_VISION = 'DELTEK_VISION',
  EPICOR_ERP = 'EPICOR_ERP',
  FINANCIALFORCE_ERP = 'FINANCIALFORCE_ERP',
  IFS_APPLICATIONS = 'IFS_APPLICATIONS',
  MICROSOFT_DYNAMICS_365 = 'MICROSOFT_DYNAMICS_365',
  ODOO = 'ODOO',
  ORACLE = 'ORACLE',
  RAMCO_ERP_SUITE = 'RAMCO_ERP_SUITE',
  ROOTSTOCK_ERP = 'ROOTSTOCK_ERP',
  SAGE_INTACCT = 'SAGE_INTACCT',
  SAP_ERP = 'SAP_ERP',
  TYLER_TECHNOLOGIES_ERP = 'TYLER_TECHNOLOGIES_ERP',
  UNIT4_ERP = 'UNIT4_ERP',
  WORKDAY = 'WORKDAY',
}

export type ProjectTypeCategoryDefinition = {
  code: ProjectTypeCategoryEnum;
  name: string;
  projectTypes: readonly ProjectTypeDefinition[];
};
export type ProjectTypeDefinition = {
  code: ProjectTypeEnum;
  name: string;
  imageUrl?: string;
};

export const projectTypeStructure: readonly ProjectTypeCategoryDefinition[] = [
  {
    code: ProjectTypeCategoryEnum.TECH,
    name: 'Tech',
    projectTypes: [
      {
        code: ProjectTypeEnum.DEVOPS,
        name: 'DevOps',
      },
      {
        code: ProjectTypeEnum.SITE_RELIABILITY_ENGINEERING,
        name: 'Site reliability engineering',
      },
      {
        code: ProjectTypeEnum.SALESFORCE_DEVELOPMENT,
        name: 'Salesforce Development',
      },
      {
        code: ProjectTypeEnum.WEB3,
        name: 'Web3',
      },
      {
        code: ProjectTypeEnum.BLOCKCHAIN,
        name: 'Blockchain',
      },
      {
        code: ProjectTypeEnum.CYBERSECURITY,
        name: 'Cybersecurity',
      },
      {
        code: ProjectTypeEnum.HARDWARE,
        name: 'Hardware',
      },
      {
        code: ProjectTypeEnum.TESTING_QA,
        name: 'Testing & QA',
      },
      {
        code: ProjectTypeEnum.DOMAIN_DRIVEN_DESIGN,
        name: 'Domain Driven Design',
      },
      {
        code: ProjectTypeEnum.TEST_DRIVEN_DEVELOPMENT,
        name: 'Test Driven Development',
      },
      {
        code: ProjectTypeEnum.TEST_DESIGN,
        name: 'Test Design',
      },
      {
        code: ProjectTypeEnum.CLEAN_CODE,
        name: 'Clean Code',
      },
      {
        code: ProjectTypeEnum.SOFTWARE_CRAFTSMANSHIP,
        name: 'Software Craftsmanship',
      },
      {
        code: ProjectTypeEnum.CLEAN_ARCHITECTURE,
        name: 'Clean Architecture',
      },
      {
        code: ProjectTypeEnum.HEXAGONAL_ARCHITECTURE,
        name: 'Hexagonal Architecture',
      },
      {
        code: ProjectTypeEnum.EVENT_DRIVEN_ARCHITECTURE,
        name: 'Event Driven Architecture',
      },
      {
        code: ProjectTypeEnum.MICROSERVICES,
        name: 'Microservices',
      },
      {
        code: ProjectTypeEnum.BACKEND_DEVELOPMENT,
        name: 'Backend Development',
      },
      {
        code: ProjectTypeEnum.FRONTEND_DEVELOPMENT,
        name: 'Frontend Development',
      },
      {
        code: ProjectTypeEnum.FULLSTACK_DEVELOPMENT,
        name: 'Fullstack Development',
      },
      {
        code: ProjectTypeEnum.MOBILE_DEVELOPMENT,
        name: 'Mobile Development',
      },
      {
        code: ProjectTypeEnum.DATA_ANALYSIS,
        name: 'Data Analysis',
      },
      {
        code: ProjectTypeEnum.DATA_VIZUALIZATION,
        name: 'Data Visualization',
      },
      {
        code: ProjectTypeEnum.SQL_DATABASE,
        name: 'SQL databases',
      },
      {
        code: ProjectTypeEnum.SQL_QUERIES,
        name: 'SQL Queries',
      },
      {
        code: ProjectTypeEnum.DATA_SCIENCE,
        name: 'Data Science',
      },
      {
        code: ProjectTypeEnum.MACHINE_LEARNING,
        name: 'Machine Learning',
      },
      {
        code: ProjectTypeEnum.DEEP_LEARNING,
        name: 'Deep Learning',
      },
      {
        code: ProjectTypeEnum.DATA_MINING,
        name: 'Data Mining',
      },
      {
        code: ProjectTypeEnum.AI,
        name: 'AI',
      },
      {
        code: ProjectTypeEnum.LLMS_LARGE_LANGUAGE_MODELS,
        name: 'LLMs / Large language models',
      },
      {
        code: ProjectTypeEnum.DATA_ENGINEERING,
        name: 'Data Engineering',
      },
      {
        code: ProjectTypeEnum.ETL,
        name: 'ETL',
      },
      {
        code: ProjectTypeEnum.SCRAPPING,
        name: 'Scrapping',
      },
      {
        code: ProjectTypeEnum.BIG_DATA,
        name: 'Big Data',
      },
    ],
  },
  {
    code: ProjectTypeCategoryEnum.TECH_LANGUAGES_AND_FRAMEWORKS,
    name: 'Tech - Languages & Frameworks',
    projectTypes: [
      {
        code: ProjectTypeEnum.HTML,
        name: 'HTML',
        imageUrl: 'https://cdn.collective.work/clybz0sco0pye1x0p0o6w4f5a.jpeg',
      },
      {
        code: ProjectTypeEnum.CSS,
        name: 'CSS',
        imageUrl: 'https://cdn.collective.work/clxvsxz6b001380pcbgc3cgkx.png',
      },
      {
        code: ProjectTypeEnum.SASS,
        name: 'Sass',
        imageUrl: 'https://cdn.collective.work/clxvsymkg001t80pccshs69ye.png',
      },
      {
        code: ProjectTypeEnum.BOOTSTRAP,
        name: 'Bootstrap',
        imageUrl: 'https://cdn.collective.work/clxvsx9af000580pcbloshl5u.png',
      },
      {
        code: ProjectTypeEnum.TAILWIND_CSS,
        name: 'Tailwind CSS',
        imageUrl: 'https://cdn.collective.work/clxvsy6oj001980pc500t2u40.png',
      },
      {
        code: ProjectTypeEnum.JAVASCRIPT,
        name: 'Javascript',
        imageUrl: 'https://cdn.collective.work/clybzwlfv0qa31x0p8c4j78ft.png',
      },
      {
        code: ProjectTypeEnum.TYPESCRIPT,
        name: 'Typescript',
        imageUrl: 'https://cdn.collective.work/clxvsusln00033mpc22m5h62m.png',
      },
      {
        code: ProjectTypeEnum.REACT,
        name: 'React',
        imageUrl: 'https://cdn.collective.work/clxvsuuc400053mpc9rfz4lo4.png',
      },
      {
        code: ProjectTypeEnum.REDUX,
        name: 'Redux',
        imageUrl: 'https://cdn.collective.work/clxvsxbyj000780pc3uku4sfq.png',
      },
      {
        code: ProjectTypeEnum.ANGULAR,
        name: 'Angular',
        imageUrl: 'https://cdn.collective.work/clxvsuwcx00073mpcfhvs2lvm.png',
      },
      {
        code: ProjectTypeEnum.VUE,
        name: 'Vue',
        imageUrl: 'https://cdn.collective.work/clybzvstm0q9r1x0paot39nsm.webp',
      },
      {
        code: ProjectTypeEnum.SVELTE,
        name: 'Svelte',
        imageUrl: 'https://cdn.collective.work/clybzh1tv0q421x0p5ow15ti6.png',
      },
      {
        code: ProjectTypeEnum.BACKBONE_JS,
        name: 'Backbone.js',
        imageUrl: 'https://cdn.collective.work/clybzc8tt0q2m1x0p31tuem3k.png',
      },
      {
        code: ProjectTypeEnum.NEXT,
        name: 'Next',
        imageUrl: 'https://cdn.collective.work/clybzuxs60q991x0p5b50al4k.png',
      },
      {
        code: ProjectTypeEnum.NUXT,
        name: 'Nuxt',
        imageUrl: 'https://cdn.collective.work/clybztm040q8t1x0pgy2gfny1.png',
      },
      {
        code: ProjectTypeEnum.NODE,
        name: 'Node',
        imageUrl: 'https://cdn.collective.work/clybzsy050q8j1x0p6g2h5o5a.png',
      },
      {
        code: ProjectTypeEnum.EXPRESS,
        name: 'Express',
        imageUrl: 'https://cdn.collective.work/clybzf1a30q3g1x0p1tfgczi0.png',
      },
      {
        code: ProjectTypeEnum.NEST,
        name: 'Nest',
        imageUrl: 'https://cdn.collective.work/clybzs0260q851x0p994og9qe.png',
      },
      {
        code: ProjectTypeEnum.PYTHON,
        name: 'Python',
        imageUrl: 'https://cdn.collective.work/clxvsvgb7000153pc13pbedx2.png',
      },
      {
        code: ProjectTypeEnum.FLASK,
        name: 'Flask',
        imageUrl: 'https://cdn.collective.work/clxvsvha3000353pcg01f70er.png',
      },
      {
        code: ProjectTypeEnum.DJANGO,
        name: 'Django',
        imageUrl: 'https://cdn.collective.work/clxvsvj8g000553pc85dbhao1.png',
      },
      {
        code: ProjectTypeEnum.NUMPY,
        name: 'NumPy',
        imageUrl: 'https://cdn.collective.work/clybzmj7v0q6a1x0p14qj1y99.webp',
      },
      {
        code: ProjectTypeEnum.PANDAS,
        name: 'pandas',
        imageUrl: 'https://cdn.collective.work/clybznuj30q6q1x0p5d909u2e.png',
      },
      {
        code: ProjectTypeEnum.SCIKIT_LEARN,
        name: 'scikit-learn',
        imageUrl: 'https://cdn.collective.work/clybzoh4t0q701x0pezym3qdy.png',
      },
      {
        code: ProjectTypeEnum.KERAS,
        name: 'Keras',
        imageUrl: 'https://cdn.collective.work/clxvsxmd6000l80pcbb50attz.jpg',
      },
      {
        code: ProjectTypeEnum.PYTORCH,
        name: 'PyTorch',
        imageUrl: 'https://cdn.collective.work/clxvsxoby000n80pcgqad7qmk.png',
      },
      {
        code: ProjectTypeEnum.TENSORFLOW,
        name: 'TensorFlow',
        imageUrl: 'https://cdn.collective.work/clxvsxpxp000p80pc48klb8yu.png',
      },
      {
        code: ProjectTypeEnum.PHP,
        name: 'PHP',
        imageUrl: 'https://cdn.collective.work/clxvsvl72000753pc07jq2na6.png',
      },
      {
        code: ProjectTypeEnum.LARAVEL,
        name: 'Laravel',
        imageUrl: 'https://cdn.collective.work/clxvsvmza000953pcez528284.png',
      },
      {
        code: ProjectTypeEnum.SYMFONY,
        name: 'Symfony',
        imageUrl: 'https://cdn.collective.work/clxvsvnuf000b53pcb6rr3uti.png',
      },
      {
        code: ProjectTypeEnum.RUBY,
        name: 'Ruby',
        imageUrl: 'https://cdn.collective.work/clxvsvq6v000d53pcbrclh7kp.png',
      },
      {
        code: ProjectTypeEnum.RUBY_ON_RAILS,
        name: 'Ruby on Rails',
        imageUrl: 'https://cdn.collective.work/clybzr8ud0q7v1x0pg0ri1gh7.png',
      },
      {
        code: ProjectTypeEnum.DART,
        name: 'Dart',
        imageUrl: 'https://cdn.collective.work/clz9o3sln0fem1w2eeysaek64.png',
      },
      {
        code: ProjectTypeEnum.FLUTTER,
        name: 'Flutter',
        imageUrl: 'https://cdn.collective.work/clxvsw506000x53pc7bgmhxog.png',
      },
      {
        code: ProjectTypeEnum.GOLANG,
        name: 'Golang',
        imageUrl: 'https://cdn.collective.work/clxvsvr7e000f53pcdx8rgn9f.png',
      },
      {
        code: ProjectTypeEnum.C_SHARP,
        name: 'C#',
        imageUrl: 'https://cdn.collective.work/clxvsvu85000j53pchuv35emy.png',
      },
      {
        code: ProjectTypeEnum.DOT_NET,
        name: '.NET',
        imageUrl: 'https://cdn.collective.work/clxvsvvv1000l53pc5jijeek0.png',
      },
      {
        code: ProjectTypeEnum.SCALA,
        name: 'Scala',
        imageUrl: 'https://cdn.collective.work/clybzbgqs0q2a1x0p1g652eof.jpeg',
      },
      {
        code: ProjectTypeEnum.JAVA,
        name: 'Java',
        imageUrl: 'https://cdn.collective.work/clxvsvt57000h53pcaaiv33wx.png',
      },
      {
        code: ProjectTypeEnum.SPRING_BOOT,
        name: 'Spring Boot',
        imageUrl: 'https://cdn.collective.work/clxvsxdwz000980pc681g2t7l.png',
      },
      {
        code: ProjectTypeEnum.ANDROID,
        name: 'Android',
        imageUrl: 'https://cdn.collective.work/clxvsw10g000r53pc2fcr0ug2.png',
      },
      {
        code: ProjectTypeEnum.KOTLIN,
        name: 'Kotlin',
        imageUrl: 'https://cdn.collective.work/clxvsw262000t53pcbw5a8mj0.png',
      },
      {
        code: ProjectTypeEnum.IOS,
        name: 'IOS',
        imageUrl: 'https://cdn.collective.work/clxvsvxko000n53pcbhqogk06.png',
      },
      {
        code: ProjectTypeEnum.OBJECTIVE_C,
        name: 'Objective-C',
        imageUrl: 'https://cdn.collective.work/clz8zbgf9083f1w2ehjz14do6.png',
      },
      {
        code: ProjectTypeEnum.SWIFT,
        name: 'Swift',
        imageUrl: 'https://cdn.collective.work/clxvsvzz0000p53pc5xk19f87.png',
      },
      {
        code: ProjectTypeEnum.REACT_NATIVE,
        name: 'React Native',
        imageUrl: 'https://cdn.collective.work/clxvsw414000v53pc0ece9r9q.png',
      },
      {
        code: ProjectTypeEnum.C,
        name: 'C',
        imageUrl: 'https://cdn.collective.work/clxvsw5uu000z53pcco410rpq.png',
      },
      {
        code: ProjectTypeEnum.C_PLUS_PLUS,
        name: 'C++',
        imageUrl: 'https://cdn.collective.work/clxvsw6xm001153pchtfq29xf.png',
      },
      {
        code: ProjectTypeEnum.RUST,
        name: 'Rust',
        imageUrl: 'https://cdn.collective.work/clxvsw7o0001353pc5dx1ajeb.jpg',
      },
      {
        code: ProjectTypeEnum.F_SHARP,
        name: 'F#',
        imageUrl: 'https://cdn.collective.work/clxvt9jf600ic1x11hig9hbuv.png',
      },
      {
        code: ProjectTypeEnum.ELIXIR,
        name: 'Elixir',
        imageUrl: 'https://cdn.collective.work/clxvsx6wt000180pccvy9atiz.png',
      },
      {
        code: ProjectTypeEnum.PHOENIX,
        name: 'Phoenix',
        imageUrl: 'https://cdn.collective.work/clybzhyou0q4e1x0p68rxddii.png',
      },
      {
        code: ProjectTypeEnum.MATLAB,
        name: 'MATLAB',
        imageUrl: 'https://cdn.collective.work/clybzlvef0q601x0pa10e4idg.png',
      },
      {
        code: ProjectTypeEnum.R,
        name: 'R',
        imageUrl: 'https://cdn.collective.work/clxvsxlk7000j80pcetj5f3gk.png',
      },
      {
        code: ProjectTypeEnum.SOLIDITY,
        name: 'Solidity',
        imageUrl: 'https://cdn.collective.work/clybz248s0pz21x0p4xry82me.png',
      },
    ],
  },
  {
    code: ProjectTypeCategoryEnum.TECH_CLOUD_DATABASES,
    name: 'Tech - Cloud & Databases',
    projectTypes: [
      {
        code: ProjectTypeEnum.AWS,
        name: 'AWS',
        imageUrl: 'https://cdn.collective.work/clxvspsit0001umpc7klmgmhq.webp',
      },
      {
        code: ProjectTypeEnum.AZURE,
        name: 'Azure',
        imageUrl: 'https://cdn.collective.work/clxvspswr0003umpcd1wmf8dy.webp',
      },
      {
        code: ProjectTypeEnum.GOOGLE_CLOUD_PLATFORM,
        name: 'Google Cloud Platform',
        imageUrl: 'https://cdn.collective.work/clxvspt8p0005umpc379333pt.webp',
      },
      {
        code: ProjectTypeEnum.DIGITALOCEAN,
        name: 'DigitalOcean',
        imageUrl: 'https://cdn.collective.work/clxvsqjbe0001wipc2p2qh7m6.webp',
      },
      {
        code: ProjectTypeEnum.OVHCLOUD,
        name: 'OVHcloud',
        imageUrl: 'https://cdn.collective.work/clyc0drnj0qgs1x0p28zc7aor.jpeg',
      },
      {
        code: ProjectTypeEnum.IBM_CLOUD,
        name: 'IBM Cloud',
        imageUrl: 'https://cdn.collective.work/clyc0j2be0qis1x0palcyap0f.webp',
      },
      {
        code: ProjectTypeEnum.ORACLE_CLOUD,
        name: 'Oracle Cloud',
        imageUrl: 'https://cdn.collective.work/clyc0i9oj0qii1x0pg8r225ul.png',
      },
      {
        code: ProjectTypeEnum.SALESFORCE_CLOUD_SERVICES,
        name: 'Salesforce Cloud Services',
        imageUrl: 'https://cdn.collective.work/clyc0hlxp0qi81x0p6tssd5t5.webp',
      },
      {
        code: ProjectTypeEnum.SAP_CLOUD,
        name: 'SAP Cloud',
        imageUrl: 'https://cdn.collective.work/clyc0g6vs0qhq1x0p6w0cdz3v.jpeg',
      },
      {
        code: ProjectTypeEnum.RACKSPACE,
        name: 'Rackspace',
        imageUrl: 'https://cdn.collective.work/clyc0f4my0qhc1x0pdvws9e2q.png',
      },
      {
        code: ProjectTypeEnum.LINODE,
        name: 'Linode',
        imageUrl: 'https://cdn.collective.work/clyc0efca0qh21x0p76pi5nwp.png',
      },
      {
        code: ProjectTypeEnum.POSTGRESQL,
        name: 'PostgreSQL',
        imageUrl: 'https://cdn.collective.work/clxvsqys20001xupc0ye39fhk.png',
      },
      {
        code: ProjectTypeEnum.MYSQL,
        name: 'MySQL',
        imageUrl: 'https://cdn.collective.work/clxvsr0ev0003xupc0qbr5vu5.png',
      },
      {
        code: ProjectTypeEnum.MARIADB,
        name: 'MariaDB',
        imageUrl: 'https://cdn.collective.work/clyc08i6g0qem1x0p1bz7e0ve.png',
      },
      {
        code: ProjectTypeEnum.SQLITE,
        name: 'SQLite',
        imageUrl: 'https://cdn.collective.work/clyc07yd40qee1x0p4i6kasap.png',
      },
      {
        code: ProjectTypeEnum.MICROSOFT_SQL_SERVER,
        name: 'Microsoft SQL Server',
        imageUrl: 'https://cdn.collective.work/clxvstsla00030wpc2esz1es8.png',
      },
      {
        code: ProjectTypeEnum.ORACLE_DATABASE,
        name: 'Oracle Database',
        imageUrl: 'https://cdn.collective.work/clyc0i9oj0qii1x0pg8r225ul.png',
      },
      {
        code: ProjectTypeEnum.COCKROACHDB,
        name: 'Cockroachdb',
        imageUrl: 'https://cdn.collective.work/clyc0a6u70qfa1x0p5uyc6vq6.jpeg',
      },
      {
        code: ProjectTypeEnum.MONGODB,
        name: 'MongoDB',
      },
      {
        code: ProjectTypeEnum.DYNAMODB,
        name: 'DynamoDB',
        imageUrl: 'https://cdn.collective.work/clyc090340qeu1x0peq790zuw.png',
      },
      {
        code: ProjectTypeEnum.ELASTICSEARCH,
        name: 'Elasticsearch',
        imageUrl: 'https://cdn.collective.work/clxvstq9f00010wpc9mfk4t2y.png',
      },
      {
        code: ProjectTypeEnum.AMAZON_EC2,
        name: 'Amazon EC2',
        imageUrl: 'https://cdn.collective.work/clxvstukn00050wpcbeku784y.png',
      },
      {
        code: ProjectTypeEnum.AWS_LAMBDA,
        name: 'AWS Lambda',
        imageUrl: 'https://cdn.collective.work/clxvstwez00070wpcekaygwh4.png',
      },
      {
        code: ProjectTypeEnum.CASSANDRA,
        name: 'Cassandra',
        imageUrl: 'https://cdn.collective.work/clyc05h4i0qd91x0p3da26yby.png',
      },
      {
        code: ProjectTypeEnum.SCYLLADB,
        name: 'ScyllaDB',
        imageUrl: 'https://cdn.collective.work/clz8z770h08271w2e1y6pg2si.png',
      },
      {
        code: ProjectTypeEnum.REDIS,
        name: 'Redis',
        imageUrl: 'https://cdn.collective.work/clxvsud7q00012apcd31t4j2m.webp',
      },
      {
        code: ProjectTypeEnum.COUCHDB,
        name: 'CouchDB',
        imageUrl: 'https://cdn.collective.work/clyc048d20qcl1x0pa1z17tll.png',
      },
      {
        code: ProjectTypeEnum.NEO4J,
        name: 'Neo4j',
        imageUrl: 'https://cdn.collective.work/clyc03ajr0qc91x0pei7m3hut.jpeg',
      },
      {
        code: ProjectTypeEnum.IBM_DB2,
        name: 'IBM Db2',
        imageUrl: 'https://cdn.collective.work/clyc04wxn0qcz1x0pd5wpewxa.png',
      },
      {
        code: ProjectTypeEnum.MICROSOFT_AZURE_COSMOS_DB,
        name: 'Microsoft Azure Cosmos DB',
        imageUrl: 'https://cdn.collective.work/clybzzt8j0qb91x0pg5hz2n29.png',
      },
      {
        code: ProjectTypeEnum.SAP_HANA,
        name: 'SAP HANA',
        imageUrl: 'https://cdn.collective.work/clybzzj0x0qb11x0pbkgxerct.png',
      },
      {
        code: ProjectTypeEnum.KAFKA,
        name: 'Kafka',
        imageUrl: 'https://cdn.collective.work/clxvt6jes00gj1x11b27qcex7.jpeg',
      },
      {
        code: ProjectTypeEnum.RABBITMQ,
        name: 'RabbitMQ',
        imageUrl: 'https://cdn.collective.work/clxvt6bdh00gd1x11htzj4en1.jpeg',
      },
      {
        code: ProjectTypeEnum.SNOWFLAKE,
        name: 'Snowflake',
        imageUrl: 'https://cdn.collective.work/clybzza9t0qav1x0p1txqeett.png',
      },
      {
        code: ProjectTypeEnum.GOOGLE_BIGQUERY,
        name: 'Google BigQuery',
        imageUrl: 'https://cdn.collective.work/clxvsurxw00013mpcaj7ugwnr.png',
      },
      {
        code: ProjectTypeEnum.AMAZON_REDSHIFT,
        name: 'Amazon Redshift',
        imageUrl: 'https://cdn.collective.work/clyc04n4t0qct1x0p3vjs1v2c.png',
      },
    ],
  },
  {
    code: ProjectTypeCategoryEnum.TECH_TOOLS,
    name: 'Tech - Tools',
    projectTypes: [
      {
        code: ProjectTypeEnum.GLPI,
        name: 'GLPI',
        imageUrl: 'https://cdn.collective.work/clybziqwg0q4q1x0p17wvf16u.png',
      },
      {
        code: ProjectTypeEnum.METABASE,
        name: 'Metabase',
        imageUrl: 'https://cdn.collective.work/clybzjcdu0q501x0p9fak7den.jpeg',
      },
      {
        code: ProjectTypeEnum.POWER_BI,
        name: 'Power BI',
        imageUrl: 'https://cdn.collective.work/clybzkjtb0q5e1x0pezvjfhp7.png',
      },
      {
        code: ProjectTypeEnum.TABLEAU,
        name: 'Tableau',
        imageUrl: 'https://cdn.collective.work/clxvsxgx3000d80pc09utfp35.png',
      },
      {
        code: ProjectTypeEnum.LOOKER,
        name: 'Looker',
        imageUrl: 'https://cdn.collective.work/clxvt0juo00ej1x11d3sgddhe.png',
      },
      {
        code: ProjectTypeEnum.DBT,
        name: 'dbt',
        imageUrl: 'https://cdn.collective.work/clybzl9kv0q5q1x0p4dro5g5g.png',
      },
      {
        code: ProjectTypeEnum.DATABRICKS,
        name: 'Databricks',
        imageUrl: 'https://cdn.collective.work/clxvsxr6q000t80pc81j60wri.png',
      },
      {
        code: ProjectTypeEnum.ALTERYX,
        name: 'Alteryx',
        imageUrl: 'https://cdn.collective.work/clybz2n560pzg1x0paomw6dry.jpeg',
      },
      {
        code: ProjectTypeEnum.QUALTRICS,
        name: 'Qualtrics',
        imageUrl: 'https://cdn.collective.work/clybz2enm0pza1x0pcsy2bv2w.webp',
      },
      {
        code: ProjectTypeEnum.FIREBASE,
        name: 'Firebase',
        imageUrl: 'https://cdn.collective.work/clxvsxt89000v80pc385nbhnf.png',
      },
      {
        code: ProjectTypeEnum.KUBERNETES,
        name: 'Kubernetes',
        imageUrl: 'https://cdn.collective.work/clxvsxvbe000x80pcaqaa9l6c.png',
      },
      {
        code: ProjectTypeEnum.TERRAFORM,
        name: 'Terraform',
        imageUrl: 'https://cdn.collective.work/clxvsxwwv000z80pcdj25e8gs.png',
      },
      {
        code: ProjectTypeEnum.DOCKER,
        name: 'Docker',
        imageUrl: 'https://cdn.collective.work/clxvsxxwq001180pcffnwaoyg.png',
      },
      {
        code: ProjectTypeEnum.VERCEL,
        name: 'Vercel',
        imageUrl: 'https://cdn.collective.work/clybz1wwa0pyw1x0pbt5g6ei3.jpeg',
      },
      {
        code: ProjectTypeEnum.LINUX,
        name: 'Linux',
        imageUrl: 'https://cdn.collective.work/clxvsy3hw001580pc2aojcmh4.png',
      },
      {
        code: ProjectTypeEnum.GIT,
        name: 'Git',
        imageUrl: 'https://cdn.collective.work/clxvsy592001780pchfr606tw.png',
      },
      {
        code: ProjectTypeEnum.GRAPHQL,
        name: 'GraphQL',
        imageUrl: 'https://cdn.collective.work/clxvsy8ny001b80pcdxovc57g.png',
      },
      {
        code: ProjectTypeEnum.SUPABASE,
        name: 'Supabase',
        imageUrl: 'https://cdn.collective.work/clxvsy9em001d80pcbfsb25ra.jpg',
      },
      {
        code: ProjectTypeEnum.GATSBY,
        name: 'Gatsby',
        imageUrl: 'https://cdn.collective.work/clxvsybda001f80pcavb9935g.png',
      },
      {
        code: ProjectTypeEnum.HEROKU,
        name: 'Heroku',
        imageUrl: 'https://cdn.collective.work/clxvsyc7c001h80pc32hh225a.png',
      },
      {
        code: ProjectTypeEnum.GITHUB,
        name: 'GitHub',
        imageUrl: 'https://cdn.collective.work/clxvsye8r001j80pc6c4wdtls.png',
      },
      {
        code: ProjectTypeEnum.GITLAB,
        name: 'GitLab',
        imageUrl: 'https://cdn.collective.work/clxvsyf86001l80pceb1d94yt.png',
      },
      {
        code: ProjectTypeEnum.BITBUCKET,
        name: 'Bitbucket',
        imageUrl: 'https://cdn.collective.work/clxvsyg6c001n80pchnsof1qi.png',
      },
      {
        code: ProjectTypeEnum.CIRCLECI,
        name: 'CircleCI',
        imageUrl: 'https://cdn.collective.work/clybz5bkg0q0c1x0pfjyxek0i.jpeg',
      },
      {
        code: ProjectTypeEnum.JENKINS,
        name: 'Jenkins',
        imageUrl: 'https://cdn.collective.work/clxvsyiql001p80pc8h1a7g8q.png',
      },
      {
        code: ProjectTypeEnum.POSTMAN,
        name: 'Postman',
        imageUrl: 'https://cdn.collective.work/clxvsykp9001r80pc748xbol4.png',
      },
      {
        code: ProjectTypeEnum.WINDEV,
        name: 'WinDev',
        imageUrl: 'https://cdn.collective.work/clybz911f0q1g1x0p677rbjiy.jpeg',
      },
      {
        code: ProjectTypeEnum.RESTFUL_APIS,
        name: 'RESTful APIs',
      },
      {
        code: ProjectTypeEnum.SWAGGER_OPENAPI,
        name: 'Swagger/OpenAPI',
        imageUrl: 'https://cdn.collective.work/clybzala40q1y1x0p5qleg97z.png',
      },
      {
        code: ProjectTypeEnum.WEBPACK,
        name: 'Webpack',
        imageUrl: 'https://cdn.collective.work/clxvtl8q300rl1x11eqtm5ecp.jpeg',
      },
      {
        code: ProjectTypeEnum.DATADOG,
        name: 'Datadog',
        imageUrl: 'https://cdn.collective.work/clxvtk4tc00pr1x11hbl300bc.png',
      },
      {
        code: ProjectTypeEnum.GRAFANA,
        name: 'Grafana',
        imageUrl: 'https://cdn.collective.work/clxvsyod5001x80pc1rbjgzwa.png',
      },
      {
        code: ProjectTypeEnum.PROMETHEUS,
        name: 'Prometheus',
        imageUrl: 'https://cdn.collective.work/clxvtjy8r00pl1x110tx2hk8h.webp',
      },
      {
        code: ProjectTypeEnum.SPLUNK,
        name: 'Splunk',
        imageUrl: 'https://cdn.collective.work/clxvtjrnx00ph1x110r0xhdy9.jpeg',
      },
      {
        code: ProjectTypeEnum.BURP_SUITE,
        name: 'Burp Suite',
        imageUrl: 'https://cdn.collective.work/clxvsypep001z80pc1ws398ih.png',
      },
      {
        code: ProjectTypeEnum.ZAP,
        name: 'ZAP',
        imageUrl: 'https://cdn.collective.work/clxvtgg0z00lu1x119jkz3hpf.png',
      },
      {
        code: ProjectTypeEnum.SNORT,
        name: 'Snort',
        imageUrl: 'https://cdn.collective.work/clxvtejaa00l81x119l8adi9s.jpeg',
      },
      {
        code: ProjectTypeEnum.IONIC,
        name: 'Ionic',
        imageUrl: 'https://cdn.collective.work/clxvtd8vf00ko1x114td9abvk.png',
      },
      {
        code: ProjectTypeEnum.XCODE,
        name: 'Xcode',
        imageUrl: 'https://cdn.collective.work/clxvsysc3002380pcdoh2dvs4.png',
      },
      {
        code: ProjectTypeEnum.MAVEN,
        name: 'Maven',
        imageUrl: 'https://cdn.collective.work/clxvtdohl00kw1x114sezgnya.png',
      },
      {
        code: ProjectTypeEnum.GRADLE,
        name: 'Gradle',
        imageUrl: 'https://cdn.collective.work/clxvtb7e800js1x11b1p13g5i.jpeg',
      },
      {
        code: ProjectTypeEnum.ANSIBLE,
        name: 'Ansible',
        imageUrl: 'https://cdn.collective.work/clxvt658q00g71x115p2ogcl2.jpeg',
      },
      {
        code: ProjectTypeEnum.CYPRESS,
        name: 'Cypress',
        imageUrl: 'https://cdn.collective.work/clxvsyy15002980pchdyaeooc.png',
      },
      {
        code: ProjectTypeEnum.SELENIUM,
        name: 'Selenium',
        imageUrl: 'https://cdn.collective.work/clxvsyztg002b80pc0x1z41s6.png',
      },
      {
        code: ProjectTypeEnum.PLAYWRIGHT,
        name: 'Playwright',
        imageUrl: 'https://cdn.collective.work/clxvt3i1600ff1x11eqrhgs4y.jpeg',
      },
    ],
  },
  {
    code: ProjectTypeCategoryEnum.NOCODE_AND_LOWCODE,
    name: 'No-code & Low-code',
    projectTypes: [
      {
        code: ProjectTypeEnum.NOCODE_APPLICATIONS,
        name: 'No-code Applications',
      },
      {
        code: ProjectTypeEnum.WEBSITES,
        name: 'Websites',
      },
      {
        code: ProjectTypeEnum.E_COMMERCE_WEBSITES,
        name: 'E-commerce Websites',
      },
      {
        code: ProjectTypeEnum.NOCODE_AUTOMATIONS,
        name: 'No-code Automations',
      },
      {
        code: ProjectTypeEnum.CMS,
        name: 'CMS',
      },
      {
        code: ProjectTypeEnum.E_COMMERCE_CMS,
        name: 'E-commerce CMS',
      },
    ],
  },
  {
    code: ProjectTypeCategoryEnum.NOCODE_AND_LOWCODE_TOOLS,
    name: 'No-code & Low-code - Tools',
    projectTypes: [
      {
        code: ProjectTypeEnum.BUBBLE,
        name: 'Bubble',
        imageUrl: 'https://cdn.collective.work/clxur2i160001supce44c9ex4.png',
      },
      {
        code: ProjectTypeEnum.XANO,
        name: 'Xano',
        imageUrl: 'https://cdn.collective.work/clxur2izd0003supcgxj4cipl.png',
      },
      {
        code: ProjectTypeEnum.WEWEB,
        name: 'WeWeb',
        imageUrl: 'https://cdn.collective.work/clxur2juv0005supcewd69olr.png',
      },
      {
        code: ProjectTypeEnum.FLUTTERFLOW,
        name: 'FlutterFlow',
        imageUrl: 'https://cdn.collective.work/clxur2kpx0007supcd4ln61ml.png',
      },
      {
        code: ProjectTypeEnum.WEBFLOW,
        name: 'Webflow',
        imageUrl: 'https://cdn.collective.work/clxur2mjb0009supch3wm6ir7.png',
      },
      {
        code: ProjectTypeEnum.WORDPRESS,
        name: 'WordPress',
        imageUrl: 'https://cdn.collective.work/clxur2oio000bsupc0g9ucxec.png',
      },
      {
        code: ProjectTypeEnum.DRUPAL,
        name: 'Drupal',
        imageUrl: 'https://cdn.collective.work/clxur2pgk000dsupc76hu4hvb.png',
      },
      {
        code: ProjectTypeEnum.FRAMER,
        name: 'Framer',
        imageUrl: 'https://cdn.collective.work/clxur2qwb000fsupcd40l0zz0.png',
      },
      {
        code: ProjectTypeEnum.PRESTASHOP,
        name: 'PrestaShop',
      },
      {
        code: ProjectTypeEnum.MAGENTO,
        name: 'Magento',
        imageUrl: 'https://cdn.collective.work/clxur46nz0001w8pc6viudadh.jpg',
      },
      {
        code: ProjectTypeEnum.WOOCOMMERCE,
        name: 'WooCommerce',
        imageUrl: 'https://cdn.collective.work/clxur48dx0003w8pcf5qz7qk3.png',
      },
      {
        code: ProjectTypeEnum.SHOPIFY,
        name: 'Shopify',
        imageUrl: 'https://cdn.collective.work/clxur4ahm0005w8pcejix4jxs.png',
      },
      {
        code: ProjectTypeEnum.ZAPIER,
        name: 'Zapier',
        imageUrl: 'https://cdn.collective.work/clxur4goy0001xipchnvtgm4w.png',
      },
      {
        code: ProjectTypeEnum.MAKE,
        name: 'Make',
        imageUrl: 'https://cdn.collective.work/clxur5r9603sf1xtbfcqkb7kb.png',
      },
      {
        code: ProjectTypeEnum.N8N,
        name: 'n8n',
        imageUrl: 'https://cdn.collective.work/clxur3ffu03rp1xtb3t53dfp5.png',
      },
      {
        code: ProjectTypeEnum.STRAPI,
        name: 'Strapi',
        imageUrl: 'https://cdn.collective.work/clxur6wlz00016cpceuw7cjp1.jpg',
      },
      {
        code: ProjectTypeEnum.AIRTABLE,
        name: 'Airtable',
        imageUrl: 'https://cdn.collective.work/clxur6y5100036cpc7z56bb8s.png',
      },
      {
        code: ProjectTypeEnum.NOTION,
        name: 'Notion',
        imageUrl: 'https://cdn.collective.work/clxur709w00056cpcfo01fsxw.png',
      },
      {
        code: ProjectTypeEnum.GLIDE,
        name: 'Glide',
        imageUrl: 'https://cdn.collective.work/clxur713i00076cpcb6f52xl7.png',
      },
      {
        code: ProjectTypeEnum.SQUARESPACE,
        name: 'Squarespace',
        imageUrl: 'https://cdn.collective.work/clyc0sk9g0qle1x0pgb9u1zc0.png',
      },
      {
        code: ProjectTypeEnum.RETOOL,
        name: 'Retool',
        imageUrl: 'https://cdn.collective.work/clz8hr1lv02at1w2e69fw4zcz.png',
      },
    ],
  },
  {
    code: ProjectTypeCategoryEnum.PRODUCT,
    name: 'Product',
    projectTypes: [
      {
        code: ProjectTypeEnum.GAME_DESIGN,
        name: 'Game Design',
      },
      {
        code: ProjectTypeEnum.SCRUM,
        name: 'Scrum',
      },
      {
        code: ProjectTypeEnum.DESIGN_THINKING,
        name: 'Design Thinking',
      },
      {
        code: ProjectTypeEnum.AGILE_COACHING,
        name: 'Agile Coaching',
      },
      {
        code: ProjectTypeEnum.A_B_TESTING,
        name: 'A/B Testing',
      },
    ],
  },
  {
    code: ProjectTypeCategoryEnum.PRODUCT_TOOLS,
    name: 'Product - Tools',
    projectTypes: [
      {
        code: ProjectTypeEnum.ASANA,
        name: 'Asana',
        imageUrl: 'https://cdn.collective.work/clxuraddd0001a6pc7y0dcibg.png',
      },
      {
        code: ProjectTypeEnum.BASECAMP,
        name: 'Basecamp',
        imageUrl: 'https://cdn.collective.work/clxuraegr0003a6pc6nv0gxjo.png',
      },
      {
        code: ProjectTypeEnum.CONFLUENCE,
        name: 'Confluence',
        imageUrl: 'https://cdn.collective.work/clxurag8i0005a6pca1ae049g.png',
      },
      {
        code: ProjectTypeEnum.JIRA,
        name: 'Jira',
        imageUrl: 'https://cdn.collective.work/clxuraht10007a6pcfawx43cl.png',
      },
      {
        code: ProjectTypeEnum.MONDAY_COM,
        name: 'Monday.com',
        imageUrl: 'https://cdn.collective.work/clxurajfr0009a6pc5qlyef33.png',
      },
      {
        code: ProjectTypeEnum.OPTIMIZELY,
        name: 'Optimizely',
        imageUrl: 'https://cdn.collective.work/clz8xvbjj07p31w2eaise9c1r.png',
      },
      {
        code: ProjectTypeEnum.AMPLITUDE,
        name: 'Amplitude',
        imageUrl: 'https://cdn.collective.work/clz8xviyf07p91w2e1loj1pxf.png',
      },
    ],
  },
  {
    code: ProjectTypeCategoryEnum.MARKETING_AND_SALES,
    name: 'Marketing & Sales',
    projectTypes: [
      {
        code: ProjectTypeEnum.MARKETING_STRATEGY,
        name: 'Marketing Strategy',
      },
      {
        code: ProjectTypeEnum.GROWTH,
        name: 'Growth',
      },
      {
        code: ProjectTypeEnum.SALES_OPS,
        name: 'Sales Ops',
      },
      {
        code: ProjectTypeEnum.MARKETING_OPS,
        name: 'Marketing Ops',
      },
      {
        code: ProjectTypeEnum.CRO,
        name: 'CRO',
      },
      {
        code: ProjectTypeEnum.SEA,
        name: 'SEA',
      },
      {
        code: ProjectTypeEnum.SEO,
        name: 'SEO',
      },
      {
        code: ProjectTypeEnum.SOCIAL_ADS,
        name: 'Social Ads',
      },
      {
        code: ProjectTypeEnum.WEB_ANALYTICS,
        name: 'Web Analytics',
      },
      {
        code: ProjectTypeEnum.SALES_STRATEGY,
        name: 'Sales Strategy',
      },
      {
        code: ProjectTypeEnum.BUSINESS_DEVELOPMENT,
        name: 'Business Development',
      },
      {
        code: ProjectTypeEnum.GO_TO_MARKET,
        name: 'Go-to-market',
      },
      {
        code: ProjectTypeEnum.OUTBOUND,
        name: 'Outbound',
      },
      {
        code: ProjectTypeEnum.LEAD_GENERATION,
        name: 'Lead Generation',
      },
      {
        code: ProjectTypeEnum.LEAD_NURTURING,
        name: 'Lead Nurturing',
      },
      {
        code: ProjectTypeEnum.COLD_CALLING,
        name: 'Cold Calling',
      },
      {
        code: ProjectTypeEnum.EMAILING,
        name: 'Emailing',
      },
      {
        code: ProjectTypeEnum.COMMUNICATION_CAMPAIGN,
        name: 'Communication Campaign',
      },
      {
        code: ProjectTypeEnum.EVENT_MANAGEMENT,
        name: 'Event Management',
      },
      {
        code: ProjectTypeEnum.CONTENT_STRATEGY,
        name: 'Content Strategy',
      },
      {
        code: ProjectTypeEnum.ARTICLES_CREATION,
        name: 'Articles Creation',
      },
      {
        code: ProjectTypeEnum.COPYWRITING,
        name: 'Copywriting',
      },
      {
        code: ProjectTypeEnum.COMMUNITY_MANAGEMENT,
        name: 'Community Management',
      },
      {
        code: ProjectTypeEnum.PRESS_RELATIONS,
        name: 'Press Relations',
      },
      {
        code: ProjectTypeEnum.BRANDING_STRATEGY,
        name: 'Branding Strategy',
      },
      {
        code: ProjectTypeEnum.CRM,
        name: 'CRM',
      },
    ],
  },
  {
    code: ProjectTypeCategoryEnum.MARKETING_AND_SALES_TOOLS,
    name: 'Marketing & Sales - Tools',
    projectTypes: [
      {
        code: ProjectTypeEnum.GOOGLE_ADS,
        name: 'Google Ads',
        imageUrl: 'https://cdn.collective.work/clz89fgpj0cw71xy19h8kbubu.jpeg',
      },
      {
        code: ProjectTypeEnum.LINKEDIN_ADS,
        name: 'LinkedIn Ads',
        imageUrl: 'https://cdn.collective.work/clxuoayue02zq1xtb2yae6070.jpeg',
      },
      {
        code: ProjectTypeEnum.META_ADS,
        name: 'Meta Ads',
        imageUrl: 'https://cdn.collective.work/clxuodfgj030i1xtb8qsk6n4j.jpeg',
      },
      {
        code: ProjectTypeEnum.TIKTOK_ADS,
        name: 'TikTok Ads',
        imageUrl: 'https://cdn.collective.work/clxuo9x9902ze1xtb0kn3036w.webp',
      },
      {
        code: ProjectTypeEnum.YOUTUBE_ADS,
        name: 'YouTube Ads',
        imageUrl: 'https://cdn.collective.work/clxuodln0030o1xtbfxdjf2bd.jpeg',
      },
      {
        code: ProjectTypeEnum.SALESFORCE,
        name: 'Salesforce',
        imageUrl: 'https://cdn.collective.work/clxuodrjk030s1xtbawnkbdzp.jpeg',
      },
      {
        code: ProjectTypeEnum.SALESFORCE_MARKETING_CLOUD,
        name: 'Salesforce Marketing Cloud',
        imageUrl: 'https://cdn.collective.work/clxuodrjk030s1xtbawnkbdzp.jpeg',
      },
      {
        code: ProjectTypeEnum.HUBSPOT,
        name: 'Hubspot',
        imageUrl: 'https://cdn.collective.work/clxuodzbi03111xtbby4c59bc.jpeg',
      },
      {
        code: ProjectTypeEnum.PIPEDRIVE,
        name: 'Pipedrive',
        imageUrl: 'https://cdn.collective.work/clxuoj5cj032w1xtbgbk6ax9c.jpeg',
      },
      {
        code: ProjectTypeEnum.CUSTOMER_IO,
        name: 'Customer.io',
        imageUrl: 'https://cdn.collective.work/clxuoe6db031c1xtb6s7m7gen.jpeg',
      },
      {
        code: ProjectTypeEnum.KLAVIYO,
        name: 'Klaviyo',
        imageUrl: 'https://cdn.collective.work/clxuogokn03241xtbhubp5oen.jpeg',
      },
      {
        code: ProjectTypeEnum.GOOGLE_ANALYTICS,
        name: 'Google Analytics',
        imageUrl: 'https://cdn.collective.work/clxuojbt803301xtbf00b086p.png',
      },
      {
        code: ProjectTypeEnum.ACTIVE_CAMPAIGN,
        name: 'Active Campaign',
        imageUrl: 'https://cdn.collective.work/clxuojinf03361xtb6i6s7hwb.jpeg',
      },
      {
        code: ProjectTypeEnum.AHREFS,
        name: 'Ahrefs',
        imageUrl: 'https://cdn.collective.work/clxuokk3h033k1xtb7zana02o.webp',
      },
      {
        code: ProjectTypeEnum.MARKETO,
        name: 'Marketo',
        imageUrl: 'https://cdn.collective.work/clz89m0fs0cyd1xy1g3rs19r6.webp',
      },
      {
        code: ProjectTypeEnum.SEMRUSH,
        name: 'SEMrush',
        imageUrl: 'https://cdn.collective.work/clxur1m5j0001prpc5y9wc6fv.png',
      },
      {
        code: ProjectTypeEnum.ZOHO_CRM,
        name: 'Zoho CRM',
        imageUrl: 'https://cdn.collective.work/clz89kvw60cxz1xy1538d4oev.png',
      },
      {
        code: ProjectTypeEnum.MAILCHIMP,
        name: 'MailChimp',
        imageUrl: 'https://cdn.collective.work/clxur1o4v0003prpc7g2zeeug.png',
      },
      {
        code: ProjectTypeEnum.GOOGLE_TAG_MANAGER,
        name: 'Google Tag Manager',
        imageUrl: 'https://cdn.collective.work/clxur1pqy0005prpchoy3f44g.png',
      },
      {
        code: ProjectTypeEnum.HOTJAR,
        name: 'Hotjar',
        imageUrl: 'https://cdn.collective.work/clxur1res0007prpccyhn5scw.png',
      },
      {
        code: ProjectTypeEnum.CONVERTKIT,
        name: 'ConvertKit',
        imageUrl: 'https://cdn.collective.work/clxur1sb90009prpc6zae9qhe.png',
      },
      {
        code: ProjectTypeEnum.CONSTANTCONTACT,
        name: 'ConstantContact',
        imageUrl: 'https://cdn.collective.work/clxur1trv000bprpcdxuoh1rh.png',
      },
      {
        code: ProjectTypeEnum.BUFFER,
        name: 'Buffer',
        imageUrl: 'https://cdn.collective.work/clxur1vfr000dprpc7ykdd1n0.png',
      },
    ],
  },
  {
    code: ProjectTypeCategoryEnum.DESIGN_AND_CONTENT,
    name: 'Design & Content',
    projectTypes: [
      {
        code: ProjectTypeEnum.VISUAL_IDENTITY,
        name: 'Visual Identity',
      },
      {
        code: ProjectTypeEnum.ARTISTIC_DIRECTION,
        name: 'Artistic Direction',
      },
      {
        code: ProjectTypeEnum.GRAPHIC_DESIGN,
        name: 'Graphic Design',
      },
      {
        code: ProjectTypeEnum.ILLUSTRATION,
        name: 'Illustration',
      },
      {
        code: ProjectTypeEnum.PRINT_DESIGN,
        name: 'Print Design',
      },
      {
        code: ProjectTypeEnum.MOTION_DESIGN,
        name: 'Motion Design',
      },
      {
        code: ProjectTypeEnum.DESIGN_3D,
        name: '3D Design',
      },
      {
        code: ProjectTypeEnum.ANIMATION,
        name: 'Animation',
      },
      {
        code: ProjectTypeEnum.VIDEO_FILM,
        name: 'Video & Film',
      },
      {
        code: ProjectTypeEnum.PHOTOGRAPHY,
        name: 'Photography',
      },
      {
        code: ProjectTypeEnum.SOUND_DESIGN,
        name: 'Sound Design',
      },
      {
        code: ProjectTypeEnum.UX_PRODUCT_DESIGN,
        name: 'UX Product Design',
      },
      {
        code: ProjectTypeEnum.UI_PRODUCT_DESIGN,
        name: 'UI Product Design',
      },
      {
        code: ProjectTypeEnum.DESIGN_SYSTEM,
        name: 'Design System',
      },
      {
        code: ProjectTypeEnum.PROOFREADING,
        name: 'Proofreading',
      },
      {
        code: ProjectTypeEnum.TRANSLATION,
        name: 'Translation',
      },
      {
        code: ProjectTypeEnum.WEB_DESIGN,
        name: 'Web Design',
      },
    ],
  },
  {
    code: ProjectTypeCategoryEnum.DESIGN_AND_CONTENT_TOOLS,
    name: 'Design & Content - Tools',
    projectTypes: [
      {
        code: ProjectTypeEnum.DESIGN_3D_STUDIOMAX,
        name: '3D Studiomax',
        imageUrl: 'https://cdn.collective.work/clxuo2fdn02x81xtb5wmt716a.webp',
      },
      {
        code: ProjectTypeEnum.ADOBE_AFTER_EFFECTS,
        name: 'Adobe After Effects',
        imageUrl: 'https://cdn.collective.work/clxuq1tm40001xtpc8ha0eoce.webp',
      },
      {
        code: ProjectTypeEnum.ADOBE_ANIMATE,
        name: 'Adobe Animate',
        imageUrl: 'https://cdn.collective.work/clxuq1vg00003xtpc0n00843h.png',
      },
      {
        code: ProjectTypeEnum.ADOBE_AUDITION,
        name: 'Adobe Audition',
        imageUrl: 'https://cdn.collective.work/clxuq1vmd0005xtpc35gjghac.png',
      },
      {
        code: ProjectTypeEnum.ADOBE_CREATIVE_SUITE,
        name: 'Adobe Creative Suite',
        imageUrl: 'https://cdn.collective.work/clxuq1xge0007xtpcg6bq51no.png',
      },
      {
        code: ProjectTypeEnum.ADOBE_ILLUSTRATOR,
        name: 'Adobe Illustrator',
        imageUrl: 'https://cdn.collective.work/clxuq1zr70009xtpcf5ic8s0z.png',
      },
      {
        code: ProjectTypeEnum.ADOBE_INDESIGN,
        name: 'Adobe InDesign',
        imageUrl: 'https://cdn.collective.work/clxuq21ea000bxtpc7pv8hr62.png',
      },
      {
        code: ProjectTypeEnum.ADOBE_LIGHTROOM,
        name: 'Adobe Lightroom',
        imageUrl: 'https://cdn.collective.work/clxuq2329000dxtpc78i141yb.png',
      },
      {
        code: ProjectTypeEnum.ADOBE_PHOTOSHOP,
        name: 'Adobe Photoshop',
        imageUrl: 'https://cdn.collective.work/clxuq24s3000fxtpcbfnq1zdl.png',
      },
      {
        code: ProjectTypeEnum.ADOBE_PREMIERE_PRO,
        name: 'Adobe Premiere Pro',
        imageUrl: 'https://cdn.collective.work/clxuq26ay000hxtpcfnsc5spb.png',
      },
      {
        code: ProjectTypeEnum.AFFINITY_DESIGNER,
        name: 'Affinity Designer',
        imageUrl: 'https://cdn.collective.work/clxuq27nr000jxtpcb9598rv7.png',
      },
      {
        code: ProjectTypeEnum.AFFINITY_PHOTO,
        name: 'Affinity Photo',
        imageUrl: 'https://cdn.collective.work/clxuq29gp000lxtpc70wj62tc.png',
      },
      {
        code: ProjectTypeEnum.AFFINITY_PUBLISHER,
        name: 'Affinity Publisher',
        imageUrl: 'https://cdn.collective.work/clxuq2b4y000nxtpc9s2y066a.png',
      },
      {
        code: ProjectTypeEnum.ASEPRITE,
        name: 'Aseprite',
        imageUrl: 'https://cdn.collective.work/clxuq2c8m000pxtpc20y7h443.png',
      },
      {
        code: ProjectTypeEnum.AUTOCAD,
        name: 'AutoCAD',
        imageUrl: 'https://cdn.collective.work/clxuq2e20000rxtpcacvdb98g.png',
      },
      {
        code: ProjectTypeEnum.AUTODESK_3DS_MAX,
        name: 'Autodesk 3ds Max',
        imageUrl: 'https://cdn.collective.work/clxuq2fpr000txtpcgnyw5594.png',
      },
      {
        code: ProjectTypeEnum.AUTODESK_INVENTOR,
        name: 'Autodesk Inventor',
        imageUrl: 'https://cdn.collective.work/clxuq2git000vxtpc5mr6bcee.png',
      },
      {
        code: ProjectTypeEnum.AUTODESK_MAYA,
        name: 'Autodesk Maya',
        imageUrl: 'https://cdn.collective.work/clxuq2iav000xxtpcb1qohj0s.png',
      },
      {
        code: ProjectTypeEnum.BLENDER,
        name: 'Blender',
        imageUrl: 'https://cdn.collective.work/clxuq2jr6000zxtpcfmwn47xw.png',
      },
      {
        code: ProjectTypeEnum.CINEMA_4D,
        name: 'Cinema 4D',
        imageUrl: 'https://cdn.collective.work/clxuq2l1z0011xtpchj5a34nq.png',
      },
      {
        code: ProjectTypeEnum.CORELDRAW,
        name: 'CorelDraw',
        imageUrl: 'https://cdn.collective.work/clxuq2nb80013xtpc59johj0i.png',
      },
      {
        code: ProjectTypeEnum.DAVINCI_RESOLVE,
        name: 'DaVinci Resolve',
        imageUrl: 'https://cdn.collective.work/clxuq2q0k0015xtpc4s3s7yoi.png',
      },
      {
        code: ProjectTypeEnum.FINAL_CUT_PRO,
        name: 'Final Cut Pro',
        imageUrl: 'https://cdn.collective.work/clxuq2rs00017xtpcbwp08i6z.png',
      },
      {
        code: ProjectTypeEnum.FUSION_360,
        name: 'Fusion 360',
        imageUrl: 'https://cdn.collective.work/clxuq2syc0019xtpcekmq11nv.png',
      },
      {
        code: ProjectTypeEnum.GIMP,
        name: 'GIMP',
        imageUrl: 'https://cdn.collective.work/clxuq2v6m001bxtpc8y417uen.png',
      },
      {
        code: ProjectTypeEnum.INKSCAPE,
        name: 'Inkscape',
        imageUrl: 'https://cdn.collective.work/clxuq2yzr001dxtpcfmat8e1e.png',
      },
      {
        code: ProjectTypeEnum.PROCREATE,
        name: 'Procreate',
        imageUrl: 'https://cdn.collective.work/clxuq31aa001fxtpcf18zgt3c.png',
      },
      {
        code: ProjectTypeEnum.RHINO,
        name: 'Rhino',
        imageUrl: 'https://cdn.collective.work/clxuq32h0001hxtpc9dh5fqtw.png',
      },
      {
        code: ProjectTypeEnum.SKETCH,
        name: 'Sketch',
        imageUrl: 'https://cdn.collective.work/clxuq34g9001jxtpcbjrp0lwv.png',
      },
      {
        code: ProjectTypeEnum.SKETCHUP,
        name: 'SketchUp',
        imageUrl: 'https://cdn.collective.work/clxuq36dv001lxtpc18ftd8t2.png',
      },
      {
        code: ProjectTypeEnum.V_RAY,
        name: 'V-Ray',
        imageUrl: 'https://cdn.collective.work/clxuq6gyc03it1xtbajlj88e3.png',
      },
      {
        code: ProjectTypeEnum.ZBRUSH,
        name: 'Zbrush',
        imageUrl: 'https://cdn.collective.work/clxuq9oeu03ju1xtbdkxhb2q6.webp',
      },
      {
        code: ProjectTypeEnum.CANVA,
        name: 'Canva',
        imageUrl: 'https://cdn.collective.work/clxuo1knv02ww1xtb2o0e0g4b.webp',
      },
      {
        code: ProjectTypeEnum.FIGMA,
        name: 'Figma',
        imageUrl: 'https://cdn.collective.work/clxuo0yhi02wm1xtb72zjfhik.png',
      },
    ],
  },
  {
    code: ProjectTypeCategoryEnum.BUSINESS,
    name: 'Business',
    projectTypes: [
      {
        code: ProjectTypeEnum.STRATEGY_CONSULTING,
        name: 'Strategy Consulting',
      },
      {
        code: ProjectTypeEnum.PROJECT_MANAGEMENT_PMO,
        name: 'Project Management (PMO)',
      },
      {
        code: ProjectTypeEnum.PROJECT_OWNER,
        name: 'Project Owner',
      },
      {
        code: ProjectTypeEnum.HR,
        name: 'HR Strategy',
      },
      {
        code: ProjectTypeEnum.TALENT_ACQUISITION,
        name: 'Talent Acquisition',
      },
      {
        code: ProjectTypeEnum.LEGAL,
        name: 'Legal',
      },
      {
        code: ProjectTypeEnum.GDPR,
        name: 'GDPR & Compliance',
      },
      {
        code: ProjectTypeEnum.FINANCE,
        name: 'Finance',
      },
      {
        code: ProjectTypeEnum.OPERATION_PROCUREMENT,
        name: 'Procurement',
      },
      {
        code: ProjectTypeEnum.CONSULTING_IT,
        name: 'Consulting IT ',
      },
      {
        code: ProjectTypeEnum.CONSULTING_CYBERSECURITY,
        name: 'Consulting Cyber',
      },
      {
        code: ProjectTypeEnum.CSR,
        name: 'CSR',
      },
      {
        code: ProjectTypeEnum.E_LEARNING,
        name: 'E-learning',
      },
      {
        code: ProjectTypeEnum.KNOWLEDGE_MANAGEMENT,
        name: 'Knowledge Management',
      },
      {
        code: ProjectTypeEnum.COACHING,
        name: 'Coaching',
      },
      {
        code: ProjectTypeEnum.CUSTOMER_CARE,
        name: 'Customer Care',
      },
      {
        code: ProjectTypeEnum.CUSTOMER_SUCCESS,
        name: 'Customer Success',
      },
      {
        code: ProjectTypeEnum.ACCOUNTING,
        name: 'Accounting',
      },
    ],
  },
  {
    code: ProjectTypeCategoryEnum.BUSINESS_TOOLS,
    name: 'Business - Tools',
    projectTypes: [
      {
        code: ProjectTypeEnum.ERP,
        name: 'ERP',
      },
      {
        code: ProjectTypeEnum.ACUMATICA_CLOUD_ERP,
        name: 'Acumatica Cloud ERP',
        imageUrl: 'https://cdn.collective.work/clxunrz7d02tm1xtbac6wg2tn.jpeg',
      },
      {
        code: ProjectTypeEnum.DELTEK_VISION,
        name: 'Deltek Vision',
        imageUrl: 'https://cdn.collective.work/clxunt56o02u01xtbgdc78i0i.png',
      },
      {
        code: ProjectTypeEnum.EPICOR_ERP,
        name: 'Epicor ERP',
      },
      {
        code: ProjectTypeEnum.FINANCIALFORCE_ERP,
        name: 'FinancialForce ERP',
      },
      {
        code: ProjectTypeEnum.IFS_APPLICATIONS,
        name: 'IFS Applications',
      },
      {
        code: ProjectTypeEnum.MICROSOFT_DYNAMICS_365,
        name: 'Microsoft Dynamics 365',
        imageUrl: 'https://cdn.collective.work/clxunxseu02vk1xtb3tso0vku.png',
      },
      {
        code: ProjectTypeEnum.ODOO,
        name: 'Odoo',
        imageUrl: 'https://cdn.collective.work/clxuntyku02uc1xtbe53jadgh.jpeg',
      },
      {
        code: ProjectTypeEnum.ORACLE,
        name: 'Oracle',
        imageUrl: 'https://cdn.collective.work/clxunvhch02uu1xtbb4n54f8g.jpeg',
      },
      {
        code: ProjectTypeEnum.RAMCO_ERP_SUITE,
        name: 'Ramco ERP Suite',
      },
      {
        code: ProjectTypeEnum.ROOTSTOCK_ERP,
        name: 'Rootstock ERP',
      },
      {
        code: ProjectTypeEnum.SAGE_INTACCT,
        name: 'Sage Intacct',
      },
      {
        code: ProjectTypeEnum.SAP_ERP,
        name: 'SAP ERP',
        imageUrl: 'https://cdn.collective.work/clyc0g6vs0qhq1x0p6w0cdz3v.jpeg',
      },
      {
        code: ProjectTypeEnum.TYLER_TECHNOLOGIES_ERP,
        name: 'Tyler Technologies ERP',
      },
      {
        code: ProjectTypeEnum.UNIT4_ERP,
        name: 'Unit4 ERP',
      },
      {
        code: ProjectTypeEnum.WORKDAY,
        name: 'Workday',
        imageUrl: 'https://cdn.collective.work/clxunyxmx02vy1xtb0bl5ec85.jpeg',
      },
    ],
  },
] as const;
