import {
  Modal as ChakraModal,
  ModalBody,
  ModalBodyProps,
  ModalContent,
  ModalContentProps,
  ModalHeader,
  ModalOverlay,
  ModalProps as ChakraModalProps,
} from '@chakra-ui/react';
import { forwardRef, ReactNode, useCallback, useEffect } from 'react';

import { KEYBOARD_ESC_CODE } from '../../keyboard-event-codes';

export interface FullscreenModalProps
  extends Omit<ChakraModalProps, 'size' | 'onClose'> {
  onClose?: VoidFunction;
  header?: ReactNode;
  disableAnimation?: boolean;
  modalContentProps?: ModalContentProps;
  modalBodyProps?: ModalBodyProps;
}

export const FullscreenModal = forwardRef<HTMLDivElement, FullscreenModalProps>(
  function FullscreenModal(
    {
      onClose,
      children,
      header,
      disableAnimation,
      scrollBehavior = 'inside',
      modalContentProps,
      modalBodyProps,
      closeOnEsc = true,
      ...rest
    }: FullscreenModalProps,
    ref
  ) {
    const onKeyDown = useCallback(
      (event: KeyboardEvent) => {
        event.code === KEYBOARD_ESC_CODE && onClose?.();
      },
      [onClose]
    );

    useEffect(() => {
      if (closeOnEsc) {
        window.addEventListener('keydown', onKeyDown);
      }

      return () => {
        window.removeEventListener('keydown', onKeyDown);
      };
    }, [onKeyDown, closeOnEsc]);

    return (
      <ChakraModal
        size="full"
        onClose={() => onClose?.()}
        scrollBehavior={scrollBehavior}
        motionPreset={disableAnimation ? 'none' : 'scale'}
        trapFocus={false}
        closeOnEsc={closeOnEsc}
        {...rest}
      >
        <ModalOverlay />
        <ModalContent borderRadius={0} {...modalContentProps}>
          {!!header && <ModalHeader p={0}>{header}</ModalHeader>}
          <ModalBody
            p={0}
            flexFlow="column"
            display="flex"
            ref={ref}
            {...modalBodyProps}
          >
            {children}
          </ModalBody>
        </ModalContent>
      </ChakraModal>
    );
  }
);
