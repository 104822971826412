import { FocusEvent, ForwardedRef, forwardRef } from 'react';
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

import { InlineEdit, InlineEditProps } from '../../inline-edit/inline-edit';

const FieldInlineEditInner = <
  FormFieldValues extends FieldValues = FieldValues,
  FieldName extends FieldPath<FormFieldValues> = FieldPath<FormFieldValues>
>(
  {
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
    ...rest
  }: UseControllerProps<FormFieldValues, FieldName> & InlineEditProps,
  forwardedRef: ForwardedRef<HTMLInputElement>
) => {
  const {
    field: { ref, ...field },
  } = useController({
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
  });

  const handleBlur = (e: FocusEvent<HTMLInputElement, Element>) => {
    field.onBlur();
    rest?.onBlur?.(e);
  };

  return (
    <InlineEdit ref={forwardedRef} {...field} {...rest} onBlur={handleBlur} />
  );
};

export const FieldInlineEdit = forwardRef(FieldInlineEditInner);
