import { useEffect, useState } from 'react';
import { isMobile as deviceDetectIsMobile } from 'react-device-detect';

export const useIsomorphicIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(deviceDetectIsMobile);
  }, []);

  return isMobile;
};
