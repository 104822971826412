import { BoxProps, css, useTheme } from '@chakra-ui/react';
import { sanitize } from '@collective/utils/helpers';
import { serializeStyles } from '@emotion/serialize';
import styled from '@emotion/styled';

import Link from '../../themes/components/link';
import { Box } from '../layout/box';

interface HTMLRenderProps extends BoxProps {
  content: string;
}

export const HTMLRender = ({ content, ...rest }: HTMLRenderProps) => {
  const theme = useTheme();

  // Use link styling based on the Link component of the design system
  const StyledBox = styled(Box)`
    a {
      ${serializeStyles([css(Link.baseStyle)(theme)], {}).styles}
    }
  `;

  // Make links open in a new tab as it is not the default behavior in the Forest Admin rich text editor
  const sanitizedContent = sanitize(
    content.replaceAll(
      /<a href="([^"]*)">/g,
      '<a href="$1" rel="noreferrer noopener" target="_blank">'
    )
  );

  return (
    <StyledBox
      listStylePos="inside"
      {...rest}
      dangerouslySetInnerHTML={{ __html: sanitizedContent }}
    />
  );
};
