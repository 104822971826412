import {
  NumberInput as ChakraNumberInput,
  NumberInputField,
  NumberInputFieldProps,
  NumberInputProps as ChakraNumberInputProps,
} from '@chakra-ui/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { styledIgnoredProps } from '../../utils';

export interface NumberInputLegacyProps
  extends Omit<ChakraNumberInputProps, 'onChange'> {
  onChange?: (value: string) => void;
  inputProps?: NumberInputFieldProps;
  isDisplayOnly?: boolean;
}

const StyledNumberInputLegacy = styled(
  ChakraNumberInput,
  styledIgnoredProps(['isDisplayOnly'])
)<{
  isDisplayOnly?: boolean;
}>`
  ${(p) =>
    p.isDisabled
      ? 'input[disabled]{ background: var(--chakra-colors-primary-1); }'
      : 'input[disabled]{ background: #fff; }'}
  ${(p) =>
    p.isDisplayOnly &&
    p.value &&
    p.value !== '0' &&
    css`
      &&& > * {
        opacity: 1;
      }
    `}
`;

export const NumberInputLegacy = ({
  onChange,
  placeholder,
  inputProps,
  isDisplayOnly,
  isDisabled,
  ...rest
}: NumberInputLegacyProps) => {
  return (
    <StyledNumberInputLegacy
      isDisabled={isDisabled || isDisplayOnly}
      isDisplayOnly={isDisplayOnly}
      onChange={onChange}
      {...rest}
    >
      <NumberInputField placeholder={placeholder} {...inputProps} />
    </StyledNumberInputLegacy>
  );
};
