import { MINIMAL_VIEWPORTS } from '@storybook/addon-viewport';

/**
 * Width and height values from MINIMAL_VIEWPORTS for Chromatic screenshot
 */
export const chromaticViewports = {
  mobile1: 320,
  mobile2: 414,
  smallTablet: 768,
  tablet: 834,
  desktop: 1200,
  largeDesktop: 1400,
};

/**
 * Viewport config for Storybook
 */
export const storybookViewports = {
  ...MINIMAL_VIEWPORTS,
  smallTablet: {
    name: 'Small tablet',
    type: 'tablet',
    styles: { width: '768px', height: '1024px' },
  },
};
