import { forwardRef } from 'react';

import { Box, BoxProps } from './box';

export interface BorderBoxProps extends BoxProps {
  noHover?: boolean;
}

export const BorderBox = forwardRef<HTMLDivElement, BorderBoxProps>(
  function BorderBox({ noHover, ...rest }, ref) {
    return (
      <Box
        ref={ref}
        bgColor="white"
        border="1px solid"
        borderColor="rythm.200"
        borderRadius="12px"
        overflow="hidden"
        transition="border-color .2s"
        {...(noHover ? {} : { boxShadow: 'sm' })}
        _hover={noHover ? {} : { borderColor: 'rythm.600' }}
        {...rest}
      />
    );
  }
);
