const sizes = {
  sm: {
    minH: '24px',
  },
  md: {
    minH: '28px',
  },
};

export type TagSize = keyof typeof sizes;

export const variants = {
  matchingTag: {
    container: {
      minH: '40px',
    },
  },
};

export type TagVariant = keyof typeof variants;

const Tag = {
  baseStyle: {
    container: {
      px: '6px',
      py: '4px',
      borderRadius: '8px',
    },
    closeButton: {
      color: 'rythm.600',
      opacity: 1,
      _hover: {
        bg: 'none',
        color: 'rythm.900',
      },
      _focus: {
        color: 'rythm.900',
      },
      _active: {
        bg: 'none',
      },
    },
  },
  sizes,
  variants,
};

export default Tag;
