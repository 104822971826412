export const AUTH_PATH = 'auth';
export const LOGIN = `${AUTH_PATH}/login`;
export const LOGOUT = `${AUTH_PATH}/logout`;
export const REFRESH = `${AUTH_PATH}/refresh`;
export const SEND_RESET = `${AUTH_PATH}/send-reset`;
export const RESET_USER = `${AUTH_PATH}/reset-user`;
export const SET_PASSWORD = `${AUTH_PATH}/set-password`;
export const SET_LANGUAGE = `${AUTH_PATH}/set-language`;
export const SIGNUP_INVITE = `${AUTH_PATH}/signup-invite`;
export const SIGNUP_OPEN = `${AUTH_PATH}/signup`;
export const GOOGLE_AUTH_SCREEN = `${AUTH_PATH}/google-auth-screen`;
export const GOOGLE = `${AUTH_PATH}/google`;
export const RESEND_MEMBER_INVITATION = `${AUTH_PATH}/resend-member-invitation`;

export const COLLECTIVE_JOIN = 'collective/join';

export const CONFIRM_IBAN = 'confirm-iban';

export const UNSUBSCRIBE = 'unsubscribe';

export const CLIENT_AUTHENTICATE_EMAIL = 'client/send-auth-email';
export const CLIENT_AUTHENTICATE = 'client/authenticate';
