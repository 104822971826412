import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  MonthYearPicker,
  MonthYearPickerProps,
} from '../../..';

type FieldMonthYearPickerProps = Omit<MonthYearPickerProps, 'value'> & {
  label?: string;
};

export const FieldMonthYearPicker = <
  FormFieldValues extends FieldValues = FieldValues,
  FieldName extends FieldPath<FormFieldValues> = FieldPath<FormFieldValues>
>({
  name,
  control,
  rules,
  shouldUnregister,
  defaultValue,
  label,
  ...rest
}: UseControllerProps<FormFieldValues, FieldName> &
  FieldMonthYearPickerProps) => {
  const {
    field: { ref, ...field },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
  });

  const onInputChange = (value: string | null) => {
    field.onChange(value);
    rest?.onChange?.(value);
  };

  return (
    <FormControl isInvalid={!!error}>
      {label && <FormLabel variant="simple">{label}</FormLabel>}
      <MonthYearPicker {...field} {...rest} onChange={onInputChange} />
      {error?.message && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};
