// http -> sends to external logging service
// console -> sends to the console
// silent -> do nothing
export type HandlerType = 'http' | 'console' | 'silent';

export function getHandlers(stringHandlers: string): HandlerType[] {
  if (stringHandlers) {
    const handlerList = stringHandlers.split(',').map((h) => h as HandlerType);
    return handlerList;
  }

  return ['silent'];
}
