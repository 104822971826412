export type TagsType = { [key: string]: string };

export function getLoggerTags(stringTags?: string): TagsType {
  const tagObj: { [key: string]: string } = {};

  if (stringTags) {
    stringTags.split(',').forEach((tag) => {
      if (!tag.includes(':')) {
        return;
      }

      // tags are of the form "tag1:value1,tag2:value2"
      const name = tag.split(':')[0];
      const value = tag.split(':')[1];

      tagObj[name] = value;
    });
  }

  return tagObj;
}
