import { ReactNode } from 'react';
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  RadioGroup,
  RadioGroupProps,
} from '../../..';

type FieldRadioGroupInputProps = RadioGroupProps & {
  children: ReactNode;
  label?: string;
};

export const FieldRadioGroup = <
  FormFieldValues extends FieldValues = FieldValues,
  FieldName extends FieldPath<FormFieldValues> = FieldPath<FormFieldValues>
>({
  children,
  name,
  control,
  rules,
  shouldUnregister,
  defaultValue,
  label,
  ...rest
}: UseControllerProps<FormFieldValues, FieldName> &
  FieldRadioGroupInputProps) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
  });

  return (
    <FormControl isInvalid={!!error}>
      {label && <FormLabel variant="simple">{label}</FormLabel>}
      <RadioGroup {...field} {...rest}>
        {children}
      </RadioGroup>
      {error?.message && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};
