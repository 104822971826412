import { cssVar } from '@chakra-ui/theme-tools';

const $width = cssVar('switch-track-width');
const $height = cssVar('switch-track-height');

const Switch = {
  baseStyle: {
    track: {
      borderRadius: 'lg',
      border: '2px solid',
      borderColor: 'rythm.600',
      bg: 'white',
      _focus: {
        boxShadow: 'outline.3',
        borderColor: 'rythm.600',
      },
      _hover: {
        borderColor: 'rythm.700',
      },
      _checked: {
        padding: '3px',
        bg: 'primary.600',
        border: '1px solid',
        borderColor: 'primary.25',
        _hover: {
          bg: 'primary.700',
        },
      },
      _disabled: {
        opacity: 0.5,
      },
    },
    thumb: {
      bg: 'rythm.300',
      borderRadius: 'base',
      _checked: {
        bg: 'primary.25',
      },
    },
    label: {
      color: 'rythm.900',
      _checked: {
        color: 'rythm.900',
      },
      _disabled: {
        opacity: 0.5,
      },
    },
  },
  sizes: {
    md: {
      container: {
        minHeight: 5,
        lineHeight: 'auto',
        display: 'inline-flex',
        alignItems: 'center',
        verticalAlign: 'top',
        [$width.variable]: '1.5rem',
        [$height.variable]: '0.5rem',
      },
      track: {
        ml: 0.5,
        mr: 0.5,
      },
    },
  },
};

export default Switch;
