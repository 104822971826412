import { padStart } from 'lodash';

import { isTestUser } from './email';
import { capitalizeEach, onlyLetters, replaceAccents } from './string';

export const INVOICE_IDENTIFIER = 'COL';
export const INVOICE_FEE_IDENTIFIER = 'COL-COM';
export const TEST_INVOICE_IDENTIFIER = 'AUTOMATEDTEST';
export const DEV_SEED_IDENTIFIER = 'DEV';
export const INVOICE_STATEMENT_IDENTIFIER = 'IS';
export const QUOTE_IDENTIFIER = 'D';
export const DEFAULT_INVOICE_FEE_IDENTIFIER = 2000; // random default invoice fee identifier, used to trick collectives that we we have lot of invoice fees generated 😂

export const TEST_SUFFIX = 'TEST';

/**
 * Member invoice
 */

export const generateMemberInvoiceHash = (user: {
  firstname: string;
  lastname: string;
}): string => {
  const cleanName = (s: string) =>
    capitalizeEach(replaceAccents(onlyLetters(s)));

  const cleanedFirstname = cleanName(user.firstname);
  const cleanedLastname = cleanName(user.lastname);

  return (cleanedFirstname[0] || '') + (cleanedLastname[0] || '');
};

type GenerateMemberInvoiceReferenceOptions = {
  hash: string;
  year: number;
  referenceNumber: number;
  isPreview?: boolean;
};

export const generateMemberInvoiceReference = ({
  hash,
  year,
  referenceNumber,
  isPreview,
}: GenerateMemberInvoiceReferenceOptions) => {
  const suffix = isPreview
    ? TEST_SUFFIX
    : padStart(referenceNumber.toString(), 3, '0');

  return `${INVOICE_IDENTIFIER}-${hash}-${year}-${suffix}`;
};

/**
 * Member Fee invoice
 */

type GenerateMemberFeeInvoiceReferenceOptions = {
  year: number;
  referenceNumber: number;
};

export const generateMemberFeeInvoiceReference = ({
  referenceNumber,
  year,
}: GenerateMemberFeeInvoiceReferenceOptions) => {
  return `${year}-${INVOICE_FEE_IDENTIFIER}-${padStart(
    referenceNumber.toString(),
    5,
    '0'
  )}`;
};

/**
 * Client invoice
 */

export const generateClientInvoiceReference = (
  year: number,
  referenceNumber: number,
  invoiceIdentifierOverride?: string
) => {
  const yearAndRef = generateClientInvoiceYearAndReference(
    year,
    invoiceIdentifierOverride
  );
  return `${yearAndRef}-${padStart(referenceNumber.toString(), 5, '0')}`;
};

export const generateClientInvoiceYearAndReference = (
  year: number,
  invoiceIdentifierOverride?: string
) => {
  return `${year}-${invoiceIdentifierOverride || INVOICE_IDENTIFIER}`;
};

export const getClientInvoiceIdentifier = (email: string): string => {
  // using a custom invoice identifier for test generated invoices so we don't mess up with the numbering of real invoices
  // case: when a test creates an invoice, it will eventually delete it but this could lead to holes in the
  // monotonically increasing invoice numbering which is not acceptable
  return isTestUser(email) ? TEST_INVOICE_IDENTIFIER : INVOICE_IDENTIFIER;
};

/**
 * Invoice statement
 */

export const generateInvoiceStatementReference = (
  year: number,
  reference: string,
  isPreview = false
) => {
  return isPreview
    ? `${INVOICE_STATEMENT_IDENTIFIER}-${year}-${TEST_SUFFIX}`
    : `${INVOICE_STATEMENT_IDENTIFIER}-${year}-${reference}`;
};

/**
 * Quote
 */

export const generateQuoteReference = (
  year: number,
  reference: string,
  isPreview = false
) => {
  return isPreview
    ? `${QUOTE_IDENTIFIER}-${year}-${TEST_SUFFIX}`
    : `${QUOTE_IDENTIFIER}-${year}-${reference}`;
};
