import 'react-day-picker/lib/style.css';

import utilsDate from '@collective/utils/date';
import dayjs from 'dayjs';
import { FocusEvent, forwardRef, ReactNode, useEffect, useState } from 'react';
import {
  DateUtils,
  DayModifiers,
  DayPickerInputProps,
  DayPickerProps,
  NavbarElementProps,
  WeekdayElementProps,
} from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import {
  IconArrowNarrowLeft,
  IconArrowNarrowRight,
  IconCalendar,
} from '../icon/icon';
import { BorderBox, Box, Flex, FlexProps } from '../layout';
import { Text } from '../typography';
import { TextInput, TextInputProps } from './text-input';

export interface DatePickerProps
  extends Omit<DayPickerInputProps, 'onChange' | 'onDayChange'> {
  onChange?: (date: string | null) => void;
  isInvalid?: boolean;
  inputProps?: TextInputProps;
  displayedFormat?: string;
  inputFormat?: string;
  overlayPosition?: 'top' | 'right' | 'bottom' | 'left';
  dayPickerProps?: DayPickerProps;
}

/**
 * Should have been typed by `react-day-picker`, bummer...
 */
type OverlayProps = {
  selectedDay: Date;
  month: Date;
  classNames: Record<string, string>[];
  children: ReactNode;
  input: JSX.Element;
  onFocus: () => void;
  onBlur: () => void;
};

const NavIconStyle: FlexProps = {
  color: 'rythm.600',
  w: '24px',
  h: '24px',
  justifyContent: 'center',
  alignItems: 'center',
  _hover: {
    color: 'rythm.900',
    cursor: 'pointer',
  },
};

export const DatePicker = ({
  onChange,
  onBlur,
  isInvalid,
  value,
  displayedFormat = utilsDate.DATE_FORMAT_FR,
  inputFormat = displayedFormat,
  inputProps,
  dayPickerProps,
  ...rest
}: DatePickerProps) => {
  const [inputValue, setInputValue] = useState<string | null>(
    value ? dayjs(new Date(value)).format(displayedFormat) : null
  );

  useEffect(() => {
    // In case we got undefined first, and then the value is fetch
    // Mostly a case for testing purpose
    if (!value) {
      setInputValue('');
      return;
    }

    setInputValue(dayjs(new Date(value)).format(displayedFormat));
  }, [displayedFormat, value]);

  const onDateChange = (date: Date | undefined) => {
    if (date) {
      date.setHours(0); // for some reason the date picker is setting the hour of the chosen date to 12, so we push it back to 0

      setInputValue(dayjs(date).format(displayedFormat));

      onChange?.(utilsDate.convertToUTC(date));
    } else {
      onChange?.(null);
    }
    onBlur?.(
      new Event('blur') as unknown as FocusEvent<HTMLDivElement, Element>
    );
  };

  /**
   * Parse string directly typed in TextInput
   * @param str
   * @param configuredFormat
   */
  const parseDate = (str: string) => {
    // Hack to not taking care of the spaces in between the numbers and slashes
    // Sadly there is no easy way to give a regex as a format and with the customFormat extension
    // if the date string doesn't match exactly the format, it will consider it as an invalid date
    const parsed = dayjs(
      str.replaceAll(' ', ''),
      inputFormat.replaceAll(' ', '')
    ).toDate();

    if (DateUtils.isDate(parsed)) {
      return parsed;
    }
    return;
  };

  /**
   * Format how the Date is displayed in TextInput
   * @param date
   * @param configuredFormat
   */
  const formatDate = (date: Date, configuredFormat: string) =>
    dayjs(date).format(configuredFormat);

  const Overlay = getOverlayComponent(rest?.overlayPosition);

  return (
    <Box
      sx={{
        '.DayPicker-Month': {
          margin: 0,
        },
        '.DayPicker-wrapper, .DayPicker-Day': {
          p: 0,
          '&:focus-visible': {
            outline: 'none',
            '& > p': {
              bg: 'primary.25',
              color: 'rythm.900',
            },
          },
        },
        '.DayPicker-Day--today': {
          fontWeight: 400,
        },
        '.DayPicker-Day--selected, .DayPicker-Day:hover': {
          bg: 'transparent !important',
        },
        '.DayPicker-Day--disabled': {
          '&:focus-visible': {
            outline: 'none',
            '& > p': {
              bg: 'transparent !important',
              color: 'rythm.700',
            },
          },
        },
        '.DayPicker-Day--disabled p': {
          color: 'rythm.700',
          _hover: {
            bg: 'transparent !important',
          },
        },
      }}
    >
      <DayPickerInput
        component={InputComponent}
        inputProps={{
          isInvalid,
          ...inputProps,
        }}
        overlayComponent={Overlay}
        dayPickerProps={{
          navbarElement: Navbar,
          captionElement: () => null,
          weekdayElement: Weekday,
          renderDay: (date: Date, modifiers: DayModifiers) => (
            <Day date={date} modifiers={modifiers} />
          ),
          firstDayOfWeek: 1,
          showOutsideDays: true,
          modifiers: { highlighted: new Date(value as string) },
          ...dayPickerProps,
        }}
        format={displayedFormat}
        formatDate={formatDate}
        parseDate={parseDate}
        value={inputValue || ''}
        placeholder={inputFormat}
        onDayChange={onDateChange}
        // This fixes the bug when losing focus on the current tab
        // and coming back on the page (the widget goes back to current month)
        keepFocus={false}
        {...rest}
      />
    </Box>
  );
};

const Day = ({ date, modifiers }: { date: Date; modifiers: DayModifiers }) => {
  const { highlighted, today, outside } = modifiers;

  const highlightedStyle = highlighted && {
    backgroundColor: 'primary.600',
    color: 'white',
  };
  const todayStyle = today && {
    backgroundColor: 'rythm.200',
  };
  const outsideStyle = outside && {
    color: 'rythm.700',
  };
  return (
    <Text
      width="24px"
      height="24px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      mx={1}
      my={1}
      borderRadius="6px"
      _hover={{
        backgroundColor: !highlighted && 'primary.25',
      }}
      {...outsideStyle}
      {...todayStyle}
      {...highlightedStyle}
    >
      {date.getDate()}
    </Text>
  );
};

const Weekday = ({ weekday, locale, localeUtils }: WeekdayElementProps) => {
  return (
    <Text
      variant="desktop-s-regular"
      display="table-cell"
      textAlign="center"
      color="rythm.700"
      pb={1}
      as="span"
    >
      {localeUtils.formatWeekdayShort(weekday, locale)}
    </Text>
  );
};

const Navbar = ({
  month,
  onPreviousClick,
  onNextClick,
}: NavbarElementProps) => {
  return (
    <Flex justify="space-between" align="center" height="24px" mb={4} px={1}>
      <Flex {...NavIconStyle} onClick={() => onPreviousClick()}>
        <IconArrowNarrowLeft size="xs" />
      </Flex>
      <Text variant="desktop-s-medium">
        {dayjs(month).format(utilsDate.DATE_FORMAT_MONTH_YEAR)}
      </Text>
      <Flex {...NavIconStyle} onClick={() => onNextClick()}>
        <IconArrowNarrowRight size="xs" />
      </Flex>
    </Flex>
  );
};

const InputComponent = forwardRef<HTMLInputElement>(function InputComponent(
  props: TextInputProps,
  ref
) {
  return (
    <TextInput
      ref={ref}
      leftElement={<IconCalendar />}
      isLeftElementWrapped
      {...props}
    />
  );
});

const getOverlayComponent = (
  position?: 'top' | 'right' | 'bottom' | 'left'
) => {
  const positionProps = position
    ? {
        [position]: position === 'top' || position === 'bottom' ? '100%' : 0,
      }
    : {};

  return function Overlay({
    children,
    month,
    classNames,
    selectedDay,
    input,
    ...rest
  }: OverlayProps) {
    return (
      <BorderBox
        boxShadow="md"
        bgColor="white"
        border="1px solid"
        borderColor="rythm.200"
        p="16px"
        position="absolute"
        zIndex={10}
        {...rest}
        {...positionProps}
      >
        {children}
      </BorderBox>
    );
  };
};
