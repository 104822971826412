import { useIsOverflowing } from '../../hooks/use-is-overflowing';
import { Tooltip, TooltipProps } from '../tooltip/tooltip';
import { Text, TextProps } from './text';

type TextTooltipProps = TextProps & {
  tooltipProps?: Omit<TooltipProps, 'children'>;
};

export const TextTooltip = ({
  children,
  tooltipProps,
  ...rest
}: TextTooltipProps) => {
  const [ref, isOverflowing] = useIsOverflowing();

  return (
    <Tooltip
      placement="top-start"
      label={children}
      isDisabled={!isOverflowing}
      openDelay={1000}
      {...tooltipProps}
    >
      <Text
        ref={ref}
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        overflow="hidden"
        {...rest}
      >
        {children}
      </Text>
    </Tooltip>
  );
};
