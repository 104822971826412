import Fuse from 'fuse.js';
import { get } from 'lodash';

import { removeSpecialCharacters, replaceAccents } from './string';

export type SearchOptions = {
  threshold?: number;
  ignoreLocation?: boolean;
  keys?: string[];
};

/**
 * Abstraction over Fuse.js to provide a simple search interface.
 */
export class InMemorySearch<T> {
  fuse: Fuse<T>;

  constructor(values: ReadonlyArray<T>, options?: SearchOptions) {
    this.fuse = new Fuse<T>(values, {
      threshold: options?.threshold ?? 0, // determines the fault tolerance, 0 meaning a perfect match
      ignoreLocation: options?.ignoreLocation ?? true, // most of the time, we don't care about the location of the match
      getFn: (obj, path) => {
        const key = Array.isArray(path) ? path.join('.') : path;
        const value = get(obj, key);

        return typeof value === 'string' ? this.normalizeInput(value) : value;
      },
      includeScore: true,
      ...(options?.keys ? { keys: options?.keys } : {}),
    });
  }

  public query(queryStr: string): T[] {
    return this.queryWithScore(queryStr).map(({ item }) => item);
  }

  public queryWithScore(
    queryStr: string
  ): { item: T; score: number | undefined }[] {
    return this.fuse
      .search<T>(this.normalizeInput(queryStr))
      .map(({ item, score }) => ({ item, score }));
  }

  // normalize accented characters and remove double spaces
  private normalizeInput(search: string) {
    return removeSpecialCharacters(replaceAccents(search))
      .split(' ')
      .filter(Boolean)
      .join(' ');
  }
}
