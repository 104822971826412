import { Stack as ChakraStack, StackProps } from '@chakra-ui/react';

export type {
  StackDirection,
  StackDividerProps,
  StackProps,
} from '@chakra-ui/react';
export { HStack, StackDivider, StackItem, VStack } from '@chakra-ui/react';

export const Stack = ({ children, ...rest }: StackProps) => {
  return (
    <ChakraStack align="flex-start" {...rest}>
      {children}
    </ChakraStack>
  );
};
