import {
  FormHelperText as ChakraFormHelperText,
  TextProps,
} from '@chakra-ui/react';

// Unfortunately Chakra doesn't have a theme property for this component, hence the styling here
export const FormHelperText = (props: TextProps) => {
  return (
    <ChakraFormHelperText
      fontSize="0.75rem" // 12px
      lineHeight="1rem" // 16px
      color="rythm.700"
      {...props}
    />
  );
};
