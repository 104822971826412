const variants = {
  default: {
    dialog: {
      borderRadius: '12px',
      boxShadow: 'md',
    },
  },
  bottom: {
    dialog: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      borderBottomRadius: 0,
      maxWidth: '100%',
      width: '100%',
    },
  },
};

export type ModalVariant = keyof typeof variants;

const Modal = {
  parts: ['modal', 'dialog'],
  sizes: {
    sm: {
      dialog: {
        maxWidth: '352px',
      },
    },
    md: {
      dialog: {
        maxWidth: '608px',
      },
    },
    lg: {
      dialog: {
        maxWidth: '800px',
      },
    },
    xl: {
      dialog: {
        maxWidth: '1024px',
      },
    },
  },
  variants,
  defaultProps: {
    variant: 'default',
  },
};

export default Modal;
