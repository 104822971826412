import { sanitize as sanitizePurify } from 'isomorphic-dompurify';

export function sanitize(domStr: string) {
  return sanitizePurify(domStr, {
    ADD_ATTR: ['target', 'rel'],
    ADD_TAGS: ['video-component'],
  });
}

export function isPresent<T>(t: T | undefined | null | void): t is T {
  return typeof t !== 'undefined' && t !== null;
}

// From https://github.com/robertmassaioli/ts-is-present/blob/master/src/index.ts
export function hasPresentKey<K extends string | number | symbol>(k: K) {
  return function <T, V>(
    a: T & { [k in K]?: V | null }
  ): a is T & { [k in K]: V } {
    return a[k] !== undefined && a[k] !== null;
  };
}
