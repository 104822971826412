import { Tabs as ChakraTabs, TabsProps } from '@chakra-ui/react';

export type { TabsProps };

export const Tabs = ({ children, size = 'sm', ...rest }: TabsProps) => {
  return (
    <ChakraTabs size={size} {...rest}>
      {children}
    </ChakraTabs>
  );
};
