import { useCallback } from 'react';
import {
  Location,
  unstable_useBlocker as useBlocker,
  useBeforeUnload,
} from 'react-router-dom';

import { Modal, ModalProps } from '../modal/modal';

export type BlockerFunction = ({
  currentLocation,
  nextLocation,
}: {
  currentLocation: Location;
  nextLocation: Location;
}) => boolean;

type BlockerModalChildren = {
  continueTransition: VoidFunction;
  onClose: VoidFunction;
};

type BlockerModalProps = Omit<ModalProps, 'isOpen' | 'onClose' | 'children'> & {
  children: (props: BlockerModalChildren) => React.ReactNode;
  blockerCondition: boolean | BlockerFunction;
};

// As of writing this, react-router is re-implementing useBlocker and usePrompt after having ditched them.
// But they are only brought back in version 6.7.0-pre.3 as of writing this. That is why this version was used.
// The discussion around all that can be found here: https://github.com/remix-run/react-router/issues/8139#issuecomment-1382428200
export const BlockerModal = ({
  children,
  blockerCondition,
  ...rest
}: BlockerModalProps) => {
  const createMockLocation = (value: string) => ({
    pathname: value,
    state: null,
    key: value,
    search: value,
    hash: value,
  });

  const blocker = useBlocker(blockerCondition);

  // The beforeunload event is fired when the current window, contained document, and associated resources are about to be unloaded
  // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
  useBeforeUnload(
    useCallback(
      (event) => {
        // If blockerCondition is a function, we mock the current and next locations with 2 different locations to have the correct behavior
        // This allows keeping the same function signature as react-router blocker modal
        const displayBlocker =
          blockerCondition instanceof Function
            ? blockerCondition({
                currentLocation: createMockLocation('CURRENT'),
                nextLocation: createMockLocation('NEXT'),
              })
            : blockerCondition;

        if (displayBlocker) {
          event.preventDefault();
          event.returnValue = '';
        }
      },
      [blockerCondition]
    ),
    { capture: true }
  );

  const continueTransition = useCallback(() => {
    blocker.proceed?.();
  }, [blocker]);

  const onClose = useCallback(() => {
    blocker.reset?.();
  }, [blocker]);

  return (
    <Modal isOpen={blocker.state === 'blocked'} onClose={onClose} {...rest}>
      {children({ continueTransition, onClose })}
    </Modal>
  );
};
