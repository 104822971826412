import { cssVar } from '@chakra-ui/theme-tools';

const $bg = cssVar('tooltip-bg');
const $arrowBg = cssVar('popper-arrow-bg');

const Tooltip = {
  baseStyle: {
    [$bg.variable]: 'colors.primary.800',
    px: '12px',
    py: '8px',
    bg: [$bg.reference],
    [$arrowBg.variable]: [$bg.reference],
    color: 'white',
    borderRadius: 'md',
    fontWeight: 'medium',
    fontSize: 'xs',
    boxShadow: 'md',
    maxW: '320px',
    zIndex: 'tooltip',
  },
};

export default Tooltip;
