import Decimal from 'decimal.js';

import { DEFAULT_VAT, DISINTERMEDIATED_INVOICE_FEE } from './constants';

export type FeesAmountsTypes = {
  feePercentage: number;
  grossFeeAmount: number;
  netFeeAmount: number;
};

export const eurosToCents = (value: string | number) =>
  new Decimal(value).mul(100).trunc();

/**
 * Compute the fees for a disintermediated invoice
 *
 * @param amount in cents
 * @param isInFrance whether the freelancer's company to invoice is in France (for VAT)
 * @param customFeePercentage custom fee percentage to use instead of the default one DISINTERMEDIATED_INVOICE_FEE
 */
export const computeFees = (
  amount: number,
  isInFrance: boolean,
  customFeePercentage?: number | null
): FeesAmountsTypes => {
  const feePercentage = getFeePercentage(customFeePercentage);

  // freelancer not in france, no VAT in this case
  if (!isInFrance) {
    const netFeeAmount = new Decimal(amount).mul(
      new Decimal(feePercentage).div(100)
    );

    return {
      feePercentage,
      netFeeAmount: netFeeAmount.round().toNumber(),
      grossFeeAmount: netFeeAmount.round().toNumber(),
    };
  } else {
    const netFeeAmount = new Decimal(amount)
      .mul(new Decimal(feePercentage).div(100))
      .round();

    // add the VAT
    const grossFeeAmount = netToGross(netFeeAmount);

    return {
      feePercentage,
      netFeeAmount: netFeeAmount.round().toNumber(),
      grossFeeAmount: grossFeeAmount.round().toNumber(),
    };
  }
};

export const netToGross = (netAmount: number | Decimal) => {
  // add the VAT to the net fees to obtain the gross fees, we then add the 2 and
  // round to the nearest cent
  const vat = new Decimal(netAmount).mul(DEFAULT_VAT).div(100).round();
  return new Decimal(netAmount).add(vat).round();
};

export const getFeePercentage = (
  customFeePercentage?: number | null
): number => {
  return customFeePercentage ?? DISINTERMEDIATED_INVOICE_FEE;
};
