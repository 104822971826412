import { useCallback, useEffect, useState } from 'react';

/**
 * Check if ref element has overflowing content
 */
export function useIsOverflowing() {
  const [element, setElement] = useState<HTMLElement | null>();
  const ref = useCallback((node: HTMLElement | null) => {
    setElement(node);
  }, []);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const offsetWidth = element?.offsetWidth;
  const scrollWidth = element?.scrollWidth;

  useEffect(() => {
    if (offsetWidth && scrollWidth) {
      setIsOverflowing(offsetWidth < scrollWidth);
    }
  }, [offsetWidth, scrollWidth]);

  return [ref, isOverflowing] as const;
}
