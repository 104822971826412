import { SystemStyleObject } from '@chakra-ui/react';

const baseStyleInput: SystemStyleObject = {
  width: '100%',
  py: 0,
  _focus: { boxShadow: '0 0 1px 1px var(--chakra-colors-primary-600)' },
};

const baseStylePreview: SystemStyleObject = {
  py: 0,
  _hover: {
    boxShadow: '0 0 1px 1px var(--chakra-colors-rythm-300)',
    color: 'rythm.900',
  },
};

const Editable = {
  baseStyle: {
    input: baseStyleInput,
    preview: baseStylePreview,
  },
};

export default Editable;
