const variants = {
  simple: {
    mb: 1,
    color: 'rythm.700',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
  },
};

export type FormLabelVariants = keyof typeof variants;

export default {
  variants,
};
