// There was no doc on https://tiptap.dev/introduction that demonstrates drag and paste events but thanks to these 2 threads it was possible
// https://gist.github.com/slava-vishnyakov/16076dff1a77ddaca93c4bccd4ec4521?permalink_comment_id=4081365#gistcomment-4081365
// https://gist.github.com/waptik/f44b0d3c803fade75456817b1b1df6b4

import { isPresent } from '@collective/utils/helpers';
import { Extension } from '@tiptap/core';
import { Plugin, PluginKey } from 'prosemirror-state';

const ImagePasteExtension = Extension.create({
  name: 'ImagePaste',

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('imagePaste'),
        props: {
          handleDOMEvents: {
            paste: (_, event: Event): boolean | void => {
              const clipboardEvent = event as ClipboardEvent;
              const items = Array.from(
                clipboardEvent.clipboardData?.items || []
              );

              if (items.length === 0) {
                return;
              }

              // Check if the first element of the clipboard is an image
              // Otherwise, copying from MS Word results in pasting an image instead of formatted text
              const isFirstElementImage = items[0].type.startsWith('image/');

              if (!isFirstElementImage) {
                return false;
              }

              const images: File[] = [...items]
                .map((item) => item.getAsFile())
                .filter(isPresent);

              if (images.length === 0) {
                return;
              }

              event.preventDefault();

              this.options.setIsUploadingImage(true);

              const promises = images
                .filter((image) => image.type.indexOf('image') === 0)
                .map((image) => this.options.upload(image));

              Promise.all(promises).then((urls: string[]) => {
                if (urls.length > 0) {
                  const imagesToSet = urls.filter(isPresent).map((url) => {
                    return {
                      type: 'image',
                      attrs: {
                        src: url,
                      },
                    };
                  });

                  this.editor.chain().focus().insertContent(imagesToSet).run();
                }

                this.options.setIsUploadingImage(false);
              });
            },
          },
        },
      }),
    ];
  },
});

export { ImagePasteExtension };
