import { BoxProps, useStyleConfig } from '@chakra-ui/react';

import { CounterSize, CounterVariant } from '../../themes/components/counter';
import { Box } from '../layout';
import { Text } from '../typography';

export type CounterProps = BoxProps & {
  count: number;
  variant: CounterVariant;
  size?: CounterSize;
};

export const Counter = ({
  count,
  variant,
  size = 'md',
  ...rest
}: CounterProps) => {
  const styles = useStyleConfig('Counter', { variant, size });

  return (
    count > 0 && (
      <Box __css={styles} {...rest}>
        <Text color="inherit" variant="desktop-s-regular">
          {count}
        </Text>
      </Box>
    )
  );
};
