export enum ShortlistSettingCategory {
  None = 'NONE',
  Question = 'QUESTION',
  ShortlistPricing = 'SHORTLIST_PRICING',
}

export type ShortlistSettings = {
  category: ShortlistSettingCategory;
  order: number;
  value: string;
};

export type ShortlistSettingsJsonObject = {
  opportunityCard?: ShortlistSettings[];
};

export type PositionShortlistSettingsJsonObject =
  ShortlistSettingsJsonObject & {
    positionId: string;
  };

export type PositionsShortlistSettingsArray = {
  positions: PositionShortlistSettingsJsonObject[];
};
