const Checkbox = {
  baseStyle: {
    container: {
      borderColor: 'rythm.600',
      color: 'white',
      _hover: {
        borderColor: 'rythm.700',
      },
      _checked: {
        borderColor: 'primary.600',
        color: 'primary.600',
        _hover: {
          borderColor: 'primary.700',
          color: 'primary.700',
        },
      },
    },
    control: {
      borderColor: 'inherit',
      color: 'inherit',
      borderRadius: 'base',
      _checked: {
        bg: 'white',
        borderColor: 'inherit',
        color: 'inherit',
        _hover: {
          bg: 'white',
          borderColor: 'inherit',
          color: 'inherit',
        },
        _disabled: {
          bg: 'white',
          borderColor: 'inherit',
          color: 'inherit',
        },
      },
      _disabled: {
        bg: 'white',
        borderColor: 'inherit',
        color: 'inherit',
        opacity: 0.5,
      },
    },
    label: {
      color: 'rythm.900',
      _disabled: {
        opacity: 0.5,
      },
    },
  },
  sizes: {
    md: {
      container: {
        height: 6,
      },
      control: {
        w: 4.5,
        h: 4.5,
        ml: 0.5,
        mr: 0.5,
      },
      label: {
        fontSize: 'xs',
        fontWeight: 600,
        ml: 2.5,
      },
    },
  },
};

export default Checkbox;
