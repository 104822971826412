import { useFlags } from 'launchdarkly-react-client-sdk';
import { createContext, ReactNode, useContext } from 'react';

import { LaunchdarklyFlags } from '~/public-pages/types/launchdarkly';

const FeatureFlagsContext = createContext<LaunchdarklyFlags>({});

type FeatureFlagsProviderProps = {
  children: ReactNode;
  initialFlags?: LaunchdarklyFlags;
};

export const FeatureFlagsProvider = ({
  children,
  initialFlags,
}: FeatureFlagsProviderProps) => {
  const ldFlags = useFlags();

  const flags = { ...initialFlags, ...ldFlags };

  return (
    <FeatureFlagsContext.Provider value={flags}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export const useFeatureFlags = () => useContext(FeatureFlagsContext);
