const Link = {
  baseStyle: {
    color: 'rythm.700',
    textDecoration: 'underline',
    fontWeight: '500',
    lineHeight: '1.25rem', // 20px
    textUnderlinePosition: 'under',
    _hover: {
      color: 'rythm.900',
    },
    _active: {
      color: 'rythm.900',
    },
  },
};

export default Link;
