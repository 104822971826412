import { buttonFontStyles } from './text';

const buttonSizes = {
  sm: {
    ...buttonFontStyles['desktop-s-button'],
    px: 2,
    h: 6,
    borderRadius: '6px',
    minW: 'auto',
  },
  md: {
    ...buttonFontStyles['desktop-m-button'],
    px: 3,
    h: 8,
    borderRadius: '8px',
    minW: 'auto',
  },
  lg: {
    ...buttonFontStyles['desktop-m-button'],
    px: 3,
    h: 12,
    borderRadius: '12px',
    minW: 'auto',
  },
};

export type ButtonSize = keyof typeof buttonSizes;

const iconButtonSizes = {
  iconSm: {
    w: 6,
    h: 6,
    borderRadius: '6px',
  },
  iconMd: {
    w: 8,
    h: 8,
    borderRadius: '8px',
  },
  iconLg: {
    w: 12,
    h: 12,
    borderRadius: '12px',
  },
};

export type IconButtonSize = keyof typeof iconButtonSizes;

const buttonVariants = {
  primary: {
    bg: 'primary.600',
    color: 'white',

    _hover: {
      bg: 'primary.700',
      boxShadow: 'button',
      // Fix for the hover disabled, otherwise the background is 'initial' by default
      _disabled: {
        bg: 'primary.600',
      },
    },
    _focus: {
      bg: 'primary.700',
      '.js-focus-visible &:focus:not(.focus-visible)': {
        boxShadow: 'outline.1',
      },
    },
    _active: {
      bg: 'primary.700',
    },
    _disabled: {
      opacity: 0.5,
    },
  },
  secondary: {
    bg: 'white',
    color: 'rythm.700',
    border: '1px solid',
    borderColor: 'rythm.300',
    _hover: {
      borderColor: 'rythm.600',
      color: 'rythm.900',
    },
    _focus: {
      borderColor: 'rythm.700',
      color: 'rythm.900',
    },
    _active: {
      borderColor: 'rythm.900',
      color: 'rythm.900',
    },
    '& .chakra-button__icon svg': {
      color: 'rythm.600',
    },
    '&:focus .chakra-button__icon svg': {
      color: 'rythm.900',
    },
    '&:hover .chakra-button__icon svg': {
      color: 'rythm.900',
    },
  },
  third: {
    bg: 'transparent',
    color: 'primary.600',

    _hover: {
      bg: 'primary.25',
    },
    _focus: {
      bg: 'primary.25',
      color: 'primary.700',
    },
    _active: {
      bg: 'white',
    },
  },
  warn: {
    bg: 'critical.500',
    color: 'white',

    _hover: {
      bg: 'critical.700',
      // Fix for the hover disabled, otherwise the background is 'initial' by default
      _disabled: {
        bg: 'critical.500',
      },
    },
    _focus: {
      bg: 'critical.700',
      '.js-focus-visible &:focus:not(.focus-visible)': {
        boxShadow: '0 0 0 1px var(--chakra-colors-primary-300)',
      },
    },
    _active: {
      bg: 'critical.500',
    },
  },
  plain: {
    background: 'unset',
    height: 'auto',
    color: 'rythm.700',
    padding: 0,
    _hover: {
      color: 'rythm.900',
      textDecoration: 'underline',
      _disabled: {
        textDecoration: 'unset',
      },
    },
    _focus: {
      color: 'rythm.900',
      outline: 'solid',
      outlineColor: 'primary.400',
      borderRadius: '4px',
    },
    _disabled: {
      opacity: 0.3,
    },
    _active: {
      color: 'rythm.900',
    },
  },
  // Variant mainly used to disguise div as button
  naked: {
    display: 'block',
    color: 'unset',
    height: 'unset',
    width: 'unset',
    background: 'unset',
    margin: 'unset',
    padding: 'unset',
    textAlign: 'unset',
    fontWeight: 'unset',
    whiteSpace: 'unset',
    alignItems: 'unset',
    justifyContent: 'unset',
  },
  editorToolbar: {
    height: 6,
    p: 0,
    color: 'rythm.700',
    border: '1px solid transparent',
    borderRadius: '4px',
    background: 'transparent',
    _hover: { backgroundColor: 'primary.25' },
    _active: {
      boxShadow: 'outline.1',
    },
    _focus: {
      boxShadow: 'outline.1',
    },
  },
  iconButton: {
    color: 'rythm.600',
    border: '1px solid',
    borderColor: 'rythm.300',

    _hover: {
      borderColor: 'rythm.600',
      color: 'rythm.900',
    },
    _focus: {
      borderColor: 'rythm.700',
      color: 'rythm.900',
    },
    _active: {
      borderColor: 'rythm.900',
      color: 'rythm.900',
    },
  },
  navigation: {
    bg: 'transparent',
    color: 'white',
    // Transparent border needed for the element to not move when focusing on it
    border: '1px solid transparent',
    _hover: {
      bg: 'rgba(255, 255, 255, .2)',
    },
    _focus: {
      bg: 'rgba(255, 255, 255, .2)',
      border: '1px solid white',
    },
    _active: {
      bg: 'rgba(255, 255, 255, .2)',
    },
    _disabled: {
      opacity: 0.6,
    },
  },
  'brand-outline': {
    bg: 'transparent',
    color: 'primary.500',
    border: '1px solid',
    borderColor: 'primary.600',
    _hover: {
      bg: 'rgba(52, 38, 229, 0.05)',
      borderColor: 'primary.500',
    },
    _focus: {
      border: '2px solid',
    },
    _active: {
      bg: 'rgba(52, 38, 229, 0.05)',
      border: '2px solid',
    },
    '& .chakra-button__icon svg': {
      color: 'primary.600',
    },
    '&:focus .chakra-button__icon svg': {
      color: 'primary.600',
    },
    '&:hover .chakra-button__icon svg': {
      color: 'primary.600',
    },
  },
};

export type ButtonVariant = keyof typeof buttonVariants;

export default {
  baseStyle: {
    fontSize: '0.875rem',
    lineHeight: '0.875rem',
    transition: '.1s ease-in-out border-color',
  },
  sizes: { ...buttonSizes, ...iconButtonSizes },
  variants: buttonVariants,
  defaultProps: {
    variant: 'primary',
    colorScheme: 'primary',
  },
};
