import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  TextAreaWithTagsInput,
  TextAreaWithTagsInputProps,
} from '../../..';

type FieldTextAreaWithTagsProps = TextAreaWithTagsInputProps & {
  label?: string;
  info?: React.ReactNode;
};

export const FieldTextAreaWithTags = <
  FormFieldValues extends FieldValues = FieldValues,
  FieldName extends FieldPath<FormFieldValues> = FieldPath<FormFieldValues>
>({
  name,
  control,
  rules,
  shouldUnregister,
  defaultValue,
  label,
  info,
  ...rest
}: UseControllerProps<FormFieldValues, FieldName> &
  FieldTextAreaWithTagsProps) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
  });

  return (
    <FormControl isInvalid={!!error}>
      {label && <FormLabel variant="simple">{label}</FormLabel>}
      <TextAreaWithTagsInput {...field} {...rest} />
      {error?.message && <FormErrorMessage>{error.message}</FormErrorMessage>}
      {!!info && <FormHelperText>{info}</FormHelperText>}
    </FormControl>
  );
};
