import {
  chakra,
  StackProps,
  useRadio,
  useRadioGroup,
  UseRadioGroupProps,
  UseRadioProps,
} from '@chakra-ui/react';

import { Box, HStack } from '../layout';
import { Text } from '../typography';

export type TableFilterType<T> = {
  name: T;
  label: string;
};

type TableFilterProps<T> = {
  filter: TableFilterType<T>;
  radioProps: UseRadioProps;
};

const TableFilter = <T extends string>({
  filter,
  radioProps,
}: TableFilterProps<T>) => {
  const { state, getInputProps, getCheckboxProps, htmlProps, getLabelProps } =
    useRadio(radioProps);
  const { isChecked } = state;

  return (
    <chakra.label
      {...htmlProps}
      aria-label={filter.label}
      cursor="pointer"
      sx={{
        // Adds an accessible visible outline to the focused element
        '& .focus-visible + *': {
          boxShadow: 'var(--chakra-shadows-outline)',
        },
      }}
    >
      <input {...getInputProps()} />
      <Box
        {...getCheckboxProps()}
        borderRadius="8px"
        padding={1}
        bg={isChecked ? 'primary.25' : 'transparent'}
        transition=".1s ease-in-out"
        transitionProperty="background, box-shadow"
      >
        <Text
          {...getLabelProps()}
          variant="desktop-m-medium"
          color="rythm.700"
          transition="color .1s ease-in-out"
          _hover={{
            color: 'rythm.900',
          }}
          {...(isChecked && {
            color: 'primary.600',
            _hover: {
              color: 'primary.600',
            },
          })}
        >
          {filter.label}
        </Text>
      </Box>
    </chakra.label>
  );
};

export type TableFiltersProps<T> = Omit<StackProps, 'onChange'> & {
  filters: TableFilterType<T>[];
  value: T;
  onChange: (value: T) => void;
  radioGroupProps?: Omit<UseRadioGroupProps, 'onChange' | 'value'>;
};

export const TableFilters = <T extends string>({
  filters,
  value,
  onChange,
  radioGroupProps,
  ...rest
}: TableFiltersProps<T>) => {
  const { getRadioProps, getRootProps } = useRadioGroup({
    value,
    onChange,
    ...radioGroupProps,
  });

  return (
    <HStack spacing={2} {...rest} {...getRootProps()}>
      {filters.map((filter) => (
        <TableFilter
          key={filter.name}
          filter={filter}
          radioProps={getRadioProps({ value: filter.name })}
        />
      ))}
    </HStack>
  );
};
