import { Input, InputProps, useMergeRefs } from '@chakra-ui/react';
import { ChangeEvent, forwardRef, useMemo, useRef } from 'react';

import { Button, ButtonProps } from '../button';

export interface FileInputProps extends Omit<InputProps, 'onChange' | 'value'> {
  children?: React.ReactNode;
  onChange?: (files: FileList | null) => void;
  value?: File | string;
  buttonProps?: ButtonProps;
  noButton?: boolean;
}

export const FileInput = forwardRef<HTMLInputElement, FileInputProps>(
  function FileInput(
    { children, value, onChange, buttonProps, noButton, ...rest },
    ref
  ) {
    const internalRef = useRef<HTMLInputElement>(null);
    const refs = useMergeRefs(internalRef, ref);

    const onInputChange = (evt: ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(evt.target.files);
      // To be able to re-upload the same file
      evt.target.value = '';
    };

    const button = useMemo(() => {
      if (noButton) {
        return children;
      }
      if (buttonProps) {
        return (
          <Button
            type="button"
            {...buttonProps}
            onClick={() => internalRef.current?.click()}
          >
            {children}
          </Button>
        );
      }
      return (
        <button type="button" onClick={() => internalRef.current?.click()}>
          {children}
        </button>
      );
    }, [buttonProps, children, noButton]);

    return (
      <>
        <div style={{ display: 'none' }}>
          <Input
            ref={refs}
            type="file"
            data-testid="file-input"
            onChange={onInputChange}
            {...rest}
          />
        </div>
        {button}
      </>
    );
  }
);
