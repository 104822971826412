import { keyframes } from '@emotion/react';
import { motion } from 'framer-motion';

import { useTransparentize } from '../../hooks/use-transparentize';
import { IconProps } from '../icon/icon';
import { Box } from '../layout/box';
import { Center } from '../layout/center';

const animationKeyframesOuterBox = keyframes`
  0% { width: 0px; height: 0px; opacity: 1; }
  25% { width: 72px; height: 72px; opacity: 1; }
  50% { width: 120px; height: 120px; opacity: 1; }
  75% { width: 120px; height: 120px; opacity: 0; }
  100% { width: 0px; height: 0px; opacity: 0; }
`;
const animationKeyframesInnerBox = keyframes`
  0% { width: 0px; height: 0px; opacity: 1; }
  25% { width: 0px; height: 0px; opacity: 1; }
  45% { width: 72px; height: 72px; opacity: 1; }
  50% { width: 72px; height: 72px; opacity: 1; }
  75% { width: 120px; height: 120px; opacity: 1; }
  95% { width: 120px; height: 120px; opacity: 0; }
  100% { width: 0px; height: 0px; opacity: 0; }
`;

const animationOuterBox = `${animationKeyframesOuterBox} 2.5s ease-in-out infinite`;
const animationInnerBox = `${animationKeyframesInnerBox} 2.5s ease-in-out infinite`;

type PulsingIconProps = {
  Icon: React.FC<IconProps>;
};

export const PulsingIcon = ({ Icon }: PulsingIconProps) => {
  const primaryTransparent12 = useTransparentize('primary.500', 0.12);
  const primaryTransparent20 = useTransparentize('primary.500', 0.2);

  return (
    <>
      <Box
        as={motion.div}
        borderRadius={8}
        bg={primaryTransparent12}
        animation={animationOuterBox}
        sx={{ animationDelay: '1.25s' }}
      />
      <Box
        as={motion.div}
        position="absolute"
        zIndex={1}
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        borderRadius={8}
        bg={primaryTransparent20}
        animation={animationInnerBox}
        sx={{ animationDelay: '1.25s' }}
      />
      <Center
        w="40px"
        h="40px"
        bg="primary.500"
        position="absolute"
        zIndex={2}
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        borderRadius={8}
      >
        <Icon color="white" />
      </Center>
    </>
  );
};
