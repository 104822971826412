import { useMergeRefs } from '@chakra-ui/react';
import { ForwardedRef, forwardRef } from 'react';
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  NumberInput,
  NumberInputProps,
} from '../../..';
type FieldNumberInputProps = NumberInputProps & {
  label?: string;
  info?: React.ReactNode;
};

const FieldNumberInputInner = <
  FormFieldValues extends FieldValues = FieldValues,
  FieldName extends FieldPath<FormFieldValues> = FieldPath<FormFieldValues>
>(
  {
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
    label,
    info,
    ...rest
  }: UseControllerProps<FormFieldValues, FieldName> & FieldNumberInputProps,
  forwardedRef: ForwardedRef<HTMLInputElement>
) => {
  const {
    field: { ref: controllerRef, ...fieldRest },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
  });
  const refs = useMergeRefs(controllerRef, forwardedRef);

  return (
    <FormControl isInvalid={!!error}>
      {label && <FormLabel variant="simple">{label}</FormLabel>}
      <NumberInput ref={refs} isInvalid={!!error} {...fieldRest} {...rest} />
      {error?.message && <FormErrorMessage>{error.message}</FormErrorMessage>}
      {!!info && <FormHelperText>{info}</FormHelperText>}
    </FormControl>
  );
};

// Current limitations:
// - Does not automatically limit the input to 2 decimal places
export const FieldNumberInput = forwardRef(FieldNumberInputInner);
