import {
  Image as ChakraImage,
  ImageProps as ChakraImageProps,
} from '@chakra-ui/react';

export type { ImgProps, UseImageProps, UseImageReturn } from '@chakra-ui/react';
export { Img, useImage } from '@chakra-ui/react';

export type ImageProps = Omit<ChakraImageProps, 'src'> & {
  src: string | null | undefined;
};

export const Image = (props: ImageProps) => {
  return <ChakraImage {...props} src={props.src || undefined} />;
};
