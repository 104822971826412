import { BoxProps, chakra, ChakraStyledOptions, List } from '@chakra-ui/react';
import { isPresent } from '@collective/utils/helpers';
import {
  useSelect,
  UseSelectProps,
  UseSelectReturnValue,
  UseSelectStateChange,
} from 'downshift';
import { isArray } from 'lodash';
import { forwardRef, useCallback, useEffect, useMemo, useRef } from 'react';

import { IconSelector } from '../../icon/icon';
import { BorderBox, Box } from '../../layout';
import { Text } from '../../typography';
import { SelectOption } from './select-option';

const defaultItemToString = (item: SelectItem | null) => {
  if (typeof item === 'object' && item?.label) {
    return item.label;
  }
  return String(item);
};

export type SelectItem =
  | string
  | number
  | { label?: string; [key: string]: unknown };

export type SelectMethods<T extends SelectItem> = UseSelectReturnValue<T> & {
  closeMenuAndFocus: VoidFunction;
};

export type SelectChildrenProps<T extends SelectItem> = {
  items: T[];
  selectMethods: SelectMethods<T>;
  optionProps: {
    selectedItem: T | null;
    highlightedIndex: number;
    getItemProps: UseSelectReturnValue<T>['getItemProps'];
  };
};

export type AdditionalUseSelectProps<T extends SelectItem> = Omit<
  UseSelectProps<T>,
  'items'
>;

// This exported type is needed for FieldSelectWithSearch as typescript seems to have trouble
// with typing it correctly while omitting the children from it.
export type CommonSelectProps<T extends SelectItem> = ChakraStyledOptions & {
  items?: T[];
  value?: T | null;
  placeholder?: string;
  isInvalid?: boolean;
  onChange?: (selectedItem: T) => void;
  // Define how an item is represented as a string.
  // This is used to select an option by typing its first letters.
  itemToString?: (item: T | null) => string;
  // Define how an item is displayed on the button when selected
  // defaults to itemToOption
  itemToLabel?: (item: T) => React.ReactNode;
  useSelectProps?: AdditionalUseSelectProps<T>;
  wrapperProps?: BoxProps;
  dropdownProps?: BoxProps;
  focusOnOpenRef?: React.RefObject<HTMLElement>;
  focusableHeaderRefs?:
    | React.RefObject<HTMLElement>
    | React.RefObject<HTMLElement>[];
  focusableFooterRefs?:
    | React.RefObject<HTMLElement>
    | React.RefObject<HTMLElement>[];
  selectorWidth?: string;
  dropdownWidth?: string;
  shouldShowDropdownScrollbar?: boolean;
  isReadOnly?: boolean;
  isDisabled?: boolean;
  maxHeight?: string | number;
  iconColor?: string;
  iconColorActive?: string;
  menuStyle?: BoxProps;
  dropdownPosition?: 'top' | 'bottom';
  defaultSelectedItemLabel?: string;
};

export type SelectProps<T extends SelectItem> = CommonSelectProps<T> & {
  children?: (props: SelectChildrenProps<T>) => React.ReactNode;
};

const SelectInner = <T extends SelectItem>(
  {
    items = [],
    value,
    placeholder,
    isInvalid,
    children,
    onChange,
    itemToString = defaultItemToString,
    itemToLabel = (item) => (
      <Text whiteSpace="nowrap">{itemToString(item)}</Text>
    ),
    useSelectProps,
    wrapperProps,
    dropdownProps,
    focusOnOpenRef,
    focusableHeaderRefs,
    focusableFooterRefs,
    inlineElements = false,
    isReadOnly = false,
    isDisabled = false,
    selectorWidth = '100%',
    dropdownWidth = '100%',
    shouldShowDropdownScrollbar = true,
    maxHeight = '300px',
    iconColor = 'rythm.600',
    iconColorActive = 'rythm.900',
    menuStyle,
    dropdownPosition,
    defaultSelectedItemLabel,
    ...rest
  }: SelectProps<T>,
  ref: React.ForwardedRef<HTMLButtonElement>
) => {
  const selectButtonRef = useRef<HTMLButtonElement>(null);
  const selectMenuRef = useRef<HTMLUListElement>(null);

  const {
    onSelectedItemChange: onSelectedItemChangeProps,
    ...restUseSelectProps
  } = useSelectProps || {};
  const onSelectedItemChange = useCallback(
    (changes: UseSelectStateChange<T>) => {
      if (changes.selectedItem) {
        onChange?.(changes.selectedItem);
        selectButtonRef?.current?.focus();
      }
      onSelectedItemChangeProps?.(changes);
    },
    [onChange, onSelectedItemChangeProps]
  );

  const useSelectReturnValue = useSelect({
    items,
    selectedItem: value,
    itemToString,
    onSelectedItemChange,
    ...restUseSelectProps,
  });
  const {
    isOpen,
    selectedItem,
    highlightedIndex,
    setHighlightedIndex,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
  } = useSelectReturnValue;

  const selectMethods: SelectMethods<T> = useMemo(
    () => ({
      ...useSelectReturnValue,
      closeMenuAndFocus: () => {
        useSelectReturnValue.closeMenu();
        selectButtonRef.current?.focus();
      },
    }),
    [useSelectReturnValue]
  );

  const optionProps = {
    selectedItem,
    highlightedIndex,
    getItemProps,
    inlineElements,
  };

  useEffect(() => {
    if (isOpen && focusOnOpenRef?.current) {
      focusOnOpenRef.current.focus();
      setHighlightedIndex(-1);
    }
  }, [focusOnOpenRef, isOpen, setHighlightedIndex]);

  const menuProps = getMenuProps({
    ref: selectMenuRef,
  });

  const headerRefs = useMemo(() => {
    if (isArray(focusableHeaderRefs)) {
      return focusableHeaderRefs;
    }
    return [focusableHeaderRefs].filter(isPresent);
  }, [focusableHeaderRefs]);

  const footerRefs = useMemo(() => {
    if (isArray(focusableFooterRefs)) {
      return focusableFooterRefs;
    }
    return [focusableFooterRefs].filter(isPresent);
  }, [focusableFooterRefs]);

  const focusableRefs = useMemo<React.RefObject<HTMLElement>[]>(
    () => [focusOnOpenRef].concat(headerRefs, footerRefs).filter(isPresent),
    [focusOnOpenRef, footerRefs, headerRefs]
  );

  const onBlurList = useCallback(
    (e: FocusEvent) => {
      // If the blur is the consequence of focusing one of the focusable elements added to the list or the list itself, we block it to avoid closing the select dropdown
      if (
        focusableRefs?.some(
          (ref) =>
            e.relatedTarget === ref?.current ||
            e.relatedTarget === selectMenuRef?.current
        )
      ) {
        return;
      }
      menuProps.onBlur(e);
    },
    [focusableRefs, menuProps]
  );

  /*
   * The following onKeyDown handler is there to extend downshift's useSelect capabilities, by handling the move of focus
   * with the down/up arrow or tab keys when header or footer focusable elements are added, while still merging that
   * behavior with the selection and highlighting of items.
   */
  const onKeyDownList = useCallback(
    (e: KeyboardEvent) => {
      const focusableHeaderRefs = headerRefs.filter((ref) => ref.current);
      const focusableFooterRefs = footerRefs.filter((ref) => ref.current);

      const focusedFooterRefIndex =
        focusableFooterRefs?.findIndex(
          (footerRef) => footerRef?.current === document.activeElement
        ) ?? -1;
      const focusedHeaderRefIndex =
        focusableHeaderRefs?.findIndex(
          (headerRef) => headerRef?.current === document.activeElement
        ) ?? -1;

      const hasFooterElementFocused = focusedFooterRefIndex >= 0;
      const hasHeaderElementFocused = focusedHeaderRefIndex >= 0;

      if (
        e.code === 'Enter' &&
        (hasFooterElementFocused || hasHeaderElementFocused)
      ) {
        // If Enter is pressed while one of the focusable elements is focused, we block it to avoid closing the select dropdown
        return;
      }
      if (e.code === 'ArrowDown') {
        const shouldStartFocusingFooterRefs =
          focusableFooterRefs.length &&
          (items.length === 0 || highlightedIndex === items.length - 1) &&
          !hasFooterElementFocused;

        if (shouldStartFocusingFooterRefs) {
          const firstFooterRef = focusableFooterRefs[0]?.current;
          firstFooterRef?.focus();
          // If the focus worked, then we stop highlighting the item on which we were
          if (firstFooterRef === document.activeElement) {
            setHighlightedIndex(-1);
            return;
          }
        } else if (hasFooterElementFocused) {
          const nextFooterRef =
            focusableFooterRefs?.[focusedFooterRefIndex + 1];
          nextFooterRef?.current?.focus();
          return;
        } else if (hasHeaderElementFocused) {
          const isNextElementAHeader =
            focusedHeaderRefIndex < focusableHeaderRefs.length - 1;

          if (isNextElementAHeader) {
            const nextHeaderRef =
              focusableHeaderRefs[focusedHeaderRefIndex + 1];
            nextHeaderRef?.current?.focus();
          } else if (items?.length > 0) {
            setHighlightedIndex(0);
            selectMenuRef?.current?.focus();
          }
          return;
        }
      }
      if (e.code === 'ArrowUp') {
        const shouldStartFocusingHeaderRefs =
          focusableHeaderRefs.length &&
          (items.length === 0 || highlightedIndex === 0) &&
          !hasHeaderElementFocused;
        if (shouldStartFocusingHeaderRefs) {
          const lastHeaderRef =
            focusableHeaderRefs[focusableHeaderRefs.length - 1].current;
          lastHeaderRef?.focus();
          // If the focus worked, then we stop highlighting the item on which we were
          if (lastHeaderRef === document.activeElement) {
            setHighlightedIndex(-1);
            return;
          }
        } else if (hasHeaderElementFocused) {
          const nextHeaderRef =
            focusableHeaderRefs?.[focusedHeaderRefIndex - 1];
          nextHeaderRef?.current?.focus();
          return;
        } else if (hasFooterElementFocused) {
          const isNextElementAFooter = focusedFooterRefIndex > 0;

          if (isNextElementAFooter) {
            const nextFooterRef =
              focusableFooterRefs?.[focusedFooterRefIndex - 1];
            nextFooterRef?.current?.focus();
          } else if (items?.length > 0) {
            setHighlightedIndex(items.length - 1);
            selectMenuRef?.current?.focus();
          }
          return;
        }
      }
      menuProps.onKeyDown(e);
    },
    [
      footerRefs,
      headerRefs,
      highlightedIndex,
      items.length,
      menuProps,
      setHighlightedIndex,
    ]
  );

  const selectedItemLabel = selectedItem
    ? itemToLabel(selectedItem)
    : defaultSelectedItemLabel || (
        <Text {...(!isReadOnly && { color: 'rythm.600' })}>{placeholder}</Text>
      );

  return (
    <Box position="relative" w="100%" {...wrapperProps}>
      <chakra.button
        ref={ref}
        data-testid="select-button"
        type="button"
        layerStyle={
          isDisabled
            ? 'disabledSelectButton'
            : isReadOnly
            ? 'readOnlySelectButton'
            : 'selectButton'
        }
        width={selectorWidth}
        sx={{
          '&:focus, &[aria-expanded="true"]': {
            borderColor: 'primary.600',
            'svg.non-legacy-icon path': {
              stroke: iconColorActive,
              transition: '.1s ease-in-out stroke',
            },
            'svg.legacy-icon path': {
              fill: iconColorActive,
              transition: '.1s ease-in-out fill',
            },
          },
          '&:hover svg.non-legacy-icon path': {
            stroke: iconColorActive,
            transition: '.1s ease-in-out stroke',
          },
          '&:hover svg.legacy-icon path': {
            fill: iconColorActive,
            transition: '.1s ease-in-out fill',
          },
        }}
        {...(isInvalid && { borderColor: 'critical.500' })}
        {...rest}
        {...getToggleButtonProps({ ref: selectButtonRef })}
        disabled={isReadOnly || isDisabled}
      >
        <Box
          flex={1}
          textAlign="start"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          {...(inlineElements ? { display: 'flex', marginLeft: '-4px' } : {})}
        >
          {selectedItemLabel}
        </Box>
        <IconSelector color={iconColor} opacity={isReadOnly ? 0.5 : 1} />
      </chakra.button>
      <List
        {...menuProps}
        {...menuStyle}
        onBlur={onBlurList}
        onKeyDown={onKeyDownList}
      >
        <BorderBox
          position="absolute"
          width={dropdownWidth}
          boxShadow="md"
          zIndex={10}
          my={1}
          display={isOpen ? 'block' : 'none'}
          _hover={{}}
          bottom={dropdownPosition === 'top' ? '100%' : undefined}
          {...dropdownProps}
        >
          {/* This box is to contain and display a scrollbar in a decent manner */}
          <Box
            {...(shouldShowDropdownScrollbar && {
              overflowY: 'auto',
              maxHeight,
            })}
            mr="-1px"
            pr="1px"
            py={2}
          >
            {isOpen &&
              (children
                ? children?.({ items, selectMethods, optionProps })
                : items.map((item, index) => (
                    <SelectOption
                      key={
                        typeof item === 'string' || typeof item === 'number'
                          ? item
                          : `${itemToString(item)}${index}`
                      }
                      item={item}
                      index={index}
                      {...optionProps}
                    >
                      {itemToString(item)}
                    </SelectOption>
                  )))}
          </Box>
        </BorderBox>
      </List>
    </Box>
  );
};

export const Select = forwardRef(SelectInner);
