import { PAGE_KEYDOWN_EVENT, PageTrackedKeys } from '@collective/utils/helpers';
import { useEffect } from 'react';

// Propagate keyboard events to parent in order to handle them in application
export const useKeyboardEventPropagate = () => {
  useEffect(() => {
    const propagateEvent = (e: KeyboardEvent) => {
      if (e.code in PageTrackedKeys) {
        const message = {
          type: PAGE_KEYDOWN_EVENT,
          payload: e.code,
        };
        window.parent.postMessage(message, '*');
      }
    };

    document.addEventListener('keydown', propagateEvent);
    return () => {
      document.removeEventListener('keydown', propagateEvent);
    };
  }, []);
};
