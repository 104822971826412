import { TextInput, TextInputProps } from '../../inputs/text-input';
import { withField } from '../with-field';

export const FieldTextInputLegacy = withField<TextInputProps>(TextInput);
export const FieldTextInputWithErrorLegacy = withField<TextInputProps>(
  TextInput,
  {
    hasFieldDisplayErrorMessage: true,
  }
);
