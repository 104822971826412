const Drawer = {
  variants: {
    'invoice-side-panel': {
      overlay: {
        // Without overlay, the closing transition doesn't work
        // So we just hide the DrawerOverlay through a negative z-index
        'z-index': -1000,
        bgColor: 'transparent',
      },
      dialog: {
        boxShadow: 'none',
        borderLeft: '1px solid',
        borderColor: 'gray.200',
        mt: '64px',
        maxW: '380px',
      },
      closeButton: {
        position: 'absolute',
        top: '-42px',
        right: '0',
      },
      header: {
        p: 0,
      },
      body: {
        p: '0 0 80px 0',
      },
      dialogContainer: {
        // In order for the remaining content of the page to be clickable
        w: 'unset',
      },
    },
  },
};

export default Drawer;
