import InputMask, { BeforeMaskedStateChangeStates } from 'react-input-mask';

import { TextInput, TextInputProps } from './text-input';

export type IbanInputProps = TextInputProps;

export const IbanInput = ({
  onChange,
  onBlur,
  onFocus,
  value,
  ...rest
}: IbanInputProps) => {
  const beforeMaskedStateChange = ({
    nextState,
  }: BeforeMaskedStateChangeStates) => {
    return { ...nextState, value: nextState.value.toUpperCase() };
  };
  return (
    <InputMask
      mask="aa** **** **** **** **** **** **** ****"
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      maskPlaceholder={null}
      beforeMaskedStateChange={beforeMaskedStateChange}
      value={value}
    >
      <TextInput {...rest} />
    </InputMask>
  );
};
