import { Input, InputProps } from '@chakra-ui/react';
import { ForwardedRef, forwardRef } from 'react';

import { Box, BoxProps } from '../layout';

type InlineEditVariant = 'regular' | 'big';

export type InlineEditProps = Omit<InputProps, 'variant'> & {
  variant?: InlineEditVariant;
  containerProps?: BoxProps;
};

const fontPropsMap: Record<InlineEditVariant, InputProps> = {
  regular: {
    fontWeight: 400,
    fontSize: '0.875rem', // 14px
    lineHeight: '1.25rem', // 20px
  },
  big: {
    fontWeight: 600,
    fontSize: '1.125rem', // 18px
    lineHeight: '1.75rem', // 28px
  },
};

const InlineEditInner = (
  {
    variant = 'regular',
    containerProps,
    value,
    placeholder,
    onChange,
    isInvalid,
    isDisabled,
    isReadOnly,
    autoComplete,
    ...rest
  }: InlineEditProps,
  forwardedRef: ForwardedRef<HTMLInputElement>
) => {
  const fontProps = fontPropsMap[variant];

  return (
    <Box as="span" position="relative" {...containerProps}>
      {/* hidden text for input autosize */}
      <Box as="span" visibility="hidden" whiteSpace="pre" {...fontProps}>
        {value || placeholder}
      </Box>

      <Input
        ref={forwardedRef}
        value={value}
        placeholder={placeholder}
        isInvalid={isInvalid}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        onChange={onChange}
        position="absolute"
        inset="0 auto 0 0"
        w="100%"
        h="auto"
        border="none"
        boxShadow="none"
        borderRadius={0}
        padding={0}
        textDecoration="underline dashed"
        textDecorationColor="transparent"
        textUnderlineOffset="4px"
        transitionProperty="color, text-decoration-color"
        _focusVisible={{}}
        _placeholder={{
          color: 'rythm.600',
          transition: 'color .2s',
          paddingBottom: '2px',
        }}
        // Styles for when placeholder is visible
        {...(!value && {
          textDecorationColor: 'rythm.300',
          _hover: {
            color: 'rythm.700',
            textDecorationColor: 'rythm.600',
            _placeholder: { color: 'rythm.700' },
          },
        })}
        {...(value && {
          textDecorationColor: 'transparent',
          _hover: {
            textDecorationColor: 'rythm.600',
          },
        })}
        _focus={{
          textDecorationColor: 'primary.600',
        }}
        _disabled={{
          color: 'rythm.600',
          opacity: 0.5,
          _hover: {},
          textDecorationColor: value ? 'transparent' : 'rythm.300',
        }}
        _invalid={{ textDecorationColor: 'critical.500', _hover: {} }}
        // This allows the input to look very much like the equivalent Text/Heading
        {...(isReadOnly && {
          _hover: {},
          _focus: {},
          sx: {
            '&&': {
              cursor: 'text',
              background: 'unset',
              textDecoration: 'none',
              textDecorationColor: 'transparent',
            },
          },
        })}
        autoComplete={autoComplete}
        data-form-type={autoComplete === 'off' ? 'other' : undefined}
        data-1p-ignore={autoComplete === 'off' ? 'true' : undefined}
        {...fontProps}
        {...rest}
      />
    </Box>
  );
};

export const InlineEdit = forwardRef(InlineEditInner);
