import { useTheme } from '@chakra-ui/react';
import { TinyColor } from '@ctrl/tinycolor';
import { get } from 'lodash';

export type Theme = { [key: string]: string };

export function useTransparentize(color: string, opacity: number) {
  const theme = useTheme();

  return transparentize(color, opacity)(theme.colors);
}

export function transparentize(color: string, opacity: number) {
  return (theme: Theme) => {
    const raw = get(theme, color);
    return new TinyColor(raw).setAlpha(opacity).toRgbString();
  };
}
