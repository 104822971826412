import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum';

import {
  DATADOG_CLIENT_TOKEN,
  DATADOG_SITE,
  ENV,
  RUM_REPLAY_SAMPLE_RATE,
  RUM_SAMPLE_RATE,
  VERSION,
} from '../env';
import { getLogger } from '../logger/main';

const SAMPLE_RATE = parseInt(RUM_SAMPLE_RATE || '0');
const REPLAY_SAMPLE_RATE = parseInt(RUM_REPLAY_SAMPLE_RATE || '0');

const defaultRumConfig: Omit<RumInitConfiguration, 'applicationId'> = {
  clientToken: DATADOG_CLIENT_TOKEN,
  site: DATADOG_SITE,
  env: ENV,
  version: VERSION,
  silentMultipleInit: true,
  // For more information on sampling rates
  // https://docs.datadoghq.com/real_user_monitoring/browser/#browser-and-session-replay-sampling-configuration
  sessionSampleRate: SAMPLE_RATE,
  // this is the sampling rate of session replays
  sessionReplaySampleRate: REPLAY_SAMPLE_RATE,
  trackUserInteractions: true,
  trackFrustrations: true,
  // Display every input except type email, password and tel (+ some autocomplete inputs)
  // More informations : https://docs.datadoghq.com/real_user_monitoring/session_replay/privacy_options/#allow-mode
  defaultPrivacyLevel: 'allow',
  enableExperimentalFeatures: ['clickmap'],
  trackResources: true,
  trackLongTasks: true,
};

export function initDatadogUserMonitoring(
  config: Omit<RumInitConfiguration, 'clientToken'>
) {
  const mergedConfig = {
    ...defaultRumConfig,
    ...config,
  };
  if (!mergedConfig.clientToken) {
    getLogger().warn(
      'Real user monitoring failed to initialise, missing "clientToken"' +
        `in config ${JSON.stringify(mergedConfig)}`
    );
    return;
  }

  datadogRum.init(mergedConfig);

  getLogger().info(
    `Real user monitoring initialised with config ${JSON.stringify(
      mergedConfig
    )}`
  );
}

export function getDatadogRumSessionId(): string | undefined {
  return datadogRum.getInternalContext()?.session_id;
}
