import { Readable, Writable } from 'stream';

export const convertStreamToBuffer = async (
  stream: Writable | Readable
): Promise<Buffer> => {
  return new Promise((resolve, reject) => {
    const buffers: Uint8Array[] = [];
    stream.on('error', reject);
    stream.on('data', (data) => buffers.push(data));
    stream.on('end', () => resolve(Buffer.concat(buffers)));
  });
};

export const convertStreamToUint8Array = async (
  stream: Writable | Readable
): Promise<Uint8Array> => {
  return new Promise((resolve, reject) => {
    const buffers: Uint8Array[] = [];
    stream.on('error', reject);
    stream.on('data', (data) => buffers.push(data));
    stream.on('end', () => {
      const totalLength = buffers.reduce((sum, buf) => sum + buf.length, 0);
      const result = new Uint8Array(totalLength);
      let offset = 0;
      for (const buf of buffers) {
        result.set(buf, offset);
        offset += buf.length;
      }
      resolve(result);
    });
  });
};

export const convertStreamToBase64 = async (
  stream: Writable
): Promise<string> => {
  const buffer = await convertStreamToBuffer(stream);
  return buffer.toString('base64');
};

export const convertBufferToBase64 = (data: Buffer): string => {
  return btoa(
    new Uint8Array(data).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      ''
    )
  );
};
