import { InputProps } from '@chakra-ui/react';
import { InMemorySearch, SearchOptions } from '@collective/utils/helpers';
import { UseSelectStateChange } from 'downshift';
import { isArray } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';

import { IconSearch } from '../../icon/icon';
import { Box } from '../../layout';
import {
  CommonSelectProps,
  Select,
  SelectChildrenProps,
  SelectItem,
  SelectMethods,
} from '../select/select';
import { TextInput } from '../text-input';

type SelectWithSearchChildrenProps<T extends SelectItem> =
  SelectChildrenProps<T> & {
    search: string;
    setSearch: React.Dispatch<React.SetStateAction<string>>;
  };

type TextSearchOnEnterProps<T extends SelectItem> = {
  search: string | undefined;
  selectMethods: SelectMethods<T>;
};

export type SelectWithSearchProps<T extends SelectItem> =
  CommonSelectProps<T> & {
    stickySearchInput?: boolean;
    searchBarPlaceholder?: string;
    searchOptions?: SearchOptions;
    searchInputProps?: InputProps;
    children?: (props: SelectWithSearchChildrenProps<T>) => React.ReactNode;
    textSearchOnEnter?: (props: TextSearchOnEnterProps<T>) => void;
  };

export const SelectWithSearch = <T extends SelectItem>({
  items,
  stickySearchInput = false,
  searchBarPlaceholder,
  searchOptions,
  searchInputProps,
  useSelectProps,
  focusableHeaderRefs,
  children,
  dropdownWidth,
  textSearchOnEnter,
  ...rest
}: SelectWithSearchProps<T>) => {
  const [shownItems, setShownItems] = useState(items || []);
  const [search, setSearch] = useState('');
  const searchInputRef = useRef<HTMLInputElement>(null);

  const inMemorySearch = useMemo(() => {
    return new InMemorySearch(items || [], searchOptions);
  }, [items, searchOptions]);

  useEffect(() => {
    if (!search) {
      setShownItems(items || []);
    } else {
      const searchResult = inMemorySearch.query(search);
      setShownItems(searchResult);
    }
  }, [inMemorySearch, items, search]);

  const useSelectPropsWithSearch = useMemo(
    () => ({
      onIsOpenChange: ({ isOpen }: UseSelectStateChange<T>) => {
        if (!isOpen) {
          setSearch('');
        }
      },
      ...useSelectProps,
    }),
    [useSelectProps]
  );

  const focusableHeaderRefsWithSearch = useMemo(() => {
    let totalRefs: React.RefObject<HTMLElement>[] = [searchInputRef];

    if (isArray(focusableHeaderRefs)) {
      totalRefs = totalRefs.concat(focusableHeaderRefs);
    } else if (focusableHeaderRefs) {
      totalRefs.push(focusableHeaderRefs);
    }
    return totalRefs;
  }, [focusableHeaderRefs]);

  return (
    <Select<T>
      items={shownItems}
      focusOnOpenRef={searchInputRef}
      useSelectProps={useSelectPropsWithSearch}
      focusableHeaderRefs={focusableHeaderRefsWithSearch}
      dropdownWidth={dropdownWidth}
      shouldShowDropdownScrollbar={!stickySearchInput}
      {...rest}
    >
      {({ items, selectMethods, optionProps }) => (
        <>
          <Box
            px={4}
            py={2}
            onMouseOver={() => selectMethods.setHighlightedIndex(-1)}
            {...(stickySearchInput
              ? {
                  position: 'fixed',
                  backgroundColor: 'white',
                  width: `calc(${dropdownWidth} - 3px)`, // with the fixed position the elements overflows it's parent and thus breaks the wanted design, the -3px fixes that
                }
              : { width: dropdownWidth })}
          >
            <TextInput
              ref={searchInputRef}
              leftElement={<IconSearch color="rythm.600" />}
              value={search}
              placeholder={searchBarPlaceholder}
              onKeyDown={(e) => {
                // Prevents closing the select on inputting space/enter
                if (e.code === 'Space' || e.code === 'Enter') {
                  e.stopPropagation();
                }

                if (e.code === 'Enter' && textSearchOnEnter) {
                  textSearchOnEnter({
                    selectMethods,
                    search: searchInputRef?.current?.value,
                  });
                }
              }}
              onChange={(e) => {
                setSearch(e.target.value);
                selectMethods.setHighlightedIndex(-1);
              }}
              data-testid="select-search-input"
              {...searchInputProps}
            />
          </Box>
          {stickySearchInput ? (
            <Box marginTop="48px">
              {children?.({
                items,
                selectMethods,
                optionProps,
                search,
                setSearch,
              })}
            </Box>
          ) : (
            children?.({ items, selectMethods, optionProps, search, setSearch })
          )}
        </>
      )}
    </Select>
  );
};
