import { keyframes } from '@emotion/react';
import { motion } from 'framer-motion';

import { IconCheck, IconCircle } from '../icon/icon';
import { Box, BoxProps } from '../layout/box';
import { Flex } from '../layout/flex';
import { Text } from '../typography/text';

const animationKeyframesIncomingMessage = keyframes`
  0% { top:4px; opacity: 0;color: var(--chakra-colors-rythm-600); }
  30% { top:0px; opacity: 1;color: var(--chakra-colors-rythm-600); }
  60% { top:0px; opacity: 1;color: var(--chakra-colors-rythm-600); }
  100% { top:0px; opacity: 1;color: var(--chakra-colors-rythm-900); }
`;
// 37% of 4s ~= 60% of 2.5s and 63% of 4s ~= 100% of 2.5s
// -> The icon color (below) changes at the same time as the text (above)
const animationKeyframesCircle = keyframes`
  0% { color: var(--chakra-colors-rythm-600);opacity: 1 }
  37% { color: var(--chakra-colors-rythm-600);opacity: 1 }
  63% { color: var(--chakra-colors-primary-600);opacity: 1 }
  80% { color: var(--chakra-colors-primary-600);opacity: 1 }
  100% { color: var(--chakra-colors-primary-600);opacity: 0 }
`;
const animationKeyframesCheck = keyframes`
  0% { opacity: 0; }
  80% { opacity: 0; }
  100% { opacity: 1 }
`;

const animationIncomingMessage = `${animationKeyframesIncomingMessage} 2.5s ease-in-out`;
const animationCircle = `${animationKeyframesCircle} 4s ease-in-out`;
const animationCheck = `${animationKeyframesCheck} 4s ease-in-out`;

// Delay as seconds
type IncomingTextProps = { delay: number; text: string } & BoxProps;

export const IncomingText = ({ delay, text, ...rest }: IncomingTextProps) => {
  return (
    <Flex
      as={motion.div}
      position="relative"
      animation={animationIncomingMessage}
      sx={{ animationDelay: `${delay}s`, animationFillMode: 'forwards' }}
      alignItems="center"
      pl="25%"
      top="-4px"
      opacity={0}
      mb={4}
      gap={3}
      {...rest}
    >
      <Box w="24px" h="24px" position="relative">
        <Box
          color="rythm.600"
          animation={animationCircle}
          sx={{ animationDelay: `${delay}s`, animationFillMode: 'forwards' }}
        >
          <IconCircle />
        </Box>
        <Box
          color="primary.600"
          position="absolute"
          inset={0}
          opacity={0}
          zIndex={1}
          animation={animationCheck}
          sx={{ animationDelay: `${delay}s`, animationFillMode: 'forwards' }}
        >
          <IconCheck />
        </Box>
      </Box>
      <Text variant="desktop-m-bold" color="inherit">
        {text}
      </Text>
    </Flex>
  );
};
